import axios from "axios";
axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;

const url = process.env.REACT_APP_API

export const  appInfo = () => {
    return axios.get(url).then(res=>res.data)
}

export const  createAppInfo = (data) => {
    return axios.post(url, data).then(res=>res.data)
}