import React from 'react';
import styles from './YoutubeOverViewPage.module.css';
import Card from '@mui/material/Card';
import { useConfig } from './hooks/useConfig';
import OverviewCustom from 'components/OverviewCustom/OverviewCustom';

const YoutubeOverViewPage = () => {

  const { componentsItem } = useConfig();
  
  return (
    <Card className={styles.card}>
      <OverviewCustom
        columns={'1fr 1.8fr 1fr'}
        componentsItem={componentsItem}
      />
    </Card>
  );
};

export default YoutubeOverViewPage;