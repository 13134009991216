import { useState, useEffect } from 'react';

export default function useAPIFetchTemplate (fetchPromise, errorMessage) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetchPromise.then((response) => {
      setData(response);
    }).catch((error) => {
      console.error("error: ", error);
      setError(errorMessage);
    }).finally(() => {
      setLoading(false);
    });
  }, [])

  return {
    data,
    loading,
    error
  }
}

