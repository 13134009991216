import { Box, Button, Typography } from "@mui/material";

export default function SectionTemplate({  description, actions = [], children, ...props }) {
  const { sx, ...restProps } = props;

  const stylesHeader = {
    mb: 2, 
    width:'100%',
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap'
  }

  const stylesContentButtons = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: '0.5rem'
  }

  return (
    <Box
      component="section"
      sx={{
        width:'100%',
        ...sx
      }}
      {...restProps}
    >
      <Box sx={stylesHeader} >
        <Typography variant="body2" gutterBottom flex={1}>
          {description}
        </Typography>
        {actions && actions.length > 0 && 
        <Box sx={stylesContentButtons}>
          {actions.map((action) => (
            <Button
              key={action.name}
              color="info"
              variant="outlined"
              size="small"
              onClick={() => action.action()}
              disabled={action.disabled}
              fullWidth
            >
              <Typography fontWeight="bold">
                {action.name}
              </Typography>
            </Button>
          ))}
        </Box>
        }
      </Box>
      <Box sx={{width: "100%"}}>
        {children}
      </Box>
    </Box>
  );
}