import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Check as CheckIcon } from '@mui/icons-material';
import FormLabel from '@mui/material/FormLabel';

import PropTypes from 'prop-types';

const topBlockchains = [
  { label: 'ETHEREUM', value: 'ETHEREUM'},
  { label: 'POLYGON', value: 'POLYGON'},
  { label: 'AVALANCHE', value: 'AVALANCHE'},
];


const AutoCompleteBlockchain = ({ name, form, setForm, isSubmitting, setFieldValue, options, disabledYup }) => {

  const [ value, setValue] = useState([]);

  const handleAddBlockchainChange = (newValue) => {
    if(!isSubmitting){
      const uniqueValues = Array.from(new Set(newValue.map(v => v.value)))
            .map(value => newValue.find(v => v.value === value));
      setValue(uniqueValues);
      setForm((el)=>{
        return {
          ...el,
          [name]:uniqueValues
        }
      })
      if(!disabledYup || setFieldValue){
        setFieldValue(name, uniqueValues, true);
      }
    }
  };

  const handleRemoveBlockchainChange = (removedItem) => {
    if (!isSubmitting || setFieldValue) {
      const filteredValues =  value.filter((item) => item.value !== removedItem.value);
      setValue(filteredValues);
      setForm((el) => {
          return {
              ...el,
              [name]: filteredValues
          };
      });
      if(!disabledYup){
        setFieldValue(name, filteredValues, true)
      }
    }
  };

  const handleSelected = (optionSelected,re) => () => {
    if (!isSubmitting) {
      const { value } = re;
      const findOption = value.find((item) => item.value === optionSelected.value);
      if (findOption) {
        handleRemoveBlockchainChange(findOption);
      }else{
        handleAddBlockchainChange([... value, optionSelected]);
      }
    }
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  useEffect(() => {
    if(form && name && form[name]){
      setValue(form[name]);
    }
  },[form]);


  return (
    <>
      <FormLabel htmlFor={name}>{name && capitalizeFirstLetter(name)}</FormLabel>
      <Autocomplete
        id="customized-hook-demo"
        options={options}
        getOptionLabel={(option) => option?.label}
        // getOptionSelected={(option, value) => option.value === value.value}
        multiple={true}
        value={ value}
        freeSolo={false}
        onChange={(event, newValue) => {
          const addedItem = newValue.find(v => ! value.includes(v));
          const removedItem =  value.find(v => !newValue.includes(v));
          if (addedItem) {
              handleAddBlockchainChange([... value, addedItem]);
          } else if (removedItem) {
              handleRemoveBlockchainChange(removedItem);
          }
        }}
        renderInput={(params) => (
          <TextField {...params}
            name={name}
            variant="outlined"
            label=""
            placeholder={name && capitalizeFirstLetter(name)}
            disabled={isSubmitting}
          />
        )}
        renderOption={(props, option, { selected}, re) => {
          if(isSubmitting){
            return null;
          }
          return <li {...props} onClick={handleSelected(option,re)}>
            <span>{option?.label}</span>
             {selected ||  value?.some((item) => item.value === option.value) ? <CheckIcon fontSize="small" /> : null}
          </li>
        }}
      />
    </>
  );
}

AutoCompleteBlockchain.defaultProps = {
  name: 'blockchain',
  form: {},
  setForm: () => {},
  isSubmitting: false,
  options:topBlockchains,
  disabledYup: false
}

AutoCompleteBlockchain.propTypes = {
  name: PropTypes.string,
  form: PropTypes.object,
  setForm: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func,
  options: PropTypes.array,
  disabledYup: PropTypes.bool
}

export default AutoCompleteBlockchain;
