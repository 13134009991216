import { useTranslation } from 'react-i18next';
import TableMessage from 'components/EmptyTableMessage';
import CreateMessageButton from 'components/Buttons/CreateMessageButton';
import DynamicTable from 'components/DynamicTable/DynamicTable';

export default function MessagesTable() {
  const { t } = useTranslation("messages");

  return (
    <div data-testid="messages-table">
      <DynamicTable
        title={t("table.title")}
        data={[]}
        loading={false}
        activeSearchBar={true}
        activeModeView={false}
        setConfigParams={{
          emptyMessage: (
            <TableMessage
              message={t("table.messages.empty")}
              actionButton={<CreateMessageButton />}
            />
          )
        }}
      />
    </div>
  );
}
