export const BUTTONS_BY_ROLE = {
  COLLECTION_MANAGER:{
    editCollection: true,
    editNFTs: true,
    deleteCollection: true,
    hiddenCollection: true,
    lock: true,
  },
  MANAGER:{
    editCollection: true,
    approveCollection: true,
    rejectedCollection: true,
    approvedNFTs: true,
    rejectedNFTs: true,
    editNFTs: true,
    approveCampaign: true,
    rejectedCampaign: true,
    editCampaign: true,
    rejectedBenefits: true,
    approveBenefits: true,
    editBenefits: true,
    deleteCollection: true,
    hiddenCollection: true,
    lock: true,
  },
  SUPER_ADMIN:{
    editCollection: true,
    approveCollection: true,
    rejectedCollection: true,
    approvedNFTs: true,
    rejectedNFTs: true,
    editNFTs: true,
    approveCampaign: true,
    rejectedCampaign: true,
    editCampaign: true,
    rejectedBenefits: true,
    approveBenefits: true,
    editBenefits: true,
    deleteCollection: true,
    hiddenCollection: true,
    lock: true,
  }
};

export const ROLES = {
  COLLECTION_MANAGER: { min: 0, max: 10 },
  MANAGER: { min: 11, max: 30 },
  SUPER_ADMIN: { min: 0, max: 100 }
};

export const ROLES_CODES = {
  COLLECTION_MANAGER: 10,
  MANAGER: 30,
  SUPER_ADMIN: 100
};

export const getActionsByRoleCode = (roleCode, action) => {
  const findRoleByCode = (code) => Object.entries(ROLES).find(([, role]) => {
    return code >= role.min && code <= role.max;
  });

  const [roleName, role] = findRoleByCode(roleCode) || [];
  if (!roleName) {
    console.warn(`Role code ${roleCode} is not recognized.`+ role);
    return false;
  }

  const options = BUTTONS_BY_ROLE[roleName];
  return (options && options[action]) || false;
};
