import React, {useEffect, useState} from 'react';
import styles from './viewProjects.module.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { findAllByDomain } from 'services/projects/project';
import ColorsInfo from 'components/ColorsInfo/ColorsInfo';
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import { BreadCrumbsSaveData } from 'components/BreadCrumbs/BreadCrumbs';
import DynamicTable from 'components/DynamicTable/DynamicTable';

const ViewProjects = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("projects");
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ itemStatus, setItemStatus ] = useState([
    {
      label: t("status_info.inactive"),
      color: '#808080 '
    },
    {
      label: t("status_info.waiting"),
      color: '#ed6c02'
    },
    {
      label: t("status_info.approved"),
      color: '#28a745'
    },
    {
      label: t("status_info.rejected"),
      color: '#dc3545'
    }
  ]);
  const COLORS ={
    0: {
      label: t("status_project.inactive"),
      color: '#808080 '
    },
    1: {
      label: t("status_project.waiting"),
      color: '#ed6c02'
    },
    2: {
      label: t("status_project.approved"),
      color: '#28a745'
    },
    3: {
      label: t("status_project.rejected"),
      color: '#dc3545'
    }
  }
  
  const configCells = {
    blockchains: {
      render:(item) => {
        return (
          <TableCell>
            <Box
             sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              gap:'0.7rem',
             }}
            >
              {
                item?.map((blockchain) => {
                  return (
                    <CardMedia
                      component="img"
                      key={blockchain?.chainid}
                      sx={{ 
                        width: 30, 
                        height: 30,
                        objectFit: 'contain'
                      }}
                      src={blockchain?.icon}
                    />
                  )
                })
              }
            </Box>
          </TableCell>
        )
      }
    },
    status:{
      render: (item)=>{
        return (
          <TableCell>
            <Chip 
              label={COLORS[item]?.label}
              sx={{color:'#fff',background:COLORS[item]?.color}}
            />
          </TableCell>
        )
      }
    }
  }
  const handleGo = (path) => () => {
    navigate(`/dashboard/projects/${path}`);
  }

  useEffect(()=>{
    setLoading(true);
    const fetchProjectsByDomain = async () => {
      try{
        const allProjects = await findAllByDomain(process.env.REACT_APP_DOMAIN)
        console.log("All projects::",allProjects)
        setProjects(allProjects)
        setLoading(false);

      }catch(error){
        console.log(error)
      }
    };
    fetchProjectsByDomain();
  },[]);

  return (
    <section className={styles.contentSection} >
      <div className={styles.headerSection}>
        <div className={styles.subHeaderSection}>
          <Typography variant="body2" gutterBottom flex={1}>
            {t("description")}
          </Typography>
          <Button 
            variant="outlined" 
            color="info"
            onClick={handleGo("create")}
          >
            +{t("options.create_project")}
          </Button>
          <Button 
            variant="outlined" 
            color="info"
            onClick={handleGo("edit?name=test")}
          >
            +Edit
          </Button>
        </div>
      </div>
      <div className={styles.workSpace}>
        <DynamicTable 
          title={t("list_of_projects")}
          activeSearchBar={true}
          activeModeView={false}
          activeFilter={true}
          data={projects}
          loading={loading}
          preparedDataConfig={(item)=>{
            return {
              thumbnail: item.thumb_url,
              image: item.banner_url,
              name: item.name,
              description: item.description,
              blockchains: item.blockchains,
              status: item.status,
              id: item.id
            }
          }}
          setConfigParams={{
            configCells,
            onClick:(item)=> {
              const value = {
                name: item.name,
                id: item.id
              }
              BreadCrumbsSaveData("projectId",value);
              handleGo(`edit/${item.id}`)()
            }
          }}
        />
        <ColorsInfo items={itemStatus} setItemStatus={setItemStatus}/>
      </div>
    </section>
  );
};


export default ViewProjects;