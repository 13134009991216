import axios from "axios"
axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;

const path = "collection";
const url = `${process.env.REACT_APP_API}/${path}?domain=${process.env.REACT_APP_DOMAIN}`;
const collectionBenefitsURL = `${process.env.REACT_APP_API}/benefits/collection`

export const getCollectionBenefits = async ({collection_key, contract_key, limit, page, order}) => {
  const params = {collection_key, contract_key, limit, page, order, domain: process.env.REACT_APP_DOMAIN}
  console.log("params",params);
  const response = await axios.get(collectionBenefitsURL, {
    headers: {
      'X-API-Key': localStorage.getItem("userApiKey")
    },
    params
  });
  return await response.data;
}

export const createCollectionBenefit = async (collection_key, contract_key) => {
  const params = {domain: process.env.REACT_APP_DOMAIN}
  const body = {collection_key, contract_key, status: 0}
  const response = await axios.post(collectionBenefitsURL, body, {
    headers: {
      'X-API-Key': localStorage.getItem("userApiKey")
    },
    params
  });
  return response.data;
}

export const findAllByDomain = (domain) => {
  return axios.get(url + "?domain=" + domain).then(res => res.data)
}

export const findAllByCreator = async (domain, wallet) => {
  return axios.get(url + "?domain=" + domain + "&creator=" + wallet + "&order=created&limit=30&page=0").then(res => {
    let collections = []
    res.data.forEach((collection) => {
      console.log(collection.blockchain)
      collections.push({
        name: collection.name,
        thumb_url: collection.thumb_url,
        key: collection.project_key,
        domain: collection.domain,
        blockchain: collection.blockchain.blockchain_name,
        explorer: collection.blockchain.explorer,
        nfts: collection.collection_of,
      })
    })
    return collections
  })
}

export const findAllByCreatorEmpty = async (domain, wallet) => {
  let collections = await findAllByCreator(domain, wallet)
  let emptyCollections = []
  for (let index = 0; index < collections.length; index++) {
    const element = collections[index];
    if (element.nfts === 0) {
      emptyCollections.push(element)
    }
  }
  return emptyCollections
}

export const findAllWithNFTs = async (domain, wallet) => {
  let collections = await findAllByCreator(domain, wallet)
  let withNFTs = []
  for (let index = 0; index < collections.length; index++) {
    const element = collections[index];
    if (element.nfts > 0) {
      withNFTs.push(element)
    }
  }
  return withNFTs
}

export const create = async (collection) => {
  console.log("collection")
  console.log(collection)
  return await axios.post(`${process.env.REACT_APP_API}/${path}`, collection,
    {
      headers: {
        'X-API-Key': localStorage.getItem("userApiKey")
      },
      params: {
        domain: process.env.REACT_APP_DOMAIN,
      }
    });
}


export const linkCollectionToProject = async (projectId, collectioKey) => {
  const body = {
    project_id: parseInt(projectId),
    collection_key: collectioKey
  }
  const newPath = `project/${path}?domain=${process.env.REACT_APP_DOMAIN}&project_id=${projectId}`
  const response = await axios.post(`${process.env.REACT_APP_API}/${newPath}`, body);
  return response.data
}

export const update = async (collections) => {
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${process.env.REACT_APP_API}/${path}?domain=${domain}`
  const resonse = await axios.put(url, collections);
  return resonse.data;
}

export const unhideBulk = async (collections) => {
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${process.env.REACT_APP_API}/${path}/unhide?domain=${domain}`
  const resonse = await axios.post(url, collections);
  return resonse.data;
}

export const lockBulk = async (collections) => {
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${process.env.REACT_APP_API}/${path}/lock?domain=${domain}`
  const resonse = await axios.post(url, collections);
  return resonse.data;
}

export const unlockBulk = async (collections) => {
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${process.env.REACT_APP_API}/${path}/unlock?domain=${domain}`
  const resonse = await axios.post(url, collections);
  return resonse.data;
}

export const find = async (key) => {
  return axios.get(url + "&address=" + key + "&order=created&limit=30&page=0").then(res => {
    let collections = []
    res.data.forEach((collection) => {
      collections.push({
        name: collection.name,
        description: collection.description,
        thumb_url: collection.thumb_url.replace('https://persea.mypinata.cloud/ipfs/', 'https://images.persea.app/extra_files/'),
        banner: collection.banner_url.replace('https://persea.mypinata.cloud/ipfs/', 'https://images.persea.app/extra_files/'),
        key: collection.project_key,
        domain: collection.domain,
        blockchain: collection.blockchain.blockchain_name,
        explorer: collection.blockchain.explorer,
        rpc: collection.blockchain.rpc
      })
    })
    return collections[0]
  })
}


export const updateProjectKey = async (project_key, new_project_key, message, signature) => {
  const domain = process.env.REACT_APP_DOMAIN;
  const blockchain = process.env.REACT_APP_NAME_NETWORK;
  let data = {
    project_key: project_key,
    new_project_key: new_project_key,
    message: message,
    signature: signature,
    domain: domain,
    blockchain_name: blockchain
  }
  return axios.put(`${process.env.REACT_APP_API}/${path}?domain=${domain}`, data, {
    headers: {
      'Content-Type': 'text/plain;charset=utf-8',
    }
  })
    .then(res => res.data)
}

export const deleteCollection = async (collection_key) => {
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${process.env.REACT_APP_API}/${path}?domain=${domain}&id=${collection_key}`
  const res = await axios.delete(url, {headers: { 'X-API-Key': localStorage.getItem("userApiKey") } })
  return res?.data || null;
}

