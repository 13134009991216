import React, { useState, useContext, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRewardById } from 'hooks/useReward';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { formatTimestampToLocaleString, formatTimestampToLocaleStringShort, fullDate } from 'utils/date';
import { useCollectionBenefitbyRewardId } from 'hooks/benefit';
import UserContext from 'context/UserContext';
import { useConfig } from './utils/useConfig';
import { useSpinnerContext } from 'context/SpinnerContext';
import { update } from 'services/rewards/rewards';
import DynamicTable from 'components/DynamicTable/DynamicTable';


const activeItemStyles = () => ({
  background: (theme) => theme.palette.primary.main,
  color:  (theme) => theme.palette.secondary.main,
  boxShadow:  (theme) => ` inset -4px 0 0 ${theme.palette.secondary.main}`,
  '&:hover': {
    background: (theme) => theme.palette.primary.main,
    color:  (theme) => theme.palette.secondary.main,
  }
});

const itemStyles = () => ({
  '&:hover': {
    background: (theme) => theme.palette.primary.main,
    color:  "#fff"
  }
});

const OverviewReward = () => {
  const { t } = useTranslation("collections");
  const { user } = useContext(UserContext)
  const { rewardId } = useParams();
  const { reward, loadingReward } = useRewardById(rewardId);
  const params ={
    limit: 100,
    page: 0,
    order: 'created',
  }
  const { collectionBenefits, loading:loadingBenefits } = useCollectionBenefitbyRewardId(rewardId,params);
  const { handleChangeLoading, handleChangeError } = useSpinnerContext();

  const [ active, setActive ] = useState(1);
  const handleApproveReward = async () => {
    console.log("Approbing reward")
    try {
      handleChangeLoading(true);
      let approvedReward = reward
      approvedReward.status = 1
      await update({ rewards : [approvedReward]});
    } catch (error) {
      handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
    } finally {
      handleChangeLoading(false);
    }
  }
  const { 
    menu, 
    ALERT_TYPES, 
    tablePropsBenefit,
    configBenefits,
    SkeletonCollection
  } = useConfig(t,user,handleApproveReward);


  const handleSelected = (step) => {
    setActive(step);
  }

  const COMPONENT_TABLES = {
    1:{
      title: "Benefits",
      data: collectionBenefits,
      loading: loadingBenefits,
      activeSearchBar: true,
      preparedDataConfig:(item)=>{
        return {
          thumbnail: item?.reward?.thumb_url,
          name: item?.reward?.name,
          description: item?.reward?.description,
          status: item?.status,
          created: formatTimestampToLocaleStringShort(item?.reward?.created),
          updated: fullDate(item?.reward?.updated)
        }
      },
      setConfigParams: {...configBenefits,...tablePropsBenefit, textHeaders:{thumbnail:' '} }
    },
    2:{
      title: "Campaigns",
      data: [],
      loading: false,
      activeSearchBar: true
    }
  }

  const BASIC_INFO = [
    {
      label: t("details.items"),
      value: reward?.collection_of || '0',
    },
    {
      label: t("details.created"),
      value: formatTimestampToLocaleString(reward?.created),
    },
    {
      label: t("details.blockchain"),
      value: reward?.blockchain_name,
    }
  ]


  return (
    <Box>
      {
      <Box display="flex" flexDirection="column" margin="1rem 0px">
        {ALERT_TYPES[reward?.status] || null}
      </Box>
      }
      {
        loadingReward ? <SkeletonCollection /> : 
        <Fragment>
          <Paper elevation={3} style={{ position: 'relative', overflow: 'hidden' }}>
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              sx={{
                width: '100%',
                height: '300px'
              }}
            >
              <img
                src={reward?.thumb_url}
                alt="Banner"
                style={{ 
                  position: 'absolute', top: '0', left: '0',
                  width: '100%', height: '100%', 
                  objectFit: 'cover',
                  overflow: 'hidden'
                }}
                />
            </Box>      
            <Box
              position="absolute"
              top={"20px"}
              right={"0px"}
            >
              <Avatar
                variant="square"
                src={reward?.thumb_url}
                alt="Avatar"
                style={{ 
                  marginRight: '1rem',
                  width: '100px',
                  height: '100px',
                  border: '5px solid #fff',
                  borderRadius: '10px'
                }}
              />
            </Box>
          </Paper>
          <Box position="relative"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '1rem',
              gap: '1rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
              }}
            >
              <Typography variant="h5" sx={{color:'#000'}}>
                {reward?.name}
              </Typography>
              <Box flex={1} />
              <Box
                sx={{
                  width: '50%',
                }}
              >
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                "& > div":{
                  borderLeft: '1px solid #000',
                  borderRight: '1px solid #000',
                  padding: '0.5rem 1rem',
                  display: 'flex',
                  flexDirection:'column'
                }
              }}
            >
              {
                BASIC_INFO?.map((item, index) => {
                  const { label, value } = item;
                  const first = index === 0;
                  return (
                    <Box key={index} 
                      sx={{
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        borderLeft: first ? '1px solid #000': 'none !important'
                      }}
                    >
                      <Typography variant="p" sx={{color:'#000'}}>
                        {label+': '}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: typeof value === 'object' ? 'row': 'column'
                        }}
                      >
                        <Typography variant="p" sx={{color:'#000',fontWeight:'600'}}>
                          {value||''}
                        </Typography>
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>
            <Typography variant="body1" sx={{color:'#000'}}>
              {reward?.description}
            </Typography>
          </Box>
        </Fragment>
      }
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '1rem',
          marginTop: '1rem',
          padding: '1rem 0px'
        }}
      >
        <List
          sx={{ width: '100%', maxWidth: 200}}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader"
              sx={{
                color:(theme) => theme.palette.primary.main,
                fontWeight: '600',
                background: 'transparent'
              }}
            >
              {t("menu.title")}
            </ListSubheader>
          }
        >
          <List
            sx={{
              bgcolor: 'background.paper' 
            }}
          >
          {
            menu?.map((item, index) => {
              return (
                <ListItemButton 
                  key={index}
                  onClick={()=>handleSelected(item?.step)}
                  sx={
                    active === item?.step ?
                    activeItemStyles()
                    :
                    itemStyles()
                  }
                >
                  <ListItemText primary={item.label} />
                </ListItemButton>
              )
            })
          }
          </List>
        </List>
        <Box
          flex={1}
          padding={'0px 1rem'}
        >
          <DynamicTable 
            title={COMPONENT_TABLES[active]?.title || ''}
            data={COMPONENT_TABLES[active]?.data || []}
            loading={COMPONENT_TABLES[active]?.loading || false} 
            activeSearchBar={COMPONENT_TABLES[active]?.activeSearchBar || false} 
            activeModeView={false}
            activeFilter={true}
            preparedDataConfig={COMPONENT_TABLES[active]?.preparedDataConfig || null}
            setConfigParams={COMPONENT_TABLES[active]?.setConfigParams || null}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default OverviewReward;