import axios from "axios"
axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;

const path = "rewards"
const URL = `${process.env.REACT_APP_API}/${path}`;

export const getRewards = async ({ id, reward_type, limit, page, order }) => {
	const params = { id, reward_type, limit, page, order, domain: process.env.REACT_APP_DOMAIN }
	const response =  await axios.get(URL, {
		headers: {
			'X-API-Key': localStorage.getItem("userApiKey")
		},
		params
	});
	return await response.data;
}

export const getAllRewardsByDomain = async (domain) => {
	return await axios.get(URL,
		{
			headers: {
				'X-API-Key': localStorage.getItem("userApiKey")
			},
			params: {
				domain: domain

			}
		});
}

export const getRewardsByDomainId = async (domain, id) => {

	return await axios.get(`${URL}`,
		{
			headers: {
				'X-API-Key': localStorage.getItem("userApiKey")
			},
			params: {
				domain: domain,
				id: id
			}
		})
}

export const create = async (body) => {
	console.log("debug create body: ", body);
	return await axios.post(URL, body,
		{
			headers: {
				'X-API-Key': localStorage.getItem("userApiKey")

			},
			params: {
				domain: process.env.REACT_APP_DOMAIN,
				blockchain: process.env.REACT_APP_NAME_NETWORK
			}
		});
}

// export const update = async (reward) => {
//   const domain = process.env.REACT_APP_DOMAIN;
//   console.log('update reward', reward);
//   const res = await axios.put(`${process.env.REACT_APP_API}/rewards?domain=${domain}`,{
//     reward : reward
//   })
//   return res.data;
// }


export const update = async (rewards) => {
  const domain = process.env.REACT_APP_DOMAIN;
//   const data = {
//     id: parseInt(reward_id),
//     blockchain_name: reward.blockchain_name,
//     contract_address: reward.contract_address,
//     description: reward.description,
//     external_id: reward.external_id,
//     metadata_url: reward.metadata_url,
//     name: reward.name,
//     reward_type: reward.reward_type,
//     thumb_url: reward.thumb_url,
//     status:reward.status
//   }
//   console.log(data)
  const url = `${process.env.REACT_APP_API}/${path}?domain=${domain}`
  const resonse = await axios.put(url, rewards);
  return resonse.data;
}