import axios from "axios";
import { sign } from "./blockchain";
import { signMessageWithMagic } from "./providers/magicWallet";
import { buildUrlParams } from "utils/fetch";
axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;

export const findByWallet = async (domain, _, wallet) => {
  const response = await axios.get(process.env.REACT_APP_API + `/user?domain=${domain}&main_key=${wallet}&limit=1&page=0&order=main_key&cache=true`, {
    headers: {
      'X-API-Key': process.env.REACT_APP_X_API_KEY
    }
  });
  return response.data[0] || [];
}

export const create = async (domain, blockchain, user) => {
  const baseData = {
    'domain': domain,
    'blockchain_name': blockchain,
    'homepage': '',
    'username': user.address,
    'email': user.email,
    'about': '',
    'twitter': '',
    'facebook': '',
    'profile_pic_url': '',
    'telegram': '',
    'banner_url': '',
    'role': 100,
    'message': user.message,
    'action': 'USER_CREATED'
  };

  const data = user.magic_token 
    ? { ...baseData, 'magic_token': user.magic_token } 
    : { ...baseData, 'signature': user.signature };

  const params = user.magic_token 
    ? { magic_token: user.magic_token } 
    : { ...await sign(user.from,user.provider,user.signature) };

  const query = buildUrlParams(params);

  const res = await axios.post(`${process.env.REACT_APP_API}/user?domain=${domain}${query}`, data, {
    headers: {
      'Content-Type': 'text/plain;charset=utf-8',
      'X-API-Key': process.env.REACT_APP_X_API_KEY
    }
  });
  return {
    ...res,
    ...params
  }
}

export const register = async (domain, blockchain, user) => {
  const data = {
    'domain': domain,
    'blockchain_name': blockchain,
    'username': user.username,
    'email': user.email,
    'role': parseInt(user.role),
    'about': '',
    'twitter': '',
    'facebook': '',
    'telegram': '',
    'homepage': ''
  };


  const res = await axios.post(`${process.env.REACT_APP_API}/user?domain=${domain}`, data, {
    headers: {
      'Content-Type': 'text/plain;charset=utf-8',
      'X-API-Key': process.env.REACT_APP_X_API_KEY
    }
  });
  return {
    ...res
  }
}

export const createIfNotExists = async (domain, blockchain, query) => {

  const { wallet, email, magic_token, message, signature } = query;
  const user = await findByWallet(domain, blockchain,wallet);
  const isFirst = JSON.parse(localStorage.getItem("isFirst")) || false
  if (!user || user?.length === 0 || user === null || user === undefined) {
    const newUser = {
      address: wallet,
      email: email,
      magic_token: magic_token,
      message: message,
      signature: signature,
      ...query
    }
    const res = await create(domain, blockchain,newUser);
    return { res: res, isNew: true }
  } else {
    return { res: user, isNew: isFirst }
  }
}

export const findWalletsByEmail = async (emails) => {
  let params = ""
  for (const email of emails) {
    params += `&email=${email}`
  }
  const result = await axios.get(process.env.REACT_APP_API + `/relay?domain=${process.env.REACT_APP_DOMAIN}${params}`)
  return result.data;
}

export const generateJwt = async (domain,params) => {
  const query = buildUrlParams(params);
  const response = await axios.get(`${process.env.REACT_APP_API}/auth?domain=${domain}${query}`, {
    headers: {
      'X-API-Key': process.env.REACT_APP_X_API_KEY
    }
  });
  return response.data;
}

export const findAll = async (domain, limit, page, order) => {
  console.log(process.env.REACT_APP_API + `/user?domain=${domain}&limit=${limit}&page=${page}&order=${order}`)
  return axios.get(process.env.REACT_APP_API + `/user?domain=${domain}&limit=${limit}&page=${page}&order=${order}`, {
    headers: {
      'X-API-Key': process.env.REACT_APP_X_API_KEY
    }
  })
    .then(res => {
      return res.data
    })
}

export const getSingByProvider = async (user, typeProvider, message = '') => {
  if (message === '') {
    const typeProviderLowerCase = String(typeProvider).toUpperCase();
    if (typeProviderLowerCase === "BROWSER_WALLET") {
      return await sign(user?.wallet, user?.provider)
    } else if (typeProviderLowerCase === "MAGIC_WALLET") {
      return await signMessageWithMagic(user?.wallet, user?.magic)
    } else if (typeProviderLowerCase === "MAGIC_WALLET_OAUTH") {
      return await signMessageWithMagic(user?.wallet, user?.magic)
    } else {
      throw new Error("Invalid type provider: " + typeProviderLowerCase);
    }
  } else {
    const typeProviderLowerCase = String(typeProvider).toUpperCase();
    if (typeProviderLowerCase === "BROWSER_WALLET") {
      return await sign(user?.wallet, user?.provider, message)
    } else if (typeProviderLowerCase === "MAGIC_WALLET") {
      return await signMessageWithMagic(user?.wallet, user?.magic, message)
    } else if (typeProviderLowerCase === "MAGIC_WALLET_OAUTH") {
      return await signMessageWithMagic(user?.wallet, user?.magic, message)
    } else {
      throw new Error("Invalid type provider: " + typeProviderLowerCase);
    }
  }
}


export const updateUsers = async (domain, wallet, body) => {
  const response = await axios.put(process.env.REACT_APP_API + `/user?domain=${domain}&wallet=${wallet}`, body, {
    headers: {
      'X-API-Key': process.env.REACT_APP_X_API_KEY
    }
  })
  return response.data;
}
