import styles from "./SpotlightCard.module.css";
const SpotlightCard = ({ iMG, title, total }) => {
  return (
    <div className={styles.spotlight1Parent}>
      <div className={styles.spotlight1}>
        <div className={styles.ttulo}>
          <div className={styles.aLaVenta}>¡A la venta ahora!</div>
          <img className={styles.vectorIcon} alt="" src="/vector2.svg" />
        </div>
        <div className={styles.nftMiniParent}>
          <div className={styles.nftMini}>
            <img className={styles.imgIcon} alt="" src={iMG} />
            <div className={styles.ttulo1}>¡Nuevo!</div>
          </div>
          <div className={styles.ttuloParent}>
            <b className={styles.ttulo2}>{title}</b>
            <div className={styles.ttulo3}>
            ¡No te pierdas la emocionante oportunidad de adquirir un NFT único en su tipo!
            Este NFT representa una pieza de arte digital que nunca antes se ha visto y nunca se verá igual. Es una inversión en la creatividad y la innovación.
            </div>
            <div className={styles.ttulo4}>{total}</div>
            <button className={styles.botn}>
              <div className={styles.continuar}>Comprar ahora</div>
              <img className={styles.vectorIcon} alt="" src="/vector3.svg" />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.cambiarParent}>
        <div className={styles.cambiar}>Cambiar</div>
        <div className={styles.eliminar}>Eliminar</div>
      </div>
    </div>
  );
};

export default SpotlightCard;
