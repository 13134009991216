import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardInfo.module.css'
import LineChartUpDown from 'components/ChartsComponents/LineChart/LineChartUpDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const CardInfo = ({
  label,
  total
}) => {
  return (
    <div className={styles.contentCard}>
      <div className={styles.label}>{label}</div>
      <div className={styles.total}>{total}</div>
      <div className={styles.percent}><ArrowDropUpIcon sx={{color:'green'}} /> 40%</div>
      <div className={styles.beforePercent}>vs 18% prev</div>
      <div className={styles.chart}>
        <LineChartUpDown />
      </div>
    </div>
  );
};

CardInfo.propTypes = {
  title: PropTypes.string
};

export default CardInfo;