import React, { useState, useCallback, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './RenderViewContainer.module.css';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import GridOnIcon from '@mui/icons-material/GridOn';
import TableViewIcon from '@mui/icons-material/TableView';
import Typography from '@mui/material/Typography';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { useTranslation } from 'react-i18next';
import { Search, SearchIconWrapper, StyledInputBase } from '../stylesComponents';
import { searchValue } from 'components/DynamicTable/utils/search';
import ComponentTable from '../ComponentTable/ComponentTable';
import ComponentGrid from '../ComponentGrid/ComponentGrid';


const COMPONENTS_WRAPPER = {
  table: ComponentTable,
  grid: ComponentGrid
}

const RenderViewContainer = ({
  data = [],
  setConfigParams = {},
  allowedKeys = [],
  activeSearchBar = false,
  title = 'Title table',
  activeModeView = true,
  loading = false,
  activeFilter = false,
  startRowsPerPage = 10,
  activeSort
}) => {
  const { t } = useTranslation('tables');
  const [typeView, setTypeView] = useState('table');
  const [search, setSearch] = useState('');
  const refCardTable = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);
  const [filter, setFilter] = useState({
    column: 'status',
    operator: '!==',
    value: 12,
  });

  const dataFilterBySearch = searchValue(data,search);

  const handleChangeTypeView = useCallback((type) => () => {
    setTypeView(type);
  }, [setTypeView]);

  const handleChangeSearch = useCallback((event) => {
    const { value } = event.target;
    setSearch(value);
  }, [setSearch]);

  const handleClearFilter = useCallback(() => {
    setFilter({});
  }, [setFilter]);

  const BUTTONS_HEADERS = [
    { 
      label: 
      t("options.table"),
      icon: TableViewIcon, 
      onClick: handleChangeTypeView("table"),
      color:  typeView === "table" ? 'primary' : 'inherit'
    },
    { 
      label: t("options.grid"), 
      icon: GridOnIcon, 
      onClick: handleChangeTypeView("grid"),
      color:  typeView === "grid" ? 'primary' : 'inherit'
    }
  ]

  const ComponentWrapper = COMPONENTS_WRAPPER?.[typeView] || null

  useEffect(() => {
    if (refCardTable?.current?.offsetWidth) {
      setTableWidth(refCardTable.current.offsetWidth);
    }
  }, [refCardTable]);

  return (
    <div className={styles.contentParent}>
      {activeSearchBar && (
        <Card className={styles.sectionSearch}>
          <Typography variant="h6">{t("search")}</Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={t("search_placeholder")}
              inputProps={{ 'aria-label': 'search' }}
              type='search'
              value={search}
              onChange={handleChangeSearch}
            />
          </Search>
        </Card>
      )}
      <Card ref={refCardTable} sx={{ alignSelf: 'stretch' }}>
        <div className={styles.sectionOptions}>
          <div className={styles.listOptions}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                minHeight: '50px',
              }}
            >
              <Typography variant="h6" sx={{ margin: '0px 1rem' }}>{title}</Typography>
            </Box>
            <Box flex={1} />
            <Box>
              {
                Object.keys(filter).length !== 0 &&
                <IconButton
                  size="small"
                  onClick={handleClearFilter}
                  color="inherit"
                >
                  <FilterAltOffIcon
                    color="primary"
                  />
                </IconButton>
              }
              {activeModeView && (
              <Fragment>
                {
                  BUTTONS_HEADERS?.map((button,index)=>{
                    const { 
                      label,
                      icon: RenderIconButton,
                      onClick,
                      conditionShow = true,
                      color = 'inherit',
                    } = button;
                    return conditionShow ? (
                      <IconButton
                        key={label || index}
                        size="small"
                        onClick={onClick}
                        color="inherit"
                      >
                        <RenderIconButton
                          color={color}
                        />
                      </IconButton>
                    ): null;
                  })
                }
                
              </Fragment>
              )}
            </Box>
          </div>
        </div>
        {
          ComponentWrapper && 
          <ComponentWrapper
            data={dataFilterBySearch}
            allowedKeys={allowedKeys}
            {...setConfigParams} 
            loading={loading}
            tableWidth={tableWidth}
            activeFilter={activeFilter}
            filter={filter}
            setFilter={setFilter}
            startRowsPerPage={startRowsPerPage}
            isEmpty={data?.length === 0}
            activeSort={activeSort}
          />
        }
      </Card>
    </div>
  );
};

RenderViewContainer.defaultProps = {
  data: [],
  setConfigParams: {},
  allowedKeys: [],
  activeSearchBar: false,
  title: 'Title table',
  activeModeView: true,
  activeFilter: false,
  startRowsPerPage: 10,
  activeSort: true
};

RenderViewContainer.propTypes = {
  data: PropTypes.array,
  setConfigParams: PropTypes.object,
  allowedKeys: PropTypes.array,
  activeSearchBar: PropTypes.bool,
  title: PropTypes.string,
  activeModeView: PropTypes.bool,
  loading: PropTypes.bool,
  activeFilter: PropTypes.bool,
  startRowsPerPage: PropTypes.number,
  activeSort: PropTypes.bool
};

export default RenderViewContainer;
