import axios from "axios";
axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;
import { buildUrlParams } from "utils/fetch";


const PATH = "collection/campaign"
const URL = `${process.env.REACT_APP_API}/${PATH}`;




export const findAllAssociatedCampaigns = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {
    limit: 100,
    page: 0,
    order: "created"

  }
}) => {
  const result = axios.get(
    `${URL}?domain=${domain}${buildUrlParams(params)}`
  );
  const response = await result;
  console.log("ALL ASSOCIATED CAMPAIGNS IN A COLLECTION BY COLLECTION KEY::",response)

  if(Array.isArray(response?.data)) {
      return response.data.map(formatCollection);
  }
  return [];
};

const formatCollection = (collection) => {
  console.log("Collection associated::", collection)
  return collection;
}