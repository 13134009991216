import axios from "axios";
axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;

export const createPost = async ( post) => {
    let url = `${process.env.REACT_APP_API}/posts?domain=${process.env.REACT_APP_DOMAIN}`
    const response = await axios.post(url, post);
    return response.data;
}

export const getPost =  async(id_campaign) => {
    let url = `${process.env.REACT_APP_API}/posts?id_campaigns=${id_campaign}&domain=${process.env.REACT_APP_DOMAIN}`
    const response = await axios.get(url);
    console.log('usegetpost',response.data)
    return response.data;
}

