import axios from "axios"
import { buildUrlParams } from "utils/fetch";

const benefitURL = process.env.REACT_APP_API+"/benefits?domain="+process.env.REACT_APP_DOMAIN
const PATH = "benefits";
const URL = process.env.REACT_APP_API;

export const createCollectionBenefit = async (data) => {
  const response = await axios.post(benefitURL, data)
  return response.data
}

export const getCollectionBenefitByRewardId = async (reward_id,params={}) => {
  let URL = benefitURL + `&reward_id=${reward_id}`;
  Object.entries(params).forEach(([key, value]) => {
    URL += `&${key}=${value}`
  });
  const response = await axios.get(URL);
  return response.data
}

export const saveBenefit = async (data) => {
  const response = await axios.post(benefitURL, data)
  return response.data
}

export const updateBenefit = async (contractKey, projectKey, type, fieldsToUpdate) => {
  const data = {
    contract_key: contractKey,
    project_key: projectKey,
    type: type,
    ...fieldsToUpdate,
  }
  const response = await axios.put(benefitURL, data)
  return response.data
}

export const updateManyBenefit = async (benefits) => {
  console.log("benefitURL::", benefitURL)
  console.log("benefits::", benefits)
  const resonse = await axios.put(benefitURL, benefits);
  return resonse.data;
}

export const getBenefits = async (params={}) => {
  let URL = benefitURL;
  Object.entries(params).forEach(([key, value]) => {
    URL += `&${key}=${value}`
  });
  const response = await axios.get(URL);
  return response.data
}

export const findBenefits = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {
    limit: 30,
    page: 0,
    order: "contract_address"
  }
}) => {
  const result = axios.get(
    `${URL}/${PATH}?domain=${domain}${buildUrlParams(params)}`
  );
  const response = await result;

  return response?.data || [];
};

export const getBenefitCollection = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {
    limit: 10,
    page: 0,
    order: "contract_address"
  }
})=>{
  const result = axios.get(
    `${URL}/${PATH}/collection?domain=${domain}${buildUrlParams(params)}`
  );
  const response = await result;
  return response?.data || [];
}