import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Header.module.css";
import { useCallback, useState, useContext } from "react";
import PortalPopup from "./PortalPopup";
import MenuDashBoardMobile from "./MenuDashBoardMobile";
import UserContext from "context/UserContext";
import { allLogos } from 'utils/icons';
import PopUpProfile from "./PopUpProfile/PopUpProfile";
import IconButton from "@mui/material/IconButton";
import PropTypes from 'prop-types';
import { shortAddress } from "utils/strings";

export const HeaderWrapper = ({
  user,
  setUser,
  goHome,
  openModal,
  goPage,
  eventName,
  openMenu
})=>{
  console.log("debug user::",user);
  return (
    <div
      className={styles.botnIlustrado}
    >
        <div className={styles.header}>
          <div className={styles.logoPerseaNine}>
            <img
              className={styles.perseaLogoWhite}
              alt={allLogos.header.logosLeft.perseaIcon.description}
              src={allLogos.header.logosLeft.perseaIcon.src}
            />
            <img
              className={styles.perseaLogoWhite1}
              alt={allLogos.header.logosLeft.perseaText.description}
              src={allLogos.header.logosLeft.perseaText.src}
            />
            <button className={styles.dashboard} onClick={goHome}>dashboard</button>
          </div>
          <div className={styles.copySolid1Parent}>
            <div className={styles.copySolid1}>
              <div className={styles.pepsieth}>{shortAddress(user?.username)}</div>
            </div>
            <PopUpProfile 
              user={user}
              setUser={setUser}
            />
            <IconButton size="small" onClick={openModal}>
              <img 
                className={styles.iconMenu}
                alt=""
                src="/iconMenu.png"
              />
            </IconButton>
          </div>
        </div>
        {openMenu && (
        <PortalPopup
            overlayColor="transparent"
            placement="Top center"
            onOutsideClick={openModal}
            marginTop={"70px"}
            width={"100%"}
          >
            <MenuDashBoardMobile onClose={openModal} goPage={goPage}  eventName={eventName} />
          </PortalPopup>
        )}

    </div>
  )
}

HeaderWrapper.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  goHome: PropTypes.func,
  openModal: PropTypes.func,
  goPage: PropTypes.func,
  eventName: PropTypes.string,
  openMenu: PropTypes.bool
}

const Header = () => {
  const { user, setUser } = useContext(UserContext)
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const eventName = pathSegments[2];
  const navigate = useNavigate();
  const [ openMenu, setOpenMenu ] = useState(false);

  const goHome = useCallback(() =>{
    navigate(`/dashboard`);
  }, [navigate]);

  const openModal = useCallback(() =>{
    setOpenMenu(!openMenu);
  }, [openMenu]);

  const goPage = useCallback((link) => () =>{
    setOpenMenu(false);
    navigate(`/dashboard/${link}`);
  }, [navigate]);
  

  return (
    <HeaderWrapper 
      user={user}
      setUser={setUser}
      goHome={goHome}
      openModal={openModal}
      goPage={goPage}
      eventName={eventName}
      openMenu={openMenu}
    />
  )
};

export default Header;
