import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import ViewNftInfo from '../tabs/NftInfo';
import ViewNftBenefits from '../tabs/NftBenefits';
import ViewNftAttributes from '../tabs/NftAttributes';


const useConfig = (t,user) => {

  const menu = [
    {
      label: t("menu.info"),
      step: 1
    },
    // {
    //   label: t("menu.benefits"),
    //   step: 2
    // },
    {
      label: t("menu.attributes"),
      step: 3
    }
  ]

  const ALERT_TYPES = {
    0: <Alert severity="warning">
        <>
          {t("alerts.in_progress_collection")}
          {user?.role >= 80 &&
            <Button
              variant="outlined"
              color="info"
              sx={{margin:'0px 1rem'}}
              size="small"
            >
             Approve
            </Button>
          }
        </>
        </Alert>,
    1: <Alert severity="success">{t("alerts.approved_collection")}</Alert>,
    2: <Alert severity="warning">{t("alerts.pending_nfts")}</Alert>,
    3: <Alert severity="warning">{t("alerts.evaluated_nfts")}</Alert>,
    4: <Alert severity="warning">{t("alerts.rated_nfts")}</Alert>,
    5: <Alert severity="success">{t("alerts.approved_nfts")}</Alert>,
    6: <Alert severity="error">{t("alerts.rejected_nfts")}</Alert>,
    7: <Alert severity="error">{t("alerts.rejected_collection")}</Alert>,
    8: <Alert severity="info">{t("alerts.collection_ready")}</Alert>
  }

  const statusOptions = {
    0: {
      label: t("table.status.empty"),
      color: "primary"
    },
    1: {
      label:t("table.status.waiting_for_approval"),
      color: "warning"
    },
    2: {
      label: t("table.status.approved"),
      color: "success",
    },
    3: {
      label: t("table.status.rejected"),
      color: "error"
    }
  }

  const tablePropsBenefit = {
    configOptions: [
      {
        id: 'button_approve',
        label: t("table.actions.approve_benefit"),
        onClick: (item) => {
          console.log(item);
        }
      },
      {
        id: 'rejected_benefit',
        render: () => {
          return (
            <TableCell>
              <Button size="small" variant="outlined" color="error">
                {t("table.actions.rejected_benefit")}
              </Button>
            </TableCell>
          )}
      }
    ]
  }

  const configBenefits = {
    configRows :{
      status:{  
        render: (item) => {
          return (
            <TableCell>
              <Chip 
                label={statusOptions[item].label}
                color={statusOptions[item].color} 
              />
            </TableCell>
          )
        }
      }
    }
  }

  const validateIfActiveButtonCreateNFT = (status) => {
    if(!status || status === 0 || status === 2 || status === 4 || status > 7) return true; //disable = true
    return false; //disable = false
  }

  const SkeletonCollection = () => {
    return (
      <Box>
        <Paper elevation={3} style={{ position: 'relative', overflow: 'hidden' }}>
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          sx={{
            width: '100%',
            height: '300px'
          }}
        >
          <Skeleton sx={{ width:'100%', height:'100vh' }}/>
        </Box>      
        <Box
            position="absolute"
            top={"20px"}
            right={"0px"}
            display="flex"
            alignItems="center"
        >
          <Avatar
            variant="square"
            alt="Avatar"
            style={{ 
              marginRight: '1rem',
              width: '100px',
              height: '100px',
              border: '5px solid #fff',
              borderRadius: '10px'
            }}
          />
        </Box>
      </Paper>

      <Skeleton sx={{ width:'100%', height:'70px' }}/>
      <Skeleton sx={{ width:'100%', height:'50px' }}/>
    </Box>
    )
  }

  const SwitcherContainer = ({
    t,
    active,
    nft,
    benefits,
    loadingBenefits
  }) => {
    switch (active) {
      case 1:
        return (
          <ViewNftInfo
            NFTdata={nft}
            t={t}
          ></ViewNftInfo>
        );
      case 2:
        console.log("benefits useconfig", benefits)
        return (
          <ViewNftBenefits
            NFTBenefits={benefits}
            loading={loadingBenefits}
          ></ViewNftBenefits>

        );
      case 3:
        return (
          <ViewNftAttributes
            NFTAttributes={nft.attributes}
          ></ViewNftAttributes>
        );
      default:
        return null;
    }
}

  
  return {
    menu,
    ALERT_TYPES,
    statusOptions,
    tablePropsBenefit,
    configBenefits,
    validateIfActiveButtonCreateNFT,
    SkeletonCollection,
    SwitcherContainer
  }
}

export {
  useConfig
}