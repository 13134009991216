import { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, useFormikContext } from "formik";
import { Box, Button, Dialog, DialogContent, DialogTitle, FormHelperText, Grid, InputLabel, Stack } from "@mui/material";
import SelectImageOrVideoField from "components/SelectImageOrVideoField";
import WrappedMaterialTextField from "components/WrappedMaterialTextField";
import RewardCard from "components/Cards/RewardCard";

export const defaultInitialValues = {
  name: "",
  description: "",
  type: 0,
  supply: 1,
  reward: {
    rewardRecordId: 0,
    amount: 1,
  }
};


export default function BenefitForm({ actions, rewards = [], disabledFields = {}, initialValues = defaultInitialValues }) {
  const { resetForm, setFieldValue, values } = useFormikContext();

  useEffect(() => {
    resetForm({ values: initialValues });
  }, [initialValues, resetForm]);

  useEffect(() => {
    setFieldValue('asset', null);
    if (initialValues?.asset && typeof initialValues.asset === "string") {
      fetch(initialValues.asset).then(res => res.blob()).then(blob => {
        const file = new File([blob], 'default', { type: blob.type });
        setFieldValue('asset', file);
      }).catch(() => {
        setFieldValue('asset', null);
      });
    } else {
      setFieldValue('asset', initialValues?.asset);
    }
  }, [initialValues]);

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel htmlFor="asset">Asset</InputLabel>
          <Box sx={{ width: { xs: "100%", sm: "70%", lg: "100%" }, alignSelf: "center" }}>
            <SelectImageOrVideoField
              disabled={disabledFields.asset}
              htmlName="asset"
              variant="square"
              file={typeof values.asset === "string" ? null : values.asset}
              onChange={(event) => {
                setFieldValue('asset', event.currentTarget.files[0], true);
              }}
            />
          </Box>
          <ErrorMessage name="asset" render={msg => <FormHelperText error={true}>{msg}</FormHelperText>} />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="reward.rewardRecordId">Reward</InputLabel>
          <Field
            name="reward.rewardRecordId"
            disabled={disabledFields.reward}
            component={RewardsSelectField}
            rewards={rewards}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="reward.amount">Reward amount</InputLabel>
          <Field
            name="reward.amount"
            type="number"
            disabled={disabledFields.reward}
            placeholder="Reward amount"
            fullWidth
            component={WrappedMaterialTextField}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="name">Name</InputLabel>
          <Field
            disabled={disabledFields.name}
            name="name"
            placeholder="Name"
            fullWidth
            component={WrappedMaterialTextField}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="description">Description</InputLabel>
          <Field
            name="description"
            placeholder="Description"
            disabled={disabledFields.description}
            multiline
            minRows={5}
            maxRows={10}
            fullWidth
            component={WrappedMaterialTextField}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="type">Type</InputLabel>
          <Field
            name="type"
            type="number"
            disabled={disabledFields.type}
            placeholder="Type"
            fullWidth
            component={WrappedMaterialTextField}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="supply">Supply</InputLabel>
          <Field
            name="supply"
            type="number"
            placeholder="Supply"
            disabled={disabledFields.supply}
            fullWidth
            component={WrappedMaterialTextField}
          />
        </Grid>
        <Grid item xs={12}>
          {actions}
        </Grid>
      </Grid>
    </Form>
  );
}

const RewardsSelectField = ({ rewards, field, form, ...props }) => {
  const [open, setOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);

  useEffect(() => {
    if (field.value) {
      const reward = rewards.find((reward) => reward.id === field.value);
      setSelectedReward(reward);
    }
  }, [field.value, rewards]);

  const onSelectReward = (reward) => {
    form.setFieldValue(field.name, reward.id);
    handleToggle();
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Box>
      {selectedReward ? (
        <Button
          fullWidth
          onClick={handleToggle}
          {...props}
          sx={{
            p: 0,
            fontStyle: "inherit",
            textAlign: "inherit",
            fontWeight: "inherit",
            textTransform: "inherit",
          }}
        >
          <RewardCard
            sx={{ width: "100%" }}
            reward={selectedReward}
          />
        </Button>
      ) : (
        <Button
          fullWidth
          variant="outlined"
          {...props}
          onClick={handleToggle}
        >
          Select a reward
        </Button>
      )}
      <ErrorMessage name={field.name} render={msg => <FormHelperText error={true}>{msg}</FormHelperText>} />
      <SelectRewardModal
        open={open}
        onClose={handleToggle}
        rewards={rewards}
        handleSelect={onSelectReward}
      />
    </Box>
  );
}

const SelectRewardModal = ({
  open,
  onClose,
  rewards,
  handleSelect
}) => {
  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Select a reward</DialogTitle>
      <DialogContent dividers={false}>
        <Stack spacing={1}>
          {rewards.map((reward) => (
            <Button key={reward.id} sx={{
              p: 0.7,
              fontStyle: "inherit",
              textAlign: "inherit",
              fontWeight: "inherit",
              textTransform: "inherit",
            }}
              onClick={() => handleSelect(reward)}
            >
              <RewardCard
                sx={{ width: "100%" }}
                reward={reward}
              />
            </Button>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
