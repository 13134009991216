import React, { useContext, Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import { getBenefitCollection } from 'services/benefits';
import { createCollectionBenefit } from 'services/collections/collection';
import { BreadCrumbsSaveData } from 'components/BreadCrumbs/BreadCrumbs';
import UserContext from 'context/UserContext';
import { useFetch } from 'hooks/useFetch';
import { approve, find } from "services/campaigns/campaigns"
import CardMedia from '@mui/material/CardMedia';
import Sections from './Sections';
import CreatePostButton from 'components/Buttons/CreatePostButton';
import CreateMessageButton from 'components/Buttons/CreateMessageButton';
import { useSpinnerContext } from 'context/SpinnerContext';
import { getCollectionByKey } from 'services/collection/collections';
import { Link } from '@mui/material';
import GoEditCampaignButton from 'components/Buttons/GoEditCampaignButton';

const OverviewCampaign = () => {
  const { t } = useTranslation("campaigns");
  const navigate = useNavigate();
  const { user } = useContext(UserContext)
  const { campaignId } = useParams();
  const { data: campaign, loading: loadingCampaign, refresh } = useFetch(find, {
    params: { id: campaignId, limit: 100, page: 0, order: 'id_desc' }
  });
  const { handleChangeLoading, handleChangeMessage, handleChangeError } = useSpinnerContext();
  const [basicInfo, setBasicInfo] = useState({
    benefits_count: {
      label: t("details.total_benefits"),
      value: campaign?.collection_of || '0',
    },
    posts_count: {
      label: t("details.total_posts"),
      value: campaign?.total_posts || '0',
    },
    blockchain: {
      label: t("details.blockchain"),
      value: "Loading..."
    },
    testnet: {
      label: t("details.testnet"),
      value: campaign?.smart_contract?.blockchain.testnet ? "True" : "False",
    },
    collection: {
      label: t("details.collection_name"),
      value: "Loading..."
    }
  });

  useEffect(() => {
    if (!loadingCampaign && campaign) {
      getCollectionInfo().then((collectionInfo) => {
        setBasicInfo({
          ...basicInfo,
          blockchain: {
            ...basicInfo.blockchain,
            value: campaign?.smart_contract?.blockchain.name,
            image: campaign?.smart_contract?.blockchain.icon
          },
          collection: {
            ...basicInfo.collection,
            ...collectionInfo
          }
        })
      })
    }
  }, [loadingCampaign])

  const getCollectionInfo = async () => {
    if (campaign?.type !== "NFTS") {
      return {
        value: "No collection",
      }
    }
    try {
      const collection = await getCollectionByKey({params: {
        collection_key: campaign.collection_key,
        limit: 1,
        page: 0,
        order: 'created'
      }});
      return {
        value: (
          <Link
            data-testid="overview-campaign-collection-link"
            href={`/dashboard/collections/${campaign.collection_key}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/dashboard/collections/${campaign.collection_key}`);
            }}
          >
            {collection?.name}
          </Link>
        )
      }
    } catch {
      return {}
    }
  }

  const handleGoEmpty = (path) => {
    navigate(`/dashboard/${path}`);
  }

  const approveCampaing = async (campaign) => {
    handleChangeError("");
    handleChangeLoading(true);
    handleChangeMessage(t("alerts.approving_campaigns"));
    try {
      await approve(campaign.id);
      await refresh();
    } catch (error) {
      console.error(error);
      handleChangeError(t("alerts.error_approving_campaign"));
    } finally {
      handleChangeLoading(false);
    }
  }

  const ALERT_TYPES = {
    0: <Alert severity="warning">
      <>
        {t("alerts.in_progress_campaign")}
        {user?.role >= 80 &&
          <Button
            variant="outlined"
            data-testid="overview-campaign-approve-button"
            color="info"
            sx={{ margin: '0px 1rem' }}
            onClick={() => approveCampaing(campaign)}
            size="small"
          >
            Approve
          </Button>
        }
      </>
    </Alert>,
    1: <Alert severity="success">{t("alerts.approved_campaign")}</Alert>,
    2: <Alert severity="error">{t("alerts.rejected_campaign")}</Alert>
  }

  const handleCreateBenefits = async () => {
    try {
      const value = { name: "Create benefits" }
      const benefitContract = await getBenefitsContract();
      value.contract_key = benefitContract.contract_key
      const path = `benefits/create/${benefitContract.contract_key}/campaign`;
      BreadCrumbsSaveData("contractKey", value);
      handleGoEmpty(path)
    } catch (error) {
      console.log("debug error", error)
      // setError(error?.message || ('An unexpected error occurred:'+ error));
    } finally {
      // setLoading(false);
    }
  }
  const getBenefitsContract = async () => {
    const benefitCollection = await getBenefitCollection({
      params: {
        collection_key: campaign.collection_key,
        limit: 100,
        page: 0,
        order: 'contract_address',
      }
    })
    if (benefitCollection?.length > 0) {
      return benefitCollection[0];
    } else {
      return await createDraftCollectionBenefit();
    }
  }

  const createDraftCollectionBenefit = async () => {
    try {
      const collection_key = campaign?.collection_key;
      const newCollectionBenefits = await createCollectionBenefit(collection_key, `${collection_key}DRAFT`);
      return newCollectionBenefits[0];
    } catch (error) {
      console.log("debug error", error)
      error.message = t("alerts.error_creating_benefit_collection");
      throw error;
    }
  }

  const isCampaignDisabled = campaign?.status !== 1;

  return (
    <Box>
      {
        <Box display="flex" flexDirection="column" margin="1rem 0px">
          {ALERT_TYPES[campaign?.status] || null}
        </Box>
      }
      {
        loadingCampaign ? <SkeletonCollection /> :
          <Fragment>
            <Paper elevation={3} style={{ position: 'relative', overflow: 'hidden' }}>
              <Box
                position="relative"
                display="flex"
                alignItems="center"
                sx={{
                  width: '100%',
                  height: '300px'
                }}
              >
                <img
                  src={campaign?.banner_url}
                  alt="Banner"
                  style={{
                    position: 'absolute', top: '0', left: '0',
                    width: '100%', height: '100%',
                    objectFit: 'cover',
                    overflow: 'hidden'
                  }}
                />
              </Box>
            </Paper>
            <Box position="relative"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1rem',
                gap: '1rem'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1rem',
                  justifyContent: 'space-between',
                  "@media screen and (max-width: 700px)": {
                    flexDirection: 'column-reverse',
                  }
                }}
              >
                <Box
                  sx={{
                    flex:1
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    gap="1rem"
                    flexWrap={"wrap"}
                    id="contentbuttons"
                    sx={{
                      "@media screen and (max-width: 730px)": {
                        display:'grid',
                        gridTemplateColumns:'1fr 1fr',
                      },
                      "@media screen and (max-width: 450px)": {
                        gridTemplateColumns:'1fr'
                      }
                    }}
                  >
                    <GoEditCampaignButton campaignId={campaignId} />
                    <Button
                      id="id-create-benefits-button"
                      variant="outlined"
                      data-testid="overview-collection-create-benefit-button"
                      color="info"
                      disabled={isCampaignDisabled}
                      onClick={handleCreateBenefits}

                    >
                      + {t("options.create_benefit")}
                    </Button>
                    <CreatePostButton disabled={isCampaignDisabled} />
                    <CreateMessageButton disabled={isCampaignDisabled} />
                  </Box>
                </Box>
              </Box>
              <Typography variant="h5" sx={{ color: '#000' }}>
                {campaign?.name}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  "& > div": {
                    borderLeft: '1px solid #000',
                    borderRight: '1px solid #000',
                    padding: '0.5rem 1rem',
                    display: 'flex',
                    flexDirection: 'column'
                  }
                }}
              >
                {
                  Object.entries(basicInfo).map(([key, item]) => {
                    const { label, value, image } = item;
                    return (
                      <Box key={key}
                        sx={{
                          outline: '1px solid #000'
                        }}
                      >
                        <Typography variant="p" sx={{ color: '#000' }}>
                          {label + ': '}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: image || typeof value === 'object' ? 'row' : 'column',
                            gap: '0.5rem',
                            textAlign: 'center'
                          }}
                        >
                          {
                            image &&
                            <CardMedia
                              component="img"
                              sx={{
                                width: 14,
                                objectFit: 'contain'
                              }}
                              src={image}
                            />
                          }
                          <Typography variant="p" sx={{ color: '#000', fontWeight: '600' }}>
                            {value || ''}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
              <Typography
                data-testid="overview-campaign-description"
                dangerouslySetInnerHTML={{ __html: campaign?.description || '' }}
                sx={{ color: '#000' }}
              />
            </Box>
          </Fragment>
      }
      <Sections campaign={campaign} loadingCampaign={loadingCampaign} />
    </Box>
  );
};

const SkeletonCollection = () => {
  return (
    <Box>
      <Paper elevation={3} style={{ position: 'relative', overflow: 'hidden' }}>
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          sx={{
            width: '100%',
            height: '300px'
          }}
        >
          <Skeleton sx={{ width: '100%', height: '100vh' }} />
        </Box>
        <Box
          position="absolute"
          top={"20px"}
          right={"0px"}
          display="flex"
          alignItems="center"
        >
          <Avatar
            variant="square"
            alt="Avatar"
            style={{
              marginRight: '1rem',
              width: '100px',
              height: '100px',
              border: '5px solid #fff',
              borderRadius: '10px'
            }}
          />
        </Box>
      </Paper>

      <Skeleton sx={{ width: '100%', height: '70px' }} />
      <Skeleton sx={{ width: '100%', height: '50px' }} />
    </Box>
  )
}

export default OverviewCampaign;