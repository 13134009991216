import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik'
import { capitalizeFirstLetter, stringToObjectField } from 'utils/strings';
import { TextField } from "@mui/material";
import CustomLabel from '../utils/customLabel';

const WrappedMaterialTextField = ({ field, form: { touched, errors }, ...props }) => {

  const getTouched = () => {
    const isTouched = stringToObjectField(field.name, touched);
    if (isTouched === null) return false;
    return isTouched;
  }

  const getError = () => {
    if (!getTouched()) return "";
    const error = stringToObjectField(field.name, errors);
    if (error === null) return "";
    return error;
  }

  return (
    <TextField
      {...field}
      {...props}
      style={{ backgroundColor: 'white',borderRadius:'8px' }}
      error={(Boolean(getTouched()) && Boolean(getError()))}
    />
  )
}

const InputText = ({ name, placeholder, label, loading, inputDescription}) => {
  const description = inputDescription ? inputDescription : undefined;

  return (
    <Fragment>
      <CustomLabel htmlFor={name} description={description} >
        {label ? label : capitalizeFirstLetter(name)}
      </CustomLabel>
      <Field
        type="text"
        fullWidth
        placeholder={placeholder ? placeholder : capitalizeFirstLetter(name)}
        name={name}
        disabled={loading}
        component={WrappedMaterialTextField}
      />
    </Fragment>
  );
};

InputText.propTypes = {
  name: PropTypes.string,
  loading: PropTypes.bool
};

export default InputText;