import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import PropTypes from 'prop-types';

const LineChartUpDown = ({
  data,
  xLabels
}) => {
  


  const chartContainerRef = React.useRef(null);
  const [containerSize, setContainerSize] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    function updateSize() {
      if (chartContainerRef.current) {
        const { width, height } = chartContainerRef.current.getBoundingClientRect();
        setContainerSize({ width, height });
      }
    }
  
    window.addEventListener('resize', updateSize);
    updateSize();
  
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  

  const dynamicChartSetting = {
    width: containerSize.width,
    height: containerSize.width / 2,
  };

  const customize = {
    legend: { hidden: true },
    margin: { top: 5 },
    stackingOrder: 'descending',
  };

  const stackStrategy = {
    data: data,
    stack: 'total',
    label: '2d',
    area: true,
    stackOffset: 'none',
    disableTicks: true ,
    showMark: false
  };
  



  return (
    <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }}>
      <LineChart
      series={[stackStrategy]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      {...customize}
      {...dynamicChartSetting}
      sx={{
        '.MuiLineElement-root': {
          display: 'none',
        },
      }}
    />
    </div>
  );
}

const dataDefault = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const xLabels = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F',
  'Page G',
];

LineChartUpDown.defaultProps = {
  data: dataDefault,
  color: "#02D5D1",
  xLabels: xLabels
}

LineChartUpDown.propTypes = {
  data: PropTypes.array,
  colors: PropTypes.any,
  color: PropTypes.string,
  xLabels: PropTypes.any
};

export default LineChartUpDown;
