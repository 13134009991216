import { Box, CardMedia } from "@mui/material";

const ratioStypeProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}

export default function ResponsiveImageOrVideo ({ src, type='image', ratio="square", ...rest }) {

  const getRatio = () => {
    if (ratio === "square") return 100;
    if (ratio === "landscape") return 50;
    if (ratio === "portrait") return 200;
    return ratio;
  }

  const responsiveImage = () => (
    <CardMedia
      image={src}
      sx={{ 
        ...ratioStypeProperties,
        borderRadius: '8px'
      }}
      {...rest}
    />
  )

  const responsiveVideo = () => (
    <CardMedia
      component="video"
      src={src}
      sx={{
        ...ratioStypeProperties,
        borderRadius: '8px'
      }}
      controls
      {...rest}
    />
  )

  return (
    <div>
      <Box
        sx={{
          cursor: 'pointer',
          position: 'relative',
          width: '100%',
          paddingBottom: `${getRatio()}%`,  /* set the aspect ratio */
        }}
      >
        {type === 'image' && responsiveImage()}
        {type === 'video' && responsiveVideo()}
      </Box>
    </div>
  );
}