import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import PropTypes from 'prop-types';

const chartSettingDefault = {
  xAxis: [
    {
      label: ''
    }
  ],
  width: "100%",
  height: "auto",
};

const dataset = [
  {
    subscribers: 59,
    month: 'Jan',
    start_date: '2023-01-01',
    end_date: '2023-01-31'
  },
  {
    subscribers: 50,
    month: 'Feb',
    start_date: '2023-02-01',
    end_date: '2023-02-28'
  },
  {
    subscribers: 47,
    month: 'Mar',
    start_date: '2023-03-01',
    end_date: '2023-03-31'
  },
  {
    subscribers: 54,
    month: 'Apr',
    start_date: '2023-04-01',
    end_date: '2023-04-30'
  },
  {
    subscribers: 57,
    month: 'May',
    start_date: '2023-05-01',
    end_date: '2023-05-31'
  },
  {
    subscribers: 60,
    month: 'June',
    start_date: '2023-06-01',
    end_date: '2023-06-30'
  },
  {
    subscribers: 59,
    month: 'July',
    start_date: '2023-07-01',
    end_date: '2023-07-31'
  },
  {
    subscribers: 65,
    month: 'Aug',
    start_date: '2023-08-01',
    end_date: '2023-08-31'
  },
  {
    subscribers: 51,
    month: 'Sept',
    start_date: '2023-09-01',
    end_date: '2023-09-30'
  },
  {
    subscribers: 60,
    month: 'Oct',
    start_date: '2023-10-01',
    end_date: '2023-10-31'
  },
  {
    subscribers: 67,
    month: 'Nov',
    start_date: '2023-11-01',
    end_date: '2023-11-30'
  },
  {
    subscribers: 61,
    month: 'Dec',
    start_date: '2023-12-01',
    end_date: '2023-12-31'
  },
];


const valueFormatter = (value) => `${value} Subscribers`;

const BarChartHorizontal = ({
  dataYKey,
  dataXKey,
  label,
  data,
  chartSetting,
  renderData,
  barColor
}) => {

  const newData = renderData && typeof renderData === "function" ? renderData(data) : data;
  const chartContainerRef = React.useRef(null);
  const [containerSize, setContainerSize] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    if (chartContainerRef.current) {
      const { width, height } = chartContainerRef.current.getBoundingClientRect();
      setContainerSize({ width, height });
    }
  }, []);


  const dynamicChartSetting = {
    ...chartSetting,
    width: containerSize.width,
    height: containerSize.width
  };

  return (
    <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }}>
      <BarChart
        dataset={newData}
        yAxis={[{ scaleType: 'band', dataKey:  dataYKey }]}
        series={[
          { 
            dataKey: dataXKey,
            label: label, 
            valueFormatter,
            color: barColor
          }
        ]}
        layout="horizontal"
        //{...chartSetting}
        {...dynamicChartSetting}
      />
    </div>
  );
}



BarChartHorizontal.defaultProps = {
  dataYKey: 'month',
  dataXKey: 'subscribers',
  label: 'TikTok',
  data: dataset,
  chartSetting: chartSettingDefault,
  barColor: "#02D5D1"
}

BarChartHorizontal.propsTypes = {
  dataYKey: PropTypes.string,
  dataXKey: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  chartSetting: PropTypes.object,
  barColor: PropTypes.string
}



export default BarChartHorizontal;