import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { cancelMint, getDataIfExistsNfts, postConfirmMint, postCreateNFTBySheet } from 'services/massiveMint/mintBatch';
import { handleErrors } from 'utils/messages';
import Typography from '@mui/material/Typography';
import { Alert, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getFormattedDateISO8601 } from 'utils/time';
import { useNavigate } from 'react-router-dom';
import { DefaultNFTsAttrsInfor } from './DefaultNFTsAttrsInfo';

export const STEPS = {
  SHARE_INSTRUCTIONS: 0,
  URL_SUBMISSION_FORM: 1,
  PROCESSING_NOTIFICATION: 2,
  CONFIGURE_FORM: 3,
  CONFIRM: 4,
}

export default function MinterBatch({
  isLoading,
  setIsLoading,
  setError,
  onCancel,
  onConfirm,
  collection,
  setNFTs
}) {
  const [activeStep, setActiveStep] = useState(STEPS.SHARE_INSTRUCTIONS);
  const [config, setConfig] = useState(null);
  const { t } = useTranslation("massiveMint");
  const BASE_TRANSLATE = "mint_types.mint_batch";

  const handleSubmit = async (values, paramsFormik) => {
    try {
      setIsLoading(true)
      await postCreateNFTBySheet({
        sheet_uri: values.sheet_uri,
        folder_uri: values.folder_uri,
        collection_key: collection.collection_key,
        blockchain_name: collection.blockchain_name
      });
      const mintData = await getDataIfExistsNfts({ collection_key: collection.collection_key });
      setNFTs(mintData.nfts);
      setActiveStep(STEPS.PROCESSING_NOTIFICATION);
    } catch (e) {
      console.log(e);
      setError(handleErrors(e)?.message || 'An error has occurred, please try again later')
    } finally {
      paramsFormik.setSubmitting(false);
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const nftsInterval = setInterval(async () => {
      try {
        const mintData = await getDataIfExistsNfts({ collection_key: collection.collection_key });
        setNFTs(mintData?.nfts || mintData);
      } catch (e) {
        console.log(e);
      }
    }, 5000);
    return () => clearInterval(nftsInterval);
  }, [])

  const handleOnCancelConfig = async () => {
    setIsLoading(true);
    try {
      await cancelMint({ collection_key: collection.collection_key });
      setActiveStep(STEPS.URL_SUBMISSION_FORM);
    } catch (error) {
      console.log(error);
      setError(handleErrors(error)?.message || 'An error has occurred, please try again later')
    }
    setIsLoading(false);
  }

  const handleSubmitConfig = async (values) => {
    setConfig({
      price: 0,
      collection_key: collection.collection_key,
      reveal_type: values.reveal_type,
      reveal_date: values.reveal_date,
      sale_date: values.reveal_date,
    })
    setActiveStep(STEPS.CONFIRM);
  }

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await postConfirmMint(config);
      onConfirm();
    } catch (error) {
      console.log(error);
      setError(handleErrors(error)?.message || 'An error has occurred, please try again later')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box id="minter-batch">
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>{t(`${BASE_TRANSLATE}.steps.share_instructions`)}</StepLabel>
          <StepContent>
            <Typography variant='body1' component='div' mb={1} data-testid="minter-batch-share-instructions">
              {`${t("mintBatch.shared_link")}${process.env.REACT_APP_MINTER_EMAIL}`}
            </Typography>
            <Button
              id="minter-batch-accept-shared-links-button"
              onClick={() => setActiveStep(STEPS.URL_SUBMISSION_FORM)}
              variant='contained'
            >
              {t("options.continue")}
            </Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t(`${BASE_TRANSLATE}.steps.url_submmition_form`)}</StepLabel>
          <StepContent>
            <DefaultNFTsAttrsInfor collection={collection} />
            <ResourcesForm isLoading={isLoading} handleSubmit={handleSubmit} onCancel={onCancel} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t(`${BASE_TRANSLATE}.steps.processing_notification`)}</StepLabel>
          <StepContent>
            <ProccessingNFTsMessage collection_key={collection.collection_key} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t(`${BASE_TRANSLATE}.steps.configure_nfts`)}</StepLabel>
          <StepContent>
            <ConfigureForm isLoading={isLoading} onSubmit={handleSubmitConfig} onCancel={handleOnCancelConfig} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t(`${BASE_TRANSLATE}.steps.confirm`)}</StepLabel>
          <StepContent>
            <Button
              id="minter-batch-back-to-configure-button"
              onClick={() => setActiveStep(STEPS.CONFIGURE_FORM)}
            >
              {t("mint_types.mint.actions.back")}
            </Button>
            <Button
              id="minter-batch-confirm-button"
              onClick={() => handleConfirm()}
            >
              {t("mint_types.mint.actions.confirm")}
            </Button>
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
}

const ProccessingNFTsMessage = ({collection_key}) => {
  const { t } = useTranslation("massiveMint");
  const BASE_TRANSLATE = "mint_types.mint_batch.processing_notification";
  const navigate = useNavigate();

  const handleGoToCollectionOverview = () => {
    navigate(`/dashBoard/collections/${collection_key}`);
  }

  return (
    <Box>
      <Alert
        id='minter-batch-proccessing-message'
        severity="info"
        sx={{ mb: 1 }}
      >
        {t(`${BASE_TRANSLATE}.message`)}
      </Alert>
      <Button
        id="minter-batch-go-to-collection-overview-button"
        variant="contained"
        onClick={handleGoToCollectionOverview}
      >
        {t(`${BASE_TRANSLATE}.go_to_collection_overview_button`)}
      </Button>
    </Box>
  );
}

ProccessingNFTsMessage.propTypes = {
  collection_key: PropTypes.string
}

const formResorucesFields = {
  sheet_uri: 'url',
  folder_uri: 'url',
  accept: 'checkbox'
};

const formResorucesignoreValidation = {
  urlSVG: false,
  urlFolder: false,
  accept: false,
};

const ResourcesForm = ({ isLoading, handleSubmit, onCancel }) => {
  const { t } = useTranslation("massiveMint");
  const BASE_TRANSLATE = "mint_types.mint_batch.form_process_assets";

  return (
    <NewFormDynamic
      initValues={formResorucesFields}
      loading={isLoading}
      onSubmit={handleSubmit}
      t={t}
      handleCancel={onCancel}
      textLabels={{
        sheet_uri: t(`${BASE_TRANSLATE}.sheet_uri`),
        folder_uri: t(`${BASE_TRANSLATE}.folder_uri`),
        accept: t(`${BASE_TRANSLATE}.accept_chekcbox`)
      }}
      textPlaceholders={{
        sheet_uri: t(`${BASE_TRANSLATE}.sheet_uri_placeholder`),
        folder_uri: t(`${BASE_TRANSLATE}.folder_uri_placeholder`),
      }}
      textButtons={{
        submit: t("options.send"),
        cancel: t("options.cancel")
      }}
      enableReinitialize={true}
      ignoreValidation={formResorucesignoreValidation}
      oneColumn={true}
    />
  );
}

const formConfigureFields = {
  reveal_date: 'date_time'
};

const formConfigInitialValues = {
  reveal_date: new Date()
}

const formConfigureignoreValidation = {
  reveal_date: true,
  price: false
};

const ConfigureForm = ({ isLoading, onSubmit, onCancel }) => {
  const { t } = useTranslation("massiveMint");
  const BASE_TRANSLATE = "mint_types.mint_batch.form_configure";

  const handleSubmit = (values, { setSubmitting }) => {
    const reveal_date = values.reveal_date
    reveal_date.setSeconds(0)
    reveal_date.setMilliseconds(0)
    const today = new Date()
    today.setSeconds(0)
    today.setMilliseconds(0)
    onSubmit({
      price: 0,
      reveal_date: getFormattedDateISO8601(reveal_date),
      reveal_type: "mint",
      sale_date: getFormattedDateISO8601(reveal_date)
    });
    setSubmitting(false);
  }

  return (
    <NewFormDynamic
      initValues={formConfigureFields}
      initStateWithValues={formConfigInitialValues}
      loading={isLoading}
      onSubmit={handleSubmit}
      t={t}
      handleCancel={onCancel}
      textLabels={{
        price: t(`${BASE_TRANSLATE}.price`),
        reveal_date: t(`${BASE_TRANSLATE}.reveal_date`),
        mint_type: t(`${BASE_TRANSLATE}.mint_type`),
      }}
      textPlaceholders={{
        price: t(`${BASE_TRANSLATE}.price_placeholder`),
        reveal_date: t(`${BASE_TRANSLATE}.reveal_date_placeholder`),
      }}
      textButtons={{
        submit: t("options.send"),
        cancel: t("options.cancel")
      }}
      enableReinitialize={true}
      ignoreValidation={formConfigureignoreValidation}
      oneColumn={true}
    />
  );
}
