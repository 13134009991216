import mintAndList from 'contracts/PerseaSimpleCollection.json';
import CollectiblesWhitWhitelist from 'services/contracts/abis/CollectiblesWhitWhitelist.json';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import axios from "axios";
import { appInfo } from "./app";
import { findByWallet } from "./user";
import { buildUrlParams } from "utils/fetch";
axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;

const PATH = "blockchain";
const URL = process.env.REACT_APP_API;

const CONTRACTS_ABIS = {
  COLLECTIBLES_WHITELIST: 7
}

const abis = {
  1: mintAndList,
  [CONTRACTS_ABIS.COLLECTIBLES_WHITELIST]: CollectiblesWhitWhitelist,
}
const url = process.env.REACT_APP_API + "blockchain?domain" + process.env.REACT_APP_DOMAIN

export const getAllBlockchainByDomain = (domain='') => {
  return axios.get(`${process.env.REACT_APP_API}/blockchain?domain=${domain}&testnet=true&limit=10&page=0&order=name`).then(res => res.data)
}

export const getAllBlockchain = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {
    limit: 30,
    page: 0,
    order: "created"
  }
}) => {
  const result = axios.get(
    `${URL}/${PATH}?domain=${domain}${buildUrlParams(params)}`
  );
  const response = await result;
  const blockchains = response?.data?.map((item)=>{
    return {
      ...item,
      label:item.name,
      value:item.name
    }
  })
  return blockchains;
};




export const findAllByDomain = (domain) => {
  return axios.get(url + "?domain=" + domain).then(res => res.data)
}

export const findAllByName = async (domain, name) => {
  const blockchains = await findAllByDomain(domain)
  let newBlockchain = null;
  blockchains.forEach(blockchain => {
    if (toString(blockchain.name).toUpperCase() === toString(name).toUpperCase()) {
      newBlockchain = blockchain
    }
  })
  return newBlockchain
}

export const getWebInstance = (provider, rpc = null) => {
  let web3 = null;
  if (provider) {
    web3 = new Web3(provider);
  } else {
    if (rpc) {
      web3 = new Web3(rpc);
    } else {
      web3 = new Web3(process.env.REACT_APP_RPC);
    }
  }
  return web3
}

export const sales = async (provider, to, rpc = null, contractType = 1) => {
  console.log(provider, to, rpc, contractType)
  let data = await appInfo()
  return data[process.env.REACT_APP_DOMAIN][to]['count_sales']
}

export const contractSales = async (provider, to, rpc = null, contractType = 1) => {
  let web3 = getWebInstance(provider, rpc)
  let contract = new web3.eth.Contract(abis[contractType].abi, web3.utils.toChecksumAddress(to));
  let sales = await contract.methods.sales().call();
  console.log('sales', sales);
  return parseInt(sales);
}

export const priceWei = async (provider, to, rpc = null, contractType = 1) => {
  let web3 = getWebInstance(provider, rpc)
  let contract = new web3.eth.Contract(abis[contractType].abi, web3.utils.toChecksumAddress(to));
  let price = await contract.methods.currentPrice().call()
  console.log('price', price)
  return price
}

export const price = async (provider, to, rpc = null, contractType = 1) => {
  console.log(provider, to, rpc, contractType)
  let data = await appInfo()
  return { eth: parseFloat("" + data[process.env.REACT_APP_DOMAIN][to]['price']), mxn: parseFloat("" + data[process.env.REACT_APP_DOMAIN][to]['price_mxn']) }
}

export const priceMxn = async (provider, to, rpc = null, contractType = 1) => {
  console.log(provider, to, rpc, contractType)
  let data = await appInfo()
  return parseFloat("" + data[process.env.REACT_APP_DOMAIN][to]['price_mxn']).toFixed(4)
}

export const contractPrice = async (provider, to, rpc = null, contractType = 1) => {
  let web3 = getWebInstance(provider, rpc)
  let contract = new web3.eth.Contract(abis[contractType].abi, web3.utils.toChecksumAddress(to));
  let price = await contract.methods.currentPrice().call()
  console.log('price', price)
  return web3.utils.fromWei("" + price, 'ether')
}


export const getIfHaveTxt = async (domain, blockchain, from, gas = 'NONE') => {
  const url = `${process.env.REACT_APP_API}/transaction?blockchain=${blockchain}&wallet=${from}&domain=${domain}&gasinfo=${gas}`
  return axios.get(url).then(res => res.data)
}

export const validateTx = async (domain, blockchain, from, tx) => {
  const url = `${process.env.REACT_APP_API}/transaction?blockchain=${blockchain}&wallet=${from}&domain=${domain}&gasinfo=NONE&transaction=${tx}`
  return axios.get(url).then(res => res.data)
}



export const payableMint = async (provider, blockchain, from, to, tokenId, contractType = 1) => {
  console.log(provider, blockchain, from, to, tokenId, contractType)
  let transaction = await getIfHaveTxt(process.env.REACT_APP_DOMAIN, blockchain, from, 'FULL')
  if (transaction.gas_info) {
    let gasInfo = transaction.gas_info;
    gasInfo['value'] = await priceWei(provider, to)
    let web3 = new Web3(provider);
    let contract = new web3.eth.Contract(
      abis[contractType].abi,
      web3.utils.toChecksumAddress(to)
    );
    await contract.methods.payableMint(1, tokenId).send(gasInfo, async (error, transaction) => {
      console.log('error', error);
      console.log('transaction', transaction);
      if (error) {
        throw new Error("Please contact support with the administrator")
      }
      if (transaction) {
        await validateTx(process.env.REACT_APP_DOMAIN, blockchain, from, transaction)
        let intervalValidateTransaction = setInterval(async () => {
          let validation = await getIfHaveTxt(process.env.REACT_APP_DOMAIN, blockchain, from)
          if (!validation.pending) {
            if (validation && validation.receipt && validation.receipt.transaction) {
              clearInterval(intervalValidateTransaction)
              return transaction
            } else {
              clearInterval(intervalValidateTransaction)
              if (error.message) {
                throw new Error(error.message)
              }
              throw new Error("Please contact support with the administrator")
            }
          }
        }, 6000)
      }

    })

  } else {
    let web3 = new Web3(provider);
    let contract = new web3.eth.Contract(
      abis[contractType].abi,
      web3.utils.toChecksumAddress(to)
    );
    let value = await priceWei(provider, to)
    await contract.methods.payableMint(1, tokenId).send({
      from: from,
      value: value
    }, (error, transaction) => {
      console.log('error', error);
      console.log('transaction', transaction);
    })
  }
};

export const web3Connection = async (provider) => {
  const web3 = new Web3(provider)
  const chainID = await web3.eth.getChainId()
  const network = await web3.eth.net.getNetworkType()
  const accounts = await web3.eth.getAccounts()
  const wallet = accounts[0];
  const infoUser = await findByWallet(process.env.REACT_APP_DOMAIN ,process.env.REACT_APP_NAME_NETWORK , wallet);
  
  if(wallet === undefined || wallet === null){
    return null
  }
  
  return {
    provider,
    chainID,
    network,
    wallet,
    ...infoUser
  }
}

export const loginMetamask = async () => {
  let provider = await detectEthereumProvider();

  if (!provider || typeof window.ethereum === 'undefined') {
    throw Error('Por favor instala un wallet de navegador')
  }

  try {
    await provider.request({ method: 'eth_requestAccounts' });
    return await web3Connection(provider);
  } catch (error) {
    console.error('metamask error::', error);
    localStorage.clear()
    throw Error;
  }
}

export const getCurrentUser = async () => {
  const provider = await detectEthereumProvider();
  if (!provider) return null;
  try {
    const accounts = await provider.request({ method: 'eth_accounts' });
    if (!accounts || accounts.length === 0) return null;
    return await web3Connection(provider);
  } catch (error) {
    console.error('metamask error::', error);
    return null;
  }
}

export const changeNetwork = async (provider, chainId) => {
  return provider.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: `${chainId}` }],
  })
}

export const registerNetwork = async (provider, params) => {
  return provider.request({
    method: 'wallet_addEthereumChain',
    params: [params],
  });
}

export const sign = async (from, provider, text = "CREATE_COLLECTION") => {
  if (!from || !provider) {
    throw new Error("Invalid parameters passed to the sign function.");
  }

  const web3 = new Web3(provider);
  const timestamp = new Date().getTime();
  const message = web3.utils.sha3(`${text} ${timestamp}`);

  const signature = await web3.eth.personal.sign(message, from);

  localStorage.setItem('signature', signature);
  localStorage.setItem('message', message);

  return { signature, message };
};

export const isAddress = (address) => {
  const web3 = new Web3();
  return web3.utils.isAddress(address)
}

export const deployERC721WithWhiteList = async (from, provider, name, symbol, version, manager, limit, folder, whitelistAddresses, whitelistAmounts) => {
  const web3 = new Web3(provider);
  const abi = abis[CONTRACTS_ABIS.COLLECTIBLES_WHITELIST];
  const contract = new web3.eth.Contract(abi.abi)
  const chainId = await web3.eth.getChainId()
  const send = await getGasConfigurationFor(chainId)
  send['from'] = web3.utils.toChecksumAddress(from)
  return contract.deploy({
    data: abi.bytecode,
    arguments: [
      name, symbol, version, manager, whitelistAddresses, whitelistAmounts, { folderURI: folder, limit: limit }
    ]
  }).send(send)
}

export const getGasConfigurationFor = async (chainId) => {
  let data = {};
  console.log('chainId 2', chainId)
  // if(chainId === 80001) {
  //     let gas = await getGasConfigurationMumbai();
  //     console.log('gas ::',gas)
  //     data = gas.fast
  // }
  return data;
}

export const getGasConfigurationMumbai = async () => {
  return await axios.get('https://gasstation-mumbai.matic.today/v2').then((response) => response.data)
}