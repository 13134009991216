import { useCallback, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { listMenuDashboard } from "utils/icons";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';

const ItemMenuCustom = ({item}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('menuDashboard');
  const { pathname } = useLocation();
  const eventName = pathname.split('/')[2] || '';
  const [expand,setExpand] = useState(true);

  const commonStyles = {
    gap:'0.3rem',
    color: (theme) => theme.palette.menuText.main,
    '&:hover': {
      background: (theme) => theme.palette.menuHover.main
    }
  };

  const activeItemStyles = (name) => ({
    ...commonStyles,
    gap:'0.3rem',
    background: (eventName === '' && name ==='overview' ) || String(eventName).includes(name+'')  ?  (theme) => theme.palette.backgroundSelected.main: 'none',
    color: (eventName === '' && name ==='overview' ) || String(eventName).includes(name+'')  ?  (theme) => theme.palette.textSelected.main : commonStyles.color,
    boxShadow: (eventName === '' && name ==='overview' ) || String(eventName).includes(name+'')  ?  (theme) => ` inset -4px 0 0 ${theme.palette.textSelected.main}` : 'none'
  });

  const itemIconsStyles ={
    padding: '0px 0px',
    maxWidth: 'auto',
    minWidth: 'auto'
  }

  const navigateToPage = useCallback((link) => () => {
    navigate(`/dashboard/${link}`);
  }, [navigate]);
  
  const DISABLED_COLLAPSE = {
    "assetHub": true,
    "members": true
  }

  const handleExpand = useCallback((label) => ()=>{
    if(!DISABLED_COLLAPSE?.[label]){
      setExpand(!expand)
    }   
  },[setExpand,expand,item]);
  
  return (
    <Fragment>
      {item?.options?.length > 0 &&
      (<div style={{display:'flex',flex:'1',flexDirection:'column',color:'gray',padding:'0.2em 0px'}}>
        {item?.label &&
        <div style={{display:'flex',flex:'1',flexDirection:'row',justifyContent:'space-between'}}>
          <ListItemText
            primary={t(`${item.label}`)}
            onClick={handleExpand(item.label)} 
            sx={{
              padding:'.8em 1em',
              cursor:'pointer'
            }}
          />
        </div>}
        <Collapse in={expand} timeout="auto" unmountOnExit>
        {
        item.options?.map((option,indexOption)=>(
          <ListItemButton 
            key={option?.id || indexOption}
            onClick={navigateToPage(option.name)} 
            sx={activeItemStyles(option.name)}
          >
            <ListItemIcon
              sx={itemIconsStyles}
            >
              <option.icon 
                sx={{ 
                  color: 
                    (eventName === '' && option.name ==='overview' ) || String(eventName).includes(option.name+'')  ?
                      (theme) => theme.palette.textSelected.main 
                      :
                      commonStyles.color 
                }} 
              />
            </ListItemIcon>
            <ListItemText primary={t(`${option.translationKey}`)} />
          </ListItemButton>
        ))}
        </Collapse>
        <div style={{display:'flex',marginTop:'1em'}}>
          <div style={{width:'80%',margin:'0 auto'}}>
            <Divider />
          </div>
        </div>
      </div>
      )}
    </Fragment>
  )
}

const MenuDashboard = () => {

  return (
    <List disablePadding sx={{ background: (theme) => theme.palette.darkGreen.main, width: '100%', height: 'calc(100vh - 70px)',overflowY:'auto' }}>
      {listMenuDashboard?.map((item,indexItem)=>{
        return (
        <div style={{alignSelf:'stretch'}} key={item.id || indexItem}>
          <ItemMenuCustom item={item} />
        </div>)
      })}
    </List>
  );
};

export default MenuDashboard;
