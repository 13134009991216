import { useState } from "react";
import SectionTemplate from "components/SectionTemplate";
import { useTranslation } from 'react-i18next';
import { RANDOM_BENEFITS, RANDOM_COLLECTIONS } from 'mock/dynamicData';
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import DynamicTable from "components/DynamicTable/DynamicTable";

export default function ProjectBenefit() {
  const [selectedBenefit, setSelectedBenefit] = useState(null);

  return (
    <Box>
      <BenefitsSection onSelectBenefit={setSelectedBenefit} />
      {selectedBenefit && <CollectionsSection benefit={selectedBenefit} />}
    </Box>
  );
}

const BenefitsSection = ({ onSelectBenefit }) => {
  const navigate = useNavigate();
  const { t: t_benefits } = useTranslation("benefits");

  const goToCreateBenefit = () => {
    navigate('/benefits/create');
  }

  return (
    <SectionTemplate
      sx={{ mb: 5 }}
      title={t_benefits("benefits.title")}
      description={t_benefits("benefits.description")}
      actions={[
        {
          name: `+ ${t_benefits("benefits.options.create")}`,
          disabled: true,
          action: () => goToCreateBenefit(),
        }
      ]}
    >
      <DynamicTable
        title={t_benefits("benefits.table.title")}
        activeSearchBar={true}
        activeModeView={false}
        activeFilter={true}
        data={RANDOM_BENEFITS}
        setConfigParams={{
          onClick: (item) => onSelectBenefit(item)
        }}
        preparedDataConfig={(item) => {
          return {
            id: item.id,
            image: item.thumb_url,
            name: item.name,
            description: item.description,
            type: item.type,
            external_id: item.external_id,
            contract_address: item.contract_address,
          }
        }}
      />
    </SectionTemplate>
  );
}

const CollectionsSection = ({ benefit }) => {
  const { t: t_benefits } = useTranslation("benefits");
  console.log(benefit);

  return (
    <SectionTemplate
      title={t_benefits("collections.title")}
    >
      <DynamicTable
        title={t_benefits("benefits.table.title")}
        activeSearchBar={true}
        data={RANDOM_COLLECTIONS}
        preparedDataConfig={(item)=>{
          return {
            project_key: item.collection_key,
            image: item.thumb_url,
            name: item.name,
            description: item.description,
            total_nfts: item.collection_of,
            price: item.floor_price,
            status: item.status
          }
        }}
      />
    </SectionTemplate>
  );
}