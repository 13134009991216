import axios from "axios";
axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;


export const postCreateNFTByCopy = async (body) => {
  const res = await axios.post(`${process.env.REACT_APP_API}/nft/massive/copy`, body, {
    headers: {
      'X-API-Key': localStorage.getItem("userApiKey"),
      'Referer': window.location.href
    },
    params: { domain: process.env.REACT_APP_DOMAIN }
  });
  return res.data;
}
