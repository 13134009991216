import React, { useEffect, useState, useCallback} from 'react';
import styles from "./UsersDesktop.module.css";
import Typography from '@mui/material/Typography';
import { findAll } from "services/user";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ColorsInfo from 'components/ColorsInfo/ColorsInfo';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import { shortAddress } from 'utils/strings';
import { STATUS_USERS } from 'utils/status/userStatus';
import DynamicTable from 'components/DynamicTable/DynamicTable';


const UsersDesktop = () => {
  const { t } = useTranslation('users');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [ itemStatus, setItemStatus ] = useState([
    {
      label: t("status_info.inactive"),
      color: "statusGray"
    },
    {
      label: t("status_info.pending"),
      color: "statusYellow"
    },
    {
      label: t("status_info.rejected"),
      color: "statusRed"
    },
    {
      label: t("status_info.active"),
      color: "statusGreen"
    }
  ]);

  const handleGo = useCallback((path) => () => {
    navigate(`/dashboard/users/${path}`);
  },[navigate]);


  useEffect(() => {
    (async () => {
      setLoading(true);
      const domain = process.env.REACT_APP_DOMAIN;
      const limit = 50;
      const page = 0
      const order = 'last_seen';
      const result = await findAll(domain, limit, page, order);
      const filteredUsers = result.filter(user => user.role > 1);
      setUsers(filteredUsers);
      setLoading(false);
    })();
  }, []);

  const statusOptions = {
    [STATUS_USERS.INACTIVE]: {
      label: t("status_user.status_inactive"),
      color: "statusGray"
    },
    [STATUS_USERS.APPROVED]: {
      label: t("status_user.status_active"),
      color: "statusGreen"
    },
    [STATUS_USERS.PENDING_INVITATION]: {
      label: t("status_user.status_pending_invitation"),
      color: "statusYellow"
    },
    [STATUS_USERS.REJECTED]: {
      label: t("status_user.status_rejected"),
      color: "statusRed"
    }
  }

  const configCells = {
    status:{
      render: (item)=>{
        return (
          <TableCell>
            <Chip 
              label={statusOptions[item]?.label || statusOptions[STATUS_USERS.APPROVED].label}
              color={statusOptions[item]?.color || statusOptions[STATUS_USERS.APPROVED].color}
            />
          </TableCell>
        )
      }
    }
  }

  const configSelected = {
    defineId: 'address',
    options:[
      {
        id:'approve',
        label: 'Approve',
        color: 'success',
        variant: 'contained',
        tooltip:'Click to approve',
        onClick: async (item) => {
          console.log("debug item::",item)
        },
        conditions: (item)=>{
          return item?.status === 0
        }      
      },
      {
        id:'rejected',
        label: 'Rejected',
        color: 'error',
        variant: 'contained',
        tooltip:'Click to rejected',
        onClick: async (item) => {
          console.log("debug item::",item)
        },
        conditions: (item)=>{
          return item?.status === 0
        }      
      }
    ]
  }

  return (
    <section className={styles.contentSection} >
    <div className={styles.headerSection}>
      <div className={styles.subHeaderSection}>
        <Typography variant="body2" gutterBottom flex={1}>
          {t("description")}
        </Typography>
        <Button 
          variant="outlined" 
          color="info"
          onClick={handleGo("create")}
        >
          +{t("options.create_user")}
        </Button>
      </div>
    </div>
    <div className={styles.workSpace}>
      <DynamicTable 
        title={t("list_of_users")}
        activeSearchBar={true}
        activeModeView={false}
        activeFilter={true}
        data={users}
        loading={loading}
        preparedDataConfig={(item)=>{
          return {
            username: shortAddress(item.username),
            role: item.role_name,
            email: shortAddress(item.email),
            nfts: item.nfts,
            // benefits: item.benefits,
            // consumed_benefits: item.consumed_benefits,
            status: item.status || 1,
            address: shortAddress(String(item.main_key).toLowerCase())
          }
        }}
        setConfigParams={{
          configCells,
          configSelected,
          onClick:(item)=> handleGo(`edit/${item.main_key}`)()
        }}
      />
      <ColorsInfo items={itemStatus} setItemStatus={setItemStatus}/>
    </div>
  </section>
  );
};

export default UsersDesktop;
