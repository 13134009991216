import React from 'react';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import { useTranslation } from 'react-i18next';

export default function AccessControlForm({ onSubmit, onCancel }) {
  const { t } = useTranslation('clients');

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    onSubmit({...values});
  }

  const handleCancel = ({ setSubmitting }) => {
    setSubmitting(false);
    onCancel();
  }

  const formFields = {
    domain: 'string_short'
  };

  return (
    <div data-testid="create-access-control-form">
      <NewFormDynamic
        initValues={formFields}
        onSubmit={handleSubmit}
        handleCancel={handleCancel}
        textButtons={{
          submit:t("options.accept"),
          cancel:t("options.cancel")
        }}
        textLabels={{
          domain:t("form.domain"),
        }}
        textPlaceholders={{
          domain:t("form.domain"),
        }}
      />
    </div>
  );
}


