import styles from "./SeleccionaElementoDestacado.module.css";
const SeleccionaElementoDestacado = () => {
  return (
    <div className={styles.seleccionaElementoDestacado}>
      <div className={styles.quTipoDe}>
        ¿Qué tipo de elemento deseas destacar?
      </div>
      <div className={styles.frameParent}>
        <div className={styles.coleccinCompletaWrapper}>
          <b className={styles.coleccinCompleta}>Colección completa</b>
        </div>
        <div className={styles.coleccinCompletaWrapper}>
          <b className={styles.coleccinCompleta}>
            <p className={styles.coleccionable}>Coleccionable</p>
            <p className={styles.coleccionable}>individual</p>
          </b>
        </div>
      </div>
      <div className={styles.botones}>
        <div className={styles.botn}>
          <div className={styles.continuar}>Siguiente</div>
        </div>
        <div className={styles.cancelar}>
          <div className={styles.cancelar1}>Cancelar</div>
        </div>
      </div>
      <img className={styles.vectorIcon} alt="" src="/vector11.svg" />
    </div>
  );
};

export default SeleccionaElementoDestacado;
