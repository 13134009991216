import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import RenderViewContainer from './components/RenderViewContainer/RenderViewContainer';

const DynamicTable = ({
  data = [],
  setConfigParams = {},
  preparedDataConfig,
  activeSearchBar = false,
  title = 'Title table',
  activeModeView = true,
  loading = false,
  activeFilter = false,
  startRowsPerPage = 10,
  activeSort = true
}) => {
  const isPreparedDataConfigFunction = typeof preparedDataConfig === 'function';
  
  const getPreparedData = useCallback(() => {
    if (isPreparedDataConfigFunction) {
      return data.map(entry => {
        try {
          if (isPreparedDataConfigFunction) {
            return preparedDataConfig(entry);
          }
        } catch (error) {
          console.error(error);
          return {};
        }
      });
    }
    return [];
  },[isPreparedDataConfigFunction,preparedDataConfig,data]);

  const mergeData = useCallback((additionalData, originalData) => {
    if (additionalData?.length > 0) {
      const concatArray =  originalData.map((item, index) => ({
        ...item,
        ...additionalData?.[index],
      }));
      return concatArray;
    }
    return originalData;
  },[]);

  const preparedData = getPreparedData();
  const allowedKeys = preparedData?.length > 0 ? Object.keys(preparedData[0]) : [];
  const newData = mergeData(preparedData, data);

  return (
    <RenderViewContainer
      data={newData}
      allowedKeys={allowedKeys}
      setConfigParams={setConfigParams}
      activeSearchBar={activeSearchBar}
      title={title}
      activeModeView={activeModeView}
      loading={loading}
      activeFilter={activeFilter}
      startRowsPerPage={startRowsPerPage}
      activeSort={activeSort}
    />
  );
};

DynamicTable.propTypes = {
  data: PropTypes.array,
  setConfigParams: PropTypes.object,
  preparedDataConfig: PropTypes.func,
  activeSearchBar: PropTypes.bool,
  title: PropTypes.string,
  activeModeView: PropTypes.bool,
  loading: PropTypes.bool,
  activeFilter: PropTypes.bool,
  startRowsPerPage: PropTypes.number,
  activeSort: PropTypes.bool
};

export default DynamicTable;
