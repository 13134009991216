import React from 'react';
import PropTypes from 'prop-types';
import styles from '../NftDetails.module.css';


const MediaRenderer = ({ url, is_video }) => {
    if (is_video) {
        return (
            <video controls src={url} className={styles.image} />
        );
    }
    return (
        <img src={url} alt="" className={styles.image}/>
    );
};

MediaRenderer.propTypes = {
    url: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['image', 'video']).isRequired,
};

export default MediaRenderer;
