import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

export default function CreateMessageButton({...props}) {
  const { t } = useTranslation("messages");

  return (
    <Button
      data-testid="create-message-button"
      variant="outlined"
      color="info"
      {...props}
    >
      + {t("actions.create")}
    </Button>
  )
}