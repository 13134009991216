export const IPFS_GATEWAY = process.env.REACT_APP_IPFS_GATEWAY;

export const REWARD_TYPES = {
  PHYSICAL: 0,
  ERC20: 1,
  ERC721: 2,
};

export const MINT_STRATEGIES = {
  UNDEFINED: "UNDEFINED",
  UNKNOWN: "UNKNOWN",
  COPY: "COPY",
  SPREADSHEET: "SPREADSHEET",
}

export const DEFAULT_ATTRS_RESTRICTION_LEVELS = {
  OPTIONAL: "OPTIONAL",
  EDITABLE: "EDITABLE",
  REQUIRED: "REQUIRED",
}