import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function CreatePostButton({campaignId, ...props}) {
  const { t } = useTranslation("campaigns");
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      data-testid="create-post-button"
      color="info"
      onClick={() => navigate(`/dashboard/campaigns/${campaignId}/createPost`)}
      {...props}
    >
      + {t("options.create_post")}
    </Button>
  )
}