export const STATUS_COLLECTION = {
  PENDING_APPROVE: 0,
  APPROVED: 1,
  LOADING_NFTs: 2,
  LOADED_NFTs: 3,
  PENDING_APPROVE_NFTs: 4,
  APPROVED_NFTs: 5,
  REJECTED_NFTs: 6,
  REJECTED: 7,
  IN_PRODUCTION: 8,
  HIDE_COLLECTION: 12,
  LOCK_COLLECTION: 13,
}