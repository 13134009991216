import React, { useState, useCallback, useContext, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import CardMedia from '@mui/material/CardMedia';
import { createCollectionBenefit } from 'services/collections/collection';
import { formatTimestampToLocaleString, formatTimestampToLocaleStringShort, fullDate, timestampToFormattedString } from 'utils/date';
import UserContext from 'context/UserContext';
import { useConfig } from './utils/useConfig';
import { BreadCrumbsSaveData } from 'components/BreadCrumbs/BreadCrumbs';
import { getCollectionByKey } from 'services/collection/collections';
import { useFetch } from 'hooks/useFetch';
import { findAllByCollectionKey, update } from 'services/nfts';
import { update as updateCollections } from 'services/collections/collection';
import { getBenefitCollection, findBenefits } from 'services/benefits';
import { Stack } from '@mui/material';
import { findAllAssociatedCampaigns } from "services/collection/collectionCampaigns"
import { STATUS_COLLECTION } from 'utils/collectionStatus';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import { getActionsByRoleCode } from 'utils/rols';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { useSpinnerContext } from 'context/SpinnerContext';
import WithLoadingBackdropAndErrorSnack from 'HOCS/WithLoadingBackdropAndErrorSnack';
import { updateManyBenefit } from 'services/benefits';
import { deleteMintUrls, getMintUrlsCustom } from 'services/urls';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import UrlModal from './components/urlModal'
import { getUrl } from 'services/relay/relay'
import HtmlViewer from './components/htmlViewer';
import DynamicTable from 'components/DynamicTable/DynamicTable';
import ExtendCollectionNFTsModal from './components/extendCollectionNFTsModal';
import { findThumbnail } from 'utils/thumbnail';
import { MINT_STRATEGIES } from 'utils/constants';


const activeItemStyles = () => ({
  background: (theme) => theme.palette.primary.main,
  color:  (theme) => theme.palette.backgroundSelected.main,
  boxShadow:  (theme) => ` inset -4px 0 0 ${theme.palette.secondary.main}`,
  '&:hover': {
    background: (theme) => theme.palette.primary.main,
    color:  (theme) => theme.palette.backgroundSelected.main,
  }
});

const itemStyles = () => ({
  '&:hover': {
    background: (theme) => theme.palette.primary.main,
    color:  "#fff"
  }
});

export function OverviewCollection ({setError, setIsLoading}) {

  const { t } = useTranslation("collections");
  const navigate = useNavigate();
  const { user, executeFunctionIfUserIsConnected } = useContext(UserContext)
  const { collectionKey } = useParams();

  const [initParams, setInitParams] = useState({
    collection_key: collectionKey,
    limit: 100,
    page: 0,
    order: 'created',
  });
  const { data:collection, loading } = useFetch(getCollectionByKey,{ params: initParams });
  const { data:nfts, loading: loadingNFT, refresh: refreshNFTs } = useFetch(findAllByCollectionKey,{ params: initParams });
  const { data:collectionBenefits, loading: loadingBenefits, refresh } = useFetch(findBenefits,{ params: initParams});
  const { data:benefitsCollection, loading: loadingBenefitsCollection } = useFetch(getBenefitCollection, {params: initParams})
  const { data:campaigns, loading:loadingCampaigns } = useFetch(findAllAssociatedCampaigns,{ 
    params: { collection_key:collectionKey, limit: 100, page: 0} 
  });
  const { data:mintedUrls, loading: loadingMintedUrls, refresh: refreshMintedUrls } = useFetch(getMintUrlsCustom,{ params: initParams});

  const [hasCampaigns, setHasCampaigns] = useState(false);
  
  const { handleChangeLoading ,handleChangeMessage, handleChangeError } = useSpinnerContext();
  const [ active, setActive ] = useState(1);
  const [generatedUrl, setGeneratedUrl] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleApproveCollection = async () => {
    try {
      handleChangeLoading(true);
      let approvedCollection = collection
      approvedCollection.status = 1
      const responseData = await updateCollections({ collections: [approvedCollection]});
      const newCollectionKey = responseData[0]?.collection_key;
      setInitParams({ ...initParams, collection_key: newCollectionKey });
      if (!window.Cypress) {//TODO: remove this condition when cypress is fixed
        navigate(`/dashboard/collections/${newCollectionKey}`, { replace: true });
      }
    } catch (error) {
      handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
    } finally {
      handleChangeLoading(false);
    }
  }

  const { 
    menu, 
    ALERT_TYPES, 
    tablePropsBenefit,
    configBenefits,
    configNFTs,
    validateIfActiveButtonCreateNFT,
    SkeletonCollection,
  } = useConfig(t,user, handleApproveCollection);
  const inProcessCollection = validateIfActiveButtonCreateNFT(Number(collection?.status));

  const handleGo = useCallback((path) => () => {
    navigate(`/dashboard/collections/${path}`);
  },[navigate]);

  const handleGoEmpty = (path) => {
    navigate(`/dashboard/${path}`);
  }

  const handleEdit = useCallback(() => {
    navigate(`/dashboard/collections/edit/${collectionKey}`);
  },[navigate]);

  const handleSelected = (step) => {
    setActive(step);
  }

  const generateUrlHandler = async () => {
    console.log("Generate URL")
    await executeFunctionIfUserIsConnected(async()=>{
      console.log("Generate URL1")
      try {
        handleChangeLoading(true);
        handleChangeMessage(t("message.generating_url"));
        const body = {
          type: "CREATE",
          collection_key:collectionKey,
          quantity: 1
        }
        const obtainedUrl = await getUrl(body)
        setGeneratedUrl(obtainedUrl?.data);
        refreshMintedUrls()
        handleOpenModal();
      } catch (error) {
        handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
      } finally {
        handleChangeLoading(false);
      }
    });
  }

  const handleOpenModal = () => {
    console.log("Modal is opened")
    setIsModalOpen(true);
  }

  useEffect(() => {
    if (campaigns && campaigns.length > 0) {
      setHasCampaigns(true);
    } else {
      setHasCampaigns(false);
    }
  }, [campaigns]);

  const filteredMenu = menu.filter(item => item.label !== 'Campaigns' || hasCampaigns);

  const statusCampaignOptions = {
    [STATUS_COLLECTION.EMPTY]: {
      status: STATUS_COLLECTION.EMPTY,
      label: t("table.status.empty"),
      color: "primary"
    },
    [STATUS_COLLECTION.WAITING_FOR_APPROVAl]: {
      status: STATUS_COLLECTION.WAITING_FOR_APPROVAl,
      label:t("table.status.waiting_for_approval"),
      color: "warning"
    },
    [STATUS_COLLECTION.APPROVED]: {
      status: STATUS_COLLECTION.APPROVED,
      label: t("table.status.approved"),
      color: "success",
    },
    [STATUS_COLLECTION.REJECTED]: {
      status: STATUS_COLLECTION.REJECTED,
      label: t("table.status.rejected"),
      color: "error"
    }
  }
  
  const configSelectedNFTS = {
    defineId: 'token_id',
    options:[
      {
        id:'approve',
        label: 'Approve',
        color: 'success',
        variant: 'contained',
        tooltip:'Click to approve',
        onClick: async (item) => {
          try {
            handleChangeLoading(true);
            handleChangeMessage(t("message.approving"));
            let nfts = []
            for (const nft of item) {
              nfts.push({
                status : 1,
                collection_key : nft.collection_key,
                token_id : nft.token_id
              })
            }
            await update(nfts)
            handleChangeMessage(t("message.successful_approval"));
            refreshNFTs();
            return true;
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (item?.status === 0 && getActionsByRoleCode(user?.role,'approvedNFTs')) 
        }      
      },
      {
        id:'rejected',
        label: 'Rejected',
        color: 'error',
        variant: 'contained',
        tooltip:'Click to rejected',
        onClick: async (item) => {
          try {
            handleChangeLoading(true);
            handleChangeMessage(t("message.rejecting"));
            let nfts = []
            for (const nft of item) {
              nfts.push({
                status : 8,
                collection_key : nft.collection_key,
                token_id : nft.token_id
              })
            }
            await update(nfts)
            handleChangeMessage(t("message.rejected"));
            refreshNFTs();
            return true;
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,'rejectedNFTs') && item?.status === 0) 
        }      
      },
      {
        id: 'edit',
        label: 'Edit',
        color: 'info',
        variant: 'contained',
        tooltip:'Click to edit',
        onClick: (item) => {
          console.log("debug ",item);
        },
        conditions: ()=>{
          return (getActionsByRoleCode(user?.role,'editNFTs'))
        }
      }
    ]
  }

  const configSelectedBenefits = {
    defineId: 'id',
    options:[
      {
        id:'approve',
        label: 'Approve',
        color: 'success',
        variant: 'contained',
        tooltip:'Click to approve',
        onClick: async (item) => {
          try{
            let benefits = []
            for (let benefit of item) {
              delete benefit.id;
              benefit.status = 1;
              benefits.push(benefit)
            }
            await updateManyBenefit({ benefits : benefits});
            refresh();
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item) => {
          return (getActionsByRoleCode(user?.role,'approveBenefits') && item?.status === 0) 
        }      
      },
      {
        id:'rejected',
        label: 'Rejected',
        color: 'error',
        variant: 'contained',
        tooltip:'Click to rejected',
        onClick: async (item) => {
          try{
            let benefits = []
            for (let benefit of item) {
              benefit.status = 7;
              benefits.push(benefit)
            }
            await updateManyBenefit({ benefits : benefits});
            refresh();
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,'rejectedBenefits') && item?.status === 0)
        }      
      }
    ]
  }

  const configSelectedCampaigns = {
    defineId: 'name',
    options:[
      {
        id:'approve',
        label: 'Approve',
        color: 'success',
        variant: 'contained',
        tooltip:'Click to approve',
        onClick: (item) => {
          console.log("debug ",item);
        },
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,'approveCampaign') && item?.status === 0) 
        }      
      },
      {
        id:'rejected',
        label: 'Rejected',
        color: 'error',
        variant: 'contained',
        tooltip:'Click to rejected',
        onClick: (item) => {
          console.log("debug ",item);
        },
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,'rejectedCampaign') && item?.status === 0) 
        }      
      },
      {
        id: 'edit',
        label: 'Edit',
        color: 'info',
        variant: 'contained',
        tooltip:'Click to edit',
        onClick: (item) => {
          console.log("debug ",item);
        },
        conditions: ()=>{
          return getActionsByRoleCode(user?.role,'editCampaign')
        }
      }
    ]
  }


  const configSelectedUrls = {
    defineId: 'id',
    options:[
      {
        id:'rejected',
        label: 'Delete',
        color: 'error',
        variant: 'contained',
        tooltip:'Click to delete',
        onClick: async (item) => {
          try {
            handleChangeLoading(true);

            console.log("debug ",item);
            for (let _mintedUrl of item) {
              await deleteMintUrls(_mintedUrl?.id)
            }
            refreshMintedUrls()
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          console.log(item)
          return true 
        }      
      }
    ]
  }

  const configCampaigns = {
    blockchain_name:{  
      render: (item) => {
        return (
          <TableCell>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap:'0.7rem',
              }}
            >
              <CardMedia
                component={item?.is_video ? 'video' : 'img'}
                key={item?.chainid}
                sx={{ 
                  width: 30, 
                  height: 30,
                  objectFit: 'contain'
                }}
                src={item?.icon}
              />
            </Box>
          </TableCell>
        )
      }
    },
    campaign_status:{  
      render: (item) => {
        console.log("campaign_status ITEM", item)
        return (
          <TableCell>
            <Chip 
              label={statusCampaignOptions[item].label}
              color={statusCampaignOptions[item].color} 
            />
          </TableCell>
        )
      }
    }
  }

  const mintNFTsButton = (
    <Button
      variant="outlined"
      color="info" 
      disabled={inProcessCollection}
      onClick={handleGo(collectionKey+'/mint')}
    >
      + {t("options.create_nfts")}
    </Button>
  )

  const generateUrlButton = (
    <Button
      variant="outlined"
      color="info" 
      disabled={inProcessCollection}
      onClick={generateUrlHandler}
    >
      + {t("options.generate_url")}
    </Button>
  );


  const NFTsEmpyMessage = (
    <Stack spacing={2} p={1}>
      <span>{t("alerts.empty_nfts_table")}</span>
      <Box>
        { mintNFTsButton }
      </Box>
    </Stack>
  )

  const handleCreateBenefits = async () => {
    setIsLoading(true)
    try {
      const value = {name: "Create benefits"}
      const benefitContract = await getBenefitsContract();
      value.contract_key = benefitContract.contract_key
      const path = `benefits/create/${benefitContract.contract_key}/collection`;
      BreadCrumbsSaveData("contractKey",value);
      handleGoEmpty(path)
    } catch(error) {
      setError(error?.message || ('An unexpected error occurred:'+ error));
    } finally {
      setIsLoading(false)
    }
  }

  const getBenefitsContract = async () => {
    if(benefitsCollection?.length > 0){
      return benefitsCollection[0];
    } else {
      return await createDraftCollectionBenefit();
    }
  }

  const createDraftCollectionBenefit = async () => {
    try {
      const newCollectionBenefits = await createCollectionBenefit(collectionKey, `${collectionKey}DRAFT`);
      console.log("degbug_new col", newCollectionBenefits)
      return newCollectionBenefits[0];
    } catch (error) {
      console.log("debug error",error)
      error.message = t("alerts.error_creating_benefit_collection");
      throw error;
    }
  }

  const mintBenefitsButton =  (
    <Button 
      variant="outlined"
      data-testid="overview-collection-create-benefit-button"
      color="info"
      disabled={inProcessCollection}
      onClick={handleCreateBenefits}
    >
      + {t("options.create_benefit")}
    </Button>
  );

  const benefitsEmptyMessage = (
    <Stack spacing={2} p={1}>
      <span id="overview-collection-empty-benefits-message">{t("alerts.empty_benefits_table")}</span>
      <Box
        id="overview-collection-empty-benefits-button-wrapper"
        display={(Boolean(benefitsCollection.length) && !loadingBenefitsCollection) ? "block" : "none"}
      >
        { mintBenefitsButton }
      </Box>
    </Stack>
  );


  const urlEmptyMessage = (
    <Stack spacing={2} p={1}>
      <span id="overview-collection-empty-urls-message">{t("alerts.empty_urls_table")}</span>
    </Stack>
  );

  const COMPONENT_TABLES = {
    1:{
      title: "NFTs",
      data: nfts,
      loading: loadingNFT,
      preparedDataConfig: (item) => {
        return {
          token_id: item?.token_id,
          thumb_url: {
            is_video: item?.metadata?.is_video,
            thumb_url : findThumbnail(item, 650,500)
          },
          name: item?.name,
          description: item?.description||'',
          blockchain: item?.blockchain,
          status: item?.status,
          price: item?.price+''
        }
      },
      activeSearchBar: true,
      setConfigParams: {
        ...configNFTs,
        configSelected: configSelectedNFTS, 
        allowedKeys:["token_id", "thumb_url", "name", "description", "blockchain", "status", "price"],
        textHeaders:{thumb_url:' ',token_id:'Id'},
        onClick:(item)=>{
          console.log(item)
          const value = {
            name: item.name,
            collection_key: item.token_id
          }
          BreadCrumbsSaveData("tokenId",value);
          handleGo(`${collectionKey}/${item.token_id}`)()
        },
        emptyMessage: NFTsEmpyMessage
      },
      activeFilter: true
    },
    2:{
      title: "Benefits",
      data: collectionBenefits,
      loading: loadingBenefits,
      activeSearchBar: true,
      preparedDataConfig:(item)=>{
        return {
          id: item?.type+item.contract_key,
          thumbnail: item?.reward?.thumb_url ,
          name: item?.reward?.name,
          description: item?.reward?.description,
          status: item?.status,
          created: formatTimestampToLocaleStringShort(item?.reward?.created),
          updated: fullDate(item?.reward?.updated)
        }
      },
      setConfigParams: {
        ...configBenefits,
        ...tablePropsBenefit,
        configSelected:configSelectedBenefits,
        allowedKeys:["thumbnail", "name", "description", "status", "created", "updated"],
        textHeaders:{thumbnail:' '},
        emptyMessage: benefitsEmptyMessage
      }
    },
    3:{
      title: "Campaigns",
      data: campaigns,
      loading: loadingCampaigns,
      activeSearchBar: true,
      preparedDataConfig:(item)=>{
        return {
          thumb_url: (item?.thumbnails) ? findThumbnail(item, 650,500) : item?.thumb_url,
          name: item.name,
          id: item.campaign_id,
          blockchain_name: item.blockchain_name,
          type: item.type,
          campaign_status: item.campaign_status
        }
      },
      setConfigParams: {
        configCells:configCampaigns,
        ...statusCampaignOptions,
        configSelected:configSelectedCampaigns,
        textHeaders:{thumb_url:' '} }
    },
    4:{
      title: "Urls",
      data: mintedUrls,
      loading: loadingMintedUrls,
      activeSearchBar: true,
      preparedDataConfig:(item)=>{
        return {
          url: item?.short_url,
          used: item?.used ? t("table.used.yes") : t("table.used.no"),
          used_by: item?.consumed_by?.username || "",
          created: timestampToFormattedString(item?.created),
          updated: timestampToFormattedString(item?.updated),
          copy: item?.short_url
        }
      },
      setConfigParams: {
        configSelected:configSelectedUrls,
        emptyMessage: urlEmptyMessage,
        allowedKeys:["url", "used", "used_by", "created", "updated","copy"],
        configCells: {
          copy: {
            render: (item) => {
              return (
                <TableCell>
                  <CopyButton item={item} />
                </TableCell>
              )
            }
          }
        }
      }
    },
  }

  const BASIC_INFO = [
    {
      label: t("details.nfts"),
      value: collection?.collection_of || '0',
    },
    {
      label: t("details.minted"),
      value: '0'
    },
    {
      label: t("details.campaigns"),
      value: '0'
    },
    {
      label: t("details.created"),
      value: formatTimestampToLocaleString(collection?.created),
    },
    {
      label: t("details.blockchain"),
      value: collection?.blockchain_name,
      image: collection?.blockchain?.icon
    },
    {
      label: t("details.environment"),
      value: collection?.blockchain?.testnet ? t("details.testnet") : t("details.mainnet"),
    }
  ]

  const [type,setType] = React.useState('img');
  
  const handleError = () => {
    if(type === "img"){
      setType("video");
    } else if (type === "video") {
      setType("error");
    }
  }

  return (
    <Box sx={{alignSelf:'strech',width:'100%'}}>
      {
      <Box display="flex" flexDirection="column" margin="1rem 0px">
        {ALERT_TYPES[collection?.status] || null}
      </Box>
      }
      {
        loading ? <SkeletonCollection /> : 
        <Fragment>
          <Paper elevation={3} style={{ position: 'relative', overflow: 'hidden' }}>
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              sx={{
                width: '100%',
                height: '300px'
              }}
            >
              <img
                src={collection?.banner_url}
                alt="Banner"
                style={{ 
                  position: 'absolute', top: '0', left: '0',
                  width: '100%', height: '100%', 
                  objectFit: 'cover',
                  overflow: 'hidden'
                }}
                />
            </Box>      
            <Box
              position="absolute"
              top={"20px"}
              right={"0px"}
            >
              <CardMedia
                component={type}
                src={(collection?.thumbnails) ? findThumbnail(collection, 650,500) : collection?.thumb_url}
                alt="Avatar"
                style={{ 
                  marginRight: '1rem',
                  width: '100px',
                  height: '100px',
                  border: '5px solid #fff',
                  borderRadius: '10px'
                }}
                loop={type === "video"}
                autoplay={type === "video"}
                onError={handleError}
              />
              <Avatar
                variant="square"
                src={(collection?.thumbnails) ? findThumbnail(collection, 650,500) : collection?.thumb_url}
                alt="Avatar"
                style={{ 
                  display:'none',
                  marginRight: '1rem',
                  width: '100px',
                  height: '100px',
                  border: '5px solid #fff',
                  borderRadius: '10px'
                }}
                onError={handleError}
              />
            </Box>
            <Box
              position="absolute"
              top={"20px"}
              left={"0px"}
            >
              <Box padding={"0px 1rem"}>
                <IconButton 
                  sx={{
                    color:'#fff', 
                    borderRadius: '50% 50%',
                    background:'rgba(0,0,0,.5)',
                    "&:hover":{
                      background:'rgba(0,0,0,.9)',
                    }
                  }}
                  onClick={handleEdit}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
          </Paper>
          <Box position="relative"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '1rem',
              gap: '1rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
              }}
            >
              <Typography variant="h5" sx={{color:'#000'}}>
                {collection?.name}
              </Typography>
              <Box flex={1} />
              <Box
                sx={{
                  width: '50%',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  gap="1rem"
                >
                  {nfts?.length > 0 && generateUrlButton }
                  <UrlModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    textContent = {generatedUrl}
                  />

                  {collection?.mint_strategy === MINT_STRATEGIES.COPY &&
                    <ExtendCollectionNFTsModal collection_key={collectionKey} />
                  }
                  {collection?.mint_strategy === MINT_STRATEGIES.UNDEFINED &&
                    mintNFTsButton
                  }
                  { mintBenefitsButton }
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                "& > div":{
                  borderLeft: '1px solid #000',
                  borderRight: '1px solid #000',
                  padding: '0.5rem 1rem',
                  display: 'flex',
                  flexDirection:'column'
                }
              }}
            >
              {
                BASIC_INFO?.map((item, index) => {
                  const { label, value, image } = item;
                  const first = index === 0;
                  return (
                    <Box key={index} 
                      sx={{
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        borderLeft: first ? '1px solid #000': 'none !important'
                      }}
                    >
                      <Typography variant="p" sx={{color:'#000'}}>
                        {label+': '}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: image || typeof value === 'object' ? 'row': 'column',
                          gap: '0.5rem',
                          textAlign: 'center'
                        }}
                      >
                        {
                          image &&
                          <CardMedia
                            component="img"
                            sx={{ 
                              width: 14, 
                              objectFit: 'contain'
                            }}
                            src={image}
                          />
                        }
                        <Typography variant="p" sx={{color:'#000',fontWeight:'600'}}>
                          {value||''}
                        </Typography>
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>
            <Typography variant="body1" sx={{
              color:'#000 !important',
              "&, p > span":{
                color:'#000 !important'
              }
            }}>
              <HtmlViewer
                htmlContent={collection?.description}
              />
            </Typography>
          </Box>
        </Fragment>
      }
      <Card
        sx={{
          width:'100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          marginTop: '1rem',
          padding: '1rem 0px'
        }}
      >
        <List
          sx={{ width: '100%', maxWidth: 200}}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader"
              sx={{
                color:(theme) => theme.palette.primary.main,
                fontWeight: '600',
                background: 'transparent'
              }}
            >
              {t("menu.title")}
            </ListSubheader>
          }
        >
          <List
            sx={{
              bgcolor: 'background.paper' 
            }}
          >
          {
            filteredMenu.map((item) => {
              return (
                <ListItemButton 
                  key={item.step}
                  id={`overview-collection-assets-tabs-${item.step}`}
                  onClick={()=>handleSelected(item?.step)}
                  sx={
                    active === item?.step ?
                    activeItemStyles()
                    :
                    itemStyles()
                  }
                >
                  <ListItemText primary={item.label} />
                </ListItemButton>
              )
            })
          }
          </List>
        </List>
        <Box
          data-testid="view-dynamic-table"
          sx={{
            flex: 1
          }}
        >
            {active === 1 &&
            <DynamicTable 
              title={COMPONENT_TABLES[1]?.title || ''}
              data={COMPONENT_TABLES[1]?.data || []}
              loading={COMPONENT_TABLES[1]?.loading || false} 
              activeSearchBar={COMPONENT_TABLES[1]?.activeSearchBar || false} 
              activeModeView={false}
              preparedDataConfig={COMPONENT_TABLES[1]?.preparedDataConfig || []}
              setConfigParams={{
                ...COMPONENT_TABLES[1]?.setConfigParams,
              }}
              activeFilter={false}
              activeSort={false}
            />}
            {active === 2 &&
            <DynamicTable
              title={COMPONENT_TABLES[2]?.title || ''}
              data={COMPONENT_TABLES[2]?.data || []}
              loading={COMPONENT_TABLES[2]?.loading || false} 
              activeSearchBar={COMPONENT_TABLES[2]?.activeSearchBar || false} 
              activeModeView={false}
              preparedDataConfig={COMPONENT_TABLES[2]?.preparedDataConfig || []}
              setConfigParams={{
                ...COMPONENT_TABLES[2]?.setConfigParams,
              }}
              activeFilter={false}
              activeSort={false}
            />}
            {active === 4 &&
            <DynamicTable 
              title={COMPONENT_TABLES[4]?.title || ''}
              data={COMPONENT_TABLES[4]?.data || []}
              loading={COMPONENT_TABLES[4]?.loading || false} 
              activeSearchBar={COMPONENT_TABLES[4]?.activeSearchBar || false} 
              activeModeView={false}
              preparedDataConfig={COMPONENT_TABLES[4]?.preparedDataConfig || []}
              setConfigParams={{
                ...COMPONENT_TABLES[4]?.setConfigParams,
              }}
              activeFilter={false}
              activeSort={false}
            />}            
        </Box>
      </Card>
    </Box>
  );
}

function CopyButton({item}) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation("collections");

  const handleClick = () => {
    navigator.clipboard.writeText(item)
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="info"
        data-testid="copy-url-button"
        onClick={handleClick}
      >
        {t("options.copy")}
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        data-testid="copy-message"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%', color: '#fff' }}
        >
          {t("alerts.link_copied")}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default WithLoadingBackdropAndErrorSnack(OverviewCollection);