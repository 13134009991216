import { useEffect, useState } from "react";
import styles from "./EditProfile.module.css";
import Avatar from "@mui/material/Avatar";
import ImageIcon from '@mui/icons-material/Image';

const defaultColors = {
  primary: '#18212B',
  secondary: '#EBEDE9',
  third: '#EE3A43'
}

const EditProfile = () => {

  const [ selectedColor, setSelectedColor ] = useState(defaultColors);

  useEffect(() => {
    setSelectedColor(defaultColors)
  },[])

  return (
    <div className={styles.divGrficas}>
      <div className={styles.imagen}>
        <div className={styles.title}>Imagen</div>
        <div className={styles.laImagenDe}>
          La imagen de perfil representa tu marca dentro de Persea Nine.
        </div>
        <div className={styles.fotoParent}>
          <div className={styles.foto}>
            <Avatar
              alt="new profileAvatar"
              className={styles.fotoChild}
              sx={{
                width: '120px',
                height: '120px'
              }}
              src=""
            />
          </div>
          <div className={styles.loremIpsumDolorSitAmetCoParent}>
            <div className={styles.loremIpsumDolor}>
              Actuializar imagen de perfil <br/>
              <span className={styles.span}>JPG, GIF o PNG. Tamaño máximo 5MB</span>
            </div>
            <button className={styles.cambiar}>Cambiar</button>
          </div>
        </div>
      </div>
      <div className={styles.imagen}>
        <div className={styles.title}>Imagen de banner</div>
        <div className={styles.laImagenDe}>
          La imagen se mostrará en la parte superior del perfil de tu marca.
        </div>
        <div className={styles.rectangleParent}>
          <Avatar
            alt="new profileAvatar"
            className={styles.frameChild}
            variant="square"
            sx={{
              width: '120px',
              height: '120px',
              borderRadius: '8px 8px'
            }}
            src=""
          >
            <ImageIcon />
          </Avatar>
          <div className={styles.loremIpsumDolorSitAmetCoGroup}>
            <div className={styles.loremIpsumDolor}>
              Actuializar imagen de banner <br/>
              <span className={styles.span}>JPG, GIF o PNG. Tamaño máximo 5MB</span>
            </div>
            <button className={styles.cambiar}>Cambiar</button>
          </div>
        </div>
      </div>
      <div className={styles.imagen2}>
        <div className={styles.title}>Temas</div>
        <div className={styles.elTemaQue}>
          El tema que selecciones serán los colores que aparecerán en tu perfil.
        </div>
        <div className={styles.colorPrincipal}>
          <b className={styles.colorDeFondo}>Color principal</b>
          <button className={styles.ellipseParent}>
            <div className={`${styles.frameItem}`} style={{background:selectedColor.primary}} />
            <div className={styles.cambiar2}>Cambiar</div>
          </button>
        </div>
        <div className={styles.colorPrincipal}>
          <b className={styles.colorDeFondo}>Color de fondo</b>
          <button className={styles.ellipseParent}>
          <div className={`${styles.frameItem}`} style={{background:selectedColor.secondary}} />
            <div className={styles.cambiar2}>Cambiar</div>
          </button>
        </div>
        <div className={styles.colorPrincipal}>
          <b className={styles.colorDeFondo}>Color de sección</b>
          <button className={styles.ellipseParent}>
          <div className={`${styles.frameItem}`} style={{background:selectedColor.third}} />
            <div className={styles.cambiar2}>Cambiar</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
