import * as Yup from "yup";
import { isValidFormat } from 'utils/supportedFiles';
import Web3 from 'web3';

const isMultimedia = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  return Yup.mixed()
    .required(t(`${name}.required`))
    .test('validFileFormat', t(`${name}.invalidFormat`), (value) => {
      if (typeof value === "string") return false;
      if (value === undefined) return false;
      return isValidFormat(value);
    })
    .test('fileSize', t(`${name}.fileSize`), (value) => {
      if (value?.size) {
        const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB en bytes
        return value.size <= MAX_FILE_SIZE;
      }
      return false;
    });
};

const isName = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  return Yup.string()
    .test('isName',`${name}.required`, (value) => {
      if(value === undefined || value === null || value === '') return false;
      return true
    })
    .min(3, t(`${name}.short`))
    .max(100, t(`${name}.long`))
    .required(t(`${name}.required`));
};

const isDescription = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  return Yup.string()
    .required(t(`${name}.required`));
};

const isArray = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  return Yup.array()
    .min(0, t(`${name}.short`))
    .of(
      Yup.object().shape({
        title: Yup.string(),
        value: Yup.string(),
        launchYear: Yup.number(),
      })
    )
    .required(t(`${name}.required`));
};

const isCheckbox = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  return Yup.boolean()
    .required(t(`${name}.required`))
    .test('isChecked', t(`${name}.unchecked`), value => value === true);
};

const isPositiveNumber = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  return Yup.number()
    .min(1, t(`${name}.nonPositive`))  // mensaje de error si el número es 0 o negativo
    .required(t(`${name}.required`));
};

const isZeroOrGreader = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  return Yup.number()
    .min(0, t(`${name}.nonPositive`))  // mensaje de error si el número es 0 o negativo
    .required(t(`${name}.required`));
};

const isWhiteList = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  return Yup.array()
    .min(1, t(`${name}.short`))
    .of(
      Yup.string()
    )
    .test(
      t(`${name}.noEmptyString`),
      (value) => {
        return !value.some(item => item === '' || item === undefined);
      }
    )
    .required(t(`${name}.required`));
};

export const isKeyValuePairList = (t, name, ignore = false) => {
  console.log("name::",name);
  if (ignore) {
    return null;
  }
  return Yup.array(
    Yup.object({
      name: Yup.string().required(`${t(`attributes.required_name`)}`),
      value: Yup.string().required(`${t(`attributes.required_value`)}`)
    })
  )
};

const isUrl = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  return  Yup.string()
  .url(t(`${name}.should_be_valid_url`))
  .matches(/^https:\/\//, t(`${name}.use_HTTPS`))
  .required(t(`${name}.required`))
}
const isSelect = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  return  Yup.string()
    .test('isSelect',`${name}.invalid_value`, (value) => {
      if(value === undefined || value === null || value === '') return false;
      return true
    })
    .required(t(`${name}.required`));
}

const isWeb3Address = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  return Yup.string()
    .test('isWeb3Address', t(`${name}.invalid_address`), (value) => {
      return Web3.utils.isAddress(value);
    })
    .required(t(`${name}.required`));
}

const isDate = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }

  return Yup.date()
    .required(t(`${name}.required`));
}

const isEmail = (t, name, ignore = false) => {
  if (ignore) {
    return null;
  }
  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return Yup.string()
    .matches(emailRegExp, t(`${name}.invalid_email`))
    .required(t(`${name}.required`));
};
const validationMethods = {
  email: isEmail,
  array: isArray,
  string_short: isName,
  string_long: isDescription,
  rich_text_area: isDescription,
  multimedia: isMultimedia,
  checkbox: isCheckbox,
  number: isPositiveNumber,
  number_zero_or_greater: isZeroOrGreader,
  select: isSelect,
  white_list: isWhiteList,
  pair_list: isKeyValuePairList,
  url: isUrl,
  web3Address: isWeb3Address,
  date: isDate
};

export const initializeYupCustom = (t, attributes, types, ignoreValidation, concatValidations) => {
  let schema = {};
  const replace = concatValidations && Object.keys(concatValidations).length > 0 ? Object.keys(concatValidations): []
  attributes.forEach((attrKey, index) => {
    const validationType = types[index]+'';
    const ignore = ignoreValidation ? ignoreValidation[attrKey] : false;
    const validationMethod = validationMethods[validationType] || isDescription;
    schema[attrKey] = validationMethod(t, attrKey, ignore);
    if (replace.includes(attrKey)) {
      schema[attrKey] = concatValidations[attrKey];
    }
  });


  return Yup.object().shape(schema);
};
