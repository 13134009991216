import React, { useContext, useEffect } from 'react';
import MenuDashboard from 'components/MenuDashboard';
import styles from './MainDashBoard.module.css';
import Header from 'components/Header';
import UserContext from 'context/UserContext';
import { Backdrop, Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import MenuDrawer from 'components/MenuDrawer/MenuDrawer';
import RouterBreadcrumbs from 'components/BreadCrumbs/BreadCrumbs';
import SpinnerSteps from 'components/Spinner/SpinnerSteps';
import { getTokenXApiUserLS, getTypeConnectionLS, resetUserLS } from 'utils/localStorage';
import { useNavigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const RedirectToLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login");
  }, [navigate]);
  return null;
};

const SessionExpired = ({setUser}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("login");

  useEffect(() => {
    setTimeout(() =>{
      navigate("/login");
      resetUserLS();
      setUser(null);
    },3000)
  }, [navigate]);

  return(
    <Backdrop open={true} sx={{ background: '#19407f'}}>
      <Stack alignItems="center" spacing={2}>
        <Typography variant="h6" sx={{color:"#fff"}}>
          {t("expired_session_redirect_login")}
        </Typography>
        <CircularProgress sx={{color:"#fff"}} />
      </Stack>
    </Backdrop>
  )
};


const MainDashBoard = () => {

  const { user, loading, setLoading, reconnect, error, expiredSession, setUser } = useContext(UserContext);

  useEffect(()=>{
    try{
      setLoading(false)
      if(!user){
        const xApiKey = getTokenXApiUserLS();
        const provider = getTypeConnectionLS();
        if(xApiKey && provider){
          reconnect(provider);
        } 
      }
    } catch(e){
      console.error(e);
    }
  },[reconnect,user,setLoading])

  const isUserError = () => (!loading && error && !user);
  const isUserLogged = () => (!loading && !error && user);
  const isUserNotLogged = () => (!loading && !error && !user);

  if(expiredSession){
    return <SessionExpired setUser={setUser} />
  }

  if(isUserError() || isUserNotLogged()){
    return <RedirectToLogin />
  }

  return (
    <>
      {isUserLogged() && !isUserError() && !loading && <Dashboard />}
      {loading && <LoadingUser />}
    </>
  );
};

const LoadingUser = () => {
  const theme = useTheme();
  const { t } = useTranslation('users');
  return (
    <Box
      sx={{
        display: 'flex',
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        minWidth: '100vw',
        background: '#19407f'
      }}
    >
      <Backdrop open={true}>
        <Stack alignItems="center" spacing={2}>
          <Typography variant="h6" color={theme.palette.primary.contrastText}>
            {t("loading")}
          </Typography>
          <CircularProgress color="success" />
        </Stack>
      </Backdrop>
    </Box>
  );
}

const Dashboard = () => {
  return (
    <MenuDrawer
      header={<Header />}
      menu={<MenuDashboard />}
      body={
        <div className={styles.dashboardWorker}>
          <div className={styles.spaceUsedHeader} />  
          <div className={styles.workSpaceBoard}>
            <RouterBreadcrumbs />
            <Outlet />
            <SpinnerSteps />
          </div>
        </div>
      }
    />
  );
};


export default MainDashBoard;