import React from 'react';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ConfirmForm = ({ onSubmit }) => {
  const { t } = useTranslation('campaigns');
  
  const handleSubmit = async (_, {setSubmitting}) => {
    onSubmit(true)
    setSubmitting(false);
  }

  const handleCancel = async ({setSubmitting}) => {
    onSubmit(false)
    setSubmitting(false);
  }

  return (
    <NewFormDynamic
      initValues={{}}
      onSubmit={handleSubmit}
      handleCancel={handleCancel}
      textButtons={{
        submit: t("create_view.options.confirm"),
        cancel: t("create_view.options.back")
      }}
    />
  );
};

ConfirmForm.defaultProps = {
  onSubmit: () => {},
}

ConfirmForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default ConfirmForm;