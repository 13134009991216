import { useTranslation } from 'react-i18next';
import TableMessage from 'components/EmptyTableMessage';
import CreatePostButton from 'components/Buttons/CreatePostButton';
import DynamicTable from 'components/DynamicTable/DynamicTable';
import {  getPost } from 'services/posts';
import { useEffect, useState } from 'react';


export default function PostsTable ({campaign_id}) {
  const { t } = useTranslation("postsTable");
  console.log(campaign_id)
  const [ data, setData]  = useState([]);
  useEffect(() => {
    console.log(data)
    const getData = async () => {
      setData(await getPost(campaign_id))
    }
    getData()
  }, [campaign_id,getPost])
  console.log(data)
  return (
    <div data-testid="posts-table">
      <DynamicTable
        title={t("title")}
        data={data}
        loading={false}
        activeSearchBar={true}
        activeModeView={false}
        setConfigParams={{
          emptyMessage: (
            <TableMessage
              message={t("messages.empty")}
              actionButton={<CreatePostButton />}
            />
          )
        }}
      />
    </div>
  );
}