import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';

const CustomTabPanel = (props) => {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export {CustomTabPanel};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const stylesTab = {
  padding: '0.5px 30px',
  width: 'auto', 
  clipPath: 'polygon(100% 0%, 0% 0%, 10% 50%, 0% 100%, 100% 100%)',
  marginRight: '-20px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  marginLeft: '0px',
  opacity: '1',
  fontSize: '12px',
  color: '#000'
};

const  ContainerTabs = ({children,itemsStatus,initialState}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(initialState||0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  React.useEffect(() => {
    setValue(initialState);
  }, [initialState]);

  return (
    <Card sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" indicatorColor="transparent">
          {
            itemsStatus?.map((item,index)=>{
              return (
                <Tab 
                  key={item.label} 
                  label={item.label}
                  {...a11yProps(index)}
                  disabled={item.disabled}
                  sx={value === index ? 
                    {...stylesTab,
                    background: theme.tabsCustom[item?.key]+'',color:'#fff !important',
                    } :
                    {opacity: '1',...stylesTab,background:'#ccc'}
                  }
                />
              )
            })
          }
        </Tabs>
      </Box>
      {children({ value, setValue })}
    </Card>
  );
}

const defaultStates = [
  {label: "Item One",key: "creation"},
  {label: "Item Two",key: "pending"},
  {label: "Item Three",key: "addCollections"}
]

ContainerTabs.defaultProps = {
  itemsStatus: defaultStates
}

ContainerTabs.propTypes = {
  itemsStatus: PropTypes.array,
  initialState: PropTypes.number
}


export default ContainerTabs;