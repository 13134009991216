import React from 'react';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks/useFetch';
import { getAllCollections } from 'services/collection/collections';


export default function ImpulseForm({ onSuccess, onCancel }) {
  const { t } = useTranslation('campaigns');
  const { data: collections } = useFetch(getAllCollections, {
    params: { limit: 30, page: 0, order: 'created_desc' }
  });

  const impulseMenu = {
    collection: 'select'
  };

  const initStateWithValues = {
    collection: {
      value: "",
      options: collections.map(collection => ({
        label: collection.name,
        value: collection.collection_key
      }))
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    const collection = findCollection(values.collection);
    onSuccess({
      type: "NFTS",
      blockchain: collection.blockchain,
      collection: collection,
      protoObjectives: [
        {
          name: "",
          description: "",
          quantity: 1,
          type: {
            type: "GET_NFT",
            collection_key: values.collection
          }
        }
      ]
    });
    setSubmitting(false);
  }

  const findCollection = (collectionKey) => {
    return collections.find(collection => collection.collection_key === collectionKey);
  }

  const handleCancel = ({ setSubmitting }) => {
    setSubmitting(false);
    onCancel();
  }

  return (
    <div data-testid="impulse-campaign-form">
      <NewFormDynamic
        initValues={impulseMenu}
        initStateWithValues={initStateWithValues}
        onSubmit={handleSubmit}
        handleCancel={handleCancel}
        textButtons={{
          submit: t("create_view.options.create"),
          cancel: t("create_view.options.cancel")
        }}
        textLabels={{
          collection: t("form.collections"),
        }}
        textPlaceholders={{
          collection: t("form.collections")
        }}
      />
    </div>
  );
}
