import EditProfile from "../../../components/EditProfile";
import { Fragment } from "react"
import styles from "./PerfilDesktop.module.css";
import Avatar from "@mui/material/Avatar";


const PerfilDesktop = () => {

  return (
    <Fragment>
      <EditProfile />
      <div className={styles.imagen}>
        <div className={styles.acercaDeMi}>Vista previa</div>
        <div className={styles.pepsiMobile}>
          <div className={styles.content}>
            <div className={styles.coleccionable}>
              <div className={styles.coleccionable1}>
                <div className={styles.profile}>
                  <div className={styles.groupParent}>
                    <div className={styles.rectangleParent}>
                      <div className={styles.socialIcons}>
                        <img
                          className={styles.v982D3041Icon}
                          alt=""
                          src="/v982d304-1@2x.png"
                        />
                        <img
                          className={styles.v982D3043Icon}
                          alt=""
                          src="/v982d304-3@2x.png"
                        />
                        <img
                          className={styles.v982D3042Icon}
                          alt=""
                          src="/v982d304-2@2x.png"
                        />
                      </div>
                    </div>
                    <div className={styles.foto}>
                      <Avatar
                        alt="profileAvatar"
                        className={styles.fotoChild}
                        src=""
                      />
                      <img
                        className={styles.fotoItem}
                        alt=""
                        src="/frame-337.svg"
                      />
                    </div>
                    <div className={styles.frameGroup}>
                      <div className={styles.pepsiParent}>
                        <div className={styles.pepsi}>Myentherprise</div>
                      </div>
                      <div className={styles.botn}>
                        <div className={styles.general}>myentherprise.eth</div>
                        <img
                          className={styles.vectorIcon}
                          alt=""
                          src="/vector3.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.post}>
              <div className={styles.postHeader}>
                <div className={styles.datosUsuario}>
                  <Avatar
                    alt="profileAvatar"
                    className={styles.fotoIcon}
                    sx={{
                      width : '15px',
                      height: '15px'
                    }}
                    src=""
                  />
                  <div className={styles.nombre}>
                    <div className={styles.userName}>Myentherprise</div>
                    <div className={styles.owner}>Owner</div>
                    <div className={styles.owner1}>Owner</div>
                  </div>
                </div>
                <div className={styles.seguir}>
                  <div className={styles.general}>Dejar de seguir</div>
                </div>
              </div>
              <div className={styles.ilustracin}>
                <img className={styles.fotoChild} alt="" />
              </div>
              <div className={styles.usuario}>
                <div className={styles.primerNftDe}>
                Nos encanta explorar las posibilidades que ofrecen los NFTs y cómo están revolucionando diversas industrias. Si compartes nuestro entusiasmo por los NFTs o buscas colaborar en proyectos emocionantes en este espacio, ¡estamos aquí para conectarnos y crecer juntos/as en esta emocionante revolución digital!
                </div>
                <div className={styles.botones}>
                  <div className={styles.shareFromSquareSolid1}>
                    <img
                      className={styles.vectorIcon1}
                      alt=""
                      src="/vector4.svg"
                    />
                    <img
                      className={styles.shareFromSquareSolid1Child}
                      alt=""
                      src="/ellipse-9.svg"
                    />
                    <img
                      className={styles.vectorIcon2}
                      alt=""
                      src="/vector5.svg"
                    />
                    <div className={styles.vender}>Vender</div>
                  </div>
                  <div className={styles.compartirYFavorito}>
                    <img
                      className={styles.vectorIcon3}
                      alt=""
                      src="/vector6.svg"
                    />
                    <img
                      className={styles.vectorIcon4}
                      alt=""
                      src="/vector7.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.post1}>
              <div className={styles.postHeader}>
                <div className={styles.datosUsuario}>
                  <Avatar
                    alt="profileAvatar"
                    className={styles.fotoIcon}
                    sx={{
                      width : '17px',
                      height: '17px'
                    }}
                    src=""
                  />
                  <div className={styles.nombre}>
                    <div className={styles.userName}>Myentherprise</div>
                    <div className={styles.owner}>Owner</div>
                    <div className={styles.owner1}>Owner</div>
                  </div>
                </div>
                <div className={styles.seguir}>
                  <div className={styles.general}>Dejar de seguir</div>
                </div>
              </div>
              <div className={styles.ilustracin1}>
                <img className={styles.fotoChild} alt="" />
              </div>
              <div className={styles.usuario}>
                <div className={styles.botones}>
                  <div className={styles.shareFromSquareSolid1}>
                    <img
                      className={styles.vectorIcon1}
                      alt=""
                      src="/vector8.svg"
                    />
                    <img
                      className={styles.shareFromSquareSolid1Child}
                      alt=""
                      src="/ellipse-91.svg"
                    />
                    <img
                      className={styles.rectangleGroup}
                      alt=""
                      src="/vector9.svg"
                    />
                    <div className={styles.vender}>Vender</div>
                  </div>
                  <div className={styles.compartirYFavorito}>
                    <img
                      className={styles.vectorIcon3}
                      alt=""
                      src="/vector10.svg"
                    />
                    <img
                      className={styles.vectorIcon4}
                      alt=""
                      src="/vector11.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.botnIlustradostatusbar}>
            <div className={styles.header}>
              <div className={styles.logoPerseaNine}>
                <img
                  className={styles.perseaLogoWhite}
                  alt=""
                  src="/persea-logo-white2@2x.png"
                />
                <img
                  className={styles.perseaLogoWhite1}
                  alt=""
                  src="/persea-logo-white3@2x.png"
                />
              </div>
              <div className={styles.icnos}>
                <div className={styles.copySolid1}>
                  <div className={styles.general}>myentherprise.eth</div>
                  <img
                    className={styles.vectorIcon9}
                    alt=""
                    src="/vector12.svg"
                  />
                </div>
                <img
                  className={styles.vectorIcon10}
                  alt=""
                  src="/vector13.svg"
                />
              </div>
            </div>
          </div>
          <div className={styles.navBar}>
            <div className={styles.navBarChild} />
            <div className={styles.navButtonParent}>
              <div className={styles.navButton}>
                <div className={styles.rectangleGroup}>
                  <div className={styles.frameChild} />
                  <div className={styles.frameItem} />
                  <div className={styles.frameInner} />
                </div>
                <div className={styles.misColecciones}>Mis Colecciones</div>
              </div>
              <div className={styles.navButton}>
                <img
                  className={styles.navButtonChild}
                  alt=""
                  src="/frame-340@2x.png"
                />
                <div className={styles.misColecciones}>Transacciones</div>
              </div>
              <div className={styles.navbutton}>
                <img
                  className={styles.navbuttonChild}
                  alt=""
                  src="/group-1.svg"
                />
                <div className={styles.misColecciones}>Home</div>
              </div>
              <div className={styles.navButton}>
                <img
                  className={styles.navButtonItem}
                  alt=""
                  src="/frame-3401.svg"
                />
                <div className={styles.misColecciones}>Social</div>
              </div>
              <div className={styles.navButton}>
                <img
                  className={styles.navButtonInner}
                  alt=""
                  src="/frame-3402.svg"
                />
                <div className={styles.misColecciones}>Tienda</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PerfilDesktop;
