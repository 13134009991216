import UserContext from "context/UserContext";
import { useState, useEffect, useContext } from "react";
import { getBenefits, findBenefits, getCollectionBenefitByRewardId } from "services/benefits";
import { getBenefitReward, getBenefitRewardRecord} from "services/contracts/benefitsMethods";

export const useCollectionBenefit = (collection_key=null,params={}) => {
  const [collectionBenefits, setCollectionBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const resolveCollection = async () => {
    return await findBenefits(collection_key,params);
  }

  useEffect(() => {
    setLoading(true);
    setError(null);
    resolveCollection().then((result) => {
      console.log("result", result)
      setCollectionBenefits(result);
    }).catch((error) => {
      console.log(error);
      setError("An error occurred while getting the collections. please try again");
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return {
    collectionBenefits,
    loading,
    error
  };
}

export const useAllCollectionBenefit = () => {
  const [collectionBenefits, setCollectionBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const resolveCollection = async () => {
    return await findBenefits();
  }

  useEffect(() => {
    setLoading(true);
    setError(null);
    resolveCollection().then((result) => {
      console.log("result", result)
      setCollectionBenefits(result);
    }).catch((error) => {
      console.log(error);
      setError("An error occurred while getting the collections. please try again");
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return {
    collectionBenefits,
    loading,
    error
  };
}

export const useCollectionBenefitbyRewardId = (rewardId=null,params={}) => {
  const [collectionBenefits, setCollectionBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const resolveCollection = async () => {
    return await getCollectionBenefitByRewardId(rewardId,params);
  }

  useEffect(() => {
    setLoading(true);
    setError(null);
    resolveCollection().then((result) => {
      setCollectionBenefits(result);
    }).catch((error) => {
      console.log(error);
      setError("An error occurred while getting the collections. please try again");
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return {
    collectionBenefits,
    loading,
    error
  };
}

export const useBenefits = (params={}) => {
  const { user } = useContext(UserContext);
  const [benefits, setBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const resolveCollection = async () => {
    const benefits = await getBenefits(params);
    return await addRewardsToBenefits(benefits);
  }

  const addRewardsToBenefits = async (benefits) => {
    const newBenefits = [];
    for (const benefit of benefits) {
      const rewardRecord = await getBenefitRewardRecord(user.provider, benefit.contract_key, benefit.type);
      const reward = await getBenefitReward(user.provider, benefit.contract_key, benefit.type);
      newBenefits.push({
        ...benefit,
        rewardAmount: reward.amount,
        reward: rewardRecord
      });
    }
    return newBenefits;
  }


  useEffect(() => {
    setLoading(true);
    setError(null);
    resolveCollection().then((result) => {
      setBenefits(result);
    }).catch((error) => {
      console.log(error);
      setError("An error occurred while getting the collections. please try again");
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return {
    benefits,
    loading,
    error
  };
}