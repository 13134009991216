import React from 'react';
//import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
//import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks/useFetch';
//import { getAllCollections } from 'services/collection/collections';
import { findAccountTargets } from "services/account_targets/accountTarget";
import MissionForm from './MissionForm';
import { findSocialMedia } from 'services/account_targets/supportedSocialMedia';
import { getRewards } from 'services/rewards/rewards';

export default function SocialMediaForm({ onSuccess, onCancel }) {
  const { data: targets, loading, error } = useFetch(findAccountTargets, {
    params: { limit: 1000, page: 0, order: 'created' }
  });

  const { data: socialMedia, loading : loadingSocialMedia, error : errorSocialMedia } = useFetch(findSocialMedia, {
    params: { limit: 1000, page: 0, order: 'created' }
  });

  const { data: rewards, loading : loadingReward, error : errorReward } = useFetch(getRewards, {
    params: { limit: 1000, page: 0, order: 'created' }
  });


 console.log(targets,loading,error,onSuccess, onCancel, socialMedia,loadingSocialMedia, errorSocialMedia, loadingReward, errorReward)


  return (
    <div data-testid="impulse-campaign-form">
        <MissionForm targets={targets} socialNetworks = { socialMedia} rewards ={rewards} onSuccess={onSuccess} onCancel={onCancel} />
    </div>
  );
}
