import Web3 from 'web3';
import Benefits from 'services/contracts/abis/Benefits.json';
import { getGasConfigurationFor } from 'services/blockchain';

export const deploy = async (from, provider, collectiblesAddress, name, version, initialRewards) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(Benefits.abi)
  const chainId = await web3.eth.getChainId()
  const send = await getGasConfigurationFor(chainId)
  send['from'] = web3.utils.toChecksumAddress(from)
  return contract.deploy({
    data: Benefits.bytecode,
    arguments: [
      collectiblesAddress, name, version, initialRewards
    ]
  }).send(send)
}

export const mintBatch = async (from, provider, contractAddress, ids, amounts, rewards, urls) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(Benefits.abi, contractAddress)
  const chainId = await web3.eth.getChainId()
  const send = await getGasConfigurationFor(chainId)
  send['from'] = web3.utils.toChecksumAddress(from)
  return await contract.methods.mintBatch(ids, amounts, rewards, urls).send(send);
}

export const mint = async (from, provider, contractAddress, id, amount, reward, url) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(Benefits.abi, contractAddress)
  const chainId = await web3.eth.getChainId()
  const send = await getGasConfigurationFor(chainId)
  send['from'] = web3.utils.toChecksumAddress(from)
  return await contract.methods.mint(id, amount, reward, url).send(send);
}

export const getRewardRecords = async (provider, contractAddress) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(Benefits.abi, contractAddress);
  const rewards = await contract.methods.getRewardRecords().call();
  const ids = rewards[0] ? rewards[0].map((id) => parseInt(id)) : [];
  const rewardsData = rewards[1] ? rewards[1] : [];
  const promises = ids.map(async (id, index) => {
    return await formatReward(rewardsData[index], id);
  });
  return await Promise.all(promises)
}

const formatReward = async (rewardData, rewardId) => {
  const metadata = await fetch(rewardData.metadata).then(res => res.json());
  return {
    ...rewardData,
    id: rewardId,
    tokenIds: rewardData.tokenIds ? rewardData.tokenIds.map((tokenId) => parseInt(tokenId)) : [],
    rewardType: parseInt(rewardData.rewardType),
    externalId: parseInt(rewardData.externalId),
    metadataURL: rewardData.metadata,
    metadata,
  };
}

export const getBenefitRewardRecord = async (provider, contractAddress, benefitType) => {
  const web3 = new Web3(provider);
  contractAddress = web3.utils.toChecksumAddress(contractAddress);
  const contract = new web3.eth.Contract(Benefits.abi, contractAddress);
  const reward = await contract.methods.getBenefitRewardRecord(benefitType).call();
  return await formatReward(reward[1], parseInt(reward[0]));
}

export const getBenefitReward = async (provider, contractAddress, benefitType) => {
  const web3 = new Web3(provider);
  contractAddress = web3.utils.toChecksumAddress(contractAddress);
  const contract = new web3.eth.Contract(Benefits.abi, contractAddress);
  return await contract.methods.getBenefitReward(benefitType).call();
}

export const getCurrentBenefitsNonce = async (provider, contractAddress) => {
  const web3 = new Web3(provider);
  contractAddress = web3.utils.toChecksumAddress(contractAddress);
  const contract = new web3.eth.Contract(Benefits.abi, contractAddress);
  return parseInt(await contract.methods.benefitTypeNonce().call());
}