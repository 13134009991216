import { useState, useEffect } from 'react';
import { findAllByDomain, findByLimitPageOrder } from 'services/projects/project';


const useProjects = () => {

  const [ projects, setProjects ] = useState([]);
  const [ loadingProjects, setLoadingProjects ] = useState(true);
  const [ errorProjects, setErrorProjects ] = useState(null);

  const handleGetProjects = async() => {
    try{
      const res = await findAllByDomain(process.env.REACT_APP_DOMAIN);
      setProjects(res||[]);
      setLoadingProjects(false)
    }
    catch(err){
      setErrorProjects(err);
      console.log("debug err::",err);
    }
  }

  useEffect(()=>{
    handleGetProjects();
  },[])

  return {
    projects,
    loadingProjects,
    errorProjects,
    handleGetProjects
  }
}


const useProjectsAutoComplete = () => {

  const [ projects, setProjects ] = useState([]);
  const [ loadingProjects, setLoadingProjects ] = useState(true);
  const [ errorProjects, setErrorProjects ] = useState(null);

  const handleInitValue = (projects,id) => {
    return projects.filter((item)=>Number(item.value) === Number(id)) || [];
  }

  const handleGetProjects = async() => {
    try{
      const res = await findAllByDomain(process.env.REACT_APP_DOMAIN);
      const projects = res?.map((item)=>{
        return {
          ...item,
          label:item.name,
          value:item.id
        }
      })
      setProjects(projects||[]);
      setLoadingProjects(false)
    }
    catch(err){
      setErrorProjects(err);
      console.log("debug err::",err);
    }
  }

  useEffect(()=>{
    handleGetProjects();
  },[])

  return {
    projects,
    loadingProjects,
    errorProjects,
    handleInitValue
  }
}


const useProjectEdit = (projectKey) =>{

  const [ project, setProject ] = useState([]);
  const [ loadingProject, setLoadingProject ] = useState(true);
  const [ errorProject, setErrorProject ] = useState(null);

  const handleGetProjectsById = async(projectKey) => {
    try{
      setLoadingProject(true)
      const res = await findByLimitPageOrder(process.env.REACT_APP_DOMAIN,projectKey);
      setProject(res)
    }
    catch(err){
      console.log("debug err::",err);
      setErrorProject(err)
    } finally {
      setLoadingProject(false)
    }
  }

  useEffect(()=>{
    if(projectKey){
      handleGetProjectsById(projectKey);
    }
  },[])

  return {
    project,
    loadingProject,
    errorProject,
    handleGetProjectsById
  }

}

export {
  useProjectsAutoComplete,
  useProjects,
  useProjectEdit
}
