export const getTypeConnectionLS = () => {
	const type = localStorage.getItem("typeConnection");
	return type || null;
}

export const getProviderConnectedLS = () => {
	const provider = localStorage.getItem("providerConnected");
	return provider || null;
}

export const getUserAddressLS = () => {
	const token = localStorage.getItem("addressAccount");
	return token || null;
}

export const getTokenXApiUserLS = () => {
	const token = localStorage.getItem("userApiKey");
	return token || null;
}

export const getTokenMagicLS = () => {
	const token = localStorage.getItem("magicToken");
	return token || null;
}

export const resetUserLS = () => {
	localStorage.removeItem("addressAccount");
	localStorage.removeItem("userApiKey");
	localStorage.removeItem("magicToken");
	localStorage.removeItem("typeConnection");
	localStorage.removeItem("providerConnected");
	localStorage.removeItem("user");
}

export const getRouteIfUserIsNew = (userIsNew) => {
	const route = localStorage.getItem("currentPath") || null;
	const isNew = JSON.parse(localStorage.getItem("isFirst")) || null;
	if (isNew || userIsNew) {
		return "/update-info-user"
	}
	return route || "/dashboard"
};
