import styles from "./Socials.module.css";
const Socials = () => {
  return (
    <div className={styles.divGrficasWrapper}>
      <div className={styles.divGrficas}>
        <div className={styles.imagen}>
          <div className={styles.title}>Redes sociales vinculadas</div>
          <div className={styles.vinculaLasSiguientes}>
            Vincula las siguientes redes sociales para que aparezcan en tu
            perfil de marca.
          </div>
          <div className={styles.redes}>
            <div className={styles.instagram}>
              <div className={styles.logoYNombre}>
                <img
                  className={styles.v982D3041Icon}
                  alt=""
                  src="/instagram.png"
                />
                <div className={styles.direccin}>Instagram</div>
              </div>
              <div className={styles.noVinculado}>
                <div className={styles.direccin}>Dirección:</div>
                <div className={styles.buscador}>
                  <input
                    className={styles.inputEl}
                    type="text"
                    placeholder="URL Instagram"
                  />
                </div>
              </div>
            </div>
            <div className={styles.instagram}>
              <div className={styles.logoYNombre}>
                <img
                  className={styles.v982D3041Icon1}
                  alt=""
                  src="/discord.png"
                />
                <div className={styles.direccin}>Discord</div>
              </div>
              <div className={styles.noVinculado}>
                <div className={styles.direccin}>Dirección:</div>
                <div className={styles.buscador}>
                  <input
                    className={styles.inputEl}
                    type="text"
                    placeholder="URL Discord"
                  />
                </div>
              </div>
            </div>
            <div className={styles.instagram}>
              <div className={styles.logoYNombre}>
                <img
                  className={styles.v982D3041Icon2}
                  alt=""
                  src="/twitter.png"
                />
                <div className={styles.direccin}>Twitter</div>
              </div>
              <div className={styles.noVinculado}>
                <div className={styles.direccin}>Dirección:</div>
                <div className={styles.buscador}>
                  <input
                    className={styles.inputEl}
                    type="text"
                    placeholder="URL Twitter"
                  />
                </div>
              </div>
            </div>
            <div className={styles.instagram}>
              <div className={styles.logoYNombre}>
                <img
                  className={styles.v982D3041Icon1}
                  alt=""
                  src="/opensea.png"
                />
                <div className={styles.direccin}>OpenSea</div>
              </div>
              <div className={styles.noVinculado}>
                <div className={styles.direccin}>Dirección:</div>
                <div className={styles.buscador}>
                  <input
                    className={styles.inputEl}
                    type="text"
                    placeholder="URL OpenSea"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Socials;
