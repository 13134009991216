import styled from 'styled-components';

export const ContentOverView = styled('div')(({ columns, gap='1rem' }) => {

  const newColumns = typeof columns === "number" ?  `repeat(${columns}, 1fr)` : columns 
  
  return {
    alignSelf: 'stretch',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: newColumns,
    gap: gap
  }

});

export const Item = styled('div')(({ gap='1rem' }) => {
  return{
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: '0.5rem',
    gap: gap
  }
});
