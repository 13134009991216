import { useState, useEffect, useRef } from 'react';


const useFetch = (fetchPromise, { pagination = false, params }) => {

  const isFirstFetch = useRef(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [valueParams, setValueParams] = useState(params);

  const refresh = async () =>{
    await fetchData(valueParams);
  }

  useEffect(() => {
    if(JSON.stringify(params) !== JSON.stringify(valueParams)){
      setValueParams(params);
    }
  }, [JSON.stringify(params)]);

  const fetchData = async (config) => {
    setError(null);
    try {
      if(!isFirstFetch.current && loading){
        return null;
      }
      isFirstFetch.current = false;
      setLoading(true);
      const response = await fetchPromise({params: config});
      if (pagination) {
        setData(prevData => [...prevData, ...response]);
      } else {
        setData(response);
      }
    } catch (err) {
      setError(err);
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const nextPage = () => {
    if(!loading && pagination){
      setValueParams(prevParams => ({...prevParams, page: prevParams.page + 1}));
    }
  }
  
  useEffect(() => {
    if(fetchPromise && typeof fetchPromise === 'function' && params){
      fetchData(valueParams);
    }
  }, [valueParams]); 

  return { 
    data, 
    loading, 
    error,
    nextPage,
    setValueParams,
    refresh
  };
}

export { useFetch };
