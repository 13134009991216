import React, { useState, useContext }from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Card } from '@mui/material';
import styles from './NftDetails.module.css';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
// import TableTwoViews from 'components/TableTwoViews/TableTwoViews';
import { useConfig } from './utils/useConfig';
import UserContext from 'context/UserContext';
import MediaRenderer from './utils/MediaRenderer';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks/useFetch';
import { findByTokenId } from 'services/nfts';
import { useAllCollectionBenefit } from 'hooks/benefit';
import NftHistory from './NFTHistory';

const activeItemStyles = () => ({
  background: (theme) => theme.palette.primary.main,
  color:  (theme) => theme.palette.secondary.main,
  boxShadow:  (theme) => ` inset -4px 0 0 ${theme.palette.secondary.main}`,
  '&:hover': {
    background: (theme) => theme.palette.primary.main,
    color:  (theme) => theme.palette.secondary.main,
  }
});

const itemStyles = () => ({
  '&:hover': {
    background: (theme) => theme.palette.primary.main,
    color:  "#fff"
  }
});

export default function ViewNftDetails() {
  const { user } = useContext(UserContext)
  const { t } = useTranslation("nftDetails");
  const [ active, setActive ] = useState(1);
  const { tokenId } = useParams();
  const initParams = {
    token_id:tokenId,
    limit: 100,
    page: 0,
    order: 'created_desc',
  }
  
  const NFTHistory = [
    {
      event:"Mint",
      price: "0.029 WETH",
      from: "74FE32",
      to: "3Facfa",
      date:"17d ago",
    },
    {
      event:"Mint",
      price: "0.0288 WETH",
      from: "No_Bot_Holder",
      to: "3Facfa",
      date:"12d ago",
    },
    {
      event:"Mint",
      price: "0.0287 WETH",
      from: "74FE32",
      to: "3Facfa",
      date:"17d ago",
    },
    {
      event:"Mint",
      price: "0.0284 WETH",
      from: "Real_Human",
      to: "3Facfa",
      date:"30m ago",
    },
    {
      event:"Mint",
      price: "0.0283 WETH",
      from: "74FE32",
      to: "3Facfa",
      date:"12d ago",
    }
  ];
  
  const { data: nfts, loading:loadingNFT } = useFetch(findByTokenId, {params: initParams});
  const { collectionBenefits, loadingBenefits } = useAllCollectionBenefit();
  const { 
    menu,
    SwitcherContainer
  } = useConfig(t,user);

  const handleSelected = (step) => {
    setActive(step);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <div className={styles.imageContainer}>
          <MediaRenderer
              url={nfts ? nfts.thumb_url : ""}
              is_video={false }
          />
        </div>
      </Grid>
      <Grid item xs={9}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '1rem',
            marginTop: '0rem',
            padding: '1rem 0px'
          }}
        >
          <List
            sx={{ width: '100%', maxWidth: 200}}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader"
                sx={{
                  color:(theme) => theme.palette.primary.main,
                  fontWeight: '600',
                  background: 'transparent'
                }}
              >
              </ListSubheader>
            }
          >
            <List
              sx={{
                bgcolor: 'background.paper' 
              }}
            >
            {
              menu?.map((item, index) => {
                return (
                  <ListItemButton 
                    key={index}
                    onClick={()=>handleSelected(item?.step)}
                    sx={
                      active === item?.step ?
                      activeItemStyles()
                      :
                      itemStyles()
                    }
                  >
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                )
              })
            }
            </List>
          </List>
          <Box
            flex={1}
            padding={'0px 1rem'}
            minHeight="400px" 
          >
            <SwitcherContainer
              t={t}
              active={active}
              nft={nfts}
              benefits={collectionBenefits}
              loadingBenefits = {loadingBenefits}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <NftHistory
          NFTHistory={NFTHistory}
          loading={loadingNFT}
        >
        </NftHistory>
      </Grid>
    </Grid>
  );
}

