
import Events from "../components/Events";
const Eventos = () => {

  return (
    <Events />
  );
};

export default Eventos;
