import axios from "axios";

axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;
const PATH = "collection/extend-nfts"
const URL = `${process.env.REACT_APP_API}/${PATH}`;


export const extendCollection = async (collection_key, quantity) => {
  const body = {
    collection_key,
    quantity,
  }
  return await axios.post(URL, body,
    {
      headers: {
        'X-API-Key': localStorage.getItem("userApiKey")
      },
      params: {
        domain: process.env.REACT_APP_DOMAIN,
      }
    });
};