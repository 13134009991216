import React from 'react';
import PropTypes from 'prop-types';
import styles from "./ModalCreateEvent.module.css";
import Avatar from "@mui/material/Avatar";
import ImageIcon from '@mui/icons-material/Image';

const ModalCreateEvent = ({ onClose}) => {

    return (
        <div className={styles.container}>
          <div className={styles.modalcreateevent}>
          <div className={styles.regstrate}>
            <div className={styles.evento}>Evento</div>
            <div className={styles.rectangleParent}>
              <Avatar
                alt="new profileAvatar"
                className={styles.frameChild}
                variant="square"
                sx={{
                  width: '120px',
                  height: '120px',
                  borderRadius: '8px 8px'
                }}
                src=""
                >
                <ImageIcon />
              </Avatar>
              <div className={styles.cambiarImagenDe}>
                Cambiar imagen de portada
              </div>
            </div>
            <div className={styles.nombre}>
              <b className={styles.ttulo}>Título</b>
              <input
                className={styles.nombreChild}
                type="text"
                placeholder="Título del evento"
              />
            </div>
            <div className={styles.nombre}>
              <b className={styles.ttulo}>Fecha</b>
              <input
                className={styles.nombreItem}
                type="date"
                placeholder="22/22/2222"
              />
            </div>
            <div className={styles.nombre}>
              <b className={styles.ttulo}>Colaboradores</b>
              <button
                className={styles.ttuloYDescripcin}

              >
                <div className={styles.title}>
                  <span className={styles.span}>{`+ `}</span>
                  <span className={styles.aadirColaborador}>
                    AÑADIR COLABORADOR
                  </span>
                </div>
                <div className={styles.configuraLaSeccin}>
                  Configura la sección principal de tu perfil y muestra a los
                  usuarios tus NFTs mas destacados.
                </div>
              </button>
            </div>
            <div className={styles.nombre}>
              <b className={styles.ttulo}>Descripción</b>
              <textarea
                className={styles.nombreInner}
                placeholder="Descripción..."
              />
            </div>
          </div>
          <div className={styles.botones}>
            <button className={styles.botn}>
              <div className={styles.continuar}>Confirmar</div>
            </button>
            <div className={styles.cancelar}>
              <button className={styles.cancelar1}>Cancelar</button>
            </div>
          </div>
          <img className={styles.vectorIcon} alt="" src="/vector1.svg" onClick={onClose} />
        </div>
      </div>
    );
};

ModalCreateEvent.propTypes = {
    onClose: PropTypes.func
};

export default ModalCreateEvent;