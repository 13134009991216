import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@mui/material/FormLabel';
import { Field } from 'formik'
import { capitalizeFirstLetter, stringToObjectField } from 'utils/strings';
import { TextField } from "@mui/material";

const WrappedMaterialTextField = ({ field, form: { touched, errors }, ...props }) => {

  const getTouched = () => {
    const isTouched = stringToObjectField(field.name, touched);
    if (isTouched === null) return false;
    return isTouched;
  }

  const getError = () => {
    if (!getTouched()) return "";
    const error = stringToObjectField(field.name, errors);
    if (error === null) return "";
    return error;
  }

  return (
    <TextField
      {...field}
      {...props}
      style={{ backgroundColor: 'white',borderRadius:'8px' }}
      error={(Boolean(getTouched()) && Boolean(getError()))}
    />
  )
}

const InputWeb3Address = ({ name, placeholder, label, loading }) => {

  return (
    <Fragment>
      <FormLabel htmlFor={name}>{label ? label : capitalizeFirstLetter(name)}</FormLabel>
      <Field
        type="text"
        fullWidth
        placeholder={placeholder ? placeholder : capitalizeFirstLetter(name)}
        name={name}
        disabled={loading}
        component={WrappedMaterialTextField}
      />
    </Fragment>
  );
};

InputWeb3Address.propTypes = {
  name: PropTypes.string,
  loading: PropTypes.bool
};

export default InputWeb3Address;