import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import './tabs.module.css';
import { formatTimestampToLocaleStringShort, fullDate } from 'utils/date';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import UserContext from 'context/UserContext';
import { BENEFIT_STATUS } from 'utils/benefitStatus';
import DynamicTable from 'components/DynamicTable/DynamicTable';

const ViewNftBenefits = ({NFTBenefits, loading}) => {
  const { t } = useTranslation("collections");
  const { user } = useContext(UserContext);

  const statusOptions = {
    [BENEFIT_STATUS.PENDING_APPROVE]: {
      status: BENEFIT_STATUS.PENDING_APPROVE,
      label: t("table.status.pending_approve"),
      color: "statusGray"
    },
    [BENEFIT_STATUS.APPROVED]: {
      status: BENEFIT_STATUS.APPROVED,
      label: t("table.status.approved"),
      color: "statusGreen"
    },
    [BENEFIT_STATUS.REJECTED_NFTs]: {
      status: BENEFIT_STATUS.REJECTED_NFTs,
      label: t("table.status.rejected_nfts"),
      color: "statusRed"
    }
  }

  const configCells = {
    status:{  
      render: (item) => {
        return (
          <TableCell>
            <Chip 
              label={statusOptions[item].label}
              color={statusOptions[item].color} 
            />
          </TableCell>
        )
      }
    }
  }

  const configSelected = {
    defineId: 'name',
    options:[
      {
        id:'approve',
        label: 'Approve',
        color: 'success',
        variant: 'contained',
        tooltip:'Click to approve',
        onClick: (item) => {
          console.log("debug ",item);
        },
        conditions: (item)=>{
          if(user?.role > 80 && item?.status === 0){
            return true
          }
          return false
        }      
      },
      {
        id:'rejected',
        label: 'Rejected',
        color: 'error',
        variant: 'contained',
        tooltip:'Click to rejected',
        onClick: (item) => {
          console.log("debug ",item);
        },
        conditions: (item)=>{
          if(user?.role > 80 && item?.status === 0){
            return true
          }
          return false
        }      
      },
      {
        id: 'edit',
        label: 'Edit',
        color: 'info',
        variant: 'contained',
        tooltip:'Click to edit',
        onClick: (item) => {
          console.log("debug ",item);
        },
        conditions: ()=>{
          if(user?.role > 50 ){
            return true
          }
          return false
        }
      }
    ]
  }

  return (
    <DynamicTable 
    title={""}
    activeSearchBar={true}
    activeModeView={false}
    data={NFTBenefits}
    loading={loading}
    preparedDataConfig={(item)=>{
      return {
        thumbnail: item?.reward?.thumb_url,
        name: item?.reward?.name,
        description: item?.reward?.description,
        status: item?.status,
        created: formatTimestampToLocaleStringShort(item?.reward?.created),
        updated: fullDate(item?.reward?.updated)
      }
    }}
    setConfigParams={{
      configCells,
      configSelected,
      textHeaders:{thumbnail:' '}
    }}
  />
  );
}

export default ViewNftBenefits;