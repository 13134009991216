import Login from 'pages/login';
import OAuthRedirectPage from 'pages/OAuthRedirect/OAuthRedirectPage';
import PersonalizeProfile from 'pages/login/components/PersonalizeProfile';
import ViewCollections from 'pages/MainCollections/ViewCollections/ViewCollections';
import ViewCampaigns from 'pages/MainCampaigns/ViewCampaigns/ViewCampaigns';
import OverviewCampaign from 'pages/MainCampaigns/ViewCampaigns/OverviewCampaigns/OverviewCampaign';
import  OverviewCollection  from 'pages/MainCollections/OverviewCollection/OverviewCollection';
import CreateCollection from 'pages/MainCollections/CreateCollection/CreateCollection';
import MintNFTs from 'pages/MainCollections/MintNFTs';
import ViewNftDetails from 'pages/MainNfts/ViewNftDetails/NftDetails';
import NFTsDesktop from 'pages/NFTs/NFTsDesktop';
import Rewards from 'pages/Rewards/Rewards';
import CreateReward from 'pages/Rewards/CreateReward';
import OverviewReward from 'pages/Rewards/OverviewRewards/OverviewReward';
import ViewProjects from 'pages/MainProjects/ViewProjects/ViewProjects';
import EditProject from 'pages/MainProjects/EditProject/EditProject';
import ViewBenefits from 'pages/Benefits/components/ViewBenefits';
import  CreateBenefits  from 'pages/Benefits/CreateBenefits';
import ProjectBenefit from 'pages/Benefits/ProjectBenefits';
import viewNfts from 'pages/MainNfts/ViewNfts/ViewNfts';
import BeneficiosDesktop from 'pages/BeneficiosDesktop';
import CreateCampaing from 'pages/Campaings/CreateCampaign/CreateCampaing';
import ViewCreatePost from 'pages/MainCampaigns/ViewCreatePost/ViewCreatePost';
import Eventos from 'pages/Eventos';
import UsersDesktop from 'pages/MainUsers/UsersDesktop';
import UserForm from 'pages/MainUsers/Form/UserForm';
import ClientsDesktop from 'pages/MainClients/ClientsDesktop';
import ViewInvite from 'pages/MainClients/inviteForm/ViewInvite';
import ViewCreateAccessControl from 'pages/MainClients/accessControlForm/ViewCreateAccessControl';
import PerfilDesktop from 'pages/profile/components/PerfilDesktop';
import Spotlight from 'pages/profile/components/Spotlight';
import Socials from 'pages/profile/components/Socials';
import WalletTheme from 'pages/walletTheme/WalletThemeDesktop';
import DashboardTheme from 'pages/dashboardTheme/DashboardThemeDesktop';
import InVoice from 'pages/InVoice/InVoice';
import ApiKeys from 'pages/ApiKeys/ApiKeys';
import ViewCreateApiKey from 'pages/ApiKeys/ViewCreateApiKey';
import DiscordOverViewPage from 'pages/Discord/DiscordOverViewPage';
import TikTokOverViewPage from 'pages/Tiktok/TikTokOverViewPage';
import YoutubeOverViewPage from 'pages/Youtube/YoutubeOverViewPage';
import ViewTargets from 'pages/Targets/ViewTargets';
import ViewCreateTarget from 'pages/Targets/ViewCreateTarget';
import ProfileCustomsBasicPage from 'pages/ProfileCustomsBasicPage/ProfileCustomsBasicPage';
import GasStationView from 'pages/GasStation/GasStation';
import ViewEditCampaign from 'pages/MainCampaigns/ViewEditCampaign/ViewEditCampaign';

export const routesValues = {
	"routes": {
		"": true,
		"/login": true,
		"/oauth-callback": true,
		"/register": true,
		"/update-info-user": true,
		"/dashboard": {
			"/": false,
			"overview": false,
			"rewards": {
				"/": false,
				"create": false,
				"edit/:rewardId": false,
				":rewardId": false
			},
			"collections": {
				"/": false,
				":collectionKey": false,
				":collectionKey/mint": false,
				"create": false,
				"edit/:collectionKey": false,
				":collectionKey/:tokenId": false,
				":collectionKey/nfts/": false
			},
			"benefits": {
				"/": false,
				"create/:contractKey/:cameFrom": false,
				":projectKey": false,
				":collectionKey": false,
				"create/:contractKey/:projectId": false
			},
			"nftCatalog": {
				"/": false,
				":tokenId": false
			},
			"campaigns": {
				"/": false,
				"create": false,
				":campaignId": false,
				":campaignId/edit": false,
				":campaignId/createPost": false
			},
			"events": false,
			"users": {
				"/": false,
				"create": false,
				"edit/:mainKey": false
			},
			"clients": {
				"/": false,
				"invite": false,
				"access-control": false,
			},
			"profile": {
				"/": false,
				"edit": false,
				"spotlight": false,
				"social": false
			},
			"walletTheme": {
				"/": false
			},
			"dashboardTheme": {
				"/": false
			},
			"in-voice": false,
			"api-keys": {
				"/": false,
				"create": false
			},
			"gas-station": false,
			"discord": false,
			"tiktok": false,
			"youtube": false,
			"targets": {
				"/": false,
				"create": false
			}
		}
	}
};

export const staticRoutes = {
	" ": Login,
	"/login": Login,
	"/oauth-callback": OAuthRedirectPage,
	"/register": PersonalizeProfile,
	"/dashboard":ViewCollections,
	"/update-info-user": ProfileCustomsBasicPage
};

export const relativeRoutes = {
	"/dashboard":ViewCollections,
	"/dashboard/rewards": Rewards,
	"/dashboard/rewards/create": CreateReward,
	"/dashboard/rewards/edit/:rewardId": CreateReward,
	"/dashboard/rewards/:rewardId": OverviewReward,
	"/dashboard/collections": ViewCollections,
	"/dashboard/collections/:collectionKey": OverviewCollection,
	"/dashboard/collections/create": CreateCollection,
	"/dashboard/collections/:collectionKey/mint":MintNFTs,
	"/dashboard/collections/edit/:collectionKey": CreateCollection,
	"/dashboard/collections/:collectionKey/:tokenId": ViewNftDetails,
	"/dashboard/collections/:collectionKey/nfts/": NFTsDesktop,
	"/dashboard/projects": ViewProjects,
	"/dashboard/projects/create": EditProject,
	"/dashboard/projects/edit/:projectId": EditProject,
	"/dashboard/benefits": ViewBenefits,
	"/dashboard/benefits/create/:contractKey/:cameFrom": CreateBenefits,
	"/dashboard/benefits/:projectKey": ProjectBenefit,
	"/dashboard/benefits/:collectionKey": BeneficiosDesktop,
	"/dashboard/benefits/create/:contractKey/:projectId": CreateBenefits,
	"/dashboard/nftCatalog": viewNfts,
	"/dashboard/nftCatalog/:tokenId": ViewNftDetails,
	"/dashboard/campaigns": ViewCampaigns,
	"/dashboard/campaigns/create": CreateCampaing,
	"/dashboard/campaigns/:campaignId": OverviewCampaign,
	"/dashboard/campaigns/:campaignId/edit": ViewEditCampaign,
	"/dashboard/campaigns/:campaignId/createPost": ViewCreatePost,
	"/dashboard/events": Eventos,
	"/dashboard/users": UsersDesktop,
	"/dashboard/users/create": UserForm,
	"/dashboard/users/edit/:mainKey": UserForm,
	"/dashboard/clients": ClientsDesktop,
	"/dashboard/clients/invite": ViewInvite,
	"/dashboard/clients/access-control": ViewCreateAccessControl,
	"/dashboard/profile": PerfilDesktop,
	"/dashboard/profile/edit": PerfilDesktop,
	"/dashboard/profile/spotlight": Spotlight,
	"/dashboard/profile/social": Socials,
	"/dashboard/walletTheme": WalletTheme,
	"/dashboard/dashboardTheme": DashboardTheme,
	"/dashboard/in-voice": InVoice,
	"/dashboard/api-keys": ApiKeys,
	"/dashboard/api-keys/create": ViewCreateApiKey,
	"/dashboard/gas-station": GasStationView,
	"/dashboard/discord": DiscordOverViewPage,
	"/dashboard/tiktok": TikTokOverViewPage,
	"/dashboard/youtube": YoutubeOverViewPage,
	"/dashboard/targets": ViewTargets,
	"/dashboard/targets/create": ViewCreateTarget
};

