import { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router';

const LocationContext = createContext();

const LocationContextProvider = ({ children }) => {
  const location = useLocation();
  const [ currentUrl, setCurrentUrl ] = useState('');
  const [ beforeUrl, setBeforeUrl ] = useState('');

  useEffect(() => {
    if(location){
      const path = location.pathname; // Obtener la ruta actual
      const newFullUrl = path + location.search; // Construir la URL completa
      if(path !== null && path !== "/" && path !== "/login" && path !== "/oauth-callback"){
        setBeforeUrl(currentUrl);
        localStorage.setItem('beforeLocationUrl', currentUrl); // Guardar la ruta en el LocalStorage
        localStorage.setItem('currentLocationUrl', newFullUrl); // Guardar la ruta en el LocalStorage
        setCurrentUrl(newFullUrl);
      }
    }
  
  }, [location,currentUrl]);

  return (
    <LocationContext.Provider value={{ currentUrl, beforeUrl }}>
      {children}
    </LocationContext.Provider>
  );
};

export { LocationContext, LocationContextProvider };
