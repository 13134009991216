import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';

export default function RenderCommonImageConfig({ url }) {
  return (
    <TableCell>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: '0.7rem',
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: 30,
            height: 30,
            objectFit: 'contain'
          }}
          src={url}
        />
      </Box>
    </TableCell>
  )
}