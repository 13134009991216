import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './FormDynamic.module.css';
import { initializeYupCustom } from './utils/validationsYup';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Formik, Form, useFormikContext } from 'formik';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import InputText from './components/InputText';
import MediaUploader from './components/MediaUploader';
import InputNumber from './components/InputNumber';
import InputCheckbox from './components/InputCheckbox';
import InputSelect from './components/InputSelect';
import InputWhiteList from './components/InputWhiteList';
import InputTextArea from './components/InputTextArea';
import { combineWithForm } from 'utils/objects';
import InputAttr from './components/InputAttr';
import InputUrl from './components/InputUrl';
import CustomAutoComplete from 'components/CustomAutoComplete/CustomAutoComplete';
import InputWeb3Address from './components/InputWeb3Address';
import { InputDate, InputDateTime } from './components/InputDate';
import InputRichTextArea from './components/InputRichTextArea';

const INPUT_COMPONENTS_MAP = {
  email: props => <InputText {...props} />,
  string_short: props => <InputText {...props} />,
  string_long: props =>  <InputTextArea {...props} />,
  rich_text_area: props =>  <InputRichTextArea {...props} />,
  multimedia: props => <MediaUploader {...props} styles={props?.configMultimedia[props?.name]?.styles || null} />,
  array: props => <CustomAutoComplete {...props} initValue={props?.initStateWithValues[props?.name]?.values || []} options={props?.initStateWithValues[props?.name]?.options || []}  />,
  number: props => <InputNumber {...props} />,
  number_zero_or_greater: props => <InputNumber {...props} />,
  checkbox: props => <InputCheckbox {...props}  />,
  select: props => <InputSelect {...props} initialSelectedValue={props?.initStateWithValues[props?.name]?.value || ''} options={props?.initStateWithValues[props?.name]?.options || []} />,
  white_list: props => <InputWhiteList {...props} initValue={props?.initStateWithValues[props?.name] || []}  />,
  pair_list: props => <InputAttr {...props} />,
  url: props => <InputUrl {...props} />,
  web3Address: props => <InputWeb3Address {...props} />,
  date: props => <InputDate {...props} initValue={props?.initStateWithValues[props?.name] || null} minDate={props?.initStateWithValues[props?.name]?.minDate || null} maxDate={props?.initStateWithValues[props?.name]?.maxDate|| null}  />,
  date_time: props => <InputDateTime {...props} initValue={props?.initStateWithValues[props?.name] || null} minDate={props?.initStateWithValues[props?.name]?.minDate || null} maxDate={props?.initStateWithValues[props?.name]?.maxDate|| null} />
};

const isValidURL = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;  
  }
}
const isAutocomplete = (valueForm) => {
  if (isValidURL(valueForm)) {
    return valueForm;
  } else if(typeof valueForm === 'object' && valueForm !== null){
    if(valueForm?.length >= 0){
      return valueForm;
    } else if(valueForm?.value){
      return valueForm?.value
    } else if (valueForm?.values){
      return valueForm?.values
    }
  } else if(typeof valueForm === 'string' && valueForm !== ''){
    return valueForm;
  }
  return '';
}

const NewFormDynamic = ({ 
  initValues,
  initStateWithValues,
  inputDescriptions,
  loading,
  handleCancel,
  renderButtons,
  textButtons,
  onSubmit,
  textPlaceholders,
  textLabels,
  enableReinitialize,
  ignoreValidation,
  oneColumn,
  configMultimedia,
  concatValidations,
  recoverValues
}) => {
  const { t } = useTranslation('form');
  const attributes = initValues ? Object.keys([initValues][0]) : [];
  const types = Object.values(initValues);
  const form = Object.fromEntries(attributes.map(item => [item, isAutocomplete(initStateWithValues?.[item])]));
  const validationSchema = initializeYupCustom(t,attributes,types,ignoreValidation,concatValidations);
  const placeholders = combineWithForm(form,textPlaceholders);
  const labels = combineWithForm(form,textLabels);

  return (
    <BuildForm 
      attributes={attributes}
      types={types}
      form={form}
      validationSchema={validationSchema}
      initValues={initValues}
      loading={loading}
      handleCancel={handleCancel}
      renderButtons={renderButtons}
      textButtons={textButtons}
      t={t}
      onSubmit={onSubmit}
      placeholders={placeholders}
      labels={labels}
      enableReinitialize={enableReinitialize}
      oneColumn={oneColumn}
      initStateWithValues={initStateWithValues}
      inputDescriptions={inputDescriptions}
      configMultimedia={configMultimedia}
      recoverValues={recoverValues}
    />
  );
};

NewFormDynamic.defaultProps = {
  initStateWithValues: {},
  loading: false,
  handleCancel: () => {},
  renderButtons: null,
  onSubmit: null,
  textPlaceholders: null,
  textLabels: null,
  enableReinitialize:false,
  ignoreValidation: null,
  oneColumn: false,
  configMultimedia: {},
  recoverValues: null
}

NewFormDynamic.propTypes = {
  initValues: PropTypes.object,
  initStateWithValues: PropTypes.object,
  onInitSubmit: PropTypes.func,
  renderForm : PropTypes.func,
  loading: PropTypes.bool,
  handleCancel: PropTypes.func,
  renderButtons: PropTypes.any,
  textButtons: PropTypes.any,
  onSubmit: PropTypes.any,
  textPlaceholders: PropTypes.any,
  textLabels: PropTypes.any,
  enableReinitialize: PropTypes.bool,
  ignoreValidation: PropTypes.any,
  oneColumn: PropTypes.bool,
  configMultimedia: PropTypes.any,
  recoverValues: PropTypes.func
};



const BuildForm = ({
  attributes,
  types,
  form,
  validationSchema,
  initValues,
  loading,
  handleCancel,
  renderButtons,
  textButtons,
  t,
  onSubmit,
  placeholders,
  labels,
  enableReinitialize,
  oneColumn,
  initStateWithValues,
  inputDescriptions,
  configMultimedia,
  recoverValues
}) =>{


  const handleSubmit = (values, paramsFormik) => {
    if(onSubmit){
      onSubmit(values, paramsFormik);
    }
  }

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={form}
    >
      {({isSubmitting, values, ...formOptions}) => (
        <Form className={oneColumn ? styles.formFlex:styles.form}>
          {recoverValues && typeof recoverValues == "function" &&  recoverValues(values)}
          <div className={styles.col1}>
            {
              attributes.map((name,index)=>{
                if(types[index] !== "multimedia"){
                  return (
                    <div   key={name} style={{display:'flex',flexDirection:'column'}} >
                    <RenderComponent 
                      name={name} 
                      placeholder={placeholders[name]}
                      label={labels[name]}
                      type={types[index]}
                      loading={isSubmitting || loading}
                      initValues={initValues}
                      initStateWithValues={initStateWithValues}
                      inputDescriptions={inputDescriptions}
                    />
                    </div>
                  )
                }
              })
            }
            {renderButtons && renderButtons(isSubmitting)}
            {!renderButtons && (
            <div className={styles.contentButtons}>
              <Button 
                color="success" variant="contained"
                type="submit"
                disabled={isSubmitting || loading}
                data-testid="custom-form-submit-button"
              >
                {textButtons?.submit ? textButtons.submit : t("options.accept")}
              </Button>
              <Button 
                color="success" variant="transparent"
                type="button"
                onClick={() => handleCancel({ ...formOptions, isSubmitting })}
                disabled={isSubmitting || loading}
                data-testid="custom-form-cancel-button"
              >
                {textButtons?.cancel ? textButtons.cancel : t("options.cancel")}
              </Button> 
            </div>
            )}
          </div>
          <div className={styles.col2}>
            <div  className={styles.centerCol2}>
            {
            attributes.map((name,index)=>{
              if(types[index] === "multimedia"){
                return (
                  <RenderComponent 
                    key={name} 
                    name={name}
                    placeholder={placeholders[name]}
                    label={labels[name]}
                    type={types[index]}
                    loading={isSubmitting || loading}
                    initValues={initValues}
                    initStateWithValues={initStateWithValues}
                    inputDescriptions={inputDescriptions}
                    configMultimedia={configMultimedia}
                  />
                )
              }
            })
            }
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const RenderComponent = ({type, name, placeholder, label, loading, initValues, inputDescriptions, ...props }) =>{
  const InputComponent = INPUT_COMPONENTS_MAP[type];
  const { setFieldValue } = useFormikContext();
  const inputDescription = inputDescriptions ? inputDescriptions[name] : undefined;


  if (!InputComponent) {
    return <small>Unsupported input {'"'+name+'"'} type: ${type}</small>
  }

  return (
    <FromControlInput name={name} type={type}>
        <InputComponent 
          name={name}
          inputDescription={inputDescription}
          placeholder={placeholder}
          label={label}
          setFieldValue={setFieldValue} 
          loading={loading}
          initValues={initValues} 
          {...props}
        />
    </FromControlInput>
  );
}

export const ShowError = ({ children }) => {
  try {
    if (typeof children === "string") {
      return <FormHelperText error={true}>{children}</FormHelperText>;
    } else if (Array.isArray(children)) {
      return (
        <FormHelperText error={true}>
          <div style={{display:'flex',flexDirection:'column'}}>
          {children.map((errorObj) => {
            const errorMessages = Object.values(errorObj);
            return errorMessages.map((errMsg,index) => (
              <div key={index}>
              {errMsg}
              </div>
            ))}
          )}
          </div>
        </FormHelperText>
      );
    } else if (typeof children === "object") {
      const errorMessages = Object.values(children);
      return (
        <div>
          {errorMessages.map((errMsg, index) => (
            <FormHelperText key={index} error={true}>
              {errMsg}
            </FormHelperText>
          ))}
        </div>
      );
    } else {
      console.error("Unexpected error content:", children);
      return null;
    }
  } catch (error) {
    console.error("ShowError::", error);
    return null;
  }
}


const FromControlInput = ({name,type,children}) => {

  return (
    <Fragment>
      {children}
      {type !== "white_list" &&
      <ErrorMessage name={name} component={ShowError} />
      }
    </Fragment>
  )
}

export default NewFormDynamic;
