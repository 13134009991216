import { LargeFormSkeleton } from "components/Skeletons/Forms";
import { Alert } from "@mui/material";
import { useFetch } from "hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import { find, update } from "services/campaigns/campaigns";
import { formatApiDate } from "utils/date";
import NewFormDynamic from "components/NewFormDynamic/NewFormDynamic";
import { useTranslation } from 'react-i18next';
import { uploadFile } from "services/files";
import { useSpinnerContext } from "context/SpinnerContext";

export default function ViewEditCampaign() {
  const { t } = useTranslation('campaigns');
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const { handleChangeLoading, handleChangeMessage, handleChangeError } = useSpinnerContext();

  const {
    data: campaign,
    loading: loadingCampaign,
    error: errorCampaign,
  } = useFetch(find, { params: { id: campaignId, limit: 1, page: 0, order: 'id_desc' } });

  const onCancelEditCampaign = () => {
    navigate(`/dashboard/campaigns/${campaignId}`);
  }

  const onSubmitEditCampaign = async (data) => {
    handleChangeError("");
    handleChangeLoading(true);
    try {
      handleChangeMessage(t(`alerts.saving_campaign`));
      await update([data]);
      navigate(`/dashboard/campaigns/${campaignId}`);
    } catch (error) {
      console.error(error);
      handleChangeError(t('alerts.error_saving_campaign'));
    } finally {
      handleChangeLoading(false);
      handleChangeMessage("");
    }
  }

  return (
    <div data-testid="view-edit-campaign">
      {loadingCampaign && !errorCampaign && <LargeFormSkeleton />}
      {errorCampaign && <ErrorAlert />}
      {!loadingCampaign && !errorCampaign && campaign &&
        <EditCampaignForm
          campaign={campaign}
          onSubmit={onSubmitEditCampaign}
          onCancel={onCancelEditCampaign}
        />
      }
    </div>
  );
}

function ErrorAlert() {
  return (
    <div data-testid="error-alert">
      <Alert severity="error">
        Error
      </Alert>
    </div>
  );
}

const defaultFileds = {
  banner: 'multimedia',
  name: 'string_short',
  description: 'rich_text_area',
  release_date: 'date_time',
  start_date: 'date_time',
  end_date: 'date_time',
}

export function EditCampaignForm({ campaign, onCancel, onSubmit }) {
  const { t } = useTranslation('campaigns');
  const { handleChangeLoading, handleChangeMessage, handleChangeError } = useSpinnerContext();

  const initialValues = {
    banner: campaign.banner_url,
    name: campaign.name,
    description: campaign.description,
    release_date: campaign.release_date,
    start_date: campaign.start_date,
    end_date: campaign.end_date,
  }

  const handleSubmit = async (values, form) => {
    handleChangeError("");
    handleChangeLoading(true);
    try {
      const banner_url = typeof values.banner === 'string' ? values.banner : await uploadFile(values.banner);
      const data = {
        id: campaign.id,
        banner_url,
        name: values.name,
        description: values.description,
        type: campaign.type,
        release_date: formatApiDate(values.release_date),
        start_date: formatApiDate(values.start_date),
        end_date: formatApiDate(values.end_date),
      };
      form.setSubmitting(false);
      onSubmit(data);
    } catch (error) {
      console.error(error);
      handleChangeError(t('alerts.error_saving_campaign'));
      handleChangeMessage("");
      handleChangeLoading(false);
    }
  }

  const handleCancel = ({ setSubmitting }) => {
    setSubmitting(false);
    onCancel();
  }

  return (
    <div data-testid="edit-campaign-form">
      <NewFormDynamic
        initValues={defaultFileds}
        initStateWithValues={initialValues}
        onSubmit={handleSubmit}
        handleCancel={handleCancel}
        textButtons={{
          submit: t("options.save"),
          cancel: t("create_view.options.cancel")
        }}
        textLabels={{
          name: t("form.name"),
          description: t("form.description"),
          release_date: t("form.date_release"),
          start_date: t("form.date_start"),
          end_date: t("form.date_end"),
        }}
        textPlaceholders={{
          name: t("form.name"),
          description: t("form.description"),
        }}
      />
    </div>
  );
}