import axios from "axios"
import { buildUrlParams } from "utils/fetch";
axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;

const path = "gas-station/blockchain"
const url = `${process.env.REACT_APP_API}/${path}`;

export const findResume = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {limit: 100, page: 0, order: "name_desc"}
}) => {
  const response = await axios.get(`${url}?domain=${domain}${buildUrlParams(params)}`);
  return response.data;
};