import { TextField } from "@mui/material";
import { stringToObjectField } from "utils/strings";

export default function WrappedMaterialTextField({ field, form: { touched, errors }, ...props }) {

  const getTouched = () => {
    const isTouched = stringToObjectField(field.name, touched);
    if (isTouched === null) return false;
    return isTouched;
  }

  const getError = () => {
    if (!getTouched()) return "";
    const error = stringToObjectField(field.name, errors);
    if (error === null) return "";
    return error;
  }

  return (
    <TextField
      {...field}
      {...props}
      error={(Boolean(getTouched()) && Boolean(getError()))}
    />
  )
}