import React, { useCallback, useContext} from 'react';
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography';
import styles from './ViewNfts.module.css';
import { findAll, update } from 'services/nfts';
import { useFetch } from 'hooks/useFetch';
import { BreadCrumbsSaveData } from 'components/BreadCrumbs/BreadCrumbs';
import { useNavigate } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import { NFT_STATUS } from 'utils/nftStatus';
import UserContext from 'context/UserContext';
import { getActionsByRoleCode } from 'utils/rols';
import { useSpinnerContext } from 'context/SpinnerContext';
import DynamicTable from 'components/DynamicTable/DynamicTable';
import { findThumbnail } from 'utils/thumbnail';


const viewNfts = () => {
  const { t } = useTranslation("nfts")
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { handleChangeLoading ,handleChangeMessage, handleChangeError } = useSpinnerContext();
  const { data: nfts, loading:loadingNFT, refresh } = useFetch(findAll,{ 
    params: { limit: 100, page: 0, order: 'created' } 
  });


  const handleGo = useCallback((path) => () => {
    navigate(`/dashboard/nftCatalog/${path}`);
  },[navigate]);

  const statusOptions = {
    [NFT_STATUS.PENDING_APPROVE]: {
      status: NFT_STATUS.PENDING_APPROVE,
      label: t("table.status.pending_approve"),
      color: "statusGray"
    },
    [NFT_STATUS.APPROVED]: {
      status: NFT_STATUS.APPROVED,
      label: t("table.status.approved"),
      color: "statusGreen"
    },
    [NFT_STATUS.REJECTED_NFTs]: {
      status: NFT_STATUS.REJECTED_NFTs,
      label: t("table.status.rejected_nfts"),
      color: "statusRed"
    }
  }

  const configCells = {
    blockchain:{  
      render: (item) => {
        return (
          <TableCell>
            <Box
              sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              gap:'0.7rem',
              }}
            >
              <CardMedia
                component={item?.is_video ? 'video' : 'img'}
                key={item?.chainid}
                sx={{ 
                  width: 30, 
                  height: 30,
                  objectFit: 'contain'
                }}
                src={item?.icon}
              />
            </Box>
          </TableCell>
        )
      }
    },
    status:{  
      render: (item) => {
        return (
          <TableCell>
            <Chip 
              label={statusOptions[item]?.label || 'Empty'}
              color={statusOptions[item]?.color || 'statusGray'}
            />
          </TableCell>
        )
      }
    }
  }


  const configSelected = {
    defineId: 'id',
    // onSelect: ({ row, arraySelectedById }) => {
    //   console.log("debug item row::",row);
    //   console.log("debug arraySelectedById::",arraySelectedById)
    // },
    options:[
      {
        id:'approve',
        label: 'Approve',
        color: 'success',
        variant: 'contained',
        tooltip:'Click to approve',
        onClick: async (item) => {
          try {
            handleChangeLoading(true);
            handleChangeMessage(t("message.approving"));
            let nfts = []
            for (const nft of item) {
              nfts.push({
                status : 1,
                collection_key : nft.collection_key,
                token_id : nft.token_id
              })
            }
            await update(nfts)
            handleChangeMessage(t("message.successful_approval"));
            refresh();
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,'approvedNFTs') && item?.status === 0) 
        }        
      },
      {
        id:'rejected',
        label: 'Rejected',
        color: 'error',
        variant: 'contained',
        tooltip:'Click to rejected',
        onClick: async (item) => {
          try {
            handleChangeLoading(true);
            handleChangeMessage(t("message.rejecting"));
            let nfts = []
            for (const nft of item) {
              nfts.push({
                status : 8,
                collection_key : nft.collection_key,
                token_id : nft.token_id
              })
            }
            await update(nfts)
            handleChangeMessage(t("message.rejected"));
            refresh();
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,'rejectedNFTs') && item?.status === 0) 
        }       
      },
      {
        id: 'edit',
        label: 'Edit',
        color: 'info',
        variant: 'contained',
        tooltip:'Click to edit',
        onClick: (item) => {
          console.log("debug ",item);
        },
        conditions: ()=>{
          return getActionsByRoleCode(user?.role,'editNFTs')
        }   
      }
    ]
  }

  return (
    <section className={styles.contentSection} >
    <div className={styles.headerSection}>
      <div className={styles.subHeaderSection}>
        <Typography variant="body2" gutterBottom flex={1}>
          {t("description")}
        </Typography>
      </div>
    </div>
    <div className={styles.workSpace}>
      <DynamicTable 
        title={''}
        activeSearchBar={true}
        activeModeView={false}
        activeFilter={true}
        data={nfts}
        loading={loadingNFT}
        preparedDataConfig={(item)=>{
          return {
            id: item.token_id + item?.collection_key,
            token_id:item.token_id,
            thumb_url: (item?.thumbnails) ? findThumbnail(item, 650,500) : item?.thumb_url,
            is_video: item?.metadata?.is_video,
            name: item.name,
            description: item.description,
            blockchain: item.blockchain,
            status: item.status
          }
        }}
        setConfigParams={{
          configCells,
          configSelected,
          allowedKeys:["token_id", "thumb_url", "name", "description", "blockchain","status"],
          textHeaders:{
            thumb_url:' ',token_id:'Id'
          },
          onClick:(item)=>{
            const value = {
              name: item.name,
              collection_key: item.id
            }
            BreadCrumbsSaveData("tokenId",value);
            handleGo(`${item.id}`)()
          }
        }}
      />
    </div>
  </section>
  )
}

export default viewNfts;