import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ChartSlider.module.css';
import Card from '@mui/material/Card'
import BarChartHorizontal from 'components/ChartsComponents/BarChart/BarChartHorizontal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box'

const ChartSlider = ({
  title = "Subscribers",
  configChart
}) => {

  const [age, setAge] = useState('Last year');

  const handleChange = (event) => {
    setAge(event.target.value);
  };


  return (
    <Card className={styles.card}>
      <div className={styles.headerCard}>
        {title && <div className={styles.title}>{title}</div>}
        <Box flex={1} />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          onChange={handleChange}
          size="small"
        >
          <MenuItem value={'Last 7 Days'}>Last 7 Days</MenuItem>
          <MenuItem value={'Last 30 Days'}>Last 30 Days</MenuItem>
          <MenuItem value={'Last 6 months'}>Last 6 months</MenuItem>
          <MenuItem value={'Last year'}>Last year</MenuItem>
        </Select>
      </div>
      <div className={styles.contentSlide}>
        <BarChartHorizontal {...configChart} />
      </div>
    </Card>
  );
};

ChartSlider.propTypes = {
  title: PropTypes.string,
  configChart: PropTypes.object
};

export default ChartSlider;