import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { useFetch } from "hooks/useFetch";
import { findWallet } from "services/blockchains/relayWallet";

export default function RelayWallet() {
  const { data, loading, error, refresh } = useFetch(findWallet, { params: {} });

  return (
    <div data-testid="relay-wallet">
      {loading && <LoadingSkeleton />}
      {!loading && error && <ErrorMessage refresh={refresh} />}
      {!loading && !error && data && <WalletDisplayer wallet={data} />}
    </div>
  )
}

const WalletDisplayer = ({ wallet }) => {
  const { t } = useTranslation("gasStation");
  const TRANLATION_BASE = "relay_wallet";

  const handleCopy = () => {
    navigator.clipboard.writeText(wallet.address)
  }

  return (
    <Paper data-testid="wallet-displayer" sx={{ p: 2 }}>
      <Typography variant="h6">{t(`${TRANLATION_BASE}.title`)}</Typography>
      <Chip
        label={wallet.address}
        onDelete={handleCopy}
        onClick={handleCopy}
        deleteIcon={<ContentCopyIcon />}
      />
    </Paper>
  )
}

const ErrorMessage = ({ refresh }) => {
  const { t } = useTranslation("gasStation");
  const TRANLATION_BASE = "relay_wallet";

  return (
    <Alert data-testid="error-message" severity='error'>
      <Typography variant="body1" mb={1}>
        {t(`${TRANLATION_BASE}.messages.error_fetching`)}
      </Typography>
      <RetryButton refresh={refresh} />
    </Alert>
  )
}

const LoadingSkeleton = () => {
  return (
    <div data-testid="loading-skeleton">
      <Skeleton variant="rectangular" width="100%" height={115} />
    </div>
  )
}

const RetryButton = ({ refresh }) => {
  const { t } = useTranslation("gasStation");
  const TRANLATION_BASE = "blockchain_resume_table";
  return (
    <Button
      variant="outlined"
      color="info"
      onClick={refresh}
      data-testid="retry-button"
    >
      {t(`${TRANLATION_BASE}.actions.retry`)}
    </Button>
  )
}