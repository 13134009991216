import React from 'react';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { formatApiDate } from 'utils/date';


export default function CreateCamapaignForm({ onSubmit, onCancel }) {

  const { t } = useTranslation('campaigns');

  const defaultFileds = {
    banner: 'multimedia',
    name: 'string_short',
    description: 'rich_text_area',
    release_date: 'date_time',
    start_date: 'date_time',
    end_date: 'date_time',
  };

  const handleSubmit = async (values, form) => {
    onSubmit({
      ...values,
      release_date: formatApiDate(values.release_date),
      start_date: formatApiDate(values.start_date),
      end_date: formatApiDate(values.end_date),
    });
    form.setSubmitting(false);
  }

  const handleCancel = ({ setSubmitting }) => {
    setSubmitting(false);
    onCancel();
  }

  return (
    <div data-testid="create-campaign-form">
      <NewFormDynamic
        initValues={defaultFileds}
        onSubmit={handleSubmit}
        handleCancel={handleCancel}
        textButtons={{
          submit: t("create_view.options.create"),
          cancel: t("create_view.options.cancel")
        }}
        textLabels={{
          name: t("form.name"),
          description: t("form.description"),
          release_date: t("form.date_release"),
          start_date: t("form.date_start"),
          end_date: t("form.date_end"),
        }}
        textPlaceholders={{
          name: t("form.name"),
          description: t("form.description"),
        }}
      />
    </div>
  );
}

CreateCamapaignForm.defaultProps = {
  onSubmit: () => { },
}

CreateCamapaignForm.propTypes = {
  onSubmit: PropTypes.func,
}
