import { useState, useCallback, Fragment } from "react";
import SeleccionaElementoDestacado from "components/SeleccionaElementoDestacado";
import PortalPopup from "components/PortalPopup";
import SpotlightCard from "components/SpotlightCard";
import styles from "./Spotlight.module.css";

const Spotlight = () => {
  const [
    isSeleccionaElementoDestacadoOpen,
    setSeleccionaElementoDestacadoOpen,
  ] = useState(false);

  const openSeleccionaElementoDestacado = useCallback(() => {
    setSeleccionaElementoDestacadoOpen(true);
  }, []);

  const closeSeleccionaElementoDestacado = useCallback(() => {
    setSeleccionaElementoDestacadoOpen(false);
  }, []);

  return (
    <Fragment>
      <section className={styles.frameWrapper}>
        <section className={styles.divGrficasWrapper}>
          <div className={styles.divGrficas}>
            <div className={styles.divGrficasInner}>
              <div className={styles.imagenWrapper}>
                <div className={styles.imagen}>
                  <div className={styles.ttuloYDescripcinParent}>
                    <div className={styles.ttuloYDescripcin}>
                      <div className={styles.title}>Spotlight</div>
                      <div className={styles.configuraLaSeccin}>
                        Configura la sección principal de tu perfil y muestra a
                        los usuarios tus NFTs mas destacados.
                      </div>
                    </div>
                    <div
                      className={styles.ttuloYDescripcin1}
                      onClick={openSeleccionaElementoDestacado}
                    >
                      <div className={styles.title}>
                        + AÑADIR ELEMENTO DESTACADO
                      </div>
                      <div className={styles.configuraLaSeccin1}>
                        Configura la sección principal de tu perfil y muestra a
                        los usuarios tus NFTs mas destacados.
                      </div>
                    </div>
                  </div>
                  <div className={styles.frameParent}>
                    <SpotlightCard
                      iMG="/spotlight1.png"
                      total="96/100"
                      title="Cat 1"
                    />
                    <SpotlightCard
                      iMG="/spotlight2.png"
                      total="96/100"
                      title="Cat 2"
                    />
                    <SpotlightCard
                      iMG="/spotlight3.png"
                      total="89/100"
                      title="Cat 3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {isSeleccionaElementoDestacadoOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSeleccionaElementoDestacado}
        >
          <SeleccionaElementoDestacado
            onClose={closeSeleccionaElementoDestacado}
          />
        </PortalPopup>
      )}
    </Fragment>
  );
};

export default Spotlight;
