import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@mui/material';

export default function SkeletonRows({ nRows, nCells }) {
    const items = Array.from({ length: nRows }, (_, index) => index);

    return (
        items?.map((item) => (
            <TableRow key={item}>
                <SkeletonCells nCells={nCells} />
            </TableRow>
        ))
    );
}

export const SkeletonCells = ({ nCells }) => {
    const emptyCells = Array(nCells).fill(null);
    return (
        emptyCells.map((_, index) => {
            return <TableCell key={index} align="center"> <Skeleton variant="rectangular" /> </TableCell>
        })
    )
}

SkeletonRows.defaultProps = {
    nRows: 2,
    nCells: 2
};

SkeletonRows.propTypes = {
    nRows: PropTypes.number,
    nCells: PropTypes.number
};