import axios from "axios";
import { buildUrlParams } from "utils/fetch";

axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;

const PATH = "account-target"
const URL = `${process.env.REACT_APP_API}/${PATH}`;

export const createAccountTargets = async (body) => {
  const response = await axios.post(URL, body, {
    headers: {'X-API-Key': localStorage.getItem("userApiKey")},
    params: {domain: process.env.REACT_APP_DOMAIN}
  });
  return response.data;
}

export const findAccountTargets = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {limit: 100, page: 0, order: "created"}
}) => {
  const response = await axios.get(`${URL}?domain=${domain}${buildUrlParams(params)}`);
  return response.data;
}

export const createTarget = async (body) => {
  const response = await axios.post(URL, body, {
    headers: {'X-API-Key': localStorage.getItem("userApiKey")},
    params: {domain: process.env.REACT_APP_DOMAIN}
  });
  return response.data;
}