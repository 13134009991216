import styles from "./Login.module.css"

export default function LoginInputField({ children, label, id, error, errorMessage, ...props }) {
    return (
        <div className={styles.inputField} {...props}>
            <label className={styles.inputLabel} htmlFor={id}>{label}</label>
            {children}
            {error &&
                <p className={`${styles.cardParagraph} ${styles.inputErrorMessage}`}> {errorMessage}</p>
            }
        </div>
    )
}