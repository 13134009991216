import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import { createIfNotExists, generateJwt } from 'services/user';
import { getTypeConnectionLS } from 'utils/localStorage';

export const web3Connection = async (provider) => {
  const web3 = new Web3(provider)
  const chainID = await web3.eth.getChainId()
  const accounts = await web3.eth.getAccounts()
  const wallet = accounts[0];
  const emailGenerated = await generateEmail(wallet,null);
  const { res : info_user, isNew } = await createIfNotExists(
    process.env.REACT_APP_DOMAIN,
    process.env.REACT_APP_NAME_NETWORK,
    {
      wallet: wallet,
      signature:'connect',message:'connect',
      provider:web3,
      from:wallet,
      email: emailGenerated
    }
  );
  const { signature, message } = await sign(wallet, provider, "connect");
  const userXApiKey = await generateJwt(process.env.REACT_APP_DOMAIN,{message,signature});
  return {
    provider,
    chainID,
    wallet,
    userXApiKey,
    isNew,
    ...info_user,
    typeWallet: getTypeConnectionLS(),
  }
}

const generateEmail = async(account,email) => {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  if (!email || !emailRegex.test(email)) {
    return `${account}@${process.env.REACT_APP_DOMAIN}`;
  }
  return email;
}
export const loginMetamask = async () => {
  let provider = await detectEthereumProvider();

  if (!provider || typeof window.ethereum === 'undefined') {
    throw Error('Por favor instala un wallet de navegador')
  }

  try {
    await provider.request({ method: 'eth_requestAccounts' });
    const res = await web3Connection(provider);
    await checkNetwork(res);
    return  res;
  } catch (error) {
    window.localStorage.removeItem('typeWallet');
    console.error('metamask error::', error);
    localStorage.clear()
    throw new Error(error);
  }
}

export const getCurrentUser = async () => {
  const provider = await detectEthereumProvider();
  if (!provider) return null;
  try {
    const accounts = await provider.request({ method: 'eth_accounts' });
    if (!accounts || accounts.length === 0) return null;
    return await web3Connection(provider);
  } catch (error) {
    console.error('metamask error::', error);
    return null;
  }
}

export const changeNetwork = async (provider, chainId) => {
  return provider.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: `${chainId}` }],
  })
}

export const registerNetwork = async (provider, params) => {
  return provider.request({
    method: 'wallet_addEthereumChain',
    params: [params],
  });
}

export const sign = async (from, provider, text = "CREATE_COLLECTION") => {
  let web3 = new Web3(provider);
  let message = web3.utils.sha3(text + ' ' + new Date().getTime());
  let hex = '';
  for (let i = 0; i < message.length; i++) {
    hex += '' + message.charCodeAt(i).toString(16);
  }
  const hexMessage = '0x' + hex;
  let signature = await web3.eth.personal.sign(hexMessage, from);
  localStorage.setItem('signature', signature);
  localStorage.setItem('message', message);
  return { signature, message };
};

const checkNetwork = async (user) => {
  if (user.chainID !== process.env.REACT_APP_NETWORK) {
    const chainId = await changeToCorrectNetwork(user.provider);
    user.chainID = chainId;
  }
}


const changeToCorrectNetwork = async (provider) => {
  try {
    const chainId = parseInt(process.env.REACT_APP_NETWORK);
    const hexId = '0x' + chainId.toString(16);
    await changeNetwork(provider, hexId);
    return chainId;
  } catch (error) {
    if (error.code == 4902) {
      await registerAppChain(provider);
      await sleep(2000);
      return await changeToCorrectNetwork(provider);
    }
    throw error;
  }
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const registerAppChain = async (provider) => {
  const chainId = parseInt(process.env.REACT_APP_NETWORK);
  await registerNetwork(provider, {
    chainId: '0x' + chainId.toString(16),
    chainName: process.env.REACT_APP_NAME_NETWORK,
    nativeCurrency: {
      name: process.env.REACT_APP_CURRENCY_NAME_NETWORK,
      symbol: process.env.REACT_APP_CURRENCY_SYMBOL_NETWORK,
      decimals: parseInt(process.env.REACT_APP_CURRENCY_DECIMALS_NETWORK),
    },
    rpcUrls: [process.env.REACT_APP_RPC],
    blockExplorerUrls: [process.env.REACT_APP_SCAN]
  });
}

export const listenerBrowserWallet = (provider, callbackChange, callbackDisconnect) => {
  if (typeof window.ethereum === 'undefined' || !provider) {
    throw new Error('Please install a browser wallet like MetaMask and reload the website');
  }

  const chainChangedHandler = async() => {
    const res = await getCurrentUser();
    await callbackChange(res);
  };

  const accountsChangedHandler = async() => {
    const res = await getCurrentUser();
    await callbackChange(res);
  };

  const disconnectHandler = async() => {
    await callbackDisconnect();
  };

  if(provider){
    provider.on('chainChanged', chainChangedHandler);
    provider.on('accountsChanged', accountsChangedHandler);
    provider.on('disconnect', disconnectHandler);
    return () => {
      provider.removeListener('chainChanged', chainChangedHandler);
      provider.removeListener('accountsChanged', accountsChangedHandler);
      provider.removeListener('disconnect', disconnectHandler);
    };
  }
};


export const disconnectMetamask = () => {
  localStorage.clear();
};