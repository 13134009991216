import styles from "./Login.module.css"

export default function SocialLoginButton({label, icon, ...props}) {
    return (
        <button
            className={`${styles.socialLoginButton} ${styles.customButton}`}
            {...props}
        >
            {icon &&
            <img className={styles.socialIcon} alt="" src={icon} />
            }
            {label}
        </button>
    )
}