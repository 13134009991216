import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@mui/material/FormLabel';
import { FieldArray, Field, useFormikContext,ErrorMessage } from 'formik'
import { capitalizeFirstLetter } from 'utils/strings';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { stringToObjectField } from "utils/strings";
import { ShowError } from '../NewFormDynamic';

const WrappedMaterialTextField = ({ field, form: { touched, errors }, ...props }) => {

  const { handleRemove,pos, originalName, loading } = props;
  const { value } = field;

  const getTouched = () => {
    const isTouched = stringToObjectField(originalName, touched);
    if (isTouched === null) return false;
    return isTouched;
  }

  const getError = () => {
    if (!getTouched()) return "";
    const error = stringToObjectField(originalName, errors);
    if (error === null) return "";
    return error;
  }

  return (
    <TextField
      {...field}
      {...props}
      disabled={loading}
      error={value === '' ? true : false}
      helperText={value === '' ? getError() : null}
      variant="outlined"
      sx={{
        flex: 1
      }}
      InputProps={{
        style:{
          backgroundColor: 'white'
        },
        endAdornment: <IconButton  
                        size="small"
                        disabled={loading}
                        onClick={() => handleRemove(pos)}
                        sx={{
                          color: "#fff",
                          opacity: 0.8,
                          background: (theme)=> theme.palette.error.main,
                          "&:hover":{
                            opacity: 1,
                            background: (theme)=> theme.palette.error.main,
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
      }}
    />
  )
}

const InputWhiteList = ({ name, placeholder, label, loading, initValue }) => {

  const { setFieldValue } = useFormikContext();
  const newLabel = typeof label === 'string' ? label : null;
  const newPlaceHolder = typeof placeholder === 'string' ? placeholder : null;
  
  useEffect(() => {
    if(initValue && initValue?.length > 0){
      setFieldValue(`${name}`,initValue ,true);
    }
  },[initValue])

  return (
    <Fragment>
        <Box display="flex" flexDirection="column" gap="0.5rem"  >
          <FieldArray
            disabled={loading}
            name={name}
            render={(arrayHelpers) => {

              const { form, push, remove } = arrayHelpers;
              const { values } = form;
              const items = values[name] || [];

              const handleAdd = () => {
                push('');
                setFieldValue(`${name}`, [...items, ''],true);
              }

              const handleRemove = (index) => {
                remove(index);
              };

              return (
                <Fragment>
                  <Box display="flex" flexDirection="row" gap="1rem" flexWrap="wrap" alignItems="center">
                    <FormLabel htmlFor={name}>
                      {newLabel ? newLabel : capitalizeFirstLetter(name+'')}
                    </FormLabel>
                    {
                      items?.length === 0 && (
                        <Box flex={1}>
                          <IconButton
                            disabled={loading}
                            onClick={handleAdd}
                            size="small"
                            sx={{
                              color: "#fff",
                              opacity: 0.8,
                              background: (theme)=> theme.palette.success.main,
                              "&:hover":{
                                opacity: 1,
                                background: (theme)=> theme.palette.success.main,
                              }
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                      )
                    }
                  </Box>
                  {items?.length === 0 && <ErrorMessage name={name} component={ShowError} />}
                  {
                    items?.length > 0 && items?.map((_, index) => (
                      <Box key={index} flex={1} >
                        <Box 
                          sx={{
                            display:'flex',
                            flexDirection:'row',
                            alignItems:'center',
                            gap:'1rem',
                            borderRadius: '8px',
                          }}
                        >
                          <Field
                            name={`${name}[${index}]`}
                            originalName={`${name}`}
                            loading={loading}
                            placeholder={newPlaceHolder ? newPlaceHolder : capitalizeFirstLetter(name+'')}
                            handleRemove={handleRemove}
                            pos={index}
                            component={WrappedMaterialTextField}
                          />
                        </Box>
                        {
                        index === (items?.length - 1) && 
                        <Stack sx={{
                          flex:1,
                          display:'flex',
                          flexDirection:'row',
                          justifyContent:'flex-end',
                          flexWrap:'wrap',
                          marginTop:'8px',
                        }}>
                          <IconButton
                            disabled={loading}
                            onClick={handleAdd}
                            size="small"
                            sx={{
                              color: "#fff",
                              opacity: 0.8,
                              background: (theme)=> theme.palette.success.main,
                              "&:hover":{
                                opacity: 1,
                                background: (theme)=> theme.palette.success.main,
                              }
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Stack>
                        }
                      </Box>
                    ))
                  }
                </Fragment>
              )
            }}
          />
        </Box>
    </Fragment>
  )
};

InputWhiteList.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  initValue: PropTypes.array
};

export default InputWhiteList;