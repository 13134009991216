import axios from "axios";

axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;

const path = "campaign/objective"
const url = `${process.env.REACT_APP_API}/${path}`;

export const create = async (body) => {
  const response = await axios.post(url, body,
    {
      headers: {
        'X-API-Key': localStorage.getItem("userApiKey")

      },
      params: {
        domain: process.env.REACT_APP_DOMAIN,
      }
    });
  return response.data
}
