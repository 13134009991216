import { faker } from '@faker-js/faker';

const createRandomUser = () => {
  return {
    userId: faker.string.uuid(),
    username: faker.internet.userName(),
    email: faker.internet.email(),
    avatar: faker.image.avatar()
  };
}

const createRandomTask = () => {
  return {
    taskId: faker.string.uuid(),
    title: faker.lorem.sentence(),
    description: faker.lorem.paragraph(),
    completed: faker.datatype.boolean()
  };
}

let currentId = 0;
const knownBlockchains = ['Ethereum', 'Polygon', 'Avalanche'];

const getRandomBlockchains = () => {
  const numBlockchains = Math.floor(Math.random() * 3) + 2;
  return shuffle(knownBlockchains).slice(0, numBlockchains);
}

function shuffle(array) {
  let currentIndex = array.length, randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
}

const createProjectsRandom = () => {
  currentId++;
  return {
    id: currentId,
    thumbnail: faker.image.url,
    name: faker.lorem.sentence(),
    description: faker.lorem.paragraph(),
    total_collections: faker.number.int(),
    blockchains: getRandomBlockchains(),
    status: faker.number.int(2),
  };
}

let currentCampaignId = 0;

const formatNumberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const createCampaignRandom = () => {
  currentCampaignId++;

  const users = faker.number.int({ min: 1000, max: 9999 });

  return {
    campaign: `Campaña ${currentCampaignId}`, 
    total_users: formatNumberWithCommas(users),
    consumed_benefits: faker.number.int({ min: 100, max: 999 }),
  };
}


const createRandomUserWithOutAvatar = () => {
  return {
    username: faker.internet.userName(),
    email: faker.internet.email()
  };
}

const createRewardsRandom = () => {
  const types = ["PHYSICAL", "ERC721", "ERC20"];
  const contracts = ["0x123456789", "0x987654321", "0x123456789"];
  return {
    id: faker.number.int({min: 1}),
    type: types[faker.number.int({min: 0, max: types.length - 1})],
    external_id: faker.number.int({min: 1}),
    contract_address: contracts[faker.number.int({min: 0, max: contracts.length - 1})],
    thumb_url: faker.image.url(),
    name: faker.lorem.sentence(),
    description: faker.lorem.paragraph(),
    metadata_url: faker.internet.url(),
  }
}

const createCollectionRandom = () => { 
  return {
    collection_key: faker.lorem.word(),
    user_key: faker.lorem.word(),
    name: faker.lorem.sentence(),
    description: faker.lorem.paragraph(),
    status: faker.number.int({min: 0, max: 2}),
    thumb_url: faker.image.url(),
    banner_url_collection: faker.image.url(),
    transaction: faker.string.uuid(),
    related: faker.string.uuid(),
    domain: faker.internet.domainName(),
    only_domain: faker.datatype.boolean(),
    imported: faker.datatype.boolean(),
    blockchain_name: faker.lorem.word(),
    standard: faker.lorem.word(),
    is_video: faker.datatype.boolean(),
    external_url: faker.internet.url(),
    collection_of: faker.number.int({min: 1, max: 100}),
    floor_price: faker.number.float({min: 0, max: 100, precision: 0.01}),
  }
}

const listOfNftsToMint = () => { 
  return {
    image: faker.lorem.word(),
    name: faker.lorem.word(),
    description: faker.lorem.paragraph(),
    attributes: faker.number.int({min: 0, max: 2}),
  }
}

export const RANDOM_USERS = faker.helpers.multiple(
  createRandomUser, {count: 15 }
);
export const RANDOM_TASKS = faker.helpers.multiple(
  createRandomTask, {count: 15 }
);

export const RANDOM_BENEFITS = faker.helpers.multiple(
  createRewardsRandom, {count: 15 }
);

export const RANDOM_PROJECTS =  faker.helpers.multiple(
  createProjectsRandom, {count: 15 }
);

export const RANDOM_REWARDS = faker.helpers.multiple(
  createRewardsRandom, {count: 15 }
);

export const RANDOM_CAMPAIGNS = faker.helpers.multiple(
  createCampaignRandom, {count: 15 }
);
export const RANDOM_USERS_WITHOUT_AVATAR = faker.helpers.multiple(
  createRandomUserWithOutAvatar, {count: 15 }
);

export const RANDOM_COLLECTIONS = faker.helpers.multiple(
  createCollectionRandom, {count: 15 }
);

export const RANDOM_LIST_OF_NFTS_TO_MINT = faker.helpers.multiple(
  listOfNftsToMint, {count: 15 }
);