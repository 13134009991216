import * as React from 'react';
import { useState, useCallback } from 'react';
import { Grid, Button, Card, CardContent, Typography, Stepper, Step, StepLabel, StepContent } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { createPost } from 'services/posts';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import CardPost from 'components/CardPost/CardPost';
import { uploadFile } from 'services/files';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSpinnerContext } from 'context/SpinnerContext';
import { handleErrors } from 'utils/messages';

const STEPS = {
  CREATE_POST: 0,
  CONFIRM: 1,
  POST_SENT: 2,
}

const ViewCreatePost = () => {
  const { t } = useTranslation("post");
  const matches = useMediaQuery('(max-width:800px)');
  const { handleChangeLoading, handleChangeError } = useSpinnerContext();

  const FIEDLS_FORM = {
    thumbnail: 'multimedia',
    banner: 'multimedia',
    title: 'string_short',
    subtitle: 'string_short',
    content: 'rich_text_area'
  }

  const [activeStep, setActiveStep] = useState(STEPS.CREATE_POST);
  const [dataFields, setDataFields] = useState(null);
  const [recoverData, setRecoverData] = useState({});

  const handleChangeStep = useCallback((step) => {
    setActiveStep(step);
  },[setActiveStep]);

  const handleSubmitContinue = async (values,formik) => {
    setDataFields(values)
    formik?.setSubmitting?.(false);
    handleChangeStep(STEPS.CONFIRM);
  }

  const handleConfirm = async (step,dataFields) => {
    try{
      handleChangeLoading(true);
      const thumb_url = dataFields?.thumbnail ? await uploadFile(dataFields.thumbnail): ''
      const banner_url = dataFields?.banner ? await uploadFile(dataFields.banner): ''
      let post = {
        "title":  dataFields?.title,
        "subtitle": dataFields?.subtitle,
        "content": dataFields?.content,
        "status": 1,
        "thumb_url": thumb_url,
        "banner_url": banner_url,
        "id_campaigns": 20,
        "main_key_users": "user_key",
        "domain_users": "proline.test"
      }
      await createPost(post);
      setActiveStep(step);
      setDataFields(null);
      setRecoverData(null);
    } catch (error) {
      console.log(error);
      handleChangeError(handleErrors(error)?.message || t("errors.error_unknown"));
    } finally {
      handleChangeLoading(false);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Card sx={{width:'100%'}}>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>{t(`steps.create`)}</StepLabel>
              <StepContent>
                <NewFormDynamic
                  initValues={FIEDLS_FORM}
                  initStateWithValues={dataFields || {}}
                  loading={false}
                  onSubmit={handleSubmitContinue}
                  t={t}
                  handleCancel={() => {}}
                  textButtons={{
                    submit: t("buttons.continue"),
                    cancel: t("buttons.cancel")
                  }}
                  textLabels={{
                    title: t("form.title"),
                    subtitle: t("form.subtitle"),
                    content: t("form.description")
                  }}
                  textPlaceholders={{
                    title: t("form.title"),
                    subtitle: t("form.subtitle"),
                    content: t("form.description")
                  }}
                  recoverValues={setRecoverData}
                  ignoreValidation={{
                    subtitle: true,
                  }}
                  oneColumn={matches} 
                />
              </StepContent>
            </Step>
            <Step>
              <StepLabel>{t(`steps.confirm`)}</StepLabel>
              <StepContent>
                <Box sx={{display:'flex',flexDirection:'column',gap:'2em'}}>
                  {t(`messages.approve`)}
                  <Box>
                    <Button
                      color="success"
                      variant="contained"
                      data-testid="submit-confirm"
                      onClick={() => handleConfirm(STEPS.POST_SENT,dataFields)}
                    >
                      {t(`buttons.confirm`)}
                    </Button>
                    <Button 
                      variant="transparent"
                      type="button"
                      onClick={() => handleChangeStep(STEPS.CREATE_POST)}
                    >
                      {t(`buttons.cancel`)}
                    </Button>
                  </Box>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>{t(`steps.information`)}</StepLabel>
              <StepContent sx={{padding:'1em'}}>
                {t(`messages.information`)}
              </StepContent>
            </Step>
          </Stepper>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div" sx={{marginBottom:2}}>
              {t(`preview.title`)}
            </Typography>
            <CardPost item={recoverData}  />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ViewCreatePost;
