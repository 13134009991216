import { useState, Fragment, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import LogoutIcon from '@mui/icons-material/Logout';
import styles from "../Header.module.css";
import { shortAddress } from 'utils/strings';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { disconnect } from 'services/providers/providers';
import { resetUserLS } from 'utils/localStorage';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import i18n from 'i18next';


const PopUpProfile = ({user,setUser}) => {

  const navigate = useNavigate();
  const { t } = useTranslation("login");
  const [anchorEl, setAnchorEl] = useState(null);
  const [ expand, setExpand ] = useState(true);
  const [ logout, setLogout ] = useState(false);
  const [ avatarUrl, setAvatarUrl ] = useState(null);
  const [language, setLanguage] = useState(i18n.language);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleLogout = useCallback(async (e) => {
    e.preventDefault();
    if(logout) { return }
    try{
      if(user?.typeWallet){
        setLogout(true);
        await disconnect(user?.typeWallet,user?.magic)
        resetUserLS();
        setUser(null);
      }
    }catch(err){
      console.error(err?.message+"" || "Error logout");
    } finally {
      setLogout(true);
    }
  },[user,logout]);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  function loadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(url);
      img.onerror = reject;
      img.src = url;
    });
  }
  
  useEffect(() => {
    loadImage(user?.wallet_pic_url)
      .then(url => setAvatarUrl(url))
      .catch(() => setAvatarUrl("./avatarDefault.png"));
  }, [user]);

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            data-testid="account-menu"
          >
            <Avatar
              className={styles.frameChild}
              alt={"avatar user"}
              src={avatarUrl}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              "&.avatar-xs":{
                width: 32,
                height: 32,
              },
              "&.avatar-lg":{
                width: 70,
                height: 70,
              }
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgColor: 'red',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            "& .MuiList-root":{
              padding: '0px 0px !important',
            },
            "&.MuiPaper-root":{
              backgroundColor: (theme)=> theme.palette.primary.main,
              color: '#fff'
            }
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box
          sx={{
            width: 400,
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5rem',
            padding: '0px 1rem'
          }}
        >
          <MenuList
            sx={{
              width: "100%",
              marginTop: '5px'
            }}
          >
            <Box 
              sx={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5rem',
                padding:'0.5rem 0px'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  right: '10px',
                  top: '0px'
                }}
              >
                <IconButton onClick={handleClose} data-testid="button-close" >
                  <HighlightOffIcon 
                    sx={{
                      fontSize:'30px',
                      color: (theme)=> theme.palette.secondary.main
                    }} 
                  />
                </IconButton>
              </Box>
            </Box>
          </MenuList>
          <MenuList
            sx={{
              width: "100%",
              margin: '1rem 0px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center" gap="1rem" >
                <Avatar 
                  className="avatar-lg"
                  src={avatarUrl}
                /> 
                <div data-testid="name-popup">
                {user?.username ? shortAddress(user?.username) : ''}
                </div>
                <Button 
                  variant="outlined"
                  color="secondary"
                  sx={{
                    borderRadius: '20px'
                  }}
                  onClick={() => {navigate('/update-info-user')}}
                >
                  {t("profile_panel.manage_account")}
                </Button>
              </Box>
            </Box>
          </MenuList>
          <MenuItem
            sx={{
              width: "100%",
              borderRadius: '20px',
              padding: '0px 0px'
            }}
          >
            <Button 
              variant="outlined"
              color="secondary"
              fullWidth
              sx={{
                borderRadius: '20px',
                padding: '1rem 0px'
              }}
            >
            <GppMaybeIcon htmlColor="#ffc107" />
              {t("profile_panel.actions_recommended")}
            </Button>
          </MenuItem>
          <Box >
            <Select
              data-testid="language-select"
              labelId="language-select-label"
              id="language-select"
              value={language}
              onChange={handleLanguageChange}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{
                width: "100%",
                borderRadius: '20px',
                padding: '0rem',
                border: (theme)=> `1px solid ${theme.palette.secondary.main}`,
                color: "#fff"

              }}
            >
              <MenuItem value="es">Español</MenuItem>
              <MenuItem value="en">English</MenuItem>
            </Select>
          </Box>
          <List
            sx={{
              width: "100%",
              borderRadius: '20px',
              marginBottom: '1rem',
              padding: '1rem',
              border: (theme)=> `1px solid ${theme.palette.secondary.main}`,
            }}
          >
            <ListItemButton
              onClick={handleExpand}
              sx={{
                borderRadius: '20px',
                color: "#fff"
              }}
            >
              <ListItemText primary={t("profile_panel.accounts")} />
              {expand ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider />
                <ListItemButton 
                  sx={{
                    color: "#fff",
                    borderTop: (theme) => `1px solid ${theme.palette.secondary.main}`
                  }}
                >
                  <ListItemText primary={user?.wallet ? shortAddress(user?.wallet) : ''} />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton 
              sx={{
                color: "#fff",
                borderTop: (theme) => `1px solid ${theme.palette.secondary.main}`
              }}
              onClick={handleLogout}
            >
              <ListItemIcon>
                <LogoutIcon 
                  sx={{
                    color: (theme)=> theme.palette.secondary.main
                  }} 
                />
              </ListItemIcon>
              <ListItemText primary={t("profile_panel.logout")} />
            </ListItemButton>
          </List>
        </Box>
      </Menu>
    </Fragment>
  );
}


PopUpProfile.propTypes = {
  user: PropTypes.object
};

export default PopUpProfile;