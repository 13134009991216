import React, { useState, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { useTranslation } from 'react-i18next';
import TablePagination from "@mui/material/TablePagination";
import SkeletonRows from 'components/Skeletons/Rows';
import Typography from '@mui/material/Typography';
import PopUpFilter from 'components/PopUpFilter/PopUpFilter';
import { extractKeys } from 'components/DynamicTable/utils/extractKeys';
import { applyFilter, availableFiltersDic, getComparator, stableSort } from 'components/DynamicTable/utils/search';
import { formatFilterColumns } from 'components/DynamicTable/utils/formatData';
import DynamicHeaderTable from './components/DynamicHeaderTable';
import DynamicBodyTable from './components/DynamicBodyTable';
import EnhancedTableToolbar from './components/EnhancedTableToolbar';

const LoadingData = ({size}) => {
  return (
    <div 
    style={{
      width: '100%',
      padding: '1rem'
    }}
  >
    <TableContainer>
      <Table
        sx={{ minWidth: '100% '}}
        aria-labelledby="tableTitle"
        size={size}
        data-testid="table-custom-loading"
      >
        <TableBody>
          <SkeletonRows nCells={6} nRows={4} />
        </TableBody>
      </Table>
    </TableContainer>
  </div>
  )
}

const EmptyData = ({emptyMessage,t}) => {
  return (
    <center data-testid="table-custom-empty">
      <br/>
      <br/>
      <Typography variant="h6">
        { emptyMessage || t("no_results_found") }
      </Typography>
      <br/>
    </center>
  )
}

const NoMatchData = ({noMatchDataMessage,t}) => {
  return (
    <center data-testid="table-custom-NoMatchData">
      <br/>
      <br/>
      <Typography variant="h6">
        { noMatchDataMessage || t("no_match_data") }
      </Typography>
      <br/>
    </center>
  )
}

const ComponentTable = ({
  data, 
  allowedKeys,
  activePointer,
  textHeaders,
  size,
  configCells,
  onClick,
  configOptions,
  loading,
  tableWidth,
  configSelected,
  emptyMessage,
  noMatchDataMessage,
  activeFilter,
  filter,
  setFilter,
  isEmpty,
  activeSort
}) => {
  const { t } = useTranslation('tables');
  const [ order, setOrder ] = useState("asc");
  const [ orderBy, setOrderBy ] = useState(t(`table_dynamic.name`));
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(10);
  const [ selected, setSelected ] = useState([]);
  const [ selectedItems, setSelectedItems ] = useState([]);
  const columnsHeader = extractKeys(allowedKeys,data);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ID_MENU_FILTER = "menuFilter";
  const matchesFilter = applyFilter(data,filter);
  const availableFilters = availableFiltersDic(t);
  const selectColumns = formatFilterColumns(t,columnsHeader);
  
  const rows = React.useMemo(() => {
    return matchesFilter || data || []
  }, [data,matchesFilter]);

  const visibleRows = React.useMemo(() => {
      return stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
  }, [order, rows, orderBy, page, rowsPerPage]);

  const handleClickOpenMenuFilter = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  },[setAnchorEl]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    event.stopPropagation();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n[configSelected?.defineId] || n.id);
      setSelected(newSelected);
      setSelectedItems(rows);
      if(configSelected?.onSelect && typeof configSelected?.onSelect === 'function'){
        configSelected.onSelect({ arraySelectedById:newSelected, allSelectedItems: rows });
      }
      return;
    }
    setSelected([]);
    setSelectedItems([]);
    if(configSelected?.onSelect && typeof configSelected?.onSelect === 'function'){
      configSelected.onSelect({ arraySelectedById: [], allSelectedItems: []});
    }
  };

  const resetSelected = useCallback(() => {
    setSelected([]);
    setSelectedItems([]);
  }, [setSelected, setSelectedItems]);

  useEffect(() => {
    if(configCells || allowedKeys){
      setSelectedItems([])
      setSelected([])
    }
  }, [configCells,allowedKeys]);

  if(loading){ return (<LoadingData size={size} />) }

  return (
    <Fragment>
      <EnhancedTableToolbar 
        numSelected={selected.length} 
        options={configSelected?.options || []}
        selectedItems={selectedItems}
        resetSelected={resetSelected}
        activeFilter={activeFilter}
      />
      <TablePagination
        labelRowsPerPage={t('table_dynamic.rows_per_page')}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <TableContainer sx={{ minWidth: '100%', width:'100%', maxWidth: '1px', overflowX: 'auto' }}>
        <Table
          aria-labelledby="tableTitle"
          size={size}
        >
          <DynamicHeaderTable
            headers={columnsHeader}
            textHeaders={textHeaders}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            t={t}
            activeSelectedAll={Boolean(configSelected?.defineId)}
            numSelected={selected.length}
            rowCount={visibleRows.length}
            handleSelectAllClick={handleSelectAllClick}
            activeFilter={activeFilter}
            idMenu={ID_MENU_FILTER}
            handleClickOpenMenuFilter={handleClickOpenMenuFilter}
            open={open}
            isEmpty={isEmpty}
            activeSort={activeSort}
          />
          <DynamicBodyTable 
            cellWidth={(Number(tableWidth) / columnsHeader.length)}
            allowedKeys={allowedKeys}
            rows={visibleRows}
            configCells={configCells}
            onClick={onClick}
            configOptions={configOptions}
            activePointer={activePointer}
            tableWidth={tableWidth}
            selected={selected}
            setSelected={setSelected}
            isSelected={isSelected}
            configSelected={configSelected}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </Table>
        {!loading && isEmpty && (<EmptyData emptyMessage={emptyMessage} t={t} />) }
        {!loading && !isEmpty && visibleRows?.length === 0  && (<NoMatchData noMatchDataMessage={noMatchDataMessage} t={t} />) }
      </TableContainer>
      <TablePagination
        labelRowsPerPage={t('table_dynamic.rows_per_page')}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <PopUpFilter
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        id={ID_MENU_FILTER}
        t={t}
        headers={selectColumns} 
        filter={filter}
        setFilter={setFilter}
        availableFilters={availableFilters}
      />
    </Fragment>
  );
};

ComponentTable.defaultProps = {
  data: [],
  allowedKeys: [],
  configCells: null,
  activePointer: true,
  textHeaders: {},
  size: 'small',
  onClick: () => {},
  configOptions: [],
  loading: false,
  tableWidth: '100%',
  configSelected: null,
  activeFilter: false,
  startPagination: 10
};

ComponentTable.propTypes = {
  data: PropTypes.array,
  allowedKeys: PropTypes.array,
  activePointer: PropTypes.bool,
  textHeaders: PropTypes.object,
  size: PropTypes.string,
  configCells: PropTypes.object,
  onClick: PropTypes.func,
  configOptions: PropTypes.array,
  loading: PropTypes.bool,
  tableWidth: PropTypes.any,
  configSelected: PropTypes.any,
  emptyMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  activeFilter: PropTypes.bool,
  startPagination: PropTypes.number
};


export default ComponentTable;