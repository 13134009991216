import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import './tabs.module.css';

const ViewNftAttributes = ({NFTAttributes}) => {
  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      {NFTAttributes.map((attribute, index) => ( 
        <Grid item xs key={index}>  
          <Card>
            <CardContent>
              <Typography variant="caption" color="textSecondary">{attribute.name}</Typography>
              <Typography variant="body1">{attribute.value}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default ViewNftAttributes;