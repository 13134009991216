import { api } from 'config/axios';

const path = "collection";
export const getAllCollections = async () => {
    const result = api.get(
        `/${path}?domain=${process.env.REACT_APP_DOMAIN}&limit=300&page=0&order=created`
    );
    const response = await result;
    return response.data;
};

export const getCollectionByKey = async (projectKey) => {
    const fullPath = `/${path}?domain=${process.env.REACT_APP_DOMAIN}&collection_key=${projectKey}&limit=1&page=0&order=created`;
    const result = api.get(fullPath);
    const response = await result;
    if (response.data.length == 0){
      return null
    }else {
      return response.data[0];
    }
};


export const getCollectionByProjectKey = async (projectKey) => {
  const fullPath = `project/${path}?domain=${process.env.REACT_APP_DOMAIN}&project_id=${projectKey}&limit=1&page=0&order=created`;
  const result = api.get(fullPath);
  const response = await result;
  return response.data;
};
