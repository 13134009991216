import { useTranslation } from 'react-i18next';
import { findParticipants } from "services/campaigns/campaignGoal/participants";
import DynamicTable from "components/DynamicTable/DynamicTable";
import { useFetch } from "hooks/useFetch";

export default function ParticipantsTable({ campaignId }) {
  const { t } = useTranslation('participantTable');
  const { data: participants, loading, error } = useFetch(findParticipants, {
    params: { campaign_id: campaignId, limit: 100, page: 0, order: 'id_desc' }
  });

  const itemMapper = (item) => {
    return {
      username: item.user.username,
      email: item.user.email,
      collection_address: item.nft_collection_key || "",
      token_id: item.nft_token_id || "",
    }
  }

  const getMessage = () => {
    if (!loading && error) {
      return <ErrorMessage />
    }
    if (!loading && !participants?.length) {
      return <EmptyMessage />
    }
    return <></>
  }

  return (
    <div data-testid="participants-table">
      <DynamicTable
        title={t("title")}
        data={participants}
        loading={loading}
        activeModeView={false}
        activeSearchBar={true}
        preparedDataConfig={itemMapper}
        setConfigParams={{
          emptyMessage: getMessage(),
        }}
      />
    </div>
  )
}

const EmptyMessage = () => {
  const { t } = useTranslation('participantTable');
  return (
    <div data-testid="participants-table-empty-message">
      {t("messages.empty")}
    </div>
  );
}

const ErrorMessage = () => {
  const { t } = useTranslation('participantTable');
  return (
    <div data-testid="participants-table-error-message">
      {t("messages.error")}
    </div>
  );
}
