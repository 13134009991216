import axios from "axios"
axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;

const path = "project"
const url = `${process.env.REACT_APP_API}/${path}?domain=${process.env.REACT_APP_DOMAIN}`;

export const findAllByDomain = async (domain) => {
    return await axios.get(url+"?domain="+domain+"&limit=300&page=0&order=created").then(res=>res.data)
}

export const findAllWithCollection = async (domain, collection) => {
  return axios.get(url+"/collection?domain="+domain+"&colection="+collection).then(res=>res.data)
}

export const findById = async (domain, id) => {
  return axios.get(url+"?domain="+domain+"&id="+id)
  .then(res => {
    return prepareProjectData(res.data[0]);
  });
}

export const findByLimitPageOrder = async (domain, id) => {
  return axios.get(`${url}?domain=${domain}&id=${id}&limit=300&page=0&order=created`)
  .then(res => {
    return prepareProjectData(res.data[0]);
  });
}

export const addLabelAndValuesBlockchainsProject = (blockchains) => {
  return blockchains?.map((item)=>{
    return {
      ...item,
      label:item.blockchain_name,
      value:item.blockchain_name
    }
  }
  ) || blockchains
}

export const prepareProjectData = (project) => {
  delete project.short_primary_key;
  delete project.primary_key;
  delete project.full;
  delete project.id;
  project.thumbnail = project.thumb_url;
  project.banner = project.banner_url;
  delete project.thumb_url;
  delete project.banner_url;
  return project;
}

export const create  = async (project) => {
  console.log("creating...")

  const response = await  axios.post(`${url}`,project)
  return response.data
}

export const update  = async (project, domain) => {
  console.log("updating...")
  const response = await  axios.put(`${url}?domain=${domain}`,project)
  return response.data
}

export const associateCollection  = async (projectId, collection, domain) => {
  const newurl = `${process.env.REACT_APP_API}/${path}/collection?domain=${domain}`;

  console.log("collection::",collection.collection_key)


  const body = {
    project_id: parseInt(projectId),
    collection_key: collection.collection_key
  }
  console.log("body::", body)
  const response = await  axios.post(`${newurl}`,body)
  return response.data
}
