import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TopCollections.module.css';
import Card from '@mui/material/Card'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box'
import CardInfo from './CardInfo';

const TopCollections = ({
  title = "Top collections",
  data
}) => {

  const [age, setAge] = useState('0');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Card className={styles.card}>
      <div className={styles.headerCard}>
        {title && <div className={styles.title}>{title}</div>}
        <Box flex={1} />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          onChange={handleChange}
          size="small"
        >
          <MenuItem value={'0'}>Last 7 Days</MenuItem>
          <MenuItem value={'1'}>Last 30 Days</MenuItem>
          <MenuItem value={'2'}>Last 6 months</MenuItem>
          <MenuItem value={'3'}>Last year</MenuItem>
        </Select>
      </div>
      <div className={styles.contentInfo}>
        {
          data && data?.length > 0 && data?.map((item, index)=>{
            return <CardInfo key={index} label={item?.title} total={item?.total} />
          })
        }
      </div>
    </Card>
  );
};

TopCollections.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array
};

export default TopCollections;