import { useState, useEffect } from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { upperFirstLetter } from 'utils/strings';
import { useTranslation } from 'react-i18next'





const findAndUpdateOrCreate = (findObject, key, value) => {
  const index = findObject.findIndex(obj => Object.prototype.hasOwnProperty.call(obj, key));
  if (index !== -1) {
    findObject[index][key] = value;
  } else {
    findObject.push({ [key]: value });
  }

  window.localStorage.setItem('selected', JSON.stringify(findObject));
};

export const BreadCrumbsSaveData = (key, value) => {

  const findString = window.localStorage.getItem('selected') || JSON.stringify([]);
  const findObject = JSON.parse(findString);

  findAndUpdateOrCreate(findObject, key, value);
};

const RouterBreadcrumbs = () =>{

  const globalParams = useParams();
  const globalParamsMap = new Map(Object.entries(globalParams));
  const location = useLocation();
  const pathname = location.pathname;
  const pathnames = pathname.split('/').filter((x) => x);
  const [ routes, setRoutes ] = useState([]);
  const { t } = useTranslation("breadcrumbs")



  const nameMappings = {
    nftCatalog: t('nft_catalog'),
  };


  const findNameIntoParams = (text) => {
    let newName;
    const selectedLocalStorage = window.localStorage.getItem('selected');
    const selectItems = selectedLocalStorage ? JSON.parse(selectedLocalStorage) : [];
  
    if (selectItems && selectItems.length > 0) {
      for (let item of selectItems) {
        const matchingKey = Array.from(globalParamsMap.keys()).find(key => {
            return Object.prototype.hasOwnProperty.call(item, key) && globalParamsMap.get(key) === text;
        });

        if (matchingKey) {
            const matchingValue = item[matchingKey];
            if (matchingValue?.name) {
                newName = matchingValue.name;
                break;
            }
        }
      }
    }
    return nameMappings[text] || upperFirstLetter(newName || text);
  }
  
  const breadcrumbs = pathnames?.slice(1,pathnames?.length)?.map((name, index) => {
    const to = `/${pathnames.slice(0, index + 2).join('/')}`;
    const breadcrumb = {
      label: findNameIntoParams(name),
      path: `${to}`,
    };
    return breadcrumb
  });

  const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

  useEffect(()=>{
    if(pathname){
      if(pathnames?.length <= 2){
        setRoutes(breadcrumbs);
        return ()=>{
          
        };
      }
      const newRoutes = routes?.filter(route => route?.path !== pathname);
      const to = `/${pathnames.slice(0, 1 + 2).join('/')}`;
      newRoutes.push({path: to, label: findNameIntoParams(pathnames[pathnames.length - 1])});
      setRoutes(newRoutes)
    }
  },[pathname]);

  const handleDelete = (pos) => {
    const newRoutes = routes?.filter((route, index) => index !== pos+1);
    setRoutes(newRoutes)
  }

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" sx={{ marginBottom:'0.5rem' }}>
      {pathname === "/dashboard" &&  
        <Typography color="text.primary" variant="h6" gutterBottom >
          Overview
        </Typography>
      }
      {routes.map((item, index) => {
        const last = index === breadcrumbs.length - 1;
        const breadcrumb = item?.label || item?.breadcrumb;
        const color = index === breadcrumbs.length - 1 ? 'textPrimary' : 'inherit';
        if (last) {
          return (
            <Typography key={item?.path} color="text.primary" variant="h6" gutterBottom >
              {breadcrumb}
            </Typography>
          );
        }
        return (
          <LinkRouter key={item?.path} color={color} to={item.path} onClick={()=>handleDelete(index)}>
              {breadcrumb}
          </LinkRouter>
        );
      })}
    </MuiBreadcrumbs>
  )
}

export default RouterBreadcrumbs;

