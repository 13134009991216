import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';

export default function RenderChipConfig({color, label}) {
  return (
    <TableCell>
      <Chip
        label={label}
        color={color}
      />
    </TableCell>
  )
}