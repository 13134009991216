import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SquareIcon from '@mui/icons-material/Square';
import { useTheme } from '@mui/material/styles';
import { getColorComponentMui } from 'utils/theme';

const ColorsInfo = ({ items }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '1rem',
        gap: '0.5rem'
      }}
    >
      {
        items?.map((item) => (
          <Box
            key={item.color}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.2rem'
            }}
          >
            <SquareIcon {...getColorComponentMui(item.color,theme)} />
            <Typography variant="body2" gutterBottom sx={{margin:'0px 0px'}}>
              {item.label}
            </Typography>
          </Box>
        ))
      }
    </Box>
  )
};

ColorsInfo.propTypes = {
  items: PropTypes.array
};

export default ColorsInfo;
