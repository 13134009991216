import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import MinterWithCopy from './components/MinterWithCopy';
import MinterBatch from './components/MinterBatch';
import ContainerTabs, { CustomTabPanel } from 'components/ContainerTabs/ContainerTabs';
import { Alert, Grid } from '@mui/material';
import { grey } from '@mui/material/colors'
import { useNavigate } from "react-router-dom";
import { COLLECTION_STATUS_CATALOG } from 'utils/status/collection';
import DynamicTable from 'components/DynamicTable/DynamicTable';

export const MINT_TYPES = {
  WITH_COPY: {
    name: 'withCopy',
    index: 0
  },
  MINT_BATCH: {
    name: 'mintBatch',
    index: 1
  },
}

const FormMint = ({
  collection,
  isLoading,
  setIsLoading,
  setError
}) => {

  const { t } = useTranslation("massiveMint");
  const [nfts, setNFTs] = useState([]);
  const navigate = useNavigate();

  const goCollectionsPage = () => {
    navigate(`/dashBoard/collections/${collection.collection_key}`);
  }

  const tabs = [
    {
      label: t("mint_types.mint.name"),
      value: MINT_TYPES.WITH_COPY.name,
      key: "creation"
    },
    {
      label: t("mint_types.mint_batch.name"),
      value: MINT_TYPES.MINT_BATCH.name,
      key: "creation"
    }
  ]

  return (
    <Box>
      {/* <MinterWrapper nfts={nfts} collection={collection} minterId={MINT_TYPES.WITH_COPY.name}>
        <MinterWithCopy
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setError={setError}
          collection={collection}
          onCancel={goCollectionsPage}
          onConfirm={goCollectionsPage}
          setNFTs={setNFTs}
        />
      </MinterWrapper> */}
      <ContainerTabs
        itemsStatus={tabs}
        initialState={MINT_TYPES.WITH_COPY.index}
      >
        {({ value }) => (
          <>
            <CustomTabPanel value={value} index={MINT_TYPES.WITH_COPY.index}>
              <MinterWrapper nfts={nfts} collection={collection} minterId={MINT_TYPES.WITH_COPY.name}>
                <MinterWithCopy
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setError={setError}
                  collection={collection}
                  onCancel={goCollectionsPage}
                  onConfirm={goCollectionsPage}
                  setNFTs={setNFTs}
                />
              </MinterWrapper>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={MINT_TYPES.MINT_BATCH.index}>
              <MinterWrapper nfts={nfts} collection={collection} minterId={MINT_TYPES.MINT_BATCH.name}>
                <MinterBatch
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setError={setError}
                  onConfirm={goCollectionsPage}
                  onCancel={goCollectionsPage}
                  collection={collection}
                  setNFTs={setNFTs}
                />
              </MinterWrapper>
            </CustomTabPanel>
          </>
        )}
      </ContainerTabs>
    </Box>
  );
};

const MinterWrapper = ({ children, nfts, collection, minterId }) => {

  const isAvailableToMint = () => {
    const currentStatus = collection?.status;
    return currentStatus ? [
      COLLECTION_STATUS_CATALOG.NFTS_APPROVED,
      COLLECTION_STATUS_CATALOG.COLLECTION_APPROVED
    ].includes(currentStatus) : false 
  }

  return (
    <Box>
      {isAvailableToMint() ? (
        <Box id={`form-mint-type-${minterId}`}>
          <MinterPreviewLayout nfts={nfts}>
            {children}
          </MinterPreviewLayout>
        </Box>
      ) : (
        <Box id={`form-mint-type-${minterId}-lock-component`}>
          {getLockedComponent(collection.status)}
        </Box>
      )}
    </Box>
  );
}

// TODO: this function will be a switchcase to
// chose the correct message
const getLockedComponent = (collectionStatus) => {
  console.log(collectionStatus)
  return <NFTsPendignAlert />
}

const NFTsPendignAlert = () => {
  const { t } = useTranslation("massiveMint");
  return (
    <Alert severity='info'>
      {t('alerts.nfts_pending_to_approve')}
    </Alert>
  )
}

const MinterPreviewLayout = ({ children, nfts }) => {
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item xs={12} md={6} borderRight={1} borderColor={grey[400]}>
        {children}
      </Grid>
      <Grid item xs={12} md={6}>
        <Preview nfts={nfts} />
      </Grid>
    </Grid>
  )
}

const Preview = ({ nfts }) => {
  const { t } = useTranslation("massiveMint");
  return (
    <DynamicTable
      title={t("mint_preview.title")}
      activeSearchBar={true}
      activeModeView={false}
      data={nfts}
      setConfigParams={{ emptyMessage: t("mint_preview.empty_message") }}
      preparedDataConfig={(item) => {
        return {
          id: item.token_id,
          thumbnail: item.thumb_url,
          name: item.metadata.json_data.name,
          description: item.metadata.json_data.description,
        }
      }}
    />
  );
}

FormMint.defaultProps = {
  isLoading: false,
  setIsLoading: () => { },
  setError: () => { }
};

FormMint.propTypes = {
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  setError: PropTypes.func,
  collection: PropTypes.object.isRequired,
};

export default FormMint;