import React, { useEffect, useState, Fragment} from "react";
import PropTypes from "prop-types";
import { getActionsByRoleCode } from "utils/rols";
import Box from '@mui/material/Box'

const AuthorizeByRole = ({ children, role, action }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    try {
      if(!role || !action) return;
      const roleButtons = getActionsByRoleCode(role,action) || false;
      setIsAuthorized(roleButtons);
    } catch (e) {
      setMessage(e);
    }
    return ()=>{

    }
  }, [role, action]);


  if (message) {
    return <Box padding="0.5rem" >{message + ''}</Box>;
  }

  if (!isAuthorized) {
    return <Box padding="0.5rem"> Not Authorized</Box>;
  }

  return <Fragment>{children}</Fragment>;
};

AuthorizeByRole.propTypes = {
  children: PropTypes.node,
  role: PropTypes.number,
  action: PropTypes.string,
};

export default AuthorizeByRole;
