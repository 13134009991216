import React, { useState, useEffect, Fragment} from 'react';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import styles from './EditProject.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ContainerTabs, { CustomTabPanel } from 'components/ContainerTabs/ContainerTabs';
import {  create, associateCollection, update, addLabelAndValuesBlockchainsProject } from 'services/projects/project'
import useBlockchains from 'hooks/blockchains.hook';
import { uploadFile } from 'services/files';
import Button from '@mui/material/Button';
import AutoCompleteBlockchain from 'pages/MainProjects/CreateProject/components/AutoCompleteBlockchain';
import { useCollection } from "hooks/collection.hook.js";
import { Stack } from '@mui/material';
import WithLoadingBackdropAndErrorSnack from 'HOCS/WithLoadingBackdropAndErrorSnack';
import { handleErrors } from 'utils/messages';
import { useProjectEdit } from 'hooks/useProjects';


const EditProject = ({
  isLoading,
  setIsLoading,
  setError
}) => {
  const { t } = useTranslation('projects');
  const { t:t_form } = useTranslation('form');
  const navigate = useNavigate();
  const { projectId } = useParams();
  const isEditMode = !!projectId;
  const { blockchains, loadingBlockchains } = useBlockchains();
  const [ activeTab, setActiveTab ] = useState(isEditMode ? 1 : 0);
  const [ edited, setEdited ] = useState(false);
  const [ editableProject, setEditableProject] = useState(false);
  const { collections } = useCollection();
  const [ formCollection, setFormCollection ] = useState({});
  const [ projectStatus, setProjectStatus ] = useState(0);
  const { project, loadingProject } = useProjectEdit(projectId);

  useEffect(() => {
    if (isEditMode && projectStatus === 2) {
      setActiveTab(projectStatus);
    }
    if (edited){
      setActiveTab(2);
    }
  }, [projectStatus, edited]);

  useEffect(() => {
    if(isEditMode && projectId){
      setProjectStatus(project.status||0)
      setEditableProject(project)
    }
  }, [project,projectId]);

  const handleCancel = () => {
    navigate(`/dashboard/projects`);
  }

  const defaultStates = [
    {label: t("tab_menu.step1"), key:"creation"},
    {label: t("tab_menu.step2"), key:"pending", disabled:!isEditMode},
    {label: t("tab_menu.step3"), key:"addCollections",disabled: projectStatus !== 2}
  ]

  const goProjectsPage = () => {
    navigate("/dashBoard/projects");
  }
  
  const goCreateCollectionsPage = () => {
    navigate(`/dashBoard/collections/${projectId}/create`);
  }

  const handleSubmit = async (values,optionsFormik) => {
    try{
      console.log("handleSubmit");
      setError(null);
      setIsLoading(true)
      if (isEditMode){
        await updateProject(values)
        setActiveTab(1);
      }else{
        await createProject(values)
        setActiveTab(1);
      }
    } catch (e){
      console.log(e);
      setError(handleErrors(e)?.message || 'An error has occurred, please try again later')
    } finally {
      optionsFormik.setSubmitting(false);
      setIsLoading(false);
    }
   
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleAssociateCollections(formCollection);
  };

  const handleAssociateCollections = async (values) =>{
    console.log("handle associate collections::", values.collections[0])
    await associateCollection(projectId, values.collections[0], process.env.REACT_APP_DOMAIN)
  }

  const createProject = async (values) =>{
    const thumb_url = await uploadFile(values.thumbnail);
    const banner_url = await uploadFile(values.banner);
    const blockchainList = values.blockchains.map(blockchain => blockchain.blockchain_name);

    await create({
      status:1,
      name: values.name,
      description: values.description,
      thumb_url: thumb_url,
      banner_url: banner_url,
      blockchains: blockchainList
    })

  }

  const updateProject = async(values) =>{
    setActiveTab(1);

    console.log("updateProject")
    console.log("Values", values)
    const thumb_url = await uploadFile(values.thumbnail);
    const banner_url = await uploadFile(values.banner);
    const blockchainList = values.blockchain.map(blockchain => blockchain.value);


    const response = await update({
      status:1,
      name: values.name,
      description: values.description,
      thumb_url: thumb_url,
      banner_url: banner_url,
      blockchains: blockchainList,
      id:parseInt(projectId)
    }, process.env.REACT_APP_DOMAIN )

    console.log("Response Edited project::", response)

    setEdited(true)

  }
  
  const initValuesNew = {
    blockchains: 'array',
    name: 'string_short',
    description: 'string_long',
    thumbnail: 'multimedia',
    banner: 'multimedia'
  }

  const options = collections?.map((item)=>{
    return {
      ...item,
      label:item.name,
      value:item.name
    }
  });

  return (
    <div className={styles.contentSection}>
      <ContainerTabs
        itemsStatus = {defaultStates}
        initialState = {activeTab}
      >
      {({ value }) => (
        <Fragment>
          <CustomTabPanel value={value} index={0}>
            <div className={styles.headerSection}>
              <Typography variant="h6" gutterBottom >
                {isEditMode ? t("edit_project.title") : t("create_project.title")}
              </Typography>
              <div className={styles.subHeaderSection}>
                <Typography variant="body2" gutterBottom flex={1}>
                {isEditMode ? t("edit_project.description") : t("create_project.description")}
                </Typography>
              </div>
            </div>
            <div className={styles.workSpace}>
              {
                isEditMode ?
                  (!loadingProject && project &&
                    <NewFormDynamic
                      initValues={initValuesNew}
                      loading={isLoading||loadingProject}
                      initStateWithValues={{
                        blockchains: {
                          values: addLabelAndValuesBlockchainsProject(project?.blockchains)||[],
                          options: blockchains
                        },
                        name: editableProject?.name||'',
                        description: editableProject?.description||'',
                        thumbnail: editableProject?.thumbnail||'',
                        banner: editableProject?.banner||''
                      }}
                      onSubmit={handleSubmit}
                      t={t_form}
                      handleCancel={handleCancel}
                      textButtons={{
                        submit:t("options.accept"),
                        cancel:t("options.cancel")
                      }}
                      textLabels={{
                        name:t("form.name"),
                        description:t("form.description"),
                        thumbnail:t("form.thumbnail"),
                        banner:t("form.banner"),
                        blockchains:t("form.blockchains")
                      }}
                      textPlaceholders={{
                        name:t("form.name"),
                        description:t("form.description"),
                        thumbnail:t("form.thumbnail"),
                        banner:t("form.banner"),
                        blockchains:t("form.blockchains")
                      }}
                    />
                  )
                  :
                  <NewFormDynamic
                    initValues={initValuesNew}
                    initStateWithValues={{
                      blockchains: {
                        values: [],
                        options: blockchains
                      }
                    }}
                    loading={isLoading || loadingBlockchains}
                    onSubmit={handleSubmit}
                    t={t_form}
                    handleCancel={goProjectsPage}
                    textButtons={{
                        submit:t("options.send"),
                        cancel:t("options.cancel")
                    }}
                    textLabels={{
                      name:t("form.name"),
                      description:t("form.description"),
                      thumbnail:t("form.thumbnail"),
                      banner:t("form.banner"),
                      blockchains:t("form.blockchains")
                    }}
                    textPlaceholders={{
                      name:t("form.name"),
                      description:t("form.description"),
                      thumbnail:t("form.thumbnail"),
                      banner:t("form.banner"),
                      blockchains:t("form.blockchains")
                    }}
                  />
              }
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {(() => {
                switch (projectStatus) {
                    case 0:
                        return <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }}>{t("button_states.state0")}</Button>;
                    case 1:
                        return <Button variant="contained" color="info">{t("button_states.state1")}</Button>;
                    case 2:
                        return <Button variant="contained" color="success">{t("button_states.state2")}</Button>;
                    case 3:
                        return <Button variant="contained" color="danger">{t("button_states.state3")}</Button>;
                    default:
                        return null;
                }
            })()}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <form onSubmit={handleFormSubmit}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p>{t("modal_associate_collection.description")}</p>
                  <Button color="success" variant="transparent" onClick={goCreateCollectionsPage}>
                    {t("modal_associate_collection.options.create")}
                  </Button>
              </div>
                <Stack spacing={2}>
                  <AutoCompleteBlockchain 
                    form={formCollection}
                    name={"collections"}
                    setForm={setFormCollection}
                    options={options}
                    disabledYup={true}
                  />
                </Stack>
                <Button type ="submit" color="success" variant="contained">{t("modal_associate_collection.options.save")}</Button>
                <Button color="success" variant="transparent" >{t("modal_associate_collection.options.cancel")}</Button>
            </form>
          </CustomTabPanel>
        </Fragment>
      )}
      </ContainerTabs> 
    </div>
  );
};

export default WithLoadingBackdropAndErrorSnack(EditProject);