import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './OverviewCustom.module.css';
import { ContentOverView, Item } from './styles';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useFetch } from 'hooks/useFetch';
import { findAll } from "services/campaigns/campaigns"
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

export default function OverviewCustom({
  columns,
  componentsItem
}) {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const { data: campaigns, loading: loading } = useFetch(findAll, {
    params: { limit: 100, page: 0, order: 'created' }
  });

  useEffect(() => {
    console.log('selectedCampaign', selectedCampaign);
  }, [selectedCampaign]);

  return (
    <Card>
      <div className={styles.headerOverView}>
        <div style={{ flex: 1 }} />
        {loading ?
          <LoadingSelect loading={loading}/>
          :
          <CampaignsSelect campaigns={campaigns} setSelectedCampaign={setSelectedCampaign} />
        }
      </div>
      <ContentOverView
        columns={columns}
        gap={"1rem"}
      >
        {
          componentsItem && componentsItem?.length > 0 &&
          componentsItem?.map((Component, index) => {
            return (
              <Item key={index}>
                {Component()}
              </Item>
            )
          })
        }
      </ContentOverView>
    </Card>
  );
}

OverviewCustom.propTypes = {
  componentsItem: PropTypes.array
};

function LoadingSelect({loading}) {
  const { t } = useTranslation('campaigns');
  return (
    <Button 
      data-testid="campaigns-select-loading"
      endIcon={loading ? <CircularProgress color="inherit" size={"18px"} /> : <SendIcon />}
      loadingPosition="end"
      variant="contained"
    >
      <span>{t("statistics.messages.loading_campaigns")}</span>
    </Button>
  );
}

function CampaignsSelect({ campaigns, setSelectedCampaign }) {
  const { t } = useTranslation('campaigns');
  const [value, setValue] = useState('');

  useEffect(() => {
    if (campaigns?.length > 0) {
      handleChange(campaigns[0].id);
    } else {
      handleChange("0");
    }
  }, [campaigns]);

  const handleChange = (newValue) => {
    setValue(newValue);
    const selectedCampaign = campaigns.find(campaign => campaign.id === newValue);
    setSelectedCampaign(selectedCampaign || null);
  };

  return (
    <Select
      data-testid="campaigns-select"
      size="small"
      value={value}
      disabled={campaigns?.length === 0}
      onChange={(event) => handleChange(event.target.value)}
    >
      {campaigns?.length === 0 &&
        <MenuItem value={'0'}>{t("statistics.messages.there_are_no_campaigns")}</MenuItem>
      }
      {campaigns?.map((campaign) => {
        return (
          <MenuItem key={campaign.id} value={campaign.id}>
            {campaign.name}
          </MenuItem>
        );
      })}
    </Select>
  );
}