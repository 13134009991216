import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import UserContext from 'context/UserContext';
import Typography from '@mui/material/Typography'
import { recoverMagicOAuth, validateApiTokenIsValid } from 'services/providers/magicWalletOAuth';
import { getRouteIfUserIsNew } from 'utils/localStorage';
import { CUSTOM_ERRORS_TRANSLATE, handleErrors } from 'utils/messages';

const OAuthRedirectPage = () => {
  const { t } = useTranslation("redirect");
  const { t:t_message } = useTranslation("messages");
  const navigate = useNavigate();
  const { setUser, setLoading, setError } = useContext(UserContext);
  const [ stepText, setStepText ] = useState(t("loading"));


  const getError = useCallback((e) => {
    try {
      const customError = CUSTOM_ERRORS_TRANSLATE[e?.message] || null;
      if(customError){
        setError(t_message(customError()+''));
      }else{
        setError(handleErrors(e,t_message)?.message+'' || t_message("errors.messages.error_trying_to_connect_your_wallet"));
      }
    }catch (e) {
      console.error(e);
    }
  },[setError,t_message]);

  const init = useCallback(async () => {
    try{
      setLoading(true);
      console.log("init 2")
      const res = await recoverMagicOAuth(setStepText,t);
      console.log("res 2",res)
      setUser(res);
      const route = getRouteIfUserIsNew(res.isNew);
      if(route === "/update-info-user"){
        navigate(route)
      }else{
        navigate("/dashboard/collections")
      }
    }catch(e){
      console.log('error',e);
      getError(e);
      try{
        const res = await validateApiTokenIsValid();
        console.log("res",res)
        if(res){
          const route = getRouteIfUserIsNew(res.isNew);
          if(route === "/update-info-user"){
            navigate(route)
          }else{
            navigate("/dashboard/collections")
          }
        }else{
          navigate("/login")
        }
      } catch (e) {
        console.log('error 2',e);
        getError(e);
        navigate("/login")
      }
    } finally {
      setLoading(false);
    }
  },[setLoading,recoverMagicOAuth,navigate,setUser,t]);

  useEffect(()=>{
    console.log("init")
    init();
  }, [init]);

  return (
    <Box 
      component="div"
      display="flex" 
      flexDirection="column"
      justifyContent="center" 
      alignItems="center" 
      height="100vh"
      sx={{
        background: '#19407f',
        color: '#fff'
      }}
    >
      <CircularProgress color="secondary" />
      {stepText && <Box marginTop={2}><Typography>{stepText}</Typography></Box>}
    </Box>
  );
};


export default OAuthRedirectPage;