import React, { Fragment } from 'react';
import FormLabel from '@mui/material/FormLabel';
import SmartTooltipModal from 'components/SmartTooltip/SmartTooltipModal';

const CustomLabel = ({ description, children, ...props }) => {
  return (
    <Fragment>
      {description ? (
        <SmartTooltipModal text={description}>
          <FormLabel {...props}>{children}</FormLabel>
        </SmartTooltipModal>
      ) : (
        <FormLabel {...props}></FormLabel>
      )}
    </Fragment>
  )
}


export default CustomLabel;