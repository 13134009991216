import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useSpinnerContext } from 'context/SpinnerContext';

const SpinnerSteps = () => {
  const { loadingSpinner, messageSpinner, error, messageSuccess, cleanAll } = useSpinnerContext();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(()=>{
    if(error || messageSuccess){
      setOpen(true);
    }
  },[error,messageSuccess])

  return (
    <Box data-testid="spinner-component">
      <Backdrop
        sx={{ color: '#38B764', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingSpinner}
        data-testid="global-spinner-backdrop"
      >
        <Box sx={{display:'flex', gap:'1rem', flexDirection: 'column', justifyContent:'center', alignItems:'center'}}>
          <CircularProgress color="success" />
          {messageSpinner && <Typography variant="body1" sx={{fontWeight:'500'}} >{messageSpinner+''}</Typography>}
        </Box>
      </Backdrop>
      {!loadingSpinner && Boolean(error) &&
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(error)}
        onClose={() => cleanAll()}
        data-testid="global-error-snakbar"
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={() => cleanAll(null)}
          sx={{ minWidth: '200px' }}
          iconMapping={{
            error: (
              <Box onClick={handleOpen}>
                {open ? (
                  <ExpandLessIcon fontSize="inherit" sx={{ cursor: 'pointer' }} />
                ) : (
                  <ErrorOutlineIcon fontSize="inherit" sx={{ cursor: 'pointer' }} />
                )}
              </Box>
            )
          }}
        >
          <Typography variant="body1" gutterBottom>
            Error
          </Typography>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Typography variant="body2" gutterBottom>
              {error+''}
            </Typography>
          </Collapse>
        </Alert>
      </Snackbar>
      }

      {!loadingSpinner && Boolean(messageSuccess) &&
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(messageSuccess)}
        onClose={() => cleanAll()}
        data-testid="global-messageSuccess-snakbar"
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={() => cleanAll(null)}
          sx={{ minWidth: '200px' }}
          iconMapping={{
            error: (
              <Box onClick={handleOpen}>
                {open ? (
                  <ExpandLessIcon fontSize="inherit" sx={{ cursor: 'pointer' }} />
                ) : (
                  <ErrorOutlineIcon fontSize="inherit" sx={{ cursor: 'pointer' }} />
                )}
              </Box>
            )
          }}
        >
          <Typography variant="body1" gutterBottom>
            Message
          </Typography>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Typography variant="body2" gutterBottom>
              {messageSuccess+''}
            </Typography>
          </Collapse>
        </Alert>
      </Snackbar>
      }
    </Box>
  );
};

export default SpinnerSteps;
