import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import MainDashBoard from 'layouts/DashBoard/MainDashBoard';
import { routesValues, staticRoutes, relativeRoutes } from 'mock/requestRoutes';
import Pulse from 'components/Pulse/Pulse';



const RoutesMain = () => {
	const [loading, setLoading] = useState(true);
	const [routes, setRoutes] = useState({});

	const [error, setError] = useState(null);

	const getJsonRoutes = (pass = true) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				if (pass) {
					resolve(routesValues);
				} else {
					reject(new Error('Claim failed'));
				}
			}, 2000);
		});
	};

	const init = useCallback(async () => {
		try {
			setLoading(true);
			const result = await getJsonRoutes(true);
			const { routes } = result;
			setRoutes(routes);
		} catch (error) {
			setError(error?.message + '');
		} finally {
			setLoading(false);
		}
	}, [setLoading, setError, setRoutes]);


	useEffect(() => {
		init();
	}, [init])


	if (loading) {
		return <div style={{background:'#19407f',width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}><Pulse /></div>;
	}

	if (!loading && error) {
		return 'Error ocurred';
	}

	const renderRoutes = (values, path = '') => {
		return Object.keys(values)?.map((key) => {
			const routes = values[key];
			const newPath = key;
			const findToRoute = `${path}${key === "/" ? '' : "/" + key}`;

			if (newPath === "/") {
				const Component = relativeRoutes[findToRoute] || null;
				return (
					<Route
						key={`route-${newPath}${findToRoute}`}
						path={""}
						element={Component ? <Component /> : <>404 Not Found</>}
					/>
				)
			}

			if (typeof routes === 'object') {
				return (
					<Route key={newPath} path={`${newPath}/*`} element={<Outlet />}>
						{renderRoutes(routes, findToRoute)}
					</Route>
				);
			}

			return (
				<Route
					key={`route-${newPath}`}
					path={newPath}
					element={<RelativeRoute path={findToRoute} />}
				/>
			);
		});
	};

	return (
		<Routes>
			<Route path="/" element={<Navigate to="/login" />} />
			{routes &&
				Object.keys(routes)?.map((key) => {
					const isPublic = routes[key];
					const isObject = typeof isPublic === 'object';
					if (isObject) {
						return (
							<Route
								key={`route-${key}`}
								path={`${key}/*`}
								element={<MainDashBoard />}
							>
								{renderRoutes(isPublic, key)}
							</Route>)
					}
					return (
						<Route
							key={`route-${key}`}
							path={key}
							element={
								<StaticRoute
									path={key}
									isPublic={routes[key]}
								/>
							}
						/>
					)
				})
			}
			<Route path={"*"} element={<Navigate to="/dashboard" />} />
		</Routes>
	);
}


const RelativeRoute = ({ path }) => {
	const ComponentPeerRelative = relativeRoutes[path] || null;
	if (ComponentPeerRelative) {
		return (
			<ComponentPeerRelative />
		)
	}
	return <>404 Not Found</>
}

RelativeRoute.propTypes = {
	path: PropTypes.string.isRequired
}

const StaticRoute = ({ path }) => {
	const ComponentPeerStatic = staticRoutes[path] || null;
	if (ComponentPeerStatic) {
		return (
			<ComponentPeerStatic />
		)
	}
	return <>404 Not Found</>
}

StaticRoute.propTypes = {
	path: PropTypes.string.isRequired
}


export default RoutesMain;