import React, { useEffect, useContext } from 'react';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import { useTranslation } from 'react-i18next';
import { useSpinnerContext } from 'context/SpinnerContext';
import { useFetch } from 'hooks/useFetch';
import { findSocialMedia } from 'services/account_targets/supportedSocialMedia';
import UserContext from 'context/UserContext';


export default function CreateApiKeyForm({ onSubmit, onCancel }) {
  const { t } = useTranslation('apikeys');
  const { user } = useContext(UserContext)
  const BASE_TRANSLATE = 'create_apikey_form';
  const { data: socialMedia, loading: loadingSocialMedia, error: errorSocialMedia } = useFetch(
    findSocialMedia,
    { params: { order: 'created', limit: 100, page: 0 } }
  )
  const { handleChangeLoading, handleChangeMessage, handleChangeError } = useSpinnerContext();
  
  useEffect(() => {
    handleChangeError("");
    if (loadingSocialMedia) {
      handleChangeLoading(true);
      handleChangeMessage(t(`${BASE_TRANSLATE}.messages.loading_social_media`));
    } else if (errorSocialMedia) {
      handleChangeLoading(false);
      handleChangeError(t(`${BASE_TRANSLATE}.messages.error_loading_social_media`));
    } else if (socialMedia) {
      handleChangeLoading(false);
    }
  }, [socialMedia, loadingSocialMedia, errorSocialMedia]);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    onSubmit({...values, user_main_key: user.wallet.toUpperCase()});
  }

  const handleCancel = ({ setSubmitting }) => {
    setSubmitting(false);
    onCancel();
  }

  const formFields = {
    name: 'string_short',
    key: 'string_long'
  };

  return (
    <div data-testid="create-apikey-form">
      <NewFormDynamic
        initValues={formFields}
        onSubmit={handleSubmit}
        handleCancel={handleCancel}
        textButtons={{
          submit: t(`${BASE_TRANSLATE}.fields.submit`),
          cancel: t(`${BASE_TRANSLATE}.fields.cancel`)
        }}
        textLabels={{
          name: t(`${BASE_TRANSLATE}.fields.name`),
          key: t(`${BASE_TRANSLATE}.fields.value`),
        }}
        textPlaceholders={{
          name: t(`${BASE_TRANSLATE}.fields.name_placeholder`),
          key: t(`${BASE_TRANSLATE}.fields.value_placeholder`),
        }}
      />
    </div>
  );
}