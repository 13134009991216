import { useState } from "react";
import styles from "./Benefits.module.css";
import BenefitCard from "./Cards/BenefitCard";
import { useNavigate, useParams } from "react-router-dom";
import { useBenefits, useCollectionBenefit } from "hooks/benefit";
import { Button, Stack, Typography } from "@mui/material";
import BenefitsEditModal from "./BenefitsEditModal";
import CardsContainer from "./Layouts/CardsContainer";
import { useTranslation } from 'react-i18next';

export default function Benefits() {
  const { projectKey } = useParams();

  return (
    <div className={styles.beneficiosDesktopInner}>
      <div className={styles.divGrficasWrapper}>
        <Stack spacing={4} width="100%">
          <BenefitsSection projectKey={projectKey} />
          <NftsWithBenefitsSection projectKey={projectKey} />
        </Stack>
      </div>
    </div>
  );
}

const BenefitsSection = ({ projectKey }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('benefits');
  const { benefits, loading: loadingBenefits, error: errorBenefits } = useBenefits({ project_key: projectKey });
  const { collectionBenefits, loading: loadingCollection, error: errorCollection } = useCollectionBenefit({ project_key: projectKey });
  const collectionBenefit = collectionBenefits ? collectionBenefits[0] : null;

  const isLoading = (loadingBenefits || loadingCollection);
  const error = (errorBenefits || errorCollection);

  const handleOpenCreateProject = () => {
    const { contract_key, project_key } = collectionBenefit;
    navigate(`/dashboard/benefits/create/${contract_key}/${project_key}`);
  };

  return (
    <SectionTemplate
      title={t("benefits.title")}
      description={t("benefits.description")}
      actions={[
        {
          name: "+ Create benefit",
          disabled: (isLoading || error),
          action: handleOpenCreateProject
        }
      ]}
    >
      <BenefitsContainer
        isLoading={isLoading}
        error={error}
        benefits={benefits}
      />
    </SectionTemplate>
  );
}

const SectionTemplate = ({ title, description, actions, children }) => {
  return (
    <div className={styles.divGrficas}>
      <div className={styles.imagen}>
        <div className={styles.ttuloYDescripcinParent}>
          <div className={styles.ttuloYDescripcin}>
            <div className={styles.title}>{ title }</div>
            <div className={styles.configuraLaSeccin}>
              { description }
            </div>
          </div>
          <div className={styles.ttuloYDescripcin1}>
            {actions.map((action) => (
              <Button
                key={action.name}
                color="info"
                onClick={() => action.action()}
                disabled={action.disabled}
              >
                <Typography fontWeight="bold">
                  { action.name }
                </Typography>
              </Button>
            ))}
          </div>
          { children }
        </div>
      </div>
    </div>
  );
}

function NftsWithBenefitsSection ({ projectKey }) {
  return (
    <p>{projectKey}</p>
  )
}

const BenefitsContainer = ({ isLoading, error, benefits }) => {
  const [edit, setEdit] = useState({ edit: false, benefit: null });

  const handleEditBenefit = (benefit) => {
    setEdit({ edit: true, benefit });
  };

  const cards = benefits.map((item) => (
    <BenefitCard
      key={item.type}
      benefit={item}
      titleMaxLines={1}
      options={[
        { name: "Edit", action: handleEditBenefit },
      ]}
    />
  ));

  return (
    <>
      <CardsContainer
        isLoading={isLoading}
        error={error}
        cards={cards}
      />
      <BenefitsEditModal
        open={edit.edit}
        benefit={edit.benefit}
        handleClose={() => setEdit({ edit: false, benefit: null })}
      />
    </>
  );
}
