import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Check as CheckIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { capitalizeFirstLetter, stringToObjectField } from 'utils/strings';
import { Field } from 'formik';
import Chip from '@mui/material/Chip';
import FormLabel from '@mui/material/FormLabel';

const WrappedMaterialSelect = ({ field, form: { touched, errors, setFieldValue }, options, placeholder, initialValue, ...props }) => {
  
  const getTouched = () => {
    const isTouched = stringToObjectField(field.name, touched);
    if (isTouched === null) return false;
    return isTouched;
  }

  const getError = () => {
    if (!getTouched()) return "";
    const error = stringToObjectField(field.name, errors);
    if (error === null) return "";
    return error;
  }


  const { disabled, loading } = props;
  const fixedOptions = !disabled ? []: initialValue;
  const [value, setValue] = useState(fixedOptions);

  useEffect(()=>{
    if (initialValue && initialValue.length > 0) {
      setValue(initialValue);
    }
  },[initialValue])

  useEffect(()=>{
    if (value) {
      getError()
      setFieldValue(field.name, value,true);
    }
  },[value])

  useEffect(()=>{
    return () => {
      if (value) {
        setFieldValue(field.name, value,true);
      }
    }
  },[])

  return (
    <div data-testid={field.name}>
    <Autocomplete
      multiple
      value={value}
      disabled={disabled || loading}
      limitTags={2}
      onChange={(event, newValue) => {
        setValue(newValue);
        // setValue([
        //   ...fixedOptions,
        //   ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
        // ]);
      }}
      options={options}
      getOptionLabel={(option) => option?.label }
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          if(option?.label && option?.value){
            return (
            <Chip
              key={index}
              label={option?.label||''}
              {...getTagProps({ index })}
              // disabled={fixedOptions.indexOf(option) !== -1}
            />
            )
          }
        })
      }
      renderInput={(params) => (
        <TextField 
          {...params} 
          error={Boolean(getError())}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          style={{ background: 'white',borderRadius: '8px' }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <span>{option?.label}</span>
          {selected ? <CheckIcon fontSize="small" /> : null}
        </li>
      )}
    />
    </div>
  );
};

const CustomAutoComplete = ({
  options,
  name,
  label,
  placeholder,
  disabled,
  loading,
  initValue,
}) => {
  
  const newLabel = typeof label === 'string' ? label : null;
  const newPlaceHolder = typeof placeholder === 'string' ? placeholder : null;
  
  return (
    <Fragment>
      <FormLabel htmlFor={name}>
        {newLabel || capitalizeFirstLetter(name+'')}
      </FormLabel>
      <Field
        name={name}
        disabled={disabled}
        loading={loading}
        options={options}
        placeholder={newPlaceHolder || capitalizeFirstLetter(name+'')}
        initialValue={initValue || []}
        component={WrappedMaterialSelect}
      />
    </Fragment>
  );
};

CustomAutoComplete.defaultProps = {
  label: 'item',
  placeholder: 'item',
  options: [],
  initValue: [],
  disabled: false,
  loading: false,
  name: 'items',
  disabledYup:false,
  setFieldValue: () => {}
};

CustomAutoComplete.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array, 
  initValue: PropTypes.array,
  onChange: PropTypes.any,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  disabledYup: PropTypes.bool,
  setFieldValue: PropTypes.func
};

export default CustomAutoComplete;