import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SectionTemplate from 'components/SectionTemplate';
import ColorsInfo from 'components/ColorsInfo/ColorsInfo';
import { formatTimestampToLocaleStringShort, fullDate } from 'utils/date';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import UserContext from 'context/UserContext';
import { BENEFIT_STATUS } from 'utils/benefitStatus';
import { useFetch } from 'hooks/useFetch';
import { findBenefits } from 'services/benefits';
import { Alert, Box } from '@mui/material';
import DynamicTable from 'components/DynamicTable/DynamicTable';

export default function ViewBenefits() {
  const { t: t_benefits } = useTranslation("benefits");

  const itemStatus = useRef([
    {
      label: t_benefits("status_info.pending_review"),
      color: '#ed6c02'
    },
    {
      label: t_benefits("status_info.in_progress"),
      color: '#2196f3'
    },
    {
      label: t_benefits("status_info.waiting_for_approval"),
      color: '#f44336'
    },
    {
      label: t_benefits("status_info.approved"),
      color: '#4caf50'
    }
  ]);

  return (
    <SectionTemplate
      title={t_benefits("title")}
      description={t_benefits("description")}
    >
      <BenefitsTable />
      <ColorsInfo items={itemStatus.current} />
    </SectionTemplate>
  );
}

const BenefitsTable = () => {
  const { t } = useTranslation("collections");
  const { t: t_benefits } = useTranslation("benefits");
  const { user } = useContext(UserContext);
  const { data: collectionBenefits, loading, error } = useFetch(findBenefits, {
    params: {
      limit: 100,
      page: 0,
      order: 'created',
    }
  })

  const configSelected = {
    defineId: 'name',
    options: [
      {
        id: 'approve',
        label: 'Approve',
        color: 'success',
        variant: 'contained',
        tooltip: 'Click to approve',
        onClick: (item) => {
          console.log("debug ", item);
        },
        conditions: (item) => {
          return user?.role > 80 && item?.status === 0;
        }
      },
      {
        id: 'rejected',
        label: 'Rejected',
        color: 'error',
        variant: 'contained',
        tooltip: 'Click to rejected',
        onClick: (item) => {
          console.log("debug ", item);
        },
        conditions: (item) => {
          return user?.role > 80 && item?.status === 0;
        }
      },
      {
        id: 'edit',
        label: 'Edit',
        color: 'info',
        variant: 'contained',
        tooltip: 'Click to edit',
        onClick: (item) => {
          console.log("debug ", item);
        },
        conditions: () => {
          return user?.role > 50;
        }
      }
    ]
  }

  const statusOptions = {
    [BENEFIT_STATUS.PENDING_APPROVE]: {
      status: BENEFIT_STATUS.PENDING_APPROVE,
      label: t("table.status.pending_approve"),
      color: "statusGray"
    },
    [BENEFIT_STATUS.APPROVED]: {
      status: BENEFIT_STATUS.APPROVED,
      label: t("table.status.approved"),
      color: "statusGreen"
    },
    [BENEFIT_STATUS.REJECTED]: {
      status: BENEFIT_STATUS.REJECTED,
      label: t("table.status.rejected_nfts"),
      color: "statusRed"
    }
  }

  const configCells = {
    status: {
      render: (item) => {
        return (
          <TableCell>
            <Chip
              label={statusOptions[item].label}
              color={statusOptions[item].color}
            />
          </TableCell>
        )
      }
    }
  }

  const getEmptyMessage = () => {
    if (error) {
      return (
        <Box mx={2}>
          <Alert
            data-testid="view-benefits-error-benefits-table"
            severity="error"
          >
            {t_benefits("benefits.table.error_message")}
          </Alert>
        </Box>
      );
    }
    return (
      <Box data-testid="view-benefits-empty-benerfits-table">
        {t_benefits("benefits.table.empty_message")}
      </Box>
    )
  }

  return (
    <Box data-testid="view-benefits-table">
      <DynamicTable
        title={""}
        activeSearchBar={true}
        activeModeView={false}
        data={collectionBenefits}
        loading={loading}
        preparedDataConfig={(item) => {
          return {
            thumbnail: item?.reward?.thumb_url,
            name: item?.reward?.name,
            description: item?.reward?.description,
            status: item?.status,
            created: formatTimestampToLocaleStringShort(item?.reward?.created),
            updated: fullDate(item?.reward?.updated)
          }
        }}
        setConfigParams={{
          configCells,
          configSelected,
          textHeaders: { thumbnail: ' ' },
          emptyMessage: getEmptyMessage(),
        }}
      />
    </Box>
  )
}