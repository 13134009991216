export const STATUS_COLLECTION = [
  {
    label: 'open',
    color: '#2196f3'
  },
  {
    label: 'waiting for approval',
    color: '#f44336'
  },
  {
    label: 'approved',
    color: '#4caf50'
  }
];

export const COLLECTION_STATUS_CATALOG = {
  COLLECTION_PENDING_APPROVE: 0,
  COLLECTION_APPROVED: 1,
  NFTS_LOADING: 2,
  NFTS_LOADED: 3,
  NFTS_PENDING_TO_APPROVE: 4,
  NFTS_APPROVED: 5,
}