export const getTwoDigits = (number) => {
  if(number < 10) return `0${number}`;
  return number;
}

export const getYear = (date) => {
  if(!date) return 'Empty';
  const newDate = new Date(date);
  return newDate.getFullYear();
}

export const getMonth = (date) => {
  if(!date) return 'Empty';
  const newDate = new Date(date);
  return newDate.getMonth();
}

export const getDay = (date) => {
  if(!date) return 'Empty';
  const newDate = new Date(date);
  return newDate.getDate();
}

export const getHours = (date) => {
  if(!date) return 'Empty';
  const newDate = new Date(date);
  return newDate.getHours();
}

export const getMinutes = (date) => {
  if(!date) return 'Empty';
  const newDate = new Date(date);
  return newDate.getMinutes();
}

export const getSeconds = (date) => {
  if(!date) return 'Empty';
  const newDate = new Date(date);
  return newDate.getSeconds();
}

export const getMilliseconds = (date) => {
  if(!date) return 'Empty';
  const newDate = new Date(date);
  return newDate.getMilliseconds();
}

export const fullDate = (date) => {
  if(!date) return 'Empty';
  const isEN = localStorage.getItem('i18nextLng') === 'en';
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  
  return isEN ? `${getTwoDigits(month)}/${getTwoDigits(day)}/${year}`:`${getTwoDigits(day)}/${getTwoDigits(month)}/${year}`;
}


export const formatApiDate = (date) => {
  if(!date) return 'Empty';
  // const isEN = localStorage.getItem('i18nextLng') === 'en';
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const seconds = newDate.getSeconds();

  return `${getTwoDigits(month)}/${getTwoDigits(day)}/${year} ${getTwoDigits(hours)}:${getTwoDigits(minutes)}:${getTwoDigits(seconds)}`;
}

export const formatTimestampToLocaleString = (dateMilliSeconds) => {
  if(!dateMilliSeconds) return 'Empty';
  const date = new Date(dateMilliSeconds * 1000); 
  const isEN = localStorage.getItem('i18nextLng') === 'en';
  const options = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
    // timeZoneName: 'short'
  };
  return isEN ? date.toLocaleDateString('en-US', options) : date.toLocaleDateString('es-ES', options);
}

export const formatTimestampToLocaleStringShort = (dateMilliSeconds) => {
  if(!dateMilliSeconds) return 'Empty';
  const date = new Date(dateMilliSeconds * 1000); 
  const isEN = localStorage.getItem('i18nextLng') === 'en';
  const options = {
    // weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };
  return isEN ? date.toLocaleDateString('en-US', options) : date.toLocaleDateString('es-ES', options);
}

export const timestampToFormattedString = (timestamp) => {
  if (!timestamp) return 'Empty';
  const date = new Date(timestamp * 1000);
  const formattedDate = `${getTwoDigits(date.getDate())}/${getTwoDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
  const formattedTime = `${getTwoDigits(date.getHours())}:${getTwoDigits(date.getMinutes())}:${getTwoDigits(date.getSeconds())}`;
  return `${formattedDate} ${formattedTime}`;
};

export const extendedFormatToFormattedString = (extendedDateString) => {
  if (!extendedDateString) return 'Empty';
  const date = new Date(extendedDateString + ' GMT');
  const formattedDate = `${getTwoDigits(date.getDate())}/${getTwoDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
  const formattedTime = `${getTwoDigits(date.getHours())}:${getTwoDigits(date.getMinutes())}:${getTwoDigits(date.getSeconds())}`;
  
  return `${formattedDate} ${formattedTime}`;
};






export const checkDateLong = (date) => {
  if(!date) return 'Empty';
  if(typeof date === 'number'){
    return formatTimestampToLocaleString(date)
  }

  // Check if reveal_date is a number
  if(!isNaN(date)){
    return formatTimestampToLocaleString(parseFloat(date))
  }

  return fullDate(date)
}

export const checkDateShort = (date) => {
  if(!date) return 'Empty';
  if(typeof date === 'number'){
    return formatTimestampToLocaleStringShort(date)
  }

  if(!isNaN(date)){
    return formatTimestampToLocaleStringShort(parseFloat(date))
  }

  return fullDate(date)
}