import {
  useNavigationType,
  useLocation
} from 'react-router-dom';
import { useEffect } from 'react';
import { UserProvider } from 'context/UserContext';
import { ThemeProvider } from '@mui/material';
import theme from 'theme';
import { LocationContextProvider } from 'context/LocationContext';
import { SpinnerProvider } from 'context/SpinnerContext';
import RoutesMain from 'Routes';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const App = () =>{
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if(action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <LocationContextProvider>
          <UserProvider>
            <SpinnerProvider>
              <RoutesMain />
            </SpinnerProvider>
          </UserProvider>
        </LocationContextProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default App;
