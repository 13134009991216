import React, { useEffect } from 'react';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import { useTranslation } from 'react-i18next';
import { useSpinnerContext } from 'context/SpinnerContext';
import { useFetch } from 'hooks/useFetch';
import { findSocialMedia } from 'services/account_targets/supportedSocialMedia';

const TARGET_TYPES = [
  { label: "Account", value: "ACCOUNT" },
  { label: "Hashtag", value: "HASHTAG" },
]

export default function CreateTargetForm({ onSubmit, onCancel }) {
  const { t } = useTranslation('targets');
  const BASE_TRANSLATE = 'create_target_form';
  const { data: socialMedia, loading: loadingSocialMedia, error: errorSocialMedia } = useFetch(
    findSocialMedia,
    { params: { order: 'created', limit: 100, page: 0 } }
  )
  const { handleChangeLoading, handleChangeMessage, handleChangeError } = useSpinnerContext();

  useEffect(() => {
    handleChangeError("");
    if (loadingSocialMedia) {
      handleChangeLoading(true);
      handleChangeMessage(t(`${BASE_TRANSLATE}.messages.loading_social_media`));
    } else if (errorSocialMedia) {
      handleChangeLoading(false);
      handleChangeError(t(`${BASE_TRANSLATE}.messages.error_loading_social_media`));
    } else if (socialMedia) {
      handleChangeLoading(false);
    }
  }, [socialMedia, loadingSocialMedia, errorSocialMedia]);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    onSubmit({...values, social_media_id: parseInt(values.social_media_id)});
  }

  const handleCancel = ({ setSubmitting }) => {
    setSubmitting(false);
    onCancel();
  }


  const formFields = {
    name: 'string_short',
    social_media_id: 'select',
    type: 'select',
  };

  const initState = {
    social_media_id: {
      value: "",
      options: socialMedia.map(formatSocialMedia)
    },
    type: {
      value: TARGET_TYPES[0].value,
      options: TARGET_TYPES
    }
  }

  return (
    <div data-testid="create-target-form">
      <NewFormDynamic
        initValues={formFields}
        initStateWithValues={initState}
        onSubmit={handleSubmit}
        handleCancel={handleCancel}
        textButtons={{
          submit: t(`${BASE_TRANSLATE}.fields.submit`),
          cancel: t(`${BASE_TRANSLATE}.fields.cancel`)
        }}
        textLabels={{
          name: t(`${BASE_TRANSLATE}.fields.name`),
          social_media_id: t(`${BASE_TRANSLATE}.fields.social_media`),
          type: t(`${BASE_TRANSLATE}.fields.target_type`),
        }}
        textPlaceholders={{
          name: t(`${BASE_TRANSLATE}.fields.name_placeholder`),
          social_media_id: t(`${BASE_TRANSLATE}.fields.social_media_placeholder`),
          type: t(`${BASE_TRANSLATE}.fields.target_type_placeholder`),
        }}
      />
    </div>
  );
}

const formatSocialMedia = (socialMedia) => {
  return {
    label: socialMedia.name,
    value: socialMedia.id
  }
}