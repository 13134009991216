import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
//import TableTwoViews from "components/TableTwoViews/TableTwoViews";
import { useFetch } from "hooks/useFetch";
import TableMessage from 'components/EmptyTableMessage';
import { findResume } from 'services/blockchains/blockchainResume';
import RenderChipConfig from "components/TableTwoViews/helperComponents/RenderChipConfig";
import DynamicTable from 'components/DynamicTable/DynamicTable';

export default function BlockchainResumeTable({ fetchParams = {} }) {
  const { t } = useTranslation("gasStation");
  const TRANLATION_BASE = "blockchain_resume_table";
  const { data, loading, error, refresh } = useFetch(findResume, {
    params: { limit: 100, page: 0, order: 'name_desc', ...fetchParams }
  });

  const getTableMessage = () => {
    if (!loading && error) {
      return (
        <TableMessage
          message={t(`${TRANLATION_BASE}.messages.error_fetching`)}
          actionButton={<RetryButton refresh={refresh} />}
        />
      )
    }
    return (
      <TableMessage
        message={t(`${TRANLATION_BASE}.messages.empty`)}
      />
    )
  }

  const configCells = {
    type: {
      render: (item) => {
        const color = !item ? 'statusGreen' : 'statusBlue';
        const label = item ? t(`${TRANLATION_BASE}.testnet`) : t(`${TRANLATION_BASE}.mainnet`);
        return <RenderChipConfig label={label} color={color} />
      }
    },
  }

  return (
    <div data-testid="blockchain-resume-table">
      <DynamicTable
        title={t(`${TRANLATION_BASE}.title`)}
        data={data}
        loading={loading}
        activeModeView={false}
        activeFilter={false}
        preparedDataConfig={itemMapper}
        setConfigParams={{
          configCells,
          emptyMessage: getTableMessage()
        }}
      />
    </div>
  )
}

const RetryButton = ({ refresh }) => {
  const { t } = useTranslation("gasStation");
  const TRANLATION_BASE = "blockchain_resume_table";
  return (
    <Button
      variant="outlined"
      color="info"
      onClick={refresh}
      data-testid="retry-button"
    >
      {t(`${TRANLATION_BASE}.actions.retry`)}
    </Button>
  )
}

const itemMapper = (item) => {
  return {
    blockchain: item.blockchain_name,
    type: item.testnet,
    balance: `${item.balance} ${item.symbol}`,
  }
}