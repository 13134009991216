import React from 'react';
import DynamicTable from 'components/DynamicTable/DynamicTable';

const NftHistory = ({NFTHistory, loading}) => {
  return (
    <DynamicTable 
    title={""}
    activeSearchBar={true}
    activeModeView={false}
    data={NFTHistory}
    loading={loading}
    preparedDataConfig={(item)=>{
      return {

        event: item?.event,
        price: item?.price,
        from: item?.from,
        to: item?.to,
        // expiration: formatTimestampToLocaleStringShort(item?.expiration),
        date: item?.date
        // updated: fullDate(item?.reward?.updated)
      }
    }}
  />
  );
}

export default NftHistory;