import axios from "axios";
import { buildUrlParams } from "utils/fetch";
axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;

const PATH = "role";
const URL = process.env.REACT_APP_API;


export const findAll = async(domain) => {
    return axios.get(process.env.REACT_APP_API+`/role?domain=${domain}`,{
        headers: {
            'X-API-Key': process.env.REACT_APP_X_API_KEY
        }
    })
    .then(res=>{
        return res.data
    })
}

export const getRolesCustom = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {
    limit: 30,
    page: 0,
    order: "created"
  }
}) => {
  const result = axios.get(
    `${URL}/${PATH}?domain=${domain}${buildUrlParams(params)}`
  );
  const response = await result;
  return response.data;
};

