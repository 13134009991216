import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export default function TableMessage({message, actionButton, ...props}) {
  return (
    <Box
      sx={{ px: 2 }}
      component="span"
    >
      <Stack direction="column" spacing={1} {...props}>
        <Box
          data-testid="table-message"
          component="span"
        >
          {message}
        </Box>
        {actionButton && <div>{actionButton}</div>}
      </Stack>
    </Box>
  );
}