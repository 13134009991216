import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import AuthorizeByRole from 'components/AuthorizeByRole/AuthorizeByRole';

const useConfig = (t,user, onApproveCollection) => {

  const menu = [
    {
      label: t("menu.nfts"),
      step: 1
    },
    {
      label: t("menu.benefits"),
      step: 2
    },
    // {
    //   label: t("menu.campaigns"),
    //   step: 3
    // },
    {
      label: t("menu.urls"),
      step: 4
    },
  ]

  

  const ALERT_TYPES = {
    0: <Alert severity="warning">
        <>
          {t("alerts.in_progress_collection")}
          <AuthorizeByRole 
            role={user?.role || 0}
            action={"approveCollection"}
          >
            <Button
              variant="outlined"
              data-testid="approve-collection-button"
              color="info"
              sx={{margin:'0px 1rem'}}
              size="small"
              onClick={onApproveCollection}
            >
              {t("options.approve")}
            </Button>
          </AuthorizeByRole>
        </>
        </Alert>,
    1: <Alert severity="success">{t("alerts.approved_collection")}</Alert>,
    2: <Alert severity="warning">{t("alerts.pending_nfts")}</Alert>,
    3: <Alert severity="warning">{t("alerts.evaluated_nfts")}</Alert>,
    4: <Alert severity="warning">{t("alerts.rated_nfts")}</Alert>,
    5: <Alert severity="success">{t("alerts.approved_nfts")}</Alert>,
    6: <Alert severity="error">{t("alerts.rejected_nfts")}</Alert>,
    7: <Alert severity="error">{t("alerts.rejected_collection")}</Alert>,
    8: <Alert severity="info">{t("alerts.collection_ready")}</Alert>
  }

  const statusBenefits = {
    0: {
      label:t("table.status.waiting_for_approval"),
      color: "statusGray"
    },
    1: {
      label: t("table.status.approved"),
      color: "success",
    },
    7: {
      label: t("table.status.rejected"),
      color: "error"
    }
  }

  const statusNFTs = {
    0: {
      label: t("table.status.pending_to_admin"),
      color: "statusGray"
    },
    1: {
      label:t("table.status.approved"),
      color: "statusGreen"
    },
    2: {
      label: t("table.status.minted"),
      color: "statusRed",
    },
    3: {
      label: t("table.status.reveal"),
      color: "statusYellow"
    },
    8:{
      label: t("table.status.rejected"),
      color: "statusRed"
    }
  }

  const tablePropsBenefit = {
    configOptions: [
    ]
  }

  const configBenefits = {
    configCells :{
      status:{  
        render: (item) => {
          return (
            <TableCell>
              <Chip 
                label={statusBenefits[item].label}
                color={statusBenefits[item].color} 
              />
            </TableCell>
          )
        }
      }
    }
  }

  const configNFTs = {
    configCells :{
      status:{  
        render: (item) => {
          return (
            <TableCell>
              <Chip 
                label={statusNFTs[item].label}
                color={statusNFTs[item].color} 
              />
            </TableCell>
          )
        }
      },
      thumb_url:{ 
        render: (item) => {
          console.log('item ::',item )
          return (
            <TableCell>
              <CardMedia
                component={item?.is_video ? 'video' : 'img'}
                sx={{
                  width: 50,
                  height: 50,
                  objectFit: 'contain'
                }}
                image={item.thumb_url}
              />
            </TableCell>
          )
        }
      },
      blockchain:{  
        render: (item) => {
          return (
            <TableCell>
              <Box
                sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap:'0.7rem',
                }}
              >
                <CardMedia
                  component={item?.is_video ? 'video' : 'img'}
                  key={item?.chainid}
                  sx={{ 
                    width: 30, 
                    height: 30,
                    objectFit: 'contain'
                  }}
                  src={item?.icon}
                />
              </Box>
            </TableCell>
          )
        }
      },
      price:{
        render: (item) => {
          return (
            <TableCell>
              {Number(item) === 0 ? `${t("alerts.not_for_sale")}`:`${item}`}
            </TableCell>
          )
        }
      
      }
    }
  }

  const validateIfActiveButtonCreateNFT = (status) => {
    if(!status || status === 0 || status === 2 || status === 4 || status > 7) return true; //disable = true
    return false; //disable = false
  }

  const SkeletonCollection = () => {
    return (
      <Box>
        <Paper elevation={3} style={{ position: 'relative', overflow: 'hidden' }}>
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          sx={{
            width: '100%',
            height: '300px'
          }}
        >
          <Skeleton sx={{ width:'100%', height:'100vh' }}/>
        </Box>      
        <Box
            position="absolute"
            top={"20px"}
            right={"0px"}
            display="flex"
            alignItems="center"
        >
          <Avatar
            variant="square"
            alt="Avatar"
            style={{ 
              marginRight: '1rem',
              width: '100px',
              height: '100px',
              border: '5px solid #fff',
              borderRadius: '10px'
            }}
          />
        </Box>
      </Paper>

      <Skeleton sx={{ width:'100%', height:'70px' }}/>
      <Skeleton sx={{ width:'100%', height:'50px' }}/>
    </Box>
    )
  }
  
  return {
    menu,
    ALERT_TYPES,
    statusBenefits,
    tablePropsBenefit,
    configBenefits,
    configNFTs,
    validateIfActiveButtonCreateNFT,
    SkeletonCollection
  }
}

export {
  useConfig
}