import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import CardMedia from '@mui/material/CardMedia';
import { BreadCrumbsSaveData } from 'components/BreadCrumbs/BreadCrumbs';
import { useFetch } from 'hooks/useFetch';
import UserContext from 'context/UserContext';
import { useTranslation } from 'react-i18next';
import { findAll, formatCampaignsToUpdateStatus, update as updateCampaign } from "services/campaigns/campaigns"
import { getActionsByRoleCode } from 'utils/rols';
import { useNavigate } from 'react-router-dom';
import { STATUS_COLLECTION } from 'utils/collectionStatus';
import { STATUS_CAMPAIGN } from 'utils/campaignStatus';
import { useSpinnerContext } from 'context/SpinnerContext';
import DynamicTable from 'components/DynamicTable/DynamicTable';

export default function CampaignsTable({ extraParams = {} }) {
  const { user } = useContext(UserContext);
  const { t } = useTranslation('campaigns');
  const { t: t_collection } = useTranslation("collections");
  const navigate = useNavigate();
  const { data: campaigns, loading: loading, refresh } = useFetch(findAll, {
    params: { limit: 100, page: 0, order: 'id_desc', ...extraParams }
  });
  const { handleChangeLoading, handleChangeMessage, handleChangeError } = useSpinnerContext();

  const statusOptions = {
    [STATUS_COLLECTION.PENDING_APPROVE]: {
      status: STATUS_COLLECTION.PENDING_APPROVE,
      label: t_collection("table.status.pending_approve"),
      color: "statusGray"
    },
    [STATUS_COLLECTION.APPROVED]: {
      status: STATUS_COLLECTION.APPROVED,
      label: t_collection("table.status.approved"),
      color: "statusGreen"
    },
    [STATUS_COLLECTION.LOADING_NFTs]: {
      status: STATUS_COLLECTION.LOADING_NFTs,
      label: t_collection("table.status.loading_nfts"),
      color: "statusBlue"
    },
    [STATUS_COLLECTION.LOADED_NFTs]: {
      status: STATUS_COLLECTION.LOADED_NFTs,
      label: t_collection("table.status.loaded_nfts"),
      color: "statusGreen"
    },
    [STATUS_COLLECTION.PENDING_APPROVE_NFTs]: {
      status: STATUS_COLLECTION.PENDING_APPROVE_NFTs,
      label: t_collection("table.status.pending_approve_nfts"),
      color: "statusGray"
    },
    [STATUS_COLLECTION.APPROVED_NFTs]: {
      status: STATUS_COLLECTION.APPROVED_NFTs,
      label: t_collection("table.status.approved_nfts"),
      color: "statusGreen"
    },
    [STATUS_COLLECTION.REJECTED_NFTs]: {
      status: STATUS_COLLECTION.REJECTED_NFTs,
      label: t_collection("table.status.rejected_nfts"),
      color: "statusRed"
    },
    [STATUS_COLLECTION.REJECTED]: {
      status: STATUS_COLLECTION.REJECTED,
      label: t_collection("table.status.rejected"),
      color: "statusRed"
    },
    [STATUS_COLLECTION.IN_PRODUCTION]: {
      status: STATUS_COLLECTION.IN_PRODUCTION,
      label: t_collection("table.status.in_production"),
      color: "statusYellow"
    }
  }

  // const approveCampaings = async (campaigns) => {
  //   await updateCampaingsStatus(
  //     campaigns,
  //     STATUS_CAMPAIGN.APPROVED,
  //     t("alerts.approving_campaigns"),
  //     t("alerts.error_approving_campaign")
  //   )
  // }

  const rejectCampaigns = async (campaigns) => {
    await updateCampaingsStatus(
      campaigns,
      STATUS_CAMPAIGN.REJECTED,
      t("alerts.rejected_campaigns"),
      t("alerts.error_rejected_campaign")
    )
  }

  const updateCampaingsStatus = async (campaings, newStatus, loadingMsg, errorMsg) => {
    handleChangeError("");
    handleChangeLoading(true);
    handleChangeMessage(loadingMsg);
    try {
      const updateData = formatCampaignsToUpdateStatus(campaings, newStatus);
      await updateCampaign(updateData);
      await refresh();
    } catch (error) {
      console.error(error);
      handleChangeError(errorMsg);
    } finally {
      handleChangeLoading(false);
    }
  }

  const configSelected = {
    defineId: 'name',
    options: [
      // {
      //   id: 'approve',
      //   label: 'Approve',
      //   color: 'success',
      //   variant: 'contained',
      //   tooltip: 'Click to approve',
      //   onClick: async (selectedCampaigns) => {
      //     await approveCampaings(selectedCampaigns);
      //   },
      //   conditions: (item) => {
      //     return (getActionsByRoleCode(user?.role, 'approveCampaign') && item?.status === 0)
      //   }
      // },
      {
        id: 'reject',
        label: 'Rejected',
        color: 'error',
        variant: 'contained',
        tooltip: 'Click to rejected',
        onClick: async (items) => {
          await rejectCampaigns(items);
        },
        conditions: (item) => {
          return (getActionsByRoleCode(user?.role, 'rejectedCampaign') && item?.status === 0)
        }
      },
      {
        id: 'edit',
        label: 'Edit',
        color: 'info',
        variant: 'contained',
        tooltip: 'Click to edit',
        onClick: (item) => {
          console.log("debug ", item);
        },
        conditions: () => {
          return getActionsByRoleCode(user?.role, 'editCampaign')
        }
      }
    ]
  }

  const configCells = {
    blockchain: {
      render: (item) => {
        return (
          <TableCell>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: '0.7rem',
              }}
            >{ }

              <CardMedia
                component="img"
                key={item?.chainid}
                sx={{
                  width: 30,
                  height: 30,
                  objectFit: 'contain'
                }}
                src={item?.icon}
              />
            </Box>
          </TableCell>
        )
      }
    },
    campaign_status: {
      render: (item) => {
        console.log("campaign_status ITEM", item)
        return (
          <TableCell>
            <Chip
              label={statusOptions[item].label}
              color={statusOptions[item].color}
            />
          </TableCell>
        )
      }
    }
  }

  const handleGo = (path) => () => {
    navigate(`/dashboard/campaigns/${path}`);
  }

  return (
    <div data-testid="campaigns-table">
      <DynamicTable
        title={t("list_of_campaigns")}
        activeSearchBar={true}
        activeModeView={false}
        activeFilter={true}
        data={campaigns}
        loading={loading}
        preparedDataConfig={(item) => {
          return {
            thumb_url: item.banner_url,
            name: item.name,
            blockchain: item.smart_contract.blockchain,
            type: item.type,
            campaign_status: item.status
          }
        }}
        setConfigParams={{
          configCells,
          configSelected,
          allowedKeys:["thumb_url", "name", "blockchain", "type", "campaign_status"],
          textHeaders: {
            thumb_url: ' '
          },
          onClick: (item) => {
            const value = {
              name: item.name,
              collection_id: item.id
            }
            BreadCrumbsSaveData("campaignId", value);
            handleGo(`${item.id}`)()
          },
          emptyMessage: <EmpyCampaignsTableMessage />
        }}
      />
    </div>
  )
}

const EmpyCampaignsTableMessage = () => {
  const { t } = useTranslation("campaigns");

  return (
    <Box
      data-testid="campaings-view-empty-message"
      sx={{ px: 2 }}
      component="span"
    >
      {t("table.empty_data_message")}
    </Box>
  )
}
