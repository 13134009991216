import { useState, useCallback, Fragment } from "react";
import PortalPopup from "./PortalPopup";
import styles from "./Events.module.css";
import ModalCreateEvent from "./ModalCreateEvent";
import { useTranslation } from 'react-i18next';
import { allLogos } from "utils/icons";

const Events = () => {

  const { t } = useTranslation('events');
  const [
    isSeleccionaElementoDestacadoOpen,
    setSeleccionaElementoDestacadoOpen,
  ] = useState(false);

  const openSeleccionaElementoDestacado = useCallback(() => {
    setSeleccionaElementoDestacadoOpen(true);
  }, []);

  const closeSeleccionaElementoDestacado = useCallback(() => {
    setSeleccionaElementoDestacadoOpen(false);
  }, []);

  return (
    <Fragment>
      <div className={styles.eventosInner}>
        <div className={styles.divGrficasWrapper}>
          <div className={styles.divGrficas}>
            <div className={styles.imagen}>
              <div className={styles.ttuloYDescripcinParent}>
                <div className={styles.ttuloYDescripcin} style={{flex:1}}>
                  <div className={styles.loremIpsumDolor} style={{textAlign:'left'}}>
                    {t("description")}
                  </div>
                </div>
                <div
                  className={styles.ttuloYDescripcin1}
                  onClick={openSeleccionaElementoDestacado}
                >
                  <div className={styles.title}>+ {t("options.create_event")}</div>
                </div>
              </div>
              {
                allLogos?.post?.list?.map((item) => (
                <div key={item.id} className={styles.spotlight1Parent}>
                   <div className={styles.spotlight1}>
                     <div className={styles.rectangleParent}>
                     <img className={styles.frameChild} alt="" src="/rectangle-2108@2x.png" />
                       <div className={styles.evento1Parent}>
                         <div className={styles.title}>{item.title}</div>
                         <div className={styles.loremIpsumDolor1}>
                           {item.description}
                         </div>
                       </div>
                     </div>
                   </div>
                   <div className={styles.parent}>
                     <div className={styles.div}>|</div>
                     <div className={styles.editar}>{t("options.edit_event")}</div>
                   </div>
                </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      {isSeleccionaElementoDestacadoOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSeleccionaElementoDestacado}
        >
          
          <ModalCreateEvent
            onClose={closeSeleccionaElementoDestacado}
          />
        </PortalPopup>
      )}
    </Fragment>
  );
};

export default Events;
