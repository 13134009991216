import React, { useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./ApiKeys.module.css";
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks/useFetch';
import { deleteApikey, getApiKeys }  from 'services/apiKeys/apikeys'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { shortAddress } from 'utils/strings';
import { Box } from '@mui/material';
import DynamicTable from 'components/DynamicTable/DynamicTable';


const ApiKeys = () => {
    const { t } = useTranslation('apikeys');
    const navigate = useNavigate();
    const { data: apiKeys, loading, refresh} = useFetch(getApiKeys,{
      params: { limit: 100, page: 0, order: 'created' }
    });
    const handleGo = useCallback((path) => () => {
      navigate(`/dashboard/api-keys/${path}`);
    },[navigate]);
    const configSelected = {
      defineId: 'id',
      options:[
        {
          id: 'edit',
          label: 'Edit',
          color: 'info',
          variant: 'contained',
          tooltip:'Click to edit',
          onClick: (item) => {
            console.log("debug ",item);
          },
          conditions: ()=>{
            return true
          }
        },
        {
          id:'rejected',
          label: 'DELETE',
          color: 'error',
          variant: 'contained',
          tooltip:'Click to rejected',
          onClick: async (item) => {
            await deleteApikey(item[0]);
            refresh();
          },
          conditions: ()=>{
            return true
          }      
        }
      ]
    }

    return (
      <section className={styles.contentSection} >
        <div className={styles.headerSection}>
          <div className={styles.subHeaderSection}>
          <Typography variant="body2" gutterBottom flex={1}>
          </Typography>
            <Button
              data-testid="id-add-button"
              variant="outlined" 
              color="info"
              onClick={handleGo("create")}
            >
              +{t("options.add_btn")}
            </Button>
          </div>
        </div>
        <div className={styles.workSpace}>
          <Box data-testid="view-apikey-table">
            <DynamicTable
              title={t("title")}
              data={apiKeys}
              activeSearchBar={false}
              activeModeView={false}
              activeFilter={true}
              loading={loading}
              preparedDataConfig={(item)=>{
                const createdDate = new Date(item.created * 1000);
                const createdISOString = createdDate.toISOString();
                return {
                  id: item.id,
                  name: shortAddress(item.name),
                  fingerprint: shortAddress(item.fingerprint),
                  added_by: item?.username ? item?.username : shortAddress(item.user_main_key),
                  created: typeof item?.created == 'number' ? createdISOString.replace('T', ' ').replace('Z', '').split(" ")[0]:item?.created,
                  updated: item.updated.split(" ")[0]
                }
              }}
              setConfigParams={{
                configSelected,
                emptyMessage: t("table.empy_message"),
              }}
            />
          </Box>
        </div>
      </section>
    );
};

export default ApiKeys;