import { createContext, useCallback, useContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

const defaultValue = {
  loadingSpinner: false,
  handleChangeLoading: () => {},
  messageSpinner: '',
  handleChangeMessage: () => {},
  error: null,
  handleChangeError: () => {},
  messageSuccess: null,
  handleChangeMessageSuccess: () => {},
  cleanAll: () => {}
};

const SpinnerContext = createContext(defaultValue);

const useSpinnerContext = () => {
  return useContext(SpinnerContext);
};

const SpinnerProvider = ({ children }) => {
  const [ loadingSpinner, setLoadingSpinner ] = useState(false);
  const [ messageSpinner, setMessageSpinner ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ messageSuccess, setMessageSuccess ] = useState(null);

  const cleanAll = useCallback(() => {
    setMessageSpinner(null);
    setMessageSuccess(null);
    setError(null);
  }, [ setMessageSuccess, setMessageSpinner,setError]);
  
  const handleChangeLoading = useCallback((valueBool) => {
    setLoadingSpinner(valueBool);
  }, [loadingSpinner]);

  const handleChangeMessage = useCallback((msg) => {
    setMessageSpinner(msg);
  }, [messageSpinner]);

  const handleChangeError = useCallback((msg) => {
    setError(msg);
  }, [error]);

  const handleChangeMessageSuccess = useCallback((msg) => {
    setMessageSuccess(msg);
  }, [messageSuccess]);

  const contextValue = useMemo(() => ({
    loadingSpinner,
    handleChangeLoading,
    messageSpinner,
    handleChangeMessage,
    error,
    handleChangeError,
    messageSuccess,
    handleChangeMessageSuccess,
    cleanAll
  }), [loadingSpinner, handleChangeLoading, messageSpinner, handleChangeMessage, error, handleChangeError,messageSuccess,handleChangeMessageSuccess,cleanAll]);

  useEffect(()=>{
    if(loadingSpinner){
      cleanAll()
    }
  },[loadingSpinner])

  return (
    <SpinnerContext.Provider value={contextValue} >
      {children}
    </SpinnerContext.Provider>
  );
};

SpinnerProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { SpinnerProvider, useSpinnerContext, SpinnerContext };
