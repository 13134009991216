import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { formatTimestampToLocaleStringShort } from 'utils/date';
import { useFetch } from 'hooks/useFetch';
import { findBenefits } from 'services/benefits';
import DynamicTable from 'components/DynamicTable/DynamicTable';

export default function BenefitsTable({ fetchParams = {} }) {
  const { t } = useTranslation("campaigns");
  const { data: benefits, loading: loadingBenefits } = useFetch(findBenefits, {
    params: {
      limit: 100,
      page: 0,
      order: 'contract_address',
      ...fetchParams
    }
  });

  const statusOptions = {
    0: {
      label: t("table.status.pending"),
      color: "primary"
    },
    1: {
      label: t("table.status.approved"),
      color: "warning"
    },
    7: {
      label: t("table.status.rejected"),
      color: "error"
    }
  }

  const configCells = {
    status: {
      render: (item) => {
        return (
          <TableCell>
            <Chip
              label={statusOptions[item].label}
              color={statusOptions[item].color}
            />
          </TableCell>
        )
      }
    }
  }

  const itemMapper = (item) => {
    return {
      thumbnail: item?.reward?.thumb_url,
      name: item?.reward?.name,
      description: item?.reward?.description,
      status: item?.status,
      created: formatTimestampToLocaleStringShort(item?.created_at),
      updated: formatTimestampToLocaleStringShort(item?.created_at),
    }
  }

  return (
    <DynamicTable
      title={"Benefits"}
      data={benefits}
      loading={loadingBenefits}
      activeSearchBar={true}
      activeModeView={false}
      activeFilter={true}
      preparedDataConfig={itemMapper}
      setConfigParams={{
        configCells,
        configOptions: [],
        emptyMessage: <EmpyBenefitsTableMessage />,
        textHeaders: { thumbnail: ' ' }
      }}
    />
  );
}

const EmpyBenefitsTableMessage = () => {
  const { t } = useTranslation("benefits");

  return (
    <Box
      data-testid="campaing-overview-empty-benefits-table"
      sx={{ px: 2 }}
      component="span"
    >
      {t("benefits.table.empty_message")}
    </Box>
  )
}
