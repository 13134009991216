import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function GoEditCampaignButton({campaignId, ...props}) {
  const { t } = useTranslation("campaigns");
  const navigate = useNavigate();

  return (
    <Button
      data-testid="go-edit-campaign-button"
      variant="outlined"
      color="info"
      onClick={() => navigate(`/dashboard/campaigns/${campaignId}/edit`)}
      {...props}
    >
      + {t("options.edit_collection")}
    </Button>
  )
}