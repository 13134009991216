import { loginMetamask, disconnectMetamask } from "./browserWallet";
import { disconnectMagicOauth, disconnectMagicStandard, loginMagic } from "./magicWallet";
import { loginMagicOAuth, validateApiTokenIsValid, connectUserEmailOTP } from "./magicWalletOAuth";

export const PROVIDERS = {
  BROWSER_WALLET: loginMetamask,
  MAGIC_WALLET: loginMagic,
  MAGIC_WALLET_OAUTH: loginMagicOAuth,
  MAGIC_EMAIL_OTP:  connectUserEmailOTP
}

export const RECONNECT_PROVIDERS = {
  BROWSER_WALLET: loginMetamask,
  MAGIC_WALLET_OAUTH: validateApiTokenIsValid,
  MAGIC_EMAIL_OTP: validateApiTokenIsValid
}

export const LOGOUT_PROVIDERS = {
  BROWSER_WALLET: disconnectMetamask,
  MAGIC_WALLET: disconnectMagicStandard,
  MAGIC_WALLET_OAUTH: disconnectMagicOauth,
  MAGIC_EMAIL_OTP: disconnectMagicOauth
}

export const newConnection = async (provider,social_provider) => {
  localStorage.setItem("typeConnection", provider);
  const init = PROVIDERS[provider] || null;
  if(init && typeof init === "function"){
    return social_provider ? await init(String(social_provider).toLowerCase()) : await init();
  }
  throw new Error ("newConnection:: Invalid type provider: " + provider);
};

export const reConnection = async (provider) => {
  const init = RECONNECT_PROVIDERS[provider] || null;
  if(init && typeof init === "function"){
    return await init();
  }
  throw new Error ("reConnection:: Invalid type provider: " + provider);
};

export const disconnect = async (provider,instance) => {
  const init = LOGOUT_PROVIDERS[provider] || null;
  if(init && typeof init === "function"){
    return instance ? await init(instance) : await init();
  }
  throw new Error ("logOut:: Invalid type provider: " + provider);
}