import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import styles from './urlModal.module.css';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';


const UrlModal = ({ open, onClose, textContent }) => {
  const [isCopied, setIsCopied] = useState(false)
  const { t } = useTranslation("collections");
  const theme = useTheme();


    const copyToClipboard = () => {
      navigator.clipboard.writeText(textContent);
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 1500);
    };

    const shortString = (inputString, start, end) => {
      if (typeof inputString !== 'string') {
        throw new Error('El primer parámetro debe ser un string.');
      }
      if (typeof start !== 'number' || typeof end !== 'number') {
        throw new Error('Los parámetros start y end deben ser números.');
      }
      if (start < 0 || end < 0) {
        throw new Error('Los parámetros start y end no pueden ser negativos.');
      }
      if (inputString.length <= 70) {
        return inputString;
      }else{
        const startPart = inputString.substring(0, start);
        const endPart = inputString.slice(-end);
        return startPart + '...' + endPart;
      }
    };
    
    return (
      <Modal
        open={open}
        onClose={onClose}
        className={styles.modalContainer}
      >
        <Card
          sx={{
            padding: 1.2,
            margin: '0 auto',
            position: 'relative'
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box p={1}>
            <Typography variant="h6" component="h2" data-testid="content-modal" color={theme.palette.success.light}>
              {t("message.generated_successfully")}
            </Typography>
            <Box className={styles.modalBox}>
              <Box position="relative" className={styles.urlBox} bgcolor={theme.palette.grey[200]}>
                <Typography variant="h6" align="center" data-testid="content-modal">
                  {shortString(textContent, 20, 12)}
                </Typography>
                <Button onClick={copyToClipboard} className={styles.copyButton} data-testid="copy-modal">
                  <ContentCopyIcon />
                </Button>
                {isCopied && <Typography className={styles.copiedMessage} data-testid="copy-message">Copied Link</Typography>}
              </Box>
            </Box>
          </Box>
        </Card>
      </Modal>
    )

};

UrlModal.propTypes = {
    onClose: PropTypes.func
};

export default UrlModal;