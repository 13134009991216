import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik'
import { capitalizeFirstLetter } from 'utils/strings';
import WrappedMaterialTextField from 'components/WrappedMaterialTextField';
import CustomLabel from '../utils/customLabel';


const WrappedMaterialTextarea = ({ ...props }) => {
  return (
    <WrappedMaterialTextField
      {...props}
      style={{ backgroundColor: 'white',borderRadius:'8px' }}
      multiline
      minRows={4}
    />
  )
}

const InputTextArea = ({ name, placeholder, label, loading, inputDescription}) => {
  const description = inputDescription ? inputDescription : undefined;

  return (
    <Fragment>
      <CustomLabel htmlFor={name} description={description} >
        {label ? label : capitalizeFirstLetter(name)}
      </CustomLabel>
      <Field
        placeholder={placeholder ? placeholder : capitalizeFirstLetter(name)}
        name={name}
        disabled={loading}
        component={WrappedMaterialTextarea}
      />
    </Fragment>
  );
};

InputTextArea.propTypes = {
  name: PropTypes.string,
  loading: PropTypes.bool
};

export default InputTextArea;