import React from 'react';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  IconButton,
  FormHelperText
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

const validationSchema = Yup.object({
  missions: Yup.array().of(
    Yup.object({
      description: Yup.string().required('Description is required'),
      startDate: Yup.date().required('Start Date is required'),
      endDate: Yup.date().required('End Date is required'),
      socialNetwork: Yup.object().required('Social Network is required'),
      target: Yup.object().required('Target is required'),
    })
  )
});

const MissionForm = ({ targets, socialNetworks,rewards,  onSuccess, onCancel}) => {
    console.log(targets)
  return (
    <Formik
      initialValues={{
        missions: [
          {
            description: '',
            startDate: '',
            endDate: '',
            socialNetwork: '',
            target: '',
            reactions: 0,
            views: 0,
            shares: 0,
            
          }
        ],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Manejar el envío del formulario
        console.log(values);
        onSuccess(values)
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '80%', margin: 'auto' }}>
          <form onSubmit={handleSubmit}>
            <FieldArray name="missions">
              {({ push, remove }) => (
                <>
                  {values.missions.map((mission, index) => (
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid #ccc', padding: 2, borderRadius: 2 }}>
                      <TextField
                        label="Description"
                        name={`missions[${index}].description`}
                        value={mission.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.missions?.[index]?.description && Boolean(errors.missions?.[index]?.description)}
                        helperText={touched.missions?.[index]?.description && errors.missions?.[index]?.description}
                        required
                        fullWidth
                      />
                      <TextField
                        label="Start Date"
                        type="date"
                        name={`missions[${index}].startDate`}
                        value={mission.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.missions?.[index]?.startDate && Boolean(errors.missions?.[index]?.startDate)}
                        helperText={touched.missions?.[index]?.startDate && errors.missions?.[index]?.startDate}
                        InputLabelProps={{ shrink: true }}
                        required
                        fullWidth
                      />
                      <TextField
                        label="End Date"
                        type="date"
                        name={`missions[${index}].endDate`}
                        value={mission.endDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.missions?.[index]?.endDate && Boolean(errors.missions?.[index]?.endDate)}
                        helperText={touched.missions?.[index]?.endDate && errors.missions?.[index]?.endDate}
                        InputLabelProps={{ shrink: true }}
                        required
                        fullWidth
                      />
                      <FormControl fullWidth error={touched.missions?.[index]?.socialNetwork && Boolean(errors.missions?.[index]?.socialNetwork)}>
                        <InputLabel id={`missions[${index}].socialNetwork-label`}>Social Network</InputLabel>
                        <Select
                          labelId={`missions[${index}].socialNetwork-label`}
                          name={`missions[${index}].socialNetwork`}
                          value={mission.socialNetwork}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        >
                            {socialNetworks.map((option) => (
                                <MenuItem key={option.name} value={option}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {touched.missions?.[index]?.socialNetwork && errors.missions?.[index]?.socialNetwork ? (
                          <FormHelperText>{errors.missions?.[index]?.socialNetwork}</FormHelperText>
                        ) : null}
                      </FormControl>
                      <FormControl fullWidth error={touched.missions?.[index]?.reward && Boolean(errors.missions?.[index]?.reward)}>
                        <InputLabel id={`missions[${index}].reward-label`}>Reward</InputLabel>
                        <Select
                          labelId={`missions[${index}].reward-label`}
                          name={`missions[${index}].reward`}
                          value={mission.reward}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        >
                            {rewards.map((option) => (
                                <MenuItem key={option.name} value={option}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {touched.missions?.[index]?.reward && errors.missions?.[index]?.reward ? (
                          <FormHelperText>{errors.missions?.[index]?.reward}</FormHelperText>
                        ) : null}
                      </FormControl>
                      <FormControl fullWidth error={touched.missions?.[index]?.target && Boolean(errors.missions?.[index]?.target)}>
                        <InputLabel id={`missions[${index}].target-label`}>Target</InputLabel>
                        <Select
                          labelId={`missions[${index}].target-label`}
                          name={`missions[${index}].target`}
                          value={mission.target}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        >
                            {targets.map((option) => (
                                <MenuItem key={option.name} value={option}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {touched.missions?.[index]?.target && errors.missions?.[index]?.target ? (
                          <FormHelperText>{errors.missions?.[index]?.target}</FormHelperText>
                        ) : null}
                      </FormControl>
                      <TextField
                        label="Reactions/Likes"
                        name={`missions[${index}].reactions`}
                        value={mission.reactions}
                        type='number'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.missions?.[index]?.reactions && Boolean(errors.missions?.[index]?.reactions)}
                        helperText={touched.missions?.[index]?.reactions && errors.missions?.[index]?.reactions}
                        required
                        fullWidth
                      />
                      {(""+mission.socialNetwork.name).toLocaleUpperCase() == 'TWITTER' &&
                        <TextField
                          label="Views"
                          name={`missions[${index}].views`}
                          value={mission.views}
                          type='number'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.missions?.[index]?.views && Boolean(errors.missions?.[index]?.views)}
                          helperText={touched.missions?.[index]?.views && errors.missions?.[index]?.views}
                          required
                          fullWidth
                        />
                      }
                      {(""+mission.socialNetwork.name).toLocaleUpperCase() != 'INSTAGRAM' && 
                        <TextField
                          label="Shares"
                          name={`missions[${index}].shares`}
                          value={mission.shares}
                          type='number'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.missions?.[index]?.shares && Boolean(errors.missions?.[index]?.shares)}
                          helperText={touched.missions?.[index]?.shares && errors.missions?.[index]?.shares}
                          required
                          fullWidth
                        />
                      }
                        <TextField
                        label="Template"
                        name={`missions[${index}].template`}
                        value={mission.template}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.missions?.[index]?.template && Boolean(errors.missions?.[index]?.template)}
                        helperText={touched.missions?.[index]?.template && errors.missions?.[index]?.template}
                        required
                        fullWidth
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconButton onClick={() => remove(index)} disabled={values.missions.length === 1}>
                          <RemoveCircleOutline />
                        </IconButton>
                        {index === values.missions.length - 1 && (
                          <IconButton onClick={() => push({
                            description: '',
                            startDate: '',
                            endDate: '',
                            socialNetwork: '',
                            target: '',
                          })}>
                            <AddCircleOutline />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </FieldArray>
            <Button variant="contained" color="secondary" fullWidth onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Siguiente
            </Button>
          </form>
        </Box>
      )}
    </Formik>
  );
};

export default MissionForm;
