import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import PropTypes from "prop-types";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';

const PopUpFilter = ({
  anchorEl,
  setAnchorEl,
  open,
  id,
  t,
  headers,
  filter,
  setFilter,
  availableFilters
}) => {
  const idPopUp = id;
  const options = [
    {label: t('filter.translate_status.PENDING_APPROVE'),value:0},
    {label: t('filter.translate_status.APPROVED'),value:1},
    {label: t('filter.translate_status.LOADING_NFTs'),value:2},
    {label: t('filter.translate_status.LOADED_NFTs'),value:3},
    {label: t('filter.translate_status.PENDING_APPROVE_NFTs'),value:4},
    {label: t('filter.translate_status.APPROVED_NFTs'),value:5},
    {label: t('filter.translate_status.REJECTED_NFTs'),value:6},
    {label: t('filter.translate_status.REJECTED'),value:7},
    {label: t('filter.translate_status.IN_PRODUCTION'),value:8},
    {label: t('filter.translate_status.HIDDEN'),value:12}
  ]

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    const { name, value } = event.target;
    setFilter({
      ...filter,
      [name]: value
    });
  },[setFilter,filter]);

  const styleItem = {
    flex:1,
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
  }

  const styleContentItems ={
    padding:'8px',
    maxWidth:'500px', 
    gap: 2,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
  
  return (
    <Menu
      anchorEl={anchorEl}
      id={idPopUp}
      open={open}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: -12,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgColor: 'red',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
          "& .MuiList-root":{
            padding: '0px 0px !important',
          }
        }
      }}
    >
      <Box sx={{paddingTop:'8px',display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'flex-end'}}>
          <Box>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <IconButton size="small" onClick={()=>setFilter({})}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={styleContentItems}>
        <Box sx={styleItem}>
          <Typography variant="subtitle1">{t("filter.columns")}</Typography>
          <Select
            labelId="column-label"
            id="column"
            value={filter?.column || ''}
            size="small"
            name="column"
            onChange={handleChange}
            sx={{  flex:1 }}
            displayEmpty={true}
          >
            <MenuItem value={""} disabled >{t("filter.columns")}</MenuItem>
            {headers?.map((header,index) => {
              return (
                <MenuItem key={header?.value || index} value={header?.value}>{header?.label}</MenuItem>
              )
            })}
          </Select>
        </Box>
        <Box sx={styleItem}>
          <Typography variant="subtitle1">{t("filter.operator")}</Typography>
          <Select
            labelId="operator-label"
            id="operator"
            value={filter?.operator || ''}
            size="small"
            name="operator"
            onChange={handleChange}
            sx={{  flex:1 }}
            displayEmpty={true}
          >
            <MenuItem value={""} disabled >{t("filter.operator")}</MenuItem>
            {availableFilters?.map((item,index) => {
              return (
                <MenuItem key={item?.value || index} value={item?.value}>{item?.label}</MenuItem>
              )
            })}
          </Select>
        </Box>
        <Box sx={styleItem}>
          <Typography variant="subtitle1">{t("filter.value.title")}</Typography>
          {
          filter?.column === "status" ? 
          <Select
            labelId="valueStatus-label"
            id="valueStatus"
            value={filter?.value || ''}
            size="small"
            name="value"
            onChange={handleChange}
            sx={{  flex:1 }}
            displayEmpty={true}
          >
            <MenuItem value={""} disabled >{t("filter.columns")}</MenuItem>
            {options?.map((status,index) => {
              return (
                <MenuItem key={status?.value || index} value={status?.value}>{status?.label}</MenuItem>
              )
            })}
          </Select>
          :
          <TextField 
            name="value"
            size='small' 
            placeholder={t("filter.value.placeholder")} 
            sx={{width:'100%'}}
            onChange={handleChange}
            value={filter?.value || ''}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />}
        </Box>
      </Box>
    </Menu>
  );
}


PopUpFilter.propTypes = {
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func,
  open: PropTypes.bool,
  id: PropTypes.string,
  t: PropTypes.func,
  headers: PropTypes.array,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  availableFilters: PropTypes.array
};

export default PopUpFilter;