import { useEffect, useState } from "react";
import styles from "./EditDashboardTheme.module.css";
import { Grid, Card, CardContent, Switch, FormControlLabel, Typography, FormControl, InputLabel, Select, MenuItem, Slider, Button  } from '@mui/material';
import { useTranslation } from 'react-i18next';

const defaultThemeSettings = {
  mode: 'light',
  palette: {
    primary: '#151D28',
    secondary: '#A7F070',
    info: '#249FDE',
    success: '#38B764',
    error: '#EE3A43',
    danger: '#DC3545',
    darkGreen: '#202e37',
    white: '#fff',
    background: {
      paper: '#e5e8e3',
      default: '#EBEDE9',
      white: '#fff',
    },
    text: {
      primary: '#151D28',
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
  shape: {
    borderRadius: 8,
  },
};

const DashboardTheme = () => {
  const [selectedColor, setSelectedColor] = useState(defaultThemeSettings);
  const [fontFamily, setFontFamily] = useState('Inter');
  const [borderRadius, setBorderRadius] = useState(8);
  const { t } = useTranslation("dashboardTheme");

  useEffect(() => {
    setSelectedColor(defaultThemeSettings)
  }, [])

  const handleChangeColor = (colorType) => (event) => {
    setSelectedColor(prevColors => {
      const newColors = JSON.parse(JSON.stringify(prevColors));
      const keys = colorType.split('.');
      let current = newColors;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = event.target.value;
      return newColors;
    });
  }

  const handleFontFamilyChange = (event) => {
    setFontFamily(event.target.value);
  };

  const handleBorderRadiusChange = (event, newValue) => {
    setBorderRadius(newValue);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log(file);
  };
  
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Card>
              <CardContent>
                <Typography >{t("palette.title")}</Typography>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("palette.main")}</b>
                  <input
                    type="color"
                    value={selectedColor.palette.primary}
                    onChange={handleChangeColor('palette.primary')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("palette.background_color")}</b>
                  <input
                    type="color"
                    value={selectedColor.palette.secondary}
                    onChange={handleChangeColor('palette.secondary')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("palette.info")}</b>
                  <input
                    type="color"
                    value={selectedColor.third}
                    onChange={handleChangeColor('palette.info')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("palette.success")}</b>
                  <input
                    type="color"
                    value={selectedColor.third}
                    onChange={handleChangeColor('palette.success')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("palette.error")}</b>
                  <input
                    type="color"
                    value={selectedColor.third}
                    onChange={handleChangeColor('palette.error')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("palette.danger")}</b>
                  <input
                    type="color"
                    value={selectedColor.palette.danger}
                    onChange={handleChangeColor('palette.danger')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("palette.green")}</b>
                  <input
                    type="color"
                    value={selectedColor.palette.darkGreen}
                    onChange={handleChangeColor('palette.darkGreen')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("palette.white")}</b>
                  <input
                    type="color"
                    value={selectedColor.palette.white}
                    onChange={handleChangeColor('palette.white')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("palette.text")}</b>
                  <input
                    type="color"
                    value={selectedColor.palette.text.primary}
                    onChange={handleChangeColor('palette.text.primary')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <Card>
                  <CardContent>
                    <Typography >{t("palette.background.title")} </Typography>
                    <div className={styles.colorPrincipal}>
                      <b className={styles.colorDeFondo}>{t("palette.background.paper")}</b>
                      <input
                        type="color"
                        value={selectedColor.palette.background.paper}
                        onChange={handleChangeColor('palette.background.paper')}
                        className={styles.circularColorInput}
                        style={{ marginLeft: '10px' }}
                      />
                    </div>
                    <div className={styles.colorPrincipal}>
                      <b className={styles.colorDeFondo}>{t("palette.background.default")}</b>
                      <input
                        type="color"
                        value={selectedColor.palette.background.default}
                        onChange={handleChangeColor('palette.background.default')}
                        className={styles.circularColorInput}
                        style={{ marginLeft: '10px' }}
                      />
                    </div>
                    <div className={styles.colorPrincipal}>
                      <b className={styles.colorDeFondo}>{t("palette.background.white")}</b>
                      <input
                        type="color"
                        value={selectedColor.palette.background.white}
                        onChange={handleChangeColor('palette.background.white')}
                        className={styles.circularColorInput}
                        style={{ marginLeft: '10px' }}
                      />
                    </div>
                  </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card>
              <CardContent>
                <Typography >{t("font.title")}</Typography>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="font-family-select-label">{t("font.family")}</InputLabel>
                  <Select
                    labelId="font-family-select-label"
                    id="font-family-select"
                    value={fontFamily}
                    label="Familia de Fuentes"
                    onChange={handleFontFamilyChange}
                  >
                    <MenuItem value="Inter">Inter</MenuItem>
                    <MenuItem value="Helvetica">Helvetica</MenuItem>
                    <MenuItem value="Arial">Arial</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
            <FormControlLabel 
              control={<Switch />}
              label={t("dark_mode")}
            />
            <Card>
              <CardContent>
                <Typography >{t("shape.title")}</Typography>
                <Typography id="border-radius-slider-label" gutterBottom>
                  {t("shape.border")}
                </Typography>
                <Slider
                  value={borderRadius}
                  onChange={handleBorderRadiusChange}
                  aria-labelledby="border-radius-slider-label"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={20}
                />
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Typography>{t("upload")}</Typography>
                <input
                  type="file"
                  onChange={handleFileUpload}
                  style={{ marginTop: '10px' }}
                />
              </CardContent>
            </Card>
            <Button 
              color="success"
              variant="contained"
              type="submit"
            >
            {t(`buttons.save`)}
            </Button>
            <Button color="success" variant="transparent" type="button">
              {t(`buttons.cancel`)}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <img src='/DashboardThemePreview.png' alt="Descripción" className={styles.previewImage} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DashboardTheme;
