import axios from "axios";
import { buildUrlParams } from "utils/fetch";
axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;

const PATH = "collection";
const URL = process.env.REACT_APP_API;


export const getAllCollections = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {
    limit: 30,
    page: 0,
    order: "created"
  }
}) => {
  const result = axios.get(
    `${URL}/${PATH}?domain=${domain}${buildUrlParams(params)}`
  );
  const response = await result;
  return response.data || [];
};

export const getCollectionByKey = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {
    collection_key: "example",
    limit: 30,
    page: 0,
    order: "created"
  }
}) => {
  console.log('url ::',`${URL}/${PATH}?domain=${domain}${buildUrlParams(params)}`)
  const result = axios.get(
    `${URL}/${PATH}?domain=${domain}${buildUrlParams(params)}`
  );
  const response = await result;
  return response.data[0] || null;
};


export const getCollectionByProjectKey = async (projectKey) => {
  const fullPath = `project/${PATH}?domain=${process.env.REACT_APP_DOMAIN}&project_id=${projectKey}&limit=1&page=0&order=created`;
  const result = axios.get(fullPath);
  const response = await result;
  return response.data;
};
