import { useEffect, useState } from "react";
import WithLoadingBackdropAndErrorSnack from "HOCS/WithLoadingBackdropAndErrorSnack";
import { getCollectionByKey } from "services/collection/collections";
import FormMint from "components/FormMint/FormMint";
import { useParams } from "react-router-dom";

export default WithLoadingBackdropAndErrorSnack(function MintNFTs({ setError, setIsLoading, isLoading }) {
  const { collectionKey } = useParams()
  const [collection, setCollection] = useState({collection_key: collectionKey});

  useEffect(() => {
    const params = {collection_key: collectionKey, limit: 1, page: 0, order: 'created'};
    setIsLoading(true);
    getCollectionByKey({params}).then(collection => {
      setCollection(collection);
    }).catch(e => {
      console.error(e);
      setError("Sorry, something went wrong fetching the collection.");
    }).finally(() => {
      setIsLoading(false);
    });
  }, [collectionKey]);

  return (
    <FormMint
      collection={collection}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      setError={setError}
    />
  );
});