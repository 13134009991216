import { useContext, useEffect, useState, useCallback } from 'react';
import styles from './login.module.css';
import UserContext from 'context/UserContext';
import { useTranslation } from 'react-i18next';
import Email from '@mui/icons-material/Email';
import { useNavigate, Link } from 'react-router-dom';
import { resetUserLS } from 'utils/localStorage';
import loginStyles from "./LoginWallet.module.css"
import LoginCardWrapper from 'components/Login/LoginCardWrapper';
import SocialLoginButton from 'components/Login/SocialLoginButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import LoginInputField from 'components/Login/LoginInputField';

const Paragraphs = ({ text }) => {
  const textTermsEs = "Términos de Servicio";
  const textTermsEn = "Terms of Service";
  const textPrivacyEn = "Privacy Statement";
  const textPrivacyEs = "Declaración de Privacidad";
  const routeTerms = "/terms-and-conditions"
  const routePrivacy = "/privacy-policy"

  const createTagToLinksHtml = useCallback((text) => {
    try {
      const terms = [textTermsEs, textTermsEn, textPrivacyEn, textPrivacyEs];
      const regex = new RegExp(`\\b(${terms.join('|')})\\b`, 'gi');

      return text.split(regex).map((segment, index) => {
        if (terms.includes(segment)) {
          if (segment === textTermsEs || segment === textTermsEn) {
            return <Link key={index} to={routeTerms} style={{ textDecoration: 'underline', color: "inherit" }}>{segment}</Link>;
          } else
            if (segment === textPrivacyEn || segment === textPrivacyEs) {
              return <Link key={index} to={routePrivacy} style={{ textDecoration: 'underline', color: "inherit" }}>{segment}</Link>;
            }
          return segment;
        } else {
          return segment;
        }
      });
    } catch (error) {
      console.error('Error creating links:', error);
      return text;
    }
  }, [textTermsEs, textTermsEn, textPrivacyEn, textPrivacyEs]);

  const paragraphs = createTagToLinksHtml(text);

  return (
    <p className={`${loginStyles.cardParagraph} ${styles.termsAndConditions}`}>{paragraphs}</p>
  );
};

const Login = () => {

  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const { loading, setLoading, error, connectOauth, connectBrowser, user, setError } = useContext(UserContext);
  
  const handleClose = () => {
    setError(null)
  }

  const handleConnect = async (type) =>  {
    resetUserLS();
    await connectOauth(type);
  }

  useEffect(()=>{
    try{
      setLoading(loading)
    }catch(error){
      console.log("debug error::",error);
    }
  },[loading])

  useEffect(()=>{
    if(!loading && !error && user && user?.wallet && user?.provider){
      navigate('/dashboard');
    }
  },[loading,error,user])

  useEffect(()=>{
    setLoading(false);
  },[setLoading])

  return (
    <div className={loginStyles.loginContainer} style={{ background: "url(/BBVA-glowy-gradient.svg)" }}>
      <LoginCardWrapper>
        {/* CARD HEADING */}
        <img src="/persea-logo-black.svg" alt="pesea logo" />
        {/* CARD BODY */}
        <div className={loginStyles.cardBody}>
          <p className={loginStyles.cardParagraph}>{t("login")}</p>
          {loading &&
            <div className={loginStyles.loadingBox}>
              <CircularProgress size="25px" sx={{ color: '#fff' }} />
            </div>
          }
          <div className={styles.socialButtons} >
            <SocialLoginButton label={t("login_google")} icon="/google-color.svg" onClick={() => handleConnect("GOOGLE")} />
            {/* <SocialLoginButton label={t("login_tiktok")} icon="/tiktok-icon-dark.svg"  onClick={() => handleConnect("TIKTOK")} /> */}
            <SocialLoginButton label={t("login_twitter")} icon="/logo_x-black.svg" onClick={() => handleConnect("TWITTER")} />
            {/* <SocialLoginButton label={t("login_discord")} icon="/discord.png" onClick={() => handleConnect("DISCORD")} /> */}
            <SocialLoginButton label={t("login_browser_wallet")} icon="/web3.png" onClick={() => connectBrowser()} />
          </div>

          <div className={styles.or}>{t("or")}</div>

          <div className={styles.loginEmailContent}>
            <EmailOption />
          </div>
        </div>
        {/* CARD FOOTER */}
        <Paragraphs text={t("intro_declarations")} />
      </LoginCardWrapper>
      <Snackbar
        open={Boolean(error)}
        onClose={handleClose}
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
      >
        <Alert severity="error" style={{ alignSelf: "center", margin: "auto" }}>{error}</Alert>
      </Snackbar>
    </div >
  );
}

const EmailOption = () => {
  const { t } = useTranslation('login');
  const { loading, connectOauthEmail } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);

  const handleMagicEmail = useCallback(async (e) => {
    e.preventDefault();
    if (loading || !isValidEmail) {
      return null;
    }
    resetUserLS();
    connectOauthEmail(email);
  }, [connectOauthEmail, loading, email, isValidEmail]);


  return (
    <div className={styles.loginEmailContent}>
    <form className={styles.loginEmailForm} onSubmit={handleMagicEmail}>
      <LoginInputField label="Email" id="newEmail" error={email && !isValidEmail} errorMessage={t("email_invalid")}>
        <input
          name="newEmail"
          id="newEmail"
          className={`${loginStyles.loginInput} ${email && !isValidEmail && loginStyles.loginInputError}`}
          placeholder="example@domain.com"
          disabled={loading}
          value={email}
          onChange={(e) => {
            const { value } = e.target;
            setEmail(value);
            setIsValidEmail(validateEmail(value));
          }}
        />
      </LoginInputField>
      <button
        className={`${loginStyles.customButton} ${loginStyles.emailButton}`}
        disabled={!isValidEmail}
        onClick={handleMagicEmail}
      >
        <Email htmlColor="inherit" />
        {t("login_email_button")}
      </button>
    </form>
    <div className={loginStyles.cardParagraph}>
      {t("messages.register_label_1")} <br /> <Link to={"/signin"}>{t("messages.register_label_2")}</Link>
    </div>
  </div>
  );
}

export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export default Login;