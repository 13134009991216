import axios from "axios";
import { buildUrlParams } from "utils/fetch";

axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;
const PATH = "collection/default-nft-attrs"
const URL = `${process.env.REACT_APP_API}/${PATH}`;


export const findDefaultAttrs = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {limit: 1000, page: 0, order: "created"}
}) => {
  const response = await axios.get(`${URL}?domain=${domain}${buildUrlParams(params)}`);
  return response.data;
};