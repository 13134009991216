export function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export function getFormattedDateMMDDYYYYhhmmaa(date) {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const formattedTime = formatAMPM(date);
  return `${month}/${day}/${year} ${formattedTime}`.toUpperCase();
}

export function getFormattedDateISO8601(date) {
  const isoDate = date.toISOString();
  const year = isoDate.slice(0, 4);
  const month = isoDate.slice(5, 7);
  const day = isoDate.slice(8, 10);
  const time = isoDate.slice(11, 23);
  return `${year}-${month}-${day} ${time}`;
}