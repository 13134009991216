import { Box, Button, Card, Divider, Stack, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { REWARD_TYPES } from 'utils/constants';
import { maxLinesProperties } from 'utils/css';

export default function RewardCard({ reward, options=[], sx={}, ...props }) {
  const theme = useTheme();

  const curatedReward = {
    ...reward,
    tokenIds: reward.tokenIds.join(", ")
  };

  const getRewardTypeTitle = () => {
    switch (curatedReward.rewardType) {
      case REWARD_TYPES.ERC20:
        return "ERC20";
      case REWARD_TYPES.ERC721:
        return "ERC721";
      case REWARD_TYPES.PHYSICAL:
        return "Physical object";
      default:
        return "Unknown";
    }
  };

  return (
    <Card sx={{...sx}}>
      <Box sx={{ display: 'flex' }} { ...props }>
        <Box sx={{ flex: "2", display: 'flex', flexDirection: 'column', p: theme.spacing(2.5) }}>
          <Typography
            component="div"
            variant="h6"
            lineHeight={1}
            mb={1}
            sx={maxLinesProperties(1)}
          >
            {curatedReward.metadata.name}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            component="div"
            sx={maxLinesProperties(1)}
          >
            <strong>Reward type: </strong> {getRewardTypeTitle()}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            component="div"
            sx={maxLinesProperties(1)}
          >
            <strong>External ID: </strong> {(curatedReward.rewardType === REWARD_TYPES.PHYSICAL) ? curatedReward.externalId : "N/A"}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            component="div"
            sx={maxLinesProperties(1)}
          >
            <strong>Contract address: </strong>{(curatedReward.rewardType !== REWARD_TYPES.PHYSICAL) ? curatedReward.contractAddress : "N/A"}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            component="div"
            sx={maxLinesProperties(1)}
          >
            <strong>Tokens: </strong>{(curatedReward.rewardType === REWARD_TYPES.ERC721) ? curatedReward.tokenIds : "N/A"}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            component="div"
            sx={maxLinesProperties(1)}
          >
            <strong>Description: </strong>{curatedReward.metadata.description}
          </Typography>
        </Box>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        display={ options.length === 0 ? "none" : "flex" }
        sx={{ backgroundColor: theme.palette.primary.light, padding: theme.spacing(1) }}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {options.map((option, index) => {
          return (
            <Button key={index} onClick={() => option.action(curatedReward)} sx={{py: 0, color: theme.palette.primary.contrastText}}>
              <Typography component="div" variant="caption">
                {option.name}
              </Typography>
            </Button>
          );
        })}
      </Stack>
    </Card>
  );
}
