import React, { useCallback, useContext, useEffect } from 'react';
import styles from './ViewCollections.module.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { BreadCrumbsSaveData } from 'components/BreadCrumbs/BreadCrumbs';
import CardMedia from '@mui/material/CardMedia';
import { STATUS_COLLECTION } from 'utils/collectionStatus';
import { checkDateShort } from 'utils/date';
import UserContext from 'context/UserContext';
import { useFetch } from 'hooks/useFetch';
import { getAllCollections } from 'services/collection/collections';
import { lockBulk, unhideBulk, unlockBulk, update } from 'services/collections/collection';
import { getActionsByRoleCode } from 'utils/rols';
import { useSpinnerContext } from 'context/SpinnerContext';
import DynamicTable from 'components/DynamicTable/DynamicTable';
import { findThumbnail } from 'utils/thumbnail';
import { MINT_STRATEGIES } from 'utils/constants';

export default function ViewCollections() {
  
  const { t } = useTranslation("collections");
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { data: collections, loading, refresh } = useFetch(getAllCollections,{
    params: { limit: 100, page: 0, order: 'created' }
  });
  const { handleChangeLoading ,handleChangeMessage, handleChangeError } = useSpinnerContext();

  const handleGo = useCallback((path) => () => {
    navigate(`/dashboard/collections/${path}`);
  },[navigate]);
  
  const statusOptions = {
    [STATUS_COLLECTION.PENDING_APPROVE]: {
      status: STATUS_COLLECTION.PENDING_APPROVE,
      label: t("table.status.pending_approve"),
      color: "statusGray"
    },
    [STATUS_COLLECTION.APPROVED]: {
      status: STATUS_COLLECTION.APPROVED,
      label: t("table.status.approved"),
      color: "statusGreen"
    },
    [STATUS_COLLECTION.LOADING_NFTs]: {
      status: STATUS_COLLECTION.LOADING_NFTs,
      label: t("table.status.loading_nfts"),
      color: "statusBlue"
    },
    [STATUS_COLLECTION.LOADED_NFTs]: {
      status: STATUS_COLLECTION.LOADED_NFTs,
      label: t("table.status.loaded_nfts"),
      color: "statusGreen"
    },
    [STATUS_COLLECTION.PENDING_APPROVE_NFTs]: {
      status: STATUS_COLLECTION.PENDING_APPROVE_NFTs,
      label: t("table.status.pending_approve_nfts"),
      color: "statusGray"
    },
    [STATUS_COLLECTION.APPROVED_NFTs]: {
      status: STATUS_COLLECTION.APPROVED_NFTs,
      label: t("table.status.approved_nfts"),
      color: "statusGreen"
    },
    [STATUS_COLLECTION.REJECTED_NFTs]: {
      status: STATUS_COLLECTION.REJECTED_NFTs,
      label: t("table.status.rejected_nfts"),
      color: "statusRed"
    },
    [STATUS_COLLECTION.REJECTED]: {
      status: STATUS_COLLECTION.REJECTED,
      label: t("table.status.rejected"),
      color: "statusRed"
    },
    [STATUS_COLLECTION.IN_PRODUCTION]: {
      status: STATUS_COLLECTION.IN_PRODUCTION,
      label: t("table.status.in_production"),
      color: "statusYellow"
    },
    [STATUS_COLLECTION.HIDE_COLLECTION]: {
      status: STATUS_COLLECTION.HIDE_COLLECTION,
      label: t("table.status.hide_collection"),
      color: "statusGray"
    },
    [STATUS_COLLECTION.LOCK_COLLECTION]: {
      status: STATUS_COLLECTION.LOCK_COLLECTION,
      label: t("table.status.locked"),
      color: "statusGray"
    }
  }

  const configCells = {
    blockchain:{  
      render: (item) => {
        return (
          <TableCell>
            <Box
              sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              gap:'0.7rem',
              }}
            >
              <CardMedia
                component={item?.is_video ? 'video' : 'img'}
                key={item?.chainid}
                sx={{ 
                  width: 30, 
                  height: 30,
                  objectFit: 'contain'
                }}
                src={item?.icon}
              />
            </Box>
          </TableCell>
        )
      }
    },
    status:{  
      render: (status) => {
        return (
          <TableCell>
            <Chip 
              label={statusOptions[status]?.label || 'Empty'}
              color={statusOptions[status]?.color || 'statusGray'}
            />
          </TableCell>
        )
      }
    },
    nft_price:{
      render: (price) => {
        return (
          <TableCell>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap:'0.7rem',
              }}
            >
              {Number(price) === 0 ? `${t("alerts.not_for_sale")}` : `${price}`}
            </Box>
          </TableCell>
        )
      }
    },
  }

  const configSelected = {
    defineId: 'name',
    // onSelect: ({ row, arraySelectedById }) => {
    //   console.log("debug item row::",row);
    //   console.log("debug arraySelectedById::",arraySelectedById)
    // },
    options:[
      {
        id:'approve',
        label: t("options.approve"),
        color: 'success',
        variant: 'contained',
        tooltip:'Click to approve',
        onClick: async (item) => {
          try {
            handleChangeLoading(true);
            handleChangeMessage(t("message.approving"));
            let collections = []
            for (let collection of item) {
              collection.status = 1;
              collections.push(collection)
            }
            await update({ collections : collections});
            handleChangeMessage(t("message.successful_approval"));
            refresh();
            return true;
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,"approveCollection") && item?.status === 0);
        }      
      },
      {
        id:'rejected',
        label: t("options.reject"),
        color: 'error',
        variant: 'contained',
        tooltip:'Click to rejected',
        onClick: async(item) => {
          try {
            handleChangeLoading(true);
            handleChangeMessage(t("message.rejecting"));
            let collections = []
            for (let collection of item) {
              collection.status = 7
              collections.push(collection)
            }
            await update({ collections : collections});
            handleChangeMessage(t("message.rejected"));
            refresh();
            return true;
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,"rejectedCollection") && item?.status === 0);
        }      
      },
      {
        id:'hidden',
        label: t("options.hidden"),
        color: 'warning',
        variant: 'contained',
        tooltip:'Click to rejected',
        onClick: async(item) => {
          try {
            handleChangeLoading(true);
            let collections = []
            for (let collection of item) {
              collection.status = STATUS_COLLECTION.HIDE_COLLECTION
              collections.push(collection)
            }
            await update({ collections : collections});
            refresh();
            return true;
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,"hiddenCollection") && item?.status > 0 && item?.status < 12);
        }      
      },
      {
        id:'unhidden',
        label: t("options.unhide"),
        color: 'warning',
        variant: 'contained',
        tooltip:'Click show again',
        onClick: async(item) => {
          try {
            handleChangeLoading(true);
            const collection_keys = []
            for (const collection of item) {
              collection_keys.push(collection.collection_key)
            }
            await unhideBulk({ collection_keys });
            refresh();
            return true;
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,"hiddenCollection") && item?.status === STATUS_COLLECTION.HIDE_COLLECTION);
        }      
      },
      {
        id:'lock',
        label: t("options.lock"),
        color: 'error',
        variant: 'contained',
        tooltip:'Click show again',
        onClick: async(item) => {
          try {
            handleChangeLoading(true);
            const collection_keys = []
            for (const collection of item) {
              collection_keys.push(collection.collection_key)
            }
            await lockBulk({ collection_keys });
            refresh();
            return true;
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (
            getActionsByRoleCode(user?.role,"lock")
            && item?.status >= STATUS_COLLECTION.APPROVED
            && item?.status < STATUS_COLLECTION.LOCK_COLLECTION
            && item?.mint_strategy !== MINT_STRATEGIES.UNKNOWN
          );
        }
      },
      {
        id:'unlock',
        label: t("options.unlock"),
        color: 'error',
        variant: 'contained',
        tooltip:'Click show again',
        onClick: async(item) => {
          try {
            handleChangeLoading(true);
            const collection_keys = []
            for (const collection of item) {
              collection_keys.push(collection.collection_key)
            }
            await unlockBulk({ collection_keys });
            refresh();
            return true;
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (
            getActionsByRoleCode(user?.role,"lock")
            && item?.status === STATUS_COLLECTION.LOCK_COLLECTION
          );
        }
      },
      {
        id:'delete',
        label: t("options.delete"),
        color: 'error',
        variant: 'contained',
        tooltip:'Click to delete',
        onClick: async(item) => {
          try {
            handleChangeLoading(true);
            let collections = []
            for (let collection of item) {
              collection.status = 12
              collections.push(collection)
            }
            await update({ collections : collections});
            //const res = await Promise.all(collections.map(element => deleteCollection(element?.collection_key)));
            //console.log("debug res::",res);
            handleChangeMessage(t("message.deleted"));
            refresh();
            return true;
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,"deleteCollection") && item?.status === 0);
        }      
      }
    ]
  }


  useEffect(()=>{
    handleChangeLoading(loading)
  },[loading])

  return (
    <section className={styles.contentSection} >
      <div className={styles.headerSection}>
        <div className={styles.subHeaderSection}>
          <Typography variant="body2" gutterBottom flex={1}>
            {t("description")}
          </Typography>
          <Button 
            variant="outlined" 
            color="info"
            onClick={handleGo(`create`)}
          >
            +{t("options.create_collection")}
          </Button>
          <Button 
            variant="outlined" 
            color="info"
            sx={{display:'none'}}
          >
          + {t("options.import_collection")}
          </Button>
        </div>
      </div>
      <div
        id='collections-view-collections-table-wapper'
        className={styles.workSpace}
      >
        <DynamicTable
          title={''}
          activeSearchBar={true}
          activeModeView={false}
          activeFilter={true}
          data={collections}
          loading={loading}
          preparedDataConfig={(item)=>{
            return {
              thumb_url: (item?.thumbnails) ? findThumbnail(item, 650,500) : item?.thumb_url,
              name: item.name,
              blockchain: item.blockchain,
              nfts: item.collection_of,
              nfts_minted: item.collection_of,
              nft_price: item?.price,
              reveal_date: checkDateShort(item?.reveal?.reveal_date),
              status: item.status
            }
          }}
          setConfigParams={{
            configCells,
            configSelected,
            allowedKeys:["thumb_url", "name", "blockchain", "nfts", "nfts_minted","nft_price","reveal_date","status"],
            textHeaders:{
              thumb_url:' '
            },
            onClick:(item)=>{
              const value = {
                name: item.name,
                collection_key: item.collection_key
              }
              localStorage.removeItem('selected')
              BreadCrumbsSaveData("collectionKey",value);
              handleGo(`${item.collection_key}`)()
            },
            emptyMessage: <EmpyCollectionsTableMessage />
          }}
        />
      </div>
    </section>
  );
}

const EmpyCollectionsTableMessage = () => {
  const { t } = useTranslation("collections");

  return(
    <span id="collections-view-empty-message">{t("alerts.empty_collections_table")}</span>
  )
}