import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import { useState, useEffect } from "react";
import { getDisplayName } from "./common";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { createPortal } from "react-dom";

export default function WithLoadingBackdropAndErrorSnack(WrappedComponent) {
  const Component = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(true);

    useEffect(()=>{
      if(error){
        setOpen(true);
      }
    },[error]);
    console.log("WrappedComponent", WrappedComponent, setIsLoading);
    return (
      <>
        <WrappedComponent {...props} setError={setError} setIsLoading={setIsLoading} isLoading={isLoading} />
        {createPortal(
          <Backdrop
            data-testid="global-loading-backdrop"
            open={isLoading}
          >
            <CircularProgress />
          </Backdrop>,
          document.body
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={error !== null}
          // autoHideDuration={6000}
          onClose={() => setError(null)}
        >
          <Alert 
              severity="error" 
              variant='filled' 
              onClose={() => setError(null)}
              sx={{minWidth:'200px'}}
              iconMapping={{
                error: <Box onClick={() => setOpen(!open)}>
                  {open ? <ExpandLessIcon fontSize="inherit" sx={{cursor:'pointer'}} /> : <ErrorOutlineIcon fontSize="inherit" sx={{cursor:'pointer'}} />}
                </Box>
              }}
            >
              <Typography variant="body1" gutterBottom>
                Error
              </Typography>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Typography variant="body2" gutterBottom>
                  {error}
                </Typography>
              </Collapse>
            </Alert>
          </Snackbar>
      </>
    );
  }

  Component.displayName = `WithLoadingBackdropAndErrorSnack(${getDisplayName(WrappedComponent)})`;
  return Component;
}