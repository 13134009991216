import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useParams } from 'react-router-dom';
import { create } from 'services/rewards/rewards';
import { uploadFile } from 'services/files';
import { handleErrors } from 'utils/messages';
import useBlockchains from 'hooks/blockchains.hook';
import { useRewardById } from 'hooks/useReward';
import WithLoadingBackdropAndErrorSnack from 'HOCS/WithLoadingBackdropAndErrorSnack';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';



const TYPE_REWARD = [
  {label: "PHYSICAL", value: "PHYSICAL"},
  {label: "ERC20", value: "ERC20"},
  {label: "ERC721", value: "ERC721"},
]

const CreateReward = ({ isLoading, setIsLoading, setError }) => {

  const navigate = useNavigate();
  const { t } = useTranslation('rewards');
  const [typeReward, setTypeReward] = useState(TYPE_REWARD[0].value);
  const { rewardId } = useParams();
  const isEditMode = !!rewardId;
  const [ message, setMessage ] = useState("");
  const { blockchains } = useBlockchains();
  const { reward } = useRewardById(rewardId);
  const [activeStep, setActiveStep] = React.useState(0);

  const [ values, setValues ] = useState("");
  const [ form, setForm ] = useState("");

  


  const valuesTypeForm = {
    "PHYSICAL":{
      name: 'string_short',
      description: 'string_long',
      thumbnail: 'multimedia',
    },
    "ERC20":{
      blockchainName: 'select',
      name: 'string_short',
      description: 'string_long',
      thumbnail: 'multimedia',
      contract_address: 'web3Address',
    },
    "ERC721":{
      blockchainName: 'select',
      name: 'string_short',
      description: 'string_long',
      thumbnail: 'multimedia',
      contract_address: 'web3Address',
    }
  }
 
  const goToRewardsPage = () => {
    navigate("/dashboard/rewards");
  }
  const handleNext = (values, form) => {
    setValues(values);
    setForm(form);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try{
      if(isEditMode){
        console.log("debug edit mode values:",values);
      } else {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setMessage("uploading image...")
        const hashImage = await uploadFile(values.thumbnail);
        const metadata = {
          name: values.name,
          description: values.description,
          thumb_url: hashImage,
        }
        setMessage("uploading metadata...")
        const fileMetadata = new File([JSON.stringify(metadata)], "metadata", {type: "application/json"});
        const hashMetadata = await uploadFile(fileMetadata);
        const body = {
          reward_type: typeReward,
          name: values.name,
          description: values.description,
          thumb_url: hashImage,
          external_id: 0,
          contract_address: values.contract_address || "",
          metadata_url: hashMetadata,
          blockchain_name: values?.blockchainName || process.env.REACT_APP_NAME_NETWORK,
        }
        setMessage("Save data...")
        await create(body)
        setMessage("End.")
        goToRewardsPage()
      }
    } catch(error){
      console.log(error);
      setError(error);
      setMessage(handleErrors(error)?.message || "Error")
    } finally {
      form.setSubmitting(false);
      setIsLoading(false);

      let temp = 22;
      if(temp === -22){
        goToRewardsPage();
      }
    }    
  }

  const handleChange = (event) => {
    setTypeReward(event.target.value);
  }
  const getValuesIfEditMode = () => {
    if(isEditMode && reward){
      if(typeReward === "PHYSICAL"){
        return {
          name: reward?.name ||'',
          description: reward?.description || '',
          thumbnail: reward?.thumb_url || '',
        }
      }else if(typeReward === "ERC20" || typeReward === "ERC721"){
        return {
          blockchainName: {
            value: "PHYSICAL",
            options: TYPE_REWARD
          },
          name: reward?.name ||'',
          description: reward?.description || '',
          thumbnail: reward?.thumb_url || '',
          contract_address: reward?.contract_address || '',
          metadata_url: reward?.metadata_url || ''
        }
      }
    }

    if(typeReward === "PHYSICAL"){
      return {};
    }else if(typeReward === "ERC20" || typeReward === "ERC721"){
      return {
        blockchainName: {
          value: "ETHEREUM",
          options: blockchains
        }
      };
    }

    return null;
  }

  const getDefaultValues = () => {
    if(typeReward === "PHYSICAL"){
      return {
        name: values?.name ||'',
        description: values?.description || '',
        thumbnail: values?.thumbnail || '',
      }
    }else if(typeReward === "ERC20" || typeReward === "ERC721"){
      return {
        blockchainName: {
          value: "ETHEREUM",
          options: blockchains
        },
        name: values?.name ||'',
        description: values?.description || '',
        thumbnail: values?.thumbnail || '',
        contract_address: values?.contract_address || '',
        metadata_url: values?.metadata_url || ''
      }
    }
  }

  return (
    <div>
        <Typography variant="body2" gutterBottom flex={1}>
          {t("create_view.description")}
        </Typography>
        <Box >
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>{t("steps.reward_settings")}</StepLabel>
              <StepContent>
                <FormControl
                  sx={{
                    width:'calc(50% - 0.5rem)',
                    margin: '0.5rem 0px',
                    gap: '0.5rem'
                  }}
                >
                  <FormLabel htmlFor={"typeBenefit"}>
                    {t("type_reward.title")}
                  </FormLabel>
                  <Select
                    onChange={handleChange}
                    value={typeReward}
                    name={"typeBenefit"}
                    sx={{
                      backgroundColor: "#fff"
                    }}
                  >
                    <MenuItem disabled value="">
                      <em> {t("type_reward.title")}</em>
                    </MenuItem>
                    {TYPE_REWARD?.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <NewFormDynamic
                  enableReinitialize={isEditMode?true:false}
                  initValues={valuesTypeForm[typeReward]}
                  initStateWithValues={isEditMode?getValuesIfEditMode():getDefaultValues()}
                  loading={isLoading}
                  onSubmit={handleNext}
                  handleCancel={() => goToRewardsPage()}
                  textButtons={{
                    submit:t("steps.continue"),
                    cancel:t("create_view.options.cancel")
                  }}
                  textLabels={{
                    name:t("form.name"),
                    description:t("form.description"),
                    thumbnail:t("form.thumbnail"),
                    contract_address:t("form.contract_address"),
                    blockchainName:t("form.blockchainName")
                  }}
                  textPlaceholders={{
                    name:t("form.name"),
                    description:t("form.description"),
                    thumbnail:t("form.thumbnail"),
                    contract_address:t("form.contract_address"),
                    blockchainName:t("form.blockchainName")
                  }}
                />
                {
                  message
                }
              </StepContent>
            </Step>
            <Step>
            <StepLabel>{t("steps.confirm")}</StepLabel>
            <StepContent>
              <Typography>{t("steps.confirm_reward_settings")}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {t("steps.confirm")}
                  </Button>
                  <Button
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {t("steps.back")}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          </Stepper>    
        </Box>
    </div>
  );
}

export default WithLoadingBackdropAndErrorSnack(CreateReward);