import axios from "axios"
import { buildUrlParams } from "utils/fetch";
axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;
const path = "ssh-public-key"
const URL = `${process.env.REACT_APP_API}/${path}`;

export const getApiKeys = async ({
    domain = process.env.REACT_APP_DOMAIN,
    params = {
      limit: 100,
      page: 0,
      order: "created"
    }
  }) => {
    const result = axios.get(
      `${URL}?domain=${domain}${buildUrlParams(params)}`
    );
    const response = await result;
    console.log("debug response de api: ", JSON.stringify(response.data))
    return response.data;
  };

export const create = async (body) => {
    console.log("debug create body: ", body);
    return await axios.post(URL, body,
        {
            headers: {
                'X-API-Key': localStorage.getItem("userApiKey")

            },
            params: {
                domain: process.env.REACT_APP_DOMAIN,
            }
        });
}

export const update = async (rewards) => {
  const domain = process.env.REACT_APP_DOMAIN;
  const url = `${process.env.REACT_APP_API}/${path}?domain=${domain}`
  const resonse = await axios.put(url, rewards);
  return resonse.data;
}

export const deleteApikey = async (apikey) => {
	const response =  await axios.delete(URL, {
		headers: {
			'X-API-Key': localStorage.getItem("userApiKey")
		},
		params :{
			domain: process.env.REACT_APP_DOMAIN,
			...apikey
		}
	});
	console.log("debug respose.data::", response.data)
	return await response.data;
}
