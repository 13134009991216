import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './ComponentGrid.module.css';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SkeletonCards from 'components/Skeletons/Cards';
import { applyFilter, availableFiltersDic, getComparator, stableSort } from 'components/DynamicTable/utils/search';
import { extractKeys } from 'components/DynamicTable/utils/extractKeys';
import { formatFilterColumns } from 'components/DynamicTable/utils/formatData';
import { isURL } from 'components/DynamicTable/utils/url';
import CardMedia from '@mui/material/CardMedia';
import PopUpFilter from 'components/PopUpFilter/PopUpFilter';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const LoadingData = () => {
  return (
    <div>
      <SkeletonCards nRows={2} nColumns={4} />
    </div>
  )
}

const EmptyData = ({emptyMessage,t}) => {
  return (
    <center data-testid="table-custom-empty">
      <br/>
      <br/>
      <Typography variant="h6">
        { emptyMessage || t("no_results_found") }
      </Typography>
      <br/>
    </center>
  )
}

const NoMatchData = ({noMatchDataMessage,t}) => {
  return (
    <center data-testid="table-custom-NoMatchData">
      <br/>
      <br/>
      <Typography variant="h6">
        { noMatchDataMessage || t("no_match_data") }
      </Typography>
      <br/>
    </center>
  )
}

const Body = ({children}) => {
  return (
    <div className={styles.bodyCard}>
      {children}
    </div>
  )
}

const GridMaterial = ({ 
  data,
  columns,
  configOptions,
  renderCard
}) => {
  const { xs, sm, md, lg, xl } = columns;
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} columns={12}>
        {data?.map((item, index) => (
          <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} key={index}>
            {
              renderCard ? renderCard(item):
              <CardDefault item={item} configOptions={configOptions} />
            }
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

const CardDefault = ({ keys, item, configOptions  }) => {

  return (
    <Fragment>
      {
        keys?.map((key) => {
          const value = item[key];
          const isImageURL = isURL(value); 
          return (
            <Fragment key={key}>
              {isImageURL ?
              <CardMedia
                component={"img"}
                alt={value}
                src={value}
                className={styles.thumbnail}
              />:
              <Body>
                <Typography variant="body2" gutterBottom
                  sx={{
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    boxSizing: 'content-box',
                  }}
                >
                  {value+''}
                </Typography>
              </Body>}
            </Fragment>
          )
        })
      }
      <div className={styles.options}>
        {configOptions?.map((option) => {
          const  label = option?.label || '';
          return (
            <Button key={option.id} color="info" onClick={option?.onClick} >
            {label+''}
            </Button>
          );
          })}
      </div>
    </Fragment>
  )
};


const DynamicGrid = ({ 
  item, 
  configOptions, 
  allowedKeys, 
  onClick
}) => {

  const dataGrid = allowedKeys?.length > 0 ? allowedKeys : Object.keys(item);
  const isOnClick = onClick && typeof onClick === 'function' ? true : false;

  const handleClick = (item) => () => {
    if(isOnClick){
      onClick(item);
    }
  }
  
  return (
    <Card
      className={styles.card}
      onClick={handleClick(item)}
      sx={{
        position: 'relative',
        [`&:hover .buttonFilter`]: {
          opacity: 1
        }
      }}
    >
      <CardDefault 
        keys={dataGrid} 
        item={item}
        configOptions={configOptions}
        onClick={onClick}
      />
    </Card>
  )
};

const BodyCards = ({
  data,
  configOptions,
  renderCard,
  allowedKeys,
  onClick
})=>{
  return (
    <div className={styles.contentGrid}>
      { data?.map((item,index) => (
        renderCard ? 
          renderCard(item) :
          <DynamicGrid 
            key={item.id || index} 
            item={item} 
            configOptions={configOptions}
            allowedKeys={allowedKeys}
            onClick={onClick}
          />
      ))}
    </div>
  )
}

const ComponentGrid = ({
  data, 
  allowedKeys,
  columnsObject,
  renderCard,
  filter,
  setFilter,
  activeFilter,
  onClick,
  configOptions,
  loading,
  emptyMessage,
  noMatchDataMessage,
  isEmpty
}) => {

  const { t } = useTranslation('tables');
  const [ order, setOrder ] = useState("asc");
  const [ orderBy, setOrderBy ] = useState(t(`table_dynamic.name`));
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ID_MENU_FILTER = "menuFilter";
  const matchesFilter = applyFilter(data,filter);
  const availableFilters = availableFiltersDic(t);
  const columnsHeader = extractKeys(allowedKeys,data);
  const selectColumns = formatFilterColumns(t,columnsHeader);

  const dataGrid = React.useMemo(() => {
    return matchesFilter || data || []
  }, [data,matchesFilter]);

  const visibleGrids = React.useMemo(
    () => {
      return stableSort(dataGrid, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    }, [order, dataGrid, orderBy, page, rowsPerPage]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpenMenuFilter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  },[setAnchorEl]);



  if(loading){ return <LoadingData /> }
  if(!loading && isEmpty){ return (<EmptyData emptyMessage={emptyMessage} t={t} />); }
  if(!loading && visibleGrids?.length === 0 ){ return (<NoMatchData noMatchDataMessage={noMatchDataMessage} t={t} />); }

  return (
    <Fragment>
      <Box sx={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'flex-start',padding:'0px 5px'}}>
        {activeFilter &&
        <Box display={"flex"} alignItems={"center"} height={"100%"} >
        <Tooltip title="Filters">
          <IconButton
            onClick={handleClickOpenMenuFilter}
            size="small"
            aria-controls={open ? ID_MENU_FILTER : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            data-testid={ID_MENU_FILTER}
            sx={{
              background: 'rgba(0,0,0,0.5)',
              color: '#fff',
              "&:hover":{
                background: 'rgba(255,255,255,0.5)',
                color: '#000',
                outline: '1px solid rgba(0,0,0,0.5)'
              }
            }}
          >
            <FilterListIcon fontSize="30px" />
          </IconButton>
        </Tooltip>
        </Box>}
      </Box>
      <TablePagination
        labelRowsPerPage={t('table_dynamic.rows_per_page')}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={dataGrid.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {!columnsObject ?
        <BodyCards
          data={visibleGrids}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          selectColumns={selectColumns}
          configOptions={configOptions}
          renderCard={renderCard}
          allowedKeys={allowedKeys}
          onClick={onClick}
        />
        :
        <GridMaterial 
          data={visibleGrids} 
          columns={columnsObject}
          configOptions={configOptions}
          renderCard={renderCard}
        />
      }
      <TablePagination
        labelRowsPerPage={t('table_dynamic.rows_per_page')}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={dataGrid.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <PopUpFilter
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        id={ID_MENU_FILTER}
        t={t}
        headers={selectColumns} 
        filter={filter}
        setFilter={setFilter}
        availableFilters={availableFilters}
      />
    </Fragment>
  );
};


ComponentGrid.defaultProps = {
  data: [],
  allowedKeys: [],
  configRows: null,
  onClick: null,
  configOptions: [],
  columnsObject: null
};

ComponentGrid.propTypes = {
  data: PropTypes.array,
  allowedKeys: PropTypes.array,
  configRows: PropTypes.object,
  onClick: PropTypes.any,
  configOptions: PropTypes.array,
  renderCard: PropTypes.node,
  columnsObject: PropTypes.any,
  loading: PropTypes.bool,
  emptyMessage: PropTypes.string
};


export default ComponentGrid;


/**
 * `GridCustom` Component
 *  <GridCustom
 *    data={RANDOM_USERS}
 *    configOptions={configOptions}
 *    onClick={()=>{alert("click row")}}
 *  />
 * 
 * Renders a dynamic table based on provided data and configuration.
 * 
 * 
 * @Data
 * @prop {Array<Object>} defineRenderByKeys - define to params to render
 * defineRenderByKeys =[
 * {
 * name:'name',
 * description
 * }
 * ]
 * 
 * @component
 * @prop {Array<Object>} data - Data to be displayed in the table.
 *   @example 
 *   // Example usage:
 *   data = [
 *     { name: 'name1', age: 20 },
 *     { name: 'name2', age: 21 },
 *     { name: 'name3', age: 22 }
 *   ]
 * 
 * 
 * @prop {Array<Object>} configOptions - Configuration object for customizing rows' options.
 *  @example
 *  // Example usage:
 *  configOptions = [
 *    {
 *      id: 'view',
 *      label: 'View',
 *      onClick: () =>{ alert('Edit')}
 *    },
 *    {
 *      id: 'edit',
 *      label: 'Edit',
 *      onClick: () =>{ alert('Edit')}
 *    }
 *  ]
 * 
 *  * @prop {Object} columnsObject - columnsObject for customizing columns display.
 *  @example
 *  // Example usage:
 *  columnsObject = {
 *    xs: 1,
 *    sm: 6,
 *    md: 6,
 *    lg: 3,
 *    xl: 3
 *  }
 * 
 * 
 * * @prop {Function} renderCard - renderCard for customizing card display.
 * @example
 * // Example usage:
 * renderCard = {ComponentToRender}
 * 
 * 
 *  
 * 
 * 
 */