import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API
    //socketPath: process.env.REACT_APP_SOCKET_PATH
});

export const socket = axios.create({
    socketPath: ''
});
