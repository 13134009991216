import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

const style = {
  border: '1px solid var(--color-prolinePrimary)',
  borderRadius: '8px',
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  minHeight: '150px',
  position: 'relative',
  paddingBottom: '40px'
}

const CardPost = ({item={}}) => {
  const { title, content, subtitle, thumbnail, banner } = item || {};
  const [bannerSrc, setBannerSrc] = useState('');
  const [thumbnailSrc, setThumbnailSrc] = useState('');

  useEffect(() => {
    if (typeof thumbnail === 'string') {
      setThumbnailSrc(thumbnail);
    } else if (thumbnail instanceof File) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailSrc(reader.result);
      };
      reader.readAsDataURL(thumbnail);
    }
  }, [thumbnail]);

  useEffect(() => {
    if (typeof banner === 'string') {
      setThumbnailSrc(banner);
    } else if (banner instanceof File) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerSrc(reader.result);
      };
      reader.readAsDataURL(banner);
    }
  }, [banner]);


  return (
    <Card sx={style}>
      <Box sx={{display:'flex',width:'100%',flex:'0 1', alignItems:'center', gap:'1em'}}>
        {thumbnailSrc && <Avatar alt='avatar' src={thumbnailSrc} />}
        <Typography sx={{fontSize:'1.2em'}}>{title}</Typography>
      </Box>
      <Divider />
      <Typography variant='small'>
        {subtitle}
      </Typography>
      <Typography dangerouslySetInnerHTML={{ __html: content }} />
      {bannerSrc && <CardMedia
        component="img"
        src={bannerSrc}
        alt={`thumbnail-${title}`}
        sx={{
          aspectRatio: '16 / 9',
          objectFit: 'cover',
          borderRadius: '8px',
        }}
      />}
      <Typography sx={{width:'100%', textAlign:'right',position:'absolute',bottom:'10px',right:'10px'}}>
        <small>
          By persea
        </small>
      </Typography>
    </Card>
  );
}

CardPost.defaultProps = {
  item: {
    title: 'Title',
    subtitle: 'Subtitle',
    content: 'Description',
    thumbnail: '',
    banner: ''
  }
}

CardPost.propTypes = {
  item: PropTypes.object
}

export default CardPost;