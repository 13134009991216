import { useTranslation } from 'react-i18next';
import TableMessage from "components/EmptyTableMessage";
import RenderChipConfig from "components/TableTwoViews/helperComponents/RenderChipConfig";
import RenderCommonImageConfig from "components/TableTwoViews/helperComponents/RenderImageConfig";
import { useFetch } from "hooks/useFetch";
import { findAccountTargets } from "services/account_targets/accountTarget";
import CreateTargetButton from 'components/Buttons/CreateTargetButton';
import DynamicTable from 'components/DynamicTable/DynamicTable';

export default function TargetsTable({ fetchParams = {} }) {
  const { t } = useTranslation("targetsTable");
  const { data: targets, loading, error } = useFetch(findAccountTargets, {
    params: { limit: 1000, page: 0, order: 'created', ...fetchParams }
  });

  const configCells = {
    social_media: {
      render: (item) => <RenderCommonImageConfig url={item} />
    },
    type: {
      render: (item) => {
        const color = item === 'ACCOUNT' ? 'statusGreen' : 'statusBlue';
        return <RenderChipConfig label={item} color={color} />
      }
    },
  }

  const getTableMessage = () => {
    if (!loading && error) {
      return (
        <TableMessage
          message={t("messages.error_fetching")}
        />
      )
    }
    return (
      <TableMessage
        message={t("messages.empty")}
        actionButton={<CreateTargetButton />}
      />
    )
  }

  return (
    <div data-testid="targets-table">
      <DynamicTable
        title={t("title")}
        data={targets}
        loading={loading}
        activeSearchBar={true}
        activeModeView={false}
        activeFilter={true}
        preparedDataConfig={itemMapper}
        setConfigParams={{
          configCells,
          emptyMessage: getTableMessage()
        }}
      />
    </div>
  )
}

const itemMapper = (item) => {
  return {
    name: item.name,
    social_media: item.social_media.thumb_url,
    type: item.type,
  }
}
