import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';

const RenderButton = ({
  index,
  label,
  handleClick,
  tooltip,
  icon: Icon,
  conditions,
  color,
  variant,
  selectedItems,
  id
}) => {

  const [ isActive, setIsActive ] = useState(false);
  const [ hidden, setHidden ] = useState(false);

  useEffect(()=>{
    try{
      let allItemsMeetCondition = false;
      if (conditions && typeof conditions === 'function') {
        allItemsMeetCondition = selectedItems?.every(item => conditions(item));
        setIsActive(allItemsMeetCondition)
        setHidden(!allItemsMeetCondition)
      }
    } catch (e) {
      console.error("Error rendering button:", e);
    }
  },[selectedItems])

  
  if(hidden){
    return null
  }

  return (
    <Tooltip key={index} title={tooltip || ''}>
      <span> 
        <Button 
          onClick={handleClick}
          size="small"
          variant={variant || 'outlined'}
          color={color || 'default'}
          disabled={!isActive}
          data-testid={`custom-table-${id}-button`}
        >
          {Icon && <Icon />}
          {label || ''}
        </Button>
      </span>
    </Tooltip>
  );
}

const EnhancedTableToolbar = ({ numSelected, options, selectedItems, resetSelected }) => {

  if(Number(numSelected) === 0 || Number(numSelected) <= 0){
    return null
  }

  const handleClick = (onClick) => async()=> {
    if(onClick && typeof onClick === 'function'){
      const success = await onClick(selectedItems);
      if(success){
        resetSelected();
      }
    }
  }

  return (
    <Toolbar
      data-testid="toolbar-table-custom"
      sx={{
        width: '100%',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...( {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Typography
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {numSelected} selected
      </Typography>
      <Box sx={{ flex: '1' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '0.5rem'
        }}
      >
      {
          options?.map((option, index) => {
            const { label, onClick, tooltip, icon: Icon, conditions, color, variant, id } = option;
            return (
              <RenderButton
                key={option?.value || index}
                id={id}
                label={label || null}
                handleClick={handleClick(onClick)}
                tooltip={tooltip || null}
                icon={Icon || null}
                conditions={conditions || null}
                color={color || null}
                variant={variant || null}
                selectedItems={selectedItems}
              />
            )
          })
      }
      </Box>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  options: PropTypes.array,
  selectedItems: PropTypes.array,
  resetSelected: PropTypes.func
};

export default EnhancedTableToolbar;
