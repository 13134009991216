import { useState , useEffect } from 'react';
import { getAllBlockchainByDomain } from 'services/blockchain';


const useBlockchains = () => {
  const [ blockchains, setBlockchains ] = useState([]);
  const [ loadingBlockchains, setLoadingBlockchains ] = useState(true);
  const [ errorBlockchains, setErrorBlockchains ] = useState(null);

  const handleGetBlockchain = async() => {
    try{
      const res = await getAllBlockchainByDomain(process.env.REACT_APP_DOMAIN);
      const blockchains = res?.map((item)=>{
        return {
          ...item,
          label:item.name,
          value:item.name
        }
      })
      setBlockchains(blockchains||[]);
    }
    catch(err){
      setErrorBlockchains(err);
      console.log("debug err::",err);
    } finally {
      setLoadingBlockchains(false);
    }
  }

  useEffect(()=>{
    handleGetBlockchain();
  },[])

  return {
    blockchains,
    loadingBlockchains,
    errorBlockchains
  }
}

export default useBlockchains;