export const getColorComponentMui = ( color,theme ) => {
  if(!color) { return { color:'primary' } }

  const colors = Object.keys(theme.palette);
  const result = colors.includes(color);
  if(result) { return { color } }
  
  return {
    sx:{
      color: color,
    }
  }
}