import React from 'react';

const HtmlViewer = ({ htmlContent }) => {
  const renderContent = () => {
    return { __html: htmlContent };
  };
  return (
    <div style={{color:'inherit'}} dangerouslySetInnerHTML={renderContent()} />
  )
}

export default HtmlViewer;