import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const DynamicHeaderTable = ({ 
  idMenu,
  headers, 
  textHeaders,
  order, 
  orderBy, 
  onRequestSort, 
  t,
  activeSelectedAll,
  numSelected,
  rowCount,
  handleSelectAllClick,
  activeFilter,
  handleClickOpenMenuFilter,
  open,
  isEmpty,
  activeSort
}) => {

  const createSortHandler =  useCallback((property) => (event) => {
    if(activeSort){
      onRequestSort(event, property);
    }
  },[onRequestSort,activeSort]);

  const getHeaderLabel = useCallback((headCell) => {
    const label = 
    (textHeaders?.[String(headCell).toLowerCase()]) || t(`table_dynamic.${String(headCell).toLowerCase()}`);
    if(label.startsWith('table_dynamic.')) { return headCell; }
    return label;
  },[t,textHeaders]);


  return (
    <TableHead>
      <TableRow>
        {
          !isEmpty && activeSelectedAll && (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            </TableCell>
          )
        }
        {headers?.map((headCell,index) => {
          const label = getHeaderLabel(headCell);
          return (
            <TableCell
              key={label}
              align="left"
              sortDirection={orderBy === label ? order : false}
              data-testid={`table-header-${index}`}
              sx={{
                position: 'relative',
                [`&:hover .buttonFilter`]: {
                  opacity: 1
                }
              }}
            >
              <TableSortLabel
                active={activeSort && orderBy === headCell}
                direction={activeSort && orderBy === label ? order : 'asc'}
                onClick={createSortHandler(String(headCell).toLowerCase())}
                sx={{ fontWeight: 'bold' }}
                data-testid={`sort-label-${index}`}
                IconComponent={activeSort ? (order === 'desc' ? ArrowDropDownIcon: ArrowDropUpIcon ) : null}
              >
                {label}
              </TableSortLabel>
              {activeFilter &&
              <Box
                className={"buttonFilter"}
                component={"span"}
                sx={{position: 'absolute', opacity: 0,top:'0px',right:'5px',height:'100%' }}
              >
                <Box display={"flex"} alignItems={"center"} height={"100%"} >
                  <Tooltip title="Filters">
                    <IconButton
                      onClick={handleClickOpenMenuFilter}
                      size="small"
                      aria-controls={open ? idMenu : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      data-testid={idMenu}
                    >
                      <FilterListIcon fontSize="30px" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
};

DynamicHeaderTable.propTypes = {
  headers: PropTypes.array,
  textHeaders: PropTypes.object,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
  t: PropTypes.func,
  activeSelectedAll: PropTypes.bool,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  handleSelectAllClick: PropTypes.func,
  activeFilter: PropTypes.bool,
  handleClickOpenMenuFilter: PropTypes.func,
  open: PropTypes.bool,
  idMenu: PropTypes.string,
};

export default DynamicHeaderTable;
