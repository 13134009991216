import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Field } from 'formik'
import { capitalizeFirstLetter, stringToObjectField } from 'utils/strings';


const WrappedMaterialTextField = ({ field, form: { touched, errors }, ...props }) => {

  const getTouched = () => {
    const isTouched = stringToObjectField(field.name, touched);
    if (isTouched === null) return false;
    return isTouched;
  }

  const getError = () => {
    if (!getTouched()) return "";
    const error = stringToObjectField(field.name, errors);
    if (error === null) return "";
    return error;
  }

  return (
    <Checkbox  
      {...field}
      {...props}
      error={Boolean(getTouched()) && Boolean(getError()) ? true : undefined}
    />
  )
}

const InputCheckbox = ({ name, placeholder, label, loading }) => {

  return (
    <Fragment>
      <FormControlLabel 
        control={
        <Field
          type="checkbox"
          placeholder={placeholder ? placeholder : capitalizeFirstLetter(name)}
          name={name}
          disabled={loading}
          component={WrappedMaterialTextField}
        />
        } 
        label={label ? label : capitalizeFirstLetter(name)}
      />
    </Fragment>
  );
};

InputCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool
};
InputCheckbox.propTypes = {
  name: PropTypes.string,
  loading: PropTypes.bool
};

export default InputCheckbox;
