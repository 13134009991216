import React from 'react';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import { useTranslation } from 'react-i18next';

export default function InviteForm({ onSubmit, onCancel }) {
  const { t } = useTranslation('clients');

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    onSubmit({...values});
  }

  const handleCancel = ({ setSubmitting }) => {
    setSubmitting(false);
    onCancel();
  }

  const formFields = {
    email: 'email'
  };

  return (
    <div data-testid="invite-form">
      <NewFormDynamic
        initValues={formFields}
        onSubmit={handleSubmit}
        handleCancel={handleCancel}
        textButtons={{
          submit:t("options.accept"),
          cancel:t("options.cancel")
        }}
        textLabels={{
          email:t("form.email"),
        }}
        textPlaceholders={{
          email:t("form.email"),
        }}
      />
    </div>
  );
}


