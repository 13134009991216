import BlockchainResumeTable from "components/AutoManagedTables/BlockchainResumeTable";
import Stack from '@mui/material/Stack';
import RelayWallet from "./components/RelayWallet";

export default function GasStationView() {
  return (
    <Stack spacing={2}>
      <RelayWallet />
      <BlockchainResumeTable />
    </Stack>
  )
}