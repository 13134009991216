import { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Card, TableCell, CardMedia } from "@mui/material";
import WithLoadingBackdropAndErrorSnack from "HOCS/WithLoadingBackdropAndErrorSnack";
import { useNavigate, useParams } from "react-router-dom";
import { saveBenefit } from "services/benefits";
import { useTranslation } from 'react-i18next';
import NewFormDynamic from "components/NewFormDynamic/NewFormDynamic";
import { useReward } from "hooks/useReward";
import { shortenAddress } from "utils/blockchain";
import { useCollectionBenefit } from "hooks/collection.hook";
import { getCurrentBenefitsNonce } from "services/contracts/benefitsMethods";
import { findBenefits } from 'services/benefits';
import { find } from "services/campaigns/campaigns"

import UserContext from "context/UserContext";
import DynamicTable from "components/DynamicTable/DynamicTable";

export function CreateBenefits({ setIsLoading, setError }) {
  const navigate = useNavigate();
  const { t } = useTranslation('benefits');
  const BASE_TRANSLATION = "create_view";
  const { contractKey, cameFrom } = useParams();
  const {
    collectionBenefits,
    loading: loadingCollectionBenefits,
    error: errorCollecitonBenefits
  } = useCollectionBenefit({ contract_key: contractKey, errorMessage: t(`${BASE_TRANSLATION}.error_fetching_benefit_collection`) });
  const collectionBenefit = collectionBenefits ? collectionBenefits[0] : null;
  const [benefits, setBenefits] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setIsLoading(loadingCollectionBenefits);
    setError(errorCollecitonBenefits);
  }, [loadingCollectionBenefits, errorCollecitonBenefits]);

  const handleSubmitBenefit = (values) => {
    setBenefits([...benefits, values]);
  }

  const onDeleteBenefit = (indexes) => {
    const newBenefits = benefits.filter((_, i) => !indexes.includes(i));
    setBenefits(newBenefits);
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await saveBenefits();
      if (cameFrom.toUpperCase() === "CAMPAIGN") {
      const campaign = await find({
        params: {
          collection_key: collectionBenefit.collection_key,
          limit: 1,
          page: 0,
          order: 'id',
        }
      });
        navigate(`/dashboard/campaigns/${campaign.id}`);
      } else if (cameFrom.toUpperCase() === "COLLECTION") {
        navigate(`/dashboard/collections/${collectionBenefit.collection_key}`);
      }
    } catch (error) {
      console.log("error: ", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  const saveBenefits = async () => {
    try {
      let benefitType = await getLastNonce();
      console.log("benefitType: ", benefitType);
      for (const benefit of benefits) {
        benefitType++;
        await saveBenefit(
          contractKey,
          collectionBenefit.collection_key,
          benefitType,
          benefit.reward.id,
        );
      }
    } catch (error) {
      console.log("error: ", error);
      error.message = "We couldn't save your benefits. Please try again later."
      throw error;
    }
  }


  const getLastNonce = async () => {
    try {
      return await getCurrentBenefitsNonce(user.provider, contractKey);
    } catch (error) {
      console.log("error: ", error);
      if (error.message.includes("Out of Gas") || error.message.includes("is not a valid Ethereum address")) {
        const initParams = {
          contract_key: contractKey,
          limit: 100,
          page: 0,
          order: 'created',
        }
        const collectionBenefits = await findBenefits({ params: initParams});
        console.log("collectionBenefits:",collectionBenefits.length)
        return (collectionBenefits.length) + 1
      }
      error.message = "We couldn't access the blockchain. Please try again later.";
      throw error;
    }
  }

  return (
    <>
    <Card component="section" sx={{ padding: '1.5rem' }} >
      <Grid container columnSpacing={5}>
        <Grid item xs={12} md={6} lg={4}>
          <BenefitForm 
            onSubmit={handleSubmitBenefit}
            collectionBenefit={collectionBenefit}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Box sx={{ display: "flex",  justifyContent: "space-between", alignItems: "center", mb: 2 }}>
            <Typography variant="h6">{t(`${BASE_TRANSLATION}.title_preview`)}</Typography>
            <Button sx={{ mb: 2 }}
              data-testid="create-benefits-btn-accept-create"
              disabled={benefits.length === 0}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {t(`${BASE_TRANSLATION}.btn_accept_create`)}
            </Button>
          </Box>
          <BenefitsPreview benefits={benefits} onDelete={onDeleteBenefit} />
        </Grid>
      </Grid>
    </Card>
    </>
  )
}

export default WithLoadingBackdropAndErrorSnack(CreateBenefits)

const BenefitForm = WithLoadingBackdropAndErrorSnack(function BenefitForm({
  onSubmit,
  setError,
  setIsLoading,
  collectionBenefit, 
}) {
  const { t } = useTranslation('benefits');
  const BASE_TRANSLATION = "create_view.form";
  const {
    rewards,
    loading: loadingRewards,
    error: errorReward
  } = useReward({ errorMessage: t(`${BASE_TRANSLATION}.error_fetching_rewards`) });

  useEffect(() => {
    setIsLoading(loadingRewards);
    setError(errorReward);
  }, [loadingRewards, errorReward]);

  const handleSubmit = async (values, { resetForm }) => {
    const { reward: rewardId, default_amount: amount, start_date, end_date } = values;
    const reward = rewards.find((r) => r.id === parseInt(rewardId));
    onSubmit({ reward, amount, start_date, end_date });
    resetForm();
  };

  const onCancel = ({ resetForm }) => {
    resetForm();
  }

  const fieldsValues = {
    reward: "select",
    default_amount: "number",
    start_date: 'date_time',
    end_date: 'date_time',
  }

  return (
    <NewFormDynamic
      oneColumn={true}
      enableReinitialize={true}
      initValues={fieldsValues}
      initStateWithValues={{
        reward: {
          values: [],
          options: rewards ? rewards.map((r) => {
            console.log("\n\n\ncollectionBenefit:: \n", collectionBenefit)
            if (r.reward_type === "PHYSICAL" || r.blockchain_name === collectionBenefit?.collection?.blockchain_name) {
              return { value: r.id, label: r.name };
            }
            return null;
          }).filter(Boolean)
          :[]
        }
      }}
      loading={false}
      onSubmit={handleSubmit}
      handleCancel={onCancel}
      textButtons={{
        submit: t(`${BASE_TRANSLATION}.btn_send`),
        cancel: t(`${BASE_TRANSLATION}.btn_cancel`),
      }}
      textLabels={{
        default_amount: t(`${BASE_TRANSLATION}.default_amount`),
        reward: t(`${BASE_TRANSLATION}.reward`),
        start_date: t(`${BASE_TRANSLATION}.start_date`),
        end_date: t(`${BASE_TRANSLATION}.end_date`),
      }}
      textPlaceholders={{
        default_amount: t(`${BASE_TRANSLATION}.default_amount_placeholder`),
        reward: t(`${BASE_TRANSLATION}.reward_placeholder`),
      }}
    />
  );
})

const BenefitsPreview = ({ benefits, onDelete }) => {
  const { t } = useTranslation('benefits');
  const BASE_TRANSLATION = "create_view.preview";
  const benefitsWithIndex = benefits.map((b, i) => ({ ...b, index: i }));

  const buttonRemove = (item) => {
    console.log("item: ", item);
    return (
      <TableCell>
        <Button
          data-testid={`remove-benefit-${item.reward.id}`}
          variant="contained"
          color="primary"
          onClick={() => onDelete([item.index])}
        >
          {t(`${BASE_TRANSLATION}.btn_remove`)}
        </Button>
      </TableCell>
    );
  };

  const configSelected = {
    defineId: 'name',
    options:[
      {
        id:'remove',
        label: t(`${BASE_TRANSLATION}.btn_remove`),
        color: 'primary',
        variant: 'contained',
        tooltip: t(`${BASE_TRANSLATION}.btn_remove`),
        onClick: (items) => {
          onDelete(items.map((i) => i.index));
        },
        conditions: () => true
      },
    ]
  }

  const tableMapper = (item) => {
    return {
      thumbnail: item.reward.thumb_url,
      name: item.reward.name,
      description: item.reward.description,
      contract_address: item.reward.contract_address ? shortenAddress(item.reward.contract_address) : "N/A",
      blockchain_name: item.reward?.blockchain,
      default_amount: item.amount,
      start_date: item.start_date,
      end_date: item.end_date,
    }
  }

  return (
    <div>
      <DynamicTable
        data={benefitsWithIndex}
        title=""
        preparedDataConfig={tableMapper}
        activeModeView={false}
        setConfigParams={{
          emptyMessage: t(`${BASE_TRANSLATION}.table.empty_message`),
          configOptions: [{
            id: "remove",
            label: "remove",
            render: buttonRemove,
          }],
          textHeaders: {
            default_amount: t(`${BASE_TRANSLATION}.table.headers.default_amount`),
            blockchain_name: "Blockchain",
            contract_address: t(`${BASE_TRANSLATION}.table.headers.contract_address`),
            start_date: t(`${BASE_TRANSLATION}.table.headers.start_date`),
            end_date: t(`${BASE_TRANSLATION}.table.headers.end_date`),

          },
          configCells: {
            blockchain_name: {
              render: (blockchain) => <BlockchainIcon blockchain={blockchain} />
            }
          },
          configSelected,
        }}
      />
    </div>
  );
}

const BlockchainIcon = ({ blockchain }) => {

  if (!blockchain?.icon) {
    return (
      <TableCell>
        <Typography variant="body2" gutterBottom>
          N/A
        </Typography>
      </TableCell>)
  }

  return (
    <TableCell>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: '0.7rem',
        }}
      >
        <CardMedia
          alt={blockchain.name}
          component="img"
          key={blockchain.chainid}
          sx={{
            width: 30,
            height: 30,
            objectFit: 'contain'
          }}
          src={blockchain.icon}
        />
      </Box>
    </TableCell>
  )
}