import { Magic } from 'magic-sdk';
import Web3 from 'web3';
import { OAuthExtension } from '@magic-ext/oauth';
import { createIfNotExists, findByWallet, generateJwt } from 'services/user';
import { getIdTokenWithMagic, getMetadataMagic } from './magicWallet';
import { getTokenMagicLS, getTokenXApiUserLS, getTypeConnectionLS, getUserAddressLS } from 'utils/localStorage';

const constructorMagic = () => {
  return new Magic(process.env.REACT_APP_MAGIC_API_KEY,{
    extensions: [new OAuthExtension()],
    network:{
      rpcUrl: process.env.REACT_APP_RPC,
      chainId: process.env.REACT_APP_NETWORK,
    }
  });
}

const getCurrentDomainWithPort = () => {
  const location = window.location;
  let domain = location.hostname;
  // Check if the port is not standard for HTTP (80) or HTTPS (443)
  if (location.port && location.port !== "80" && location.port !== "443") {
    domain += ':' + location.port;
  }
  return domain;
}

export const loginMagicOAuth = async (provider) => {
  const magic = constructorMagic();
  await magic.oauth.loginWithRedirect({
    provider: provider,
    redirectURI: `${window.location.protocol}//${getCurrentDomainWithPort()}/oauth-callback`
  });
};

const generateEmail = async(account,email) => {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  if (!email || !emailRegex.test(email)) {
    return `${account}@${process.env.REACT_APP_DOMAIN}`;
  }
  return email;
}

export const recoverMagicOAuth = async (setStepText,t) => {
  setStepText?.(t("get_data"));
  const magic = constructorMagic();
  const infoOAuth = await magic.oauth.getRedirectResult();
  console.log("infoOAuth",infoOAuth)
  //const accounts = await magic.wallet.connectWithUI();
  const account = infoOAuth?.magic.userMetadata?.publicAddress;
  const wallet = account;
  console.log("wallet",wallet)
  const provider = new Web3(magic.rpcProvider);
  const userMetadata = await getMetadataMagic(magic);
  const newEmail = await generateEmail(wallet,userMetadata?.email);
  const magic_token = await getIdTokenWithMagic(magic);
  console.log("magic_token",magic_token)
  const { res : info_user, isNew } = await createIfNotExists(
    process.env.REACT_APP_DOMAIN,
    process.env.REACT_APP_NAME_NETWORK,
    {
      wallet: wallet,
      magic_token: magic_token,
      email: newEmail,
    }
  );
  const userXApiKey = await generateJwt(process.env.REACT_APP_DOMAIN,{magic_token});
  setStepText?.(t("redirecting"));
  const isFirst = JSON.parse(localStorage.getItem("isFirst")) === true
  localStorage.setItem('addressAccount', wallet)
  localStorage.setItem('magicToken', magic_token)
  localStorage.setItem('user', JSON.stringify(userMetadata))
  localStorage.setItem('userApiKey', userXApiKey);
  localStorage.setItem("isFirst",isFirst || isNew);
  console.log("userXApiKey",userXApiKey)
  return {
    ...infoOAuth,
    ...info_user,
    provider: provider,
    wallet: wallet,
    magic: magic,
    magic_token: magic_token,
    typeWallet: getTypeConnectionLS(),
    isNew: isNew
  };
};


export const  validateApiTokenIsValid = async () => {
  const addressUser = getUserAddressLS();
  const tokenXApi = getTokenXApiUserLS();
  if(addressUser && tokenXApi){
    const infoUser = await findByWallet(process.env.REACT_APP_DOMAIN, process.env.REACT_APP_NAME_NETWORK, addressUser);
    const magic = constructorMagic();
    const isLoggedIn = await userIsLoggedOut(magic);
    if (!isLoggedIn) {
      throw new Error('User is not logged in or expired session');
    }
    const provider = new Web3(magic.rpcProvider);
    const userMetadata = await getMetadataMagic(magic);
    const loginEmail = userMetadata.email || '';
    const wallet = userMetadata?.publicAddress || '';
    const isNew = infoUser?.register || false

    if(!infoUser){
      throw new Error ("Fail reconnect user with provider: " + provider);
    }

    return {
      ...infoUser,
      provider: provider,
      wallet: wallet,
      magic: magic,
      loginEmail: loginEmail,
      magic_token: getTokenMagicLS(),
      typeWallet: getTypeConnectionLS(),
      isNew: isNew
    };
  }

  return null
}

export const userIsLoggedOut = async (magic) => {
  const res = await magic.user.isLoggedIn();
  return res;
}

export const connectUserEmailOTP = async (email) => {
  const magic = new Magic(process.env.REACT_APP_MAGIC_API_KEY, {
    extensions: [new OAuthExtension()]
  });
  const magic_token = await magic.auth.loginWithMagicLink({ email: email });
  const provider = new Web3(magic.rpcProvider);
  const userMetadata = await getMetadataMagic(magic);
  const wallet = userMetadata.publicAddress;
  const emailGenerated = await generateEmail(wallet,userMetadata?.email);
  const newEmail = isValidEmail(emailGenerated) ? String(emailGenerated+'').split('@')[0] : null;
  const { res : info_user, isNew } = await createIfNotExists(
    process.env.REACT_APP_DOMAIN,
    process.env.REACT_APP_NETWORK_NAME,
    {
      wallet: wallet,
      magic_token: magic_token,
      email: newEmail,
    }
  );
  const userXApiKey = await generateJwt(process.env.REACT_APP_DOMAIN,{magic_token});
  const isFirst = JSON.parse(localStorage.getItem("isFirst")) === true
  localStorage.setItem('addressAccount', wallet)
  localStorage.setItem('magicToken', magic_token)
  localStorage.setItem('user', JSON.stringify(userMetadata))
  localStorage.setItem('userApiKey', userXApiKey);
  localStorage.setItem("isFirst",isFirst || isNew);


  return {
    ...info_user,
    provider: provider,
    wallet: wallet,
    magic: magic,
    magic_token: magic_token,
    typeWallet: getTypeConnectionLS(),
    isNew: isNew
  };
};

const isValidEmail = (email) => {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  return emailRegex.test(email);
}


