

export const getThumbnail = (thumbnails, width,height, f_key = '') => {
    let thumbnail = thumbnails?.find(x => (x?.width === width) && (x?.height === height) && x.f_key.includes(f_key))
    return  thumbnail?.ipfs_hash;
}

export const findThumbnail = (item, width,height) => {
    let image = item?.thumb_url+''
    if(item?.thumbnails && width && height){
        let new_image = getThumbnail(item?.thumbnails, width,height, image.collection_key)
        if(new_image) {
            image = new_image
        } else {
            if(item?.metadata) {
                return item?.metadata?.image_url+'';
            } else {
                return item?.thumb_url+'';
            }
        }
    }
    return image+'';
}
