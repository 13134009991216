import React from 'react';
import styles from "./InVoice.module.css";
import { useTranslation } from 'react-i18next';
import DynamicTable from 'components/DynamicTable/DynamicTable';

const InVoice = () => {

  const { t } = useTranslation('payments');

  return (
      <div className={styles.eventosInner}>
      <div className={styles.divGrficasWrapper}>
        <div className={styles.divGrficas}>
          <div className={styles.imagen}>
            <div className={styles.ttuloYDescripcinParent}>
              <div className={styles.ttuloYDescripcin}>
                <div className={styles.loremIpsumDolor}>
                  {t("description")}
                </div>
              </div>
              <div
                className={styles.ttuloYDescripcin1}
               
              >
                <div className={styles.title}>+ {t("new_btn")}</div>
              </div>
            </div>
            <DynamicTable
              data={[]}
              loading={false}
              activeSearchBar={true}
              activeModeView={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InVoice;