import axios from "axios"
axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;

const path = "relay"
const URL = `${process.env.REACT_APP_API}/${path}`;


export const getUrl = async (body) => {
	console.log("debug generateUrl body: ", body);
	return await axios.post(URL, body,
		{
			headers: {
				'X-API-Key': localStorage.getItem("userApiKey")
			},
			params: {
				domain: process.env.REACT_APP_DOMAIN,
			}
		});
}

