import React from 'react';
import PropTypes from 'prop-types';
import styles from './personalizeProfile.module.css';

const PersonalizeProfile = () => {
    return (
        <div className={styles.bodyRegister}>
            <div className={styles.contentRegisterProfile}>
                <h3 className={styles.titleRegister}>Personaliza tu perfil</h3>
                <img className={styles.contentAvatar} src={"avatarDefault.png"} alt=""></img>

                <label className={styles.labelName}>Nombre</label>
                <input className={styles.inputName} type="test" placeholder="Juan peréz" />
            </div>
        </div>
    );
};

PersonalizeProfile.propTypes = {
    hello: PropTypes.string
};

export default PersonalizeProfile;