import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';


export default function SmartTooltipModal({ text, children }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation('smartTooltipModal');
  
  const isLongText = text.length > 50;
  const previewText = isLongText ? `${text.substring(0, 30)}... ${t("see_more")}` : text;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const flexContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    gap: '8px',
  };

  return (
    <React.Fragment>
      <div style={flexContainerStyle}>
        <div>
          {children}
        </div>
        <Tooltip title={previewText} >
          <IconButton onClick={handleOpen} size="small">
            <HelpIcon style={{ fontSize: 16 }} />
          </IconButton>
        </Tooltip>
      </div>
      {isLongText && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <IconButton
              onClick={handleClose}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="modal-title" variant="h6" component="h2">
              {t("details")}
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              {text}
            </Typography>
          </Box>
        </Modal>
      )}
    </React.Fragment>
  );
}
