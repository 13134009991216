import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { findAllByProjectKey } from "services/nfts"
import SectionTemplate from 'components/SectionTemplate';
import DynamicTable from 'components/DynamicTable/DynamicTable';

const NFTsDesktop = () => {

  const { collectionKey } = useParams();
  const [nfts, setNfts] = useState([]);
 
	const getNFTs = async () =>{
    const updatedNFTS = await findAllByProjectKey(collectionKey);
    setNfts(updatedNFTS)
  }

	useEffect(() => {
    getNFTs();
  }, []);

  return (
    <SectionTemplate
      description={"Crea tu colección o importa una ya existente."}
      sx={{
        position:'relative'
      }}
    >
      <DynamicTable
        data={nfts}
        activeSearchBar={true}
        activeModeView={false}
        activeFilter={true}
      />
    </SectionTemplate>
  );
};

export default NFTsDesktop;
