import axios from "axios";
import { sales } from "./blockchain";
import { buildUrlParams } from "utils/fetch";
axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;

const url = process.env.REACT_APP_API+"/nft?domain="+process.env.REACT_APP_DOMAIN
const PATH = "nft";
const URL = process.env.REACT_APP_API;

export const findAllByProjectKey = async(key) => {
    return axios.get(url+"&address="+key+"&order=created&limit=30&page=0").then(res=>{
       let nfts = []
       res.data.forEach((nft) => {
            nfts.push(formatNFT(nft));
       })
       return nfts;
    })
}

export const findAllByCollectionKey = async ({
    domain = process.env.REACT_APP_DOMAIN,
    params = {
      limit: 30,
      page: 0,
      order: "created"
    }
  }) => {
    const result = axios.get(
      `${URL}/${PATH}?domain=${domain}${buildUrlParams(params)}`
    );
    const response = await result;
    if(Array.isArray(response?.data)) {
        return response.data.map(formatNFT);
    }
    return [];
};

  export const findAll = async ({
    domain = process.env.REACT_APP_DOMAIN,
    params = {
      limit: 100,
      page: 0,
      order: "created"
    }
  }) => {
    const result = axios.get(
      `${URL}/${PATH}?domain=${domain}${buildUrlParams(params)}`
    );
    const response = await result;
    if(Array.isArray(response?.data)) {
        return response.data.map(formatNFT);
    }
    return [];
  };


  export const findByTokenId = async ({
    domain = process.env.REACT_APP_DOMAIN,
    params = {
      limit: 1,
      page: 0,
      order: "created_desc"
    }
  }) => {
    const result = axios.get(
      `${URL}/${PATH}?domain=${domain}${buildUrlParams(params)}`
    );
    const response = await result;
    if(Array.isArray(response?.data)) {
      return response.data.map(formatNFT)[0];
    }
    return [];

    // console.log("findByTokenId::response",response)
    // return response.data[0];
  };


export const findByUserAddress = async (userWallet) => {
    return axios.get(url+"&wallet="+userWallet+"&order=created&limit=30&page=0").then(res=>{
       let nfts = []
       res.data.forEach((nft) => {
            nfts.push(formatNFT(nft));
       })
       return nfts;
    })
}


const formatNFT = (nft) => {
    const { 
        metadata = {}, 
        thumb_url,
        collection_key, 
        token_id, 
        collection = {}, 
        creator, 
        owner, 
        reveal, 
        blockchain, 
        nft_transaction, 
        transaction,
        thumbnails,
        status,
        price,
        currency
    } = nft;
    const { json_data = {} } = metadata;
    const { name, description, attributes = [] } = json_data;

    let year = attributes.find((x) => x.trait_type === "Year");

    return {
        metadata,
        thumb_url,
        collection_key,
        token_id,
        collection,
        creator, 
        owner,
        reveal,
        blockchain,
        nft_transaction,
        transaction,
        name,
        thumbnails,
        description,
        year: year || null,
        attributes,
        status,
        price,
        currency
    }
}

export const isSold = (nft) => {
  return nft.creator.toUpperCase() !== nft.owner.toUpperCase()
}

export const addSalesCountToEach = async (nfts) => {
    const res = await sales(null,process.env.REACT_APP_COLLECTION,null);
    for (let index = 0; index < nfts.length; index++) {
        nfts[index].sales = res;
    }
    return nfts;
}

export const create = async (nft) => {
    const domain = process.env.REACT_APP_DOMAIN;
    const res = await axios.post(`${process.env.REACT_APP_API}/nft?domain=${domain}`,nft)
    return res.data;
}

export const update = async (nfts) => {
  const domain = process.env.REACT_APP_DOMAIN;
  const res = await axios.put(`${process.env.REACT_APP_API}/nft?domain=${domain}`,{
    nfts : nfts
  })
  return res.data;
}