import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ResponsiveImageOrVideo from '../ResponsiveImageOrVideo';
import { Button, Divider, Skeleton, Stack, useTheme } from '@mui/material';
import { maxLinesProperties } from 'utils/css';

export default function CustomizedCard({
  item,
  options=[],
  descriptionMaxLines=4,
  titleMaxLines=2,
  ...props
}) {
  const theme = useTheme();
  const { sx={}, ...rest } = props;

  return (
    <Card>
      <Box sx={{ display: 'flex', ...sx }} { ...rest }>
        <Box sx={{ flex: "1", padding: theme.spacing(2) }}>
          <ResponsiveImageOrVideo
            src={item.thumb_url}
          />
        </Box>
        <Box sx={{ flex: "2", display: 'flex', flexDirection: 'column', py: theme.spacing(2.5), pr: theme.spacing(2) }}>
          <Typography
            component="div"
            variant="h6"
            lineHeight={1}
            mb={1}
            sx={maxLinesProperties(titleMaxLines)}
          >
            {item.name}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            component="div"
            sx={maxLinesProperties(descriptionMaxLines)}
          >
           {item.description}
          </Typography>
        </Box>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        display={ options.length === 0 ? "none" : "flex" }
        sx={{ backgroundColor: theme.palette.primary.light, padding: theme.spacing(1) }}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {options.map((option, index) => {
          return (
            <Button key={index} onClick={() => option.action(item)} sx={{py: 0, color: theme.palette.primary.contrastText}}>
              <Typography component="div" variant="caption">
                {option.name}
              </Typography>
            </Button>
          );
        })}
      </Stack>
    </Card>
  );
}

export const CardSkeleton = () => {
  const theme = useTheme();

  return (
    <Card>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: "1", padding: theme.spacing(2) }}>
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Box>
        <Box sx={{ flex: "2", display: 'flex', flexDirection: 'column', py: theme.spacing(2.5), pr: theme.spacing(2) }}>
          <Typography component="div" variant="h5" lineHeight={1} mb={1}>
            <Skeleton variant="text" width="50%" />
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div">
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}