import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ParticipantsTable from './ParticipantsTable';
import PostsTable from './PostsTable';
import BenefitsTable from '../../../../components/AutoManagedTables/BenefitsTable';
import MessagesTable from 'components/AutoManagedTables/MessagesTable';
import CampaignAnalytics from './CampaignAnalytics';

const activeItemStyles = () => ({
  background: (theme) => theme.palette.primary.main,
  color: (theme) => theme.palette.backgroundSelected.main,
  boxShadow: (theme) => ` inset -4px 0 0 ${theme.palette.secondary.main}`,
  '&:hover': {
    background: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.backgroundSelected.main,
  }
});

const itemStyles = () => ({
  '&:hover': {
    background: (theme) => theme.palette.primary.main,
    color: "#fff"
  }
});

const SECTIONS_IDS = {
  BENEFITS: 1,
  POSTS: 2,
  MESSAGES: 3,
  PARTICIPAINTS: 6,
  ANALYTICS: 7
}

export default function Sections({ campaign, loadingCampaign }) {
  const { t } = useTranslation("campaigns");
  const [active, setActive] = useState(SECTIONS_IDS.BENEFITS);
  const handleSelected = (step) => {
    setActive(step);
  }

  const getMenus = () => {
    const baseMenu = [
      {
        label: t("menu.benefits"),
        step: SECTIONS_IDS.BENEFITS,
        testId: "overview-collection-benefits-button"
      },
      {
        label: t("menu.posts"),
        step: SECTIONS_IDS.POSTS,
        testId: "overview-collection-posts-button"
      },
      {
        label: t("menu.messages"),
        step: SECTIONS_IDS.MESSAGES,
        testId: "overview-collection-messages-button"
      },
      {
        label: t("menu.participants"),
        step: SECTIONS_IDS.PARTICIPAINTS,
        testId: "overview-collection-participants-button"
      },
      {
        label: t("menu.statistics"),
        step: SECTIONS_IDS.ANALYTICS,
        testId: "overview-collection-analytics-button"
      }
    ]
    return baseMenu;
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '1rem',
        marginTop: '1rem',
        padding: '1rem 0px'
      }}
      data-testid="overview-table-sections"
    >
      <List
        sx={{ width: '100%', maxWidth: 200 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader"
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontWeight: '600',
              background: 'transparent'
            }}
          >
            {t("menu.title")}
          </ListSubheader>
        }
      >
        <List
          sx={{
            bgcolor: 'background.paper'
          }}
        >
          {
            getMenus().map((item, index) => {
              return (
                <ListItemButton
                  key={index}
                  data-testid={item?.testId}
                  onClick={() => handleSelected(item?.step)}
                  sx={
                    active === item?.step ?
                      activeItemStyles()
                      :
                      itemStyles()
                  }
                >
                  <ListItemText primary={item.label} />
                </ListItemButton>
              )
            })
          }
        </List>
      </List>
      <Box flex={1} padding='0px 1rem' >
        {active === SECTIONS_IDS.BENEFITS
          && !loadingCampaign
          && <BenefitsTable fetchParams={{contract_address: campaign?.smart_contract.address}} />}
        {active === SECTIONS_IDS.POSTS
          && !loadingCampaign
          && <PostsTable  campaign_id={campaign?.id} />}
        {active === SECTIONS_IDS.MESSAGES && <MessagesTable />}
        {active === SECTIONS_IDS.PARTICIPAINTS
          && !loadingCampaign
          && <ParticipantsTable campaignId={campaign.id}/>}
        {active === SECTIONS_IDS.ANALYTICS
          && !loadingCampaign
          && <CampaignAnalytics campaignId={campaign.id}/>}
      </Box>
    </Card>
  );
}
