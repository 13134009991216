import { useRef, memo, forwardRef } from "react";
import { Box, CardMedia } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { SUPPORTED_FORMATS as SUPPORTED_NFT_FORMATS, isVideo } from "utils/supportedFiles";

function SelectImageOrVideoField({ htmlName, file, onChange, disabled=false, variant="square" }, ref) {
  const selectInput = ref !== null ? ref : useRef(null);

  const ratioStypeProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }

  const handleOnChange = (event) => {
    onChange(event);
  }

  const isValid = () => {
    if (!file) return false;
    console.log(file);
    const type = file.type;
    const format = type.split('/')[1];
    return SUPPORTED_NFT_FORMATS.includes(format.toUpperCase());
  }

  const selectFieldIcon = () => (
    <Box
      sx={{
        ...ratioStypeProperties,
        border: '1px dashed #C4C4C4',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '8px',
      }}
    >
      <UploadFileIcon fontSize='large' sx={{mb: 1}} />
      {SUPPORTED_NFT_FORMATS.reduce((acc, format) => { acc.push(` .${format},`); return acc; }, []).join('').slice(0, -1)}
    </Box>
  );

  const responsiveImage = () => (
    <CardMedia
      image={URL.createObjectURL(file)}
      sx={{ 
        ...ratioStypeProperties,
        borderRadius: '8px',
        filter: disabled ? 'grayscale(100%)' : 'none',
      }}
    />
  )

  const responsiveVideo = () => (
    <CardMedia
      component="video"
      src={URL.createObjectURL(file)}
      sx={{
        ...ratioStypeProperties,
        borderRadius: '8px'
      }}
      controls
    />
  )

  const getRatio = () => {
    if (variant === "square") return 100;
    if (variant === "landscape") return 50;
    if (variant === "portrait") return 200;
  }

  const handleClick = () => {
    if (disabled) return;
    selectInput.current.click();
  }

  return (
    <div>
      <input
        id={htmlName}
        name={htmlName}
        ref={selectInput}
        type="file"
        accept="image/png, image/gif, image/jpeg, video/mp4"
        onChange={handleOnChange}
        style={{ display: 'none' }}
      />
      <Box
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          position: 'relative',
          width: '100%',
          paddingBottom: `${getRatio()}%`,  /* set the aspect ratio */
        }}
      >
        {!isValid() && selectFieldIcon()}
        {isValid() && !isVideo(file) && responsiveImage()}
        {isValid() && isVideo(file) && responsiveVideo()}
      </Box>
    </div>
  );
}

const SelectImageOrVideoFieldRef = forwardRef(SelectImageOrVideoField);

export default memo(SelectImageOrVideoFieldRef, (prevProps, nextProps) => {
  return prevProps.htmlName === nextProps.htmlName && prevProps.variant === nextProps.variant && prevProps.file === nextProps.file;
});