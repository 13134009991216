import { useFetch } from 'hooks/useFetch';
import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { findDefaultAttrs } from 'services/collection/defaultNFTAttrs';
import { DEFAULT_ATTRS_RESTRICTION_LEVELS } from 'utils/constants';

export function DefaultNFTsAttrsInfor({ collection }) {
  const { t } = useTranslation("massiveMint");
  const [lists, setLists] = useState({
    OPTIONAL: [],
    REQUIRED: [],
    EDITABLE: [],
  });
  const { data: defaultAttrs, loading: loadingDefaultAttrs, error } = useFetch(findDefaultAttrs, {
    params: { collection_key: collection.collection_key }
  });

  useEffect(() => {
    if (!loadingDefaultAttrs && !error && defaultAttrs) {
      const lists = {
        OPTIONAL: [],
        REQUIRED: [],
        EDITABLE: [],
      };

      defaultAttrs.forEach((attr) => {
        lists[attr.restriction_level].push(attr);
      });

      setLists(lists);
    }
  }, [defaultAttrs, loadingDefaultAttrs, error]);

  const getHeaderName = (type) => {
    switch (type) {
      case DEFAULT_ATTRS_RESTRICTION_LEVELS.REQUIRED:
        return t("default_attrs_restrictions.required").toUpperCase();
      case DEFAULT_ATTRS_RESTRICTION_LEVELS.OPTIONAL:
        return t("default_attrs_restrictions.optional").toUpperCase();
      case DEFAULT_ATTRS_RESTRICTION_LEVELS.EDITABLE:
        return t("default_attrs_restrictions.editable").toUpperCase();
      default:
        return "";
    }
  }

  if (!loadingDefaultAttrs && !error && defaultAttrs.length === 0) {
    return <Box data-testid="default-nfts-attrs-info-empty"></Box>;
  }

  if (loadingDefaultAttrs) {
    return <Box data-testid="default-nfts-attrs-info-empty"></Box>;
  }

  if (!loadingDefaultAttrs && error) {
    return (
    <>
      <Alert severity="info" data-testid="default-nfts-attrs-info-alert" sx={{mb: 1}}>
        {t("alerts.default_attrs_explanation")}
      </Alert>
      <Alert severity="warning" data-testid="default-nfts-attrs-info-error-alert">
        {t("alerts.default_attrs_fetch_error")}
      </Alert>
    </>
    );
  }

  return (
    <Box data-testid="default-nfts-attrs-infor">
      <Alert severity="info" data-testid="default-nfts-attrs-info-alert">
        {t("alerts.default_attrs_explanation")}
      </Alert>
      {Object.keys(lists).map((key) => (
        <ItemsList
          key={key}
          itemsTypeName={getHeaderName(key)}
          type={key}
          items={lists[key]}
        />
      ))}
    </Box>
  );
}

function ItemsList({ itemsTypeName, type, items }) {
  if (items.length === 0) {
    return <></>;
  }
  return (
    <List
      component="nav"
      dense
      data-testid={`default-nfts-attrs-info-${type}`}
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {itemsTypeName}
        </ListSubheader>
      }
    >
      {items.map((item) => (
        <ListItem key={item.id}>
          <ListItemText primary={
            <>
              <Typography
                component="span"
                variant="body1"
                color="text.primary"
                fontWeight="bold"
              >
                {item.name}
              </Typography>
              <Typography
                component="span"
                variant="body1"
                color="text.primary"
              >
                {`: ${item.value}`}
              </Typography>
            </>
          } />
        </ListItem>
      ))}
    </List>
  );
}
