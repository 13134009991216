import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import FormLabel from '@mui/material/FormLabel';
import { Stepper, Step, StepLabel, StepContent, Card } from '@mui/material';
import { useSpinnerContext } from 'context/SpinnerContext';
import CreateAccessControlForm from './components/CreateAccessControlForm';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import { createAccessControl } from 'services/access_control/accessControl';

export const STEPS = {
  CONFIGURE: 0,
  CONFIRM: 1,
}

export default function ViewCreateAccessControl() {
  const { t } = useTranslation('accessControl');
  const BASE_TRANSLATE = 'create_access_control_view';
  const navigate = useNavigate();
  const [configuration, setConfiguration] = useState({});
  const [activeStep, setActiveStep] = useState(STEPS.CONFIGURE);
  const { handleChangeLoading, handleChangeMessage, handleChangeError } = useSpinnerContext();

  const handleSubmitAccessControl = (values) => {
    setConfiguration(values)
    setActiveStep(STEPS.CONFIRM);
  }

  const goToClients = () => {
    navigate('/dashBoard/clients');
  }

  const handleCancelConfig = () => {
    goToClients();
  }

  const handleConfirm = async (confirm) => {
    console.log("confirm", confirm)
    if (!confirm) {
      setActiveStep(STEPS.CONFIGURE);
      return;
    }
    await processConfirm();
  }

  const processConfirm = async () => {
    handleChangeError("");
    handleChangeLoading(true);
    try {
      handleChangeMessage(t(`${BASE_TRANSLATE}.messages.creating_access_control`));
      await createAccessControl(configuration);
      goToClients();
    } catch(e) {
      console.log(e);
      handleChangeError(t(`${BASE_TRANSLATE}.messages.error_creating_access_control`));
    } finally {
      handleChangeLoading(false);
      handleChangeMessage("");
    }
  }

  return (
    <Card sx={{ padding: '1rem' }}>
      <Stepper activeStep={activeStep} orientation="vertical" id="impulse-form">
        <Step data-testid={`step-${STEPS.CONFIGURE}`}>
          <StepLabel>{t(`${BASE_TRANSLATE}.steps.step_configure`)}</StepLabel>
          <StepContent>
            <CreateAccessControlForm
              onSubmit={handleSubmitAccessControl}
              onCancel={handleCancelConfig}
            />
          </StepContent>
        </Step>
        <Step data-testid={`step-${STEPS.CONFIRM}`}>
          <StepLabel>{t(`${BASE_TRANSLATE}.steps.step_confirm`)}</StepLabel>
          <StepContent>
            <div data-testid="campaign-configuration-confirm">
              <FormLabel htmlFor={"confirm"}>
                {t(`${BASE_TRANSLATE}.messages.confirm_access_control`)}
              </FormLabel>
              <ConfirmForm onSubmit={handleConfirm}/>
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </Card>
  )
}

const ConfirmForm = ({ onSubmit }) => {
  const { t } = useTranslation('campaigns');
  
  const handleSubmit = async (_, {setSubmitting}) => {
    onSubmit(true)
    setSubmitting(false);
  }

  const handleCancel = async ({setSubmitting}) => {
    onSubmit(false)
    setSubmitting(false);
  }

  return (
    <div data-testid="confirm-form">
      <NewFormDynamic
        initValues={{}}
        onSubmit={handleSubmit}
        handleCancel={handleCancel}
        textButtons={{
          submit: t("create_view.options.confirm"),
          cancel: t("create_view.options.back")
        }}
      />
    </div>
  );
};
