import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ProfileCustomsBasicPage.module.css';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import UserContext from 'context/UserContext';
import { uploadFileWithGateway } from 'services/files';
import { updateUsers } from 'services/user';
import { getRouteIfUserIsNew } from 'utils/localStorage';

const ProfileCustomsBasicPage = () => {

  const { t } = useTranslation("profile");
  const { user, setUser } = useContext(UserContext); 
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData,setFormData] = useState({
    username: null,
    wallet_pic_url: null
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = useCallback((value,name)=>{
    setFormData({...formData, [name]:value})
  },[formData, setFormData]);

  const handleImageUpload = event => {
    if(loading) { return null }
    handleChange(event.target.files[0],"wallet_pic_url");
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
  };

  const triggerImageUpload = useCallback(() => {
    if(loading) { return null }
    document.getElementById('imageUpload').click();
  },[loading]);

  const handleClick = useCallback(async() =>{
    try{
      setLoading(true);
      handleChange(user?.username,"username");
      let body = { ...formData, wallet: user?.wallet, sign:'update',message:'update' };
      if (formData?.wallet_pic_url) {
        const { image_url, gateway } = await uploadFileWithGateway(formData.wallet_pic_url);
        body.wallet_pic_url = gateway + image_url;
      }
      console.log("debug body::",body);
      const res = await updateUsers(process.env.REACT_APP_DOMAIN,user?.wallet,body);
      const newUser ={
        ...user,
        isNew: false,
        ...res[0]
      }
      setUser(newUser);
      localStorage.setItem("isFirst",false)
      const route = getRouteIfUserIsNew(false);
      navigate(route);
    } catch (e){
      console.error(e)
      setError('An unexpected error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  },[setError,setLoading, formData, user, setUser, navigate]);

  const handleSkip = useCallback(() =>{
    localStorage.setItem("isFirst",false)
    setTimeout(() => {
      const route = getRouteIfUserIsNew(false);
      navigate(route);
    },500);
  },[navigate]);

  useEffect(()=>{
    if(!user){
      navigate("/login")
    }
  },[user,navigate])

  const currentAvatar = user?.wallet_pic_url || null;

  return (
    <Paper 
      sx={{
        backgroundColor: "#19407f",
        color: "#fff !important",
        borderRadius: "0px"
      }}
      className={styles.parentContent}
    >
      <div className={styles.contentForm}>
        <div className={styles.form}>
          <h2 style={{marginBottom:'0px'}}>{t("custom_profile.welcome")}</h2>
          <small>
            {t("custom_profile.complete_data")}
          </small>
          <input 
            type="file" 
            id="imageUpload" 
            style={{display: 'none'}} 
            onChange={handleImageUpload} 
          />
          <Avatar 
            sx={{
              width: '100px',
              height: '100px',
              margin: '0 auto'
            }}
            alt="Remy Sharp"
            src={selectedImage || currentAvatar}
            onClick={triggerImageUpload}
          />
          <span
            style={{textAlign:'left',width:'100%',maxWidth:'210px'}}
          >
            {t("custom_profile.username")}
          </span>
          <TextField 
            size="small"
            label={""} 
            variant="outlined"
            placeholder="Username"
            disabled={loading}
            value={formData?.username === null ? user?.username : formData?.username}
            onChange={(e) =>{
              const { value } = e.target;
              handleChange(value,"username");
            }}
            sx={{
              borderRadius:'8px',
              border: '1px solid #fff',
              backgroundColor: '#fff'
            }}
          />
          {loading && 
          <div style={{display: 'flex',gap: '1rem', flexDirection:'column',alignItems:'center'}}>
            <CircularProgress 
              color="secondary"
              sx={{
                fontSize: "10px"
              }}
            />
            {t("custom_profile.loading")}
          </div>
          }
          {!loading && error && <div>{error}</div>}
          <Button 
            variant="contained" 
            color="secondary"
            onClick={handleClick}
            sx={{
              textTransform: 'none'
            }}
            disabled={loading || formData?.username === null || formData?.username === ''}
          >
            {t("custom_profile.save")}
          </Button>
          <Button 
            variant="text"
            color="secondary"
            disabled={loading}
            onClick={handleSkip}
            sx={{
              color: 'white',
              textDecoration: 'underline',
              textTransform: 'none'
            }}
          >
            {t("custom_profile.skip")}
          </Button>
        </div>
      </div>
    </Paper>
  )
};

export default ProfileCustomsBasicPage;