import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import collections_en from 'locales/sections/collections/en/translate.json';
import collections_es from 'locales/sections/collections/es/translate.json';
import benefit_en from 'locales/sections/benefits/en/translate.json';
import benefit_es from 'locales/sections/benefits/es/translate.json';
import payment_en from 'locales/sections/payment/en/translate.json';
import payment_es from 'locales/sections/payment/es/translate.json';
import user_en from 'locales/sections/users/en/translate.json';
import user_es from 'locales/sections/users/es/translate.json';
import client_en from 'locales/sections/clients/en/translate.json';
import client_es from 'locales/sections/clients/es/translate.json';
import campaigns_en from 'locales/sections/campaigns/en/translate.json';
import campaigns_es from 'locales/sections/campaigns/es/translate.json';
import apikeys_en from 'locales/sections/apikeys/en/translate.json';
import apikeys_es from 'locales/sections/apikeys/es/translate.json';
import events_en from 'locales/sections/events/en/translate.json';
import events_es from 'locales/sections/events/es/translate.json';
import tables_en from 'locales/sections/tables/en/translate.json';
import tables_es from 'locales/sections/tables/es/translate.json';
import projects_en from 'locales/sections/projects/en/translate.json';
import projects_es from 'locales/sections/projects/es/translate.json';
import menuDashboard_en from 'locales/sections/menuDashboard/en/translate.json';
import menuDashboard_es from 'locales/sections/menuDashboard/es/translate.json';
import overview_en from 'locales/sections/overview/en/translate.json';
import overview_es from 'locales/sections/overview/es/translate.json';
import login_en from 'locales/sections/login/en/translate.json';
import login_es from 'locales/sections/login/es/translate.json';
import form_en from 'locales/sections/form/en/translate.json';
import form_es from 'locales/sections/form/es/translate.json';
import rewards_en from 'locales/sections/rewards/en/translate.json';
import rewards_es from 'locales/sections/rewards/es/translate.json';
import breadcrumbs_en from 'locales/sections/breadcrumbs/en/translate.json';
import breadcrumbs_es from 'locales/sections/breadcrumbs/es/translate.json';
import massiveMint_en from 'locales/sections/massiveMint/en/translate.json';
import massiveMint_es from 'locales/sections/massiveMint/es/translate.json';
import nfts_en from 'locales/sections/nfts/en/translate.json';
import nfts_es from 'locales/sections/nfts/es/translate.json';
import nftDetails_en from 'locales/sections/nftDetails/en/translate.json';
import nftDetails_es from 'locales/sections/nftDetails/es/translate.json';
import post_en from 'locales/sections/post/en/translate.json';
import post_es from 'locales/sections/post/es/translate.json';
import dashboard_theme_en from 'locales/sections/dashboardTheme/en/translate.json'
import dashboard_theme_es from 'locales/sections/dashboardTheme/es/translate.json'
import wallet_theme_en from 'locales/sections/walletTheme/en/translate.json'
import wallet_theme_es from 'locales/sections/walletTheme/es/translate.json'
import redirect_en from 'locales/sections/redirect/en/translate.json';
import redirect_es from 'locales/sections/redirect/es/translate.json';
import participant_table_en from 'locales/sections/participantTable/en/translate.json';
import participant_table_es from 'locales/sections/participantTable/es/translate.json';
import posts_table_en from 'locales/sections/postsTable/en/translate.json';
import posts_table_es from 'locales/sections/postsTable/es/translate.json';
import targets_table_en from 'locales/sections/targetsTable/en/translate.json';
import targets_table_es from 'locales/sections/targetsTable/es/translate.json';
import targets_en from 'locales/sections/targets/en/translate.json';
import targets_es from 'locales/sections/targets/es/translate.json';
import messages_en from 'locales/sections/messages/en/translate.json';
import messages_es from 'locales/sections/messages/es/translate.json';
import social_media_feeds_en from 'locales/sections/socialMediaFeeds/en/translate.json';
import social_media_feeds_es from 'locales/sections/socialMediaFeeds/es/translate.json';
import underConstruction_en from 'locales/sections/underConstruction/en/translate.json';
import underConstruction_es from 'locales/sections/underConstruction/es/translate.json';
import profile_en from 'locales/sections/profile/en/translate.json';
import profile_es from 'locales/sections/profile/es/translate.json';
import gasStation_en from 'locales/sections/gasStation/en/translate.json';
import gasStation_es from 'locales/sections/gasStation/es/translate.json';
import inputs_es from 'locales/sections/inputs/es/translate.json';
import inputs_en from 'locales/sections/inputs/en/translate.json';
import smartTooltipModal_en from 'locales/sections/smartTooltipModal/en/translate.json';
import smartTooltipModal_es from 'locales/sections/smartTooltipModal/es/translate.json';
import accessControl_en from 'locales/sections/access_control/en/translate.json';
import accessControl_es from 'locales/sections/access_control/es/translate.json';
import invite_client_en from 'locales/sections/invite_client/en/translate.json';
import invite_client_es from 'locales/sections/invite_client/es/translate.json';
import campaignAnalytics_en from 'locales/sections/campaignAnalytics/en/translate.json';
import campaignAnalytics_es from 'locales/sections/campaignAnalytics/es/translate.json';

const resources = {
  en: {
    collections: collections_en,
    benefits: benefit_en,
    payments: payment_en,
    events: events_en,
    users : user_en,
    clients : client_en,
    campaigns: campaigns_en,
    apikeys: apikeys_en,
    tables: tables_en,
    projects: projects_en,
    menuDashboard: menuDashboard_en,
    overview: overview_en,
    login: login_en,
    form: form_en,
    rewards: rewards_en,
    breadcrumbs: breadcrumbs_en,
    massiveMint: massiveMint_en,
    nfts: nfts_en,
    nftDetails: nftDetails_en,
    post: post_en,
    dashboardTheme: dashboard_theme_en,
    walletTheme: wallet_theme_en,
    redirect: redirect_en,
    participantTable: participant_table_en,
    postsTable: posts_table_en,
    targetsTable: targets_table_en,
    targets: targets_en,
    messages: messages_en,
    socialMediaFeeds: social_media_feeds_en,
    underConstruction: underConstruction_en,
    profile: profile_en,
    gasStation: gasStation_en,
    inputs: inputs_en,
    smartTooltipModal: smartTooltipModal_en,
    accessControl: accessControl_en,
    inviteClient:invite_client_en,
    campaignAnalytics: campaignAnalytics_en
  },
  es: {
    collections: collections_es,
    benefits: benefit_es,
    payments: payment_es,
    events: events_es,
    users: user_es,
    clients : client_es,
    campaigns: campaigns_es,
    apikeys: apikeys_es,
    tables: tables_es,
    projects: projects_es,
    menuDashboard: menuDashboard_es,
    overview: overview_es,
    login: login_es,
    form: form_es,
    rewards: rewards_es,
    breadcrumbs: breadcrumbs_es,
    massiveMint: massiveMint_es,
    nfts: nfts_es,
    nftDetails: nftDetails_es,
    post: post_es,
    dashboardTheme: dashboard_theme_es,
    walletTheme: wallet_theme_es,
    redirect: redirect_es,
    participantTable: participant_table_es,
    postsTable: posts_table_es,
    targetsTable: targets_table_es,
    targets: targets_es,
    messages: messages_es,
    socialMediaFeeds: social_media_feeds_es,
    underConstruction: underConstruction_es,
    profile: profile_es,
    gasStation: gasStation_es,
    inputs: inputs_es,
    smartTooltipModal: smartTooltipModal_es,
    accessControl: accessControl_es,
    inviteClient: invite_client_es,
    campaignAnalytics: campaignAnalytics_es
  }
};

const identifySection = [
  'collections',
  'benefits',
  'payments',
  'events',
  'users',
  'clients',
  'campaigns',
  'apikeys',
  'tables',
  'projects',
  'menuDashboard',
  'overview',
  'login',
  'form',
  'rewards',
  'breadcrumbs',
  'massiveMint',
  'nfts',
  'nftDetails',
  'post',
  'dashboardTheme',
  'walletTheme',
  'redirect',
  'participantTable',
  'postsTable',
  'targetsTable',
  'targets',
  'messages',
  'socialMediaFeeds',
  'underConstruction',
  'profile',
  'gasStation',
  'inputs',
  'smartTooltipModal',
  'accessControl',
  'inviteClient',
  'campaignAnalytics'
]

i18n
  .use(detector)
  .use(initReactI18next) 
  .init({
    resources,
    fallbackLng: "en/En", 
    ns: identifySection,
    defaultNS: 'translate',
    detection: resources,
    interpolation: {
      escapeValue: false 
    }
  })

i18n.changeLanguage()

export default i18n;