import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import './tabs.module.css';

const ViewNftInfo = ({NFTdata, t}) => {

  const { 
    token_id, 
    name,
    description,
    creator,
    owner,
    status,
  } = NFTdata;
 

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Grid item xs>
        <Card>
          <CardContent>
            <Typography variant="caption" color="textSecondary">{t("tabs.info.id")}</Typography>
            <Typography variant="body1">{token_id}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card>
          <CardContent>
            <Typography variant="caption" color="textSecondary">{t("tabs.info.name")}</Typography>
            <Typography variant="body1">{name}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card>
          <CardContent>
            <Typography variant="caption" color="textSecondary">{t("tabs.info.description")}</Typography>
            <Typography variant="body1">{description}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card>
          <CardContent>
            <Typography variant="caption" color="textSecondary">{t("tabs.info.creator")}</Typography>
            <Typography variant="body1">{creator}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card>
          <CardContent>
            <Typography variant="caption" color="textSecondary">{t("tabs.info.owner")}</Typography>
            <Typography variant="body1">{owner}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card>
          <CardContent>
            <Typography variant="caption" color="textSecondary">{t("tabs.info.status")}</Typography>
            <Typography variant="body1">{status}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

}

export default ViewNftInfo;