import React, { Fragment } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { isURL } from 'components/DynamicTable/utils/url';

const isMultimedia = {
  "image":true,
  "video":true,
  "thumbnail":true,
  "banner":true,
  "image_url":true,
  "thumb_url":true
}

const RenderAdditionalOptionsCells = ({configOptions,row}) => {

  const handleClickButton = (e,onClick,item) => {
    e.preventDefault();
    e.stopPropagation();
    if(onClick !== null && onClick !==undefined && typeof onClick === 'function'){
      onClick(item);
      }
  }

  return (
    <Fragment>
    {configOptions?.map((option,index) => {
      const { label, onClick, render } = option;
        if (render) {
          return (render(row))
        }
        return (
          <TableCell 
            key={`configOptions-${index}`}
            align="center"
            sx={{
              "&,.MuiTableCell-root":{
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
                width: 'auto'
              }
            }}
          >
            <Button
              variant="outlined"
              color="info"
              size="small"
              onClick={(e) => handleClickButton(e,onClick,row)}
            >
              {label}
            </Button>
          </TableCell>
        )
      }
    )}  
  </Fragment>)
}

const CustomCell = ({
  value,
  currentKey,
  cellWidth,
  configCells
}) => {

  const config = configCells?.[currentKey] || {
    render: null,
    align: "left",
    style: {}
  };

  const { 
    render,
    align,
    style = {
      width: `${cellWidth}`,
      height: `${cellWidth}`,
      overflow: 'hidden'
    }
  } = config;

  const reduceText = (text, maxLines) => {
    return String(text).length > maxLines ?
    String(text + '').substring(0, maxLines) + '...' : text;
  }

  if(render !== null) {
    return render(value);
  }

  const newAlign = typeof value === 'number' ? "center" : align;
  const typeValue = typeof value;

  const VALUES_TYPE = {
    "number": (value) => String(value),
    "string": (value) => String(value),
    "boolean": (value) => String(value),
    "object": (value) => String(value),
    "array": (value) => String(value)
  }

  const newVAlue = VALUES_TYPE?.[typeValue]?.(value) || '---';
  const isImageURL = isURL(newVAlue) && isMultimedia?.[currentKey];
  const [type,setType] = React.useState('img');
  
  const handleError = () => {
    if(type === "img"){
      setType("video");
    } else if (type === "video") {
      setType("error");
    }
  }

  return (
    <TableCell align={newAlign} sx={style}>
      {isImageURL ? (
        <CardMedia
          component={type}
          alt={newVAlue + ''}
          src={newVAlue + ''}
          sx={{
            width: cellWidth ? cellWidth / 2 : 60,
            height: cellWidth ? cellWidth / 2 : 60,
            maxHeight: 60,
            maxWidth: 60
          }}
          loop={type === "video"}
          autoplay={type === "video"}
          onError={handleError}
        />
      ) : (
        reduceText(newVAlue,50)
      )}
    </TableCell>
  );
}

const DynamicCell = ({ 
  value,
  currentKey = null,
  cellWidth,
  configCells,
  allowedKeys
}) => {

  const props = {
    value,
    currentKey,
    cellWidth,
    configCells
  };


  if(typeof value === 'object') {
    const cells = allowedKeys?.length > 0 ? allowedKeys : Object.keys(value);
    return (
      <Fragment>
        {cells?.map((key,index) => { 
          return (
            <CustomCell 
              key={key?.id || index} 
              {...props}
              value={value[key]}
              currentKey={key}
            />
          )
        })}
      </Fragment>
    )
  }

  return <CustomCell {...props} />
  
};

const DynamicBodyTable = ({
  cellWidth,
  allowedKeys,
  rows,
  configCells,
  onClick,
  configOptions,
  activePointer,
  selected,
  setSelected,
  isSelected,
  configSelected,
  selectedItems,
  setSelectedItems
}) => {

  const renderCheckBox = (labelId,isItemSelected,configSelected,handleSelectedRow,row)=>
    configSelected ? 
    <TableCell 
      padding="checkbox" 
      onClick={(e)=>handleSelectedRow(e,row[configSelected?.defineId] || row.id, row)}
    >
      <Checkbox
        data-testid={`checkbox-selectect-row-${configSelected?.defineId || row.id}`}
        color="primary"
        checked={isItemSelected}
        inputProps={{
          'aria-labelledby': labelId,
        }}
      />
    </TableCell> : null;

  const handleClick = (e,item) => {
    e.preventDefault();
    e.stopPropagation();
    if(onClick){
      onClick(item);
    }
  }

  const handleSelectedRow = (e, id, row) => {
    e.preventDefault();
    e.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [...selected];
    let newSelectedItems = [...selectedItems];
  
    if (selectedIndex === -1) {
      newSelected.push(id);
      newSelectedItems.push(row);
    } else {
      newSelected.splice(selectedIndex, 1);
      newSelectedItems.splice(selectedIndex, 1);
    }
  
    setSelected(newSelected);
    setSelectedItems(newSelectedItems);
  
    if (configSelected?.onSelect && typeof configSelected?.onSelect === 'function') {
      configSelected.onSelect({ arraySelectedById: newSelected, allSelectedItems: newSelectedItems });
    }
  };

  return (
    <TableBody>
      {rows?.map((row, rowIndex) => {
        const isItemSelected = isSelected(row[configSelected?.defineId] || row.id);
        const labelId = `enhanced-table-checkbox-${rowIndex}`;
        return (
          <TableRow 
            key={row?.id || rowIndex} 
            hover 
            tabIndex={-1} 
            selected={isItemSelected}
            onClick={(e) => {handleClick(e, row)}}
            sx={{ 
              cursor: activePointer ? 'pointer' : 'default'
            }}
          >
            {renderCheckBox(labelId,isItemSelected,configSelected,handleSelectedRow,row)}
            <DynamicCell
              value={row}
              cellWidth={cellWidth}
              configCells={configCells}
              allowedKeys={allowedKeys}
            />
            <RenderAdditionalOptionsCells
              configOptions={configOptions}
              row={row}
            />
          </TableRow>
        );
      })}
    </TableBody>
  )
};

export default DynamicBodyTable;
