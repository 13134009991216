import React, { useCallback, useState } from 'react';
import styles from './ListItemText.module.css';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const Item = ({ item, pos }) => {

  const value = item && typeof item === "object" ? item?.value+'' : item+'';

  return (
    <div className={styles.item}>
      {pos+' - '}
      <TextField
        size="small"
        value={value}
        style={{ backgroundColor: 'white',borderRadius:'8px' }}
        error={false}
        disabled={true}
        InputProps={{
          sx: {
            "&.Mui-disabled": {
              "& .MuiInputBase-input": {
                WebkitTextFillColor: '#000',
                color: '#000',
                opacity: 1
              },
              "& .MuiOutlinedInput-notchedOutline": {
                // borderColor: 'red', // Cambia el color del borde
              },
            },
          },
        }}
      />
      <div>
        <IconButton ><RemoveCircleOutlineIcon /></IconButton>
      </div>
    </div>
  )
}

const ListItemText = ({
  title,
  data,
  configForm,
  onSubmit
}) => {

  const [ input,setInput ] = useState('');

  const handleChangeInput = useCallback((e)=>{
    const { value } = e.target;
    setInput(value+'')
  },[])

  const handleOnSubmit = async() => {
    if(onSubmit && typeof onSubmit === "function"){
      onSubmit(input);
    }
  }

  return (
    <div className={styles.contentList}>
      <Typography  color="text.primary" variant="h6" gutterBottom >
        {title}
      </Typography>
      <div className={`${styles.form}`}>
        <div>
        <TextField
          size="small"
          style={{ backgroundColor: 'white',borderRadius:'8px' }}
          error={false}
          value={input}
          onChange={handleChangeInput}
          {...configForm}
        />
        </div>
        <div>
          <IconButton onClick={handleOnSubmit} ><AddCircleOutlineIcon /></IconButton>
        </div>
        <div style={{flex:1}} />
      </div>
      <div className={styles.contentItem}>
        {
          data && data?.length > 0 && 
          data?.map((item,index)=>{
            return (
              <Item key={index} item={item} pos={index+1} />
            )
          }).reverse()
        }
      </div>
    </div>
  );
};

ListItemText.defaultProps = {
  title: '',
  data: [0,1,2],
  configForm: {},
  onSubmit: ()=>{}
}

ListItemText.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  configForm: PropTypes.any,
  onSubmit: PropTypes.func
};

export default ListItemText;