import React, { useCallback, useContext, useEffect, useState } from 'react';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import styles from './UserForm.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { handleErrors } from 'utils/messages';
import { getRolesCustom } from 'services/roles';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import { useFetch } from 'hooks/useFetch';
import { register, findByWallet, updateUsers } from 'services/user';
import UserContext from 'context/UserContext';
import { SpinnerContext } from 'context/SpinnerContext';
import PropTypes from 'prop-types';

const useUserToEdit = () => {
  const [ loadingUserToEdit, setLoadingUserToEdit ] = useState(false);

  const getDataUserToEdit = async(userAddress) => {
    try{
      setLoadingUserToEdit(true)
      const user = await findByWallet(process.env.REACT_APP_DOMAIN,process.env.REACT_APP_NAME_NETWORK,userAddress);
      return user;
    } catch (err){
      console.log(err);
    } finally {
      setLoadingUserToEdit(false)
    }
  }

  return {
    getDataUserToEdit,
    loadingUserToEdit
  };
}

export const EditUser = ({ isEditMode, mainKey, userLogged }) => {
  const isAllowedUser = userLogged?.role >= 80;
  const { handleChangeError, handleChangeLoading, handleChangeMessageSuccess } = useContext(SpinnerContext);
  const { t } = useTranslation('users');
  const { t:t_form } = useTranslation('form');
  const navigate = useNavigate();
  const [ step, setStep ] = useState(0);
  const [ newUserTemp, setNewUserTemp ] = useState(null);
  const [ editUserTemp, setEditUserTemp ] = useState(null);
  const [ successProcess, setSuccessProcess ] = useState(false);
  const { getDataUserToEdit, loadingUserToEdit } = useUserToEdit();
  const { data: roles, loading:loadingRoles, error: errorRoles } = useFetch(getRolesCustom,{
    params: {}
  });
  const allRoles = roles?.map((item)=>{
    return {
      ...item,
      value: item.key,
      label: item.role_name
    }
  }) || [];

  const handleCancel = useCallback(() => {
    navigate(`/dashboard/users`);
  },[]);

  const handleBack = useCallback(() => {
    setStep((current)=>current-1)
  },[setStep]);

  const updateUser = useCallback(async (newUser) => {
    const body = {
      ...newUser,
      sign: 'update',
      message: 'update'
    }
    const res = await updateUsers(
      process.env.REACT_APP_DOMAIN,
      userLogged?.wallet,
      body
    )
    if(res){
      setSuccessProcess(true);
      handleChangeMessageSuccess(t("messages.success_process"));
    }
  },[updateUsers,editUserTemp,userLogged,setSuccessProcess,handleChangeMessageSuccess]);

  const createUser = useCallback(async (newUser) => {
    const body = {
      ...newUser,
      sign: 'create',
      message: 'create'
    }
    console.log("newUser: ", newUser);

    await register(
      process.env.REACT_APP_DOMAIN,
      process.env.REACT_APP_NAME_NETWORK,
      body
    )

    navigate(`/dashboard/users`);

  },[register]);

  const handleSubmit = useCallback(async () => {
    try{    
      handleChangeLoading(true);
      if(isEditMode){ await updateUser(newUserTemp); return; }
      await createUser(newUserTemp);
    } catch (err){
      console.log(err);
      handleChangeError(handleErrors(err+'')?.message || t("messages.an_occurred_error"));
    } finally {
      handleChangeLoading(false);
    }
  },[isEditMode,newUserTemp,handleChangeLoading,updateUser,createUser,handleChangeError]);

  const handleNext = useCallback(async (values) => {
    const newUser = {
      wallet : mainKey || '',
      username : values?.username,
      email : values?.email,
      role: values?.role
    }
    setNewUserTemp(newUser)
    setStep(1)
  },[setNewUserTemp,setStep,newUserTemp,mainKey]);

  

  useEffect(()=>{
    if(errorRoles){
      handleChangeError(handleErrors(errorRoles+'')?.message || t("messages.roles_not_loaded"));
    }
  },[errorRoles,handleChangeError,handleErrors])

  useEffect(()=>{
    handleChangeLoading((loadingRoles || loadingUserToEdit))
  },[loadingRoles,loadingUserToEdit])

  useEffect(()=>{
    if(!isAllowedUser){
      handleChangeError(t("messages.not_authorized"))
      navigate(`/dashboard/users`);
    }
  },[handleChangeError])

  const getInfoToEdit = useCallback(async() => {
    if(isEditMode){
      const res = await getDataUserToEdit(mainKey)
      setEditUserTemp(res || null)
    }
  },[isEditMode,mainKey,getDataUserToEdit])

  useEffect(()=>{
    getInfoToEdit();
  },[])

  const initValuesNew = {
    username: 'string_short',
    role: 'select',
    email: 'email',
  }

  const getValueToEditCurrent = (name) =>  {
    if(newUserTemp){
      return newUserTemp[name] ?? '';
    }
    if(editUserTemp){
      return editUserTemp[name] ?? '';
    }
  }

  return (
    <div className={styles.contentSection}>
      <Stepper activeStep={step} orientation="vertical">
        <Step>
          <StepLabel>
            <div className={styles.headerSection}>
            <Typography variant="h6" gutterBottom >
              {isEditMode ? t("edit_user.title") : t("create_user.title")}
            </Typography>
            </div>
            <div className={styles.subHeaderSection}>
              <Typography variant="body2" gutterBottom flex={1}>
              {isEditMode ? t("edit_user.description") : t("create_user.description")}
              </Typography>
            </div>
          </StepLabel>
          <StepContent>
            <div className={styles.workSpace}>
              <NewFormDynamic
                enableReinitialize={isEditMode}
                initValues={initValuesNew}
                initStateWithValues={
                  isEditMode ? {
                    username: getValueToEditCurrent("username") || '',
                    email: getValueToEditCurrent("email") || '',
                    role: {
                      value: allRoles?.find((item)=>item.key === Number(getValueToEditCurrent("role")))?.key || '',
                      options: allRoles
                    }
                  } : {
                    role: {
                      value: '',
                      options: allRoles
                    }
                  }
                }
                loading={loadingRoles || (!isAllowedUser)}
                onSubmit={handleNext}
                t={t_form}
                handleCancel={handleCancel}
                textButtons={{
                    submit:t("options.next"),
                    cancel:t("options.cancel")
                }}
                textLabels={{
                  username:t("form.name"),
                  role:t("form.role"),
                  email:t("form.email")
                }}
                textPlaceholders={{
                  username:t("form.name"),
                  role:t("form.role"),
                  email:t("form.email")
                }}
              />
            </div>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>
            <div className={styles.headerSection}>
            <Typography variant="h6" gutterBottom >
              {isEditMode ? t("confirm_update.title") : t("confirm.title")}
            </Typography>
            </div>
            <div className={styles.subHeaderSection}>
              <Typography variant="body2" gutterBottom flex={1}>
              {isEditMode ? t("confirm_update.description") : t("confirm.description")}
              </Typography>
            </div>
          </StepLabel>
          <StepContent>
            <div className={styles.workSpace} >
              <Button
                onClick={handleSubmit}
                variant='contained'
                color='secondary'
                disabled={successProcess}
                data-testid="button-confirm"
              >
                {t("options.confirm")}
              </Button>
              <Button
                onClick={handleBack}
                variant='contained'
                color='primary'
                disabled={successProcess}
                data-testid="button-back"
              >
                {t("options.back")}
              </Button>
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
};

EditUser.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  mainKey: PropTypes.string,
  userLogged: PropTypes.any
};

const UserForm = () => {
  const { mainKey } = useParams();
  const isEditMode = !!mainKey;
  const { user } = useContext(UserContext);

  return (
    <EditUser isEditMode={isEditMode} mainKey={mainKey} userLogged={user} />
  )
}

export default UserForm