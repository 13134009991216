import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik'
import { capitalizeFirstLetter } from 'utils/strings';


import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CustomLabel from '../utils/customLabel';

const WrappedMaterialTextarea = ({ field, form, ...props }) => {
  const [editorState, setEditorState] = useState(determineInitialValue(field.value));
  const { setFieldValue, setFieldTouched } = form;

  const handleChange = (editorState) => {
    const rawHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState);
    setFieldValue(field.name, rawHtml);
    setFieldTouched(field.name, true);
  }

  return (
    <div data-testid="rich-text-editor">
      <Editor
        editorState={editorState}
        onEditorStateChange={handleChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        {...props}
      />
    </div>
  )
}

const determineInitialValue = (initValue) => {
  if (initValue) {
    const blocksFromHTML = convertFromHTML(initValue);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    return EditorState.createWithContent(state);
  }
  return EditorState.createEmpty();
}

const InputRichTextArea = ({ name, placeholder, label, loading, inputDescription }) => {
  const description = inputDescription ? inputDescription : undefined;
  return (
    <Fragment>
      <CustomLabel htmlFor={name} description={description} >
        {label ? label : capitalizeFirstLetter(name)}
      </CustomLabel>
      <Field
        placeholder={placeholder ? placeholder : capitalizeFirstLetter(name)}
        name={name}
        disabled={loading}
        component={WrappedMaterialTextarea}
      />
    </Fragment>
  );
};

InputRichTextArea.propTypes = {
  name: PropTypes.string,
  loading: PropTypes.bool
};

export default InputRichTextArea;