import React, { Fragment, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@mui/material/FormLabel';
import { FieldArray, Field } from 'formik'
import { capitalizeFirstLetter } from 'utils/strings';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { stringToObjectField } from "utils/strings";
import SmartTooltipModal from 'components/SmartTooltip/SmartTooltipModal';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ATTRS_RESTRICTION_LEVELS } from 'utils/constants';


const WrappedMaterialTextField = ({ field, form: { touched, errors }, ...props }) => {

  const getTouched = () => {
    const isTouched = stringToObjectField(field.name, touched);
    if (isTouched === null) return false;
    return isTouched;
  }

  const getError = () => {
    if (!getTouched()) return "";
    const error = stringToObjectField(field.name, errors);
    if (error === null) return "";
    return error;
  }

  return (
    <TextField
      {...field}
      {...props}
      error={(Boolean(getTouched()) && Boolean(getError()))}
      helperText={getError()}
      variant="outlined"
    />
  )
}

const styleInput = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '1rem',
}

const InputAttr = ({ name, placeholder, label, loading, inputDescription }) => {

  return (
    <div data-testid={`input-attr-${name}`}>

      <FieldArray
        disabled={loading}
        name={name} 
        render={(arrayHelpers) => (
          <RenderInputAttrs
            arrayHelpers={arrayHelpers}
            name={name}
            label={label}
            placeholder={placeholder}
            inputDescription={inputDescription}
          />
        )}
      />
    </div>
  );
};

function RenderInputAttrs({arrayHelpers, name, label, placeholder, inputDescription}) {
  const { form, push, remove } = arrayHelpers;
  const { values } = form;
  const [initValues, setInitValues] = useState(null);
  const items = values[name] || [];
  const description = inputDescription ? inputDescription : undefined;
  const { t } = useTranslation('inputs');

  useLayoutEffect(() => {
    if (initValues === null) {
      setInitValues(items);
      const formatedInitialAttributes = items.map(attr => {
        return { name: attr.name, value: attr.value }
      });
      form.setFieldValue(name, formatedInitialAttributes);
    }
  }, [items]);

  const handleAdd = () => {
    const initValue = { name: '', value: '' }
    push(initValue);
  }

  const handleRemove = (index) => {
    remove(index);
  }

  if (items.length === 0) {
    return (
      <Stack sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '1rem'
      }}>
        {description ? (
          <SmartTooltipModal text={description}>
            <FormLabel >{label ? label : capitalizeFirstLetter(name)}</FormLabel>    
          </SmartTooltipModal>
        ):(
          <FormLabel >{label ? label : capitalizeFirstLetter(name)}</FormLabel>
        )}
        <IconButton
          data-testid="add-first-attr"
          onClick={handleAdd}
        >
          <AddIcon />
        </IconButton>
      </Stack>
    )
  }

  const shouldDisableValue = (index) => {
    if (initValues && index < initValues.length) {
      return initValues[index].restriction_level === DEFAULT_ATTRS_RESTRICTION_LEVELS.REQUIRED;
    }
    return false;
  }

  const shouldDisableRemoveButton = (index) => {
    if (initValues && index < initValues.length) {
      return initValues[index].restriction_level !== DEFAULT_ATTRS_RESTRICTION_LEVELS.OPTIONAL;
    }
    return false;
  }

  return (
    <Fragment>
      <FormLabel >{label ? label : capitalizeFirstLetter(name)}</FormLabel>
      {
        values[name]?.map((_, index) => (
          <Box
            key={index}
            flex={1}
            sx={{
              marginBottom: '0.8rem'
            }}
          >
            <Box
              sx={styleInput}
            >
              <Box
                data-testid={`input-attr-${name}-name-${index}`}
              >
                <Field
                  disabled={initValues && index < initValues.length}
                  name={`${name}.${index}.name`}
                  style={{ backgroundColor: 'white', borderRadius: '8px' }}
                  placeholder={placeholder ? placeholder : t("input_attributes.add_attribute")}
                  component={WrappedMaterialTextField}
                />
              </Box>
              <Box
                data-testid={`input-attr-${name}-value-${index}`}
              >
                <Field
                  disabled={shouldDisableValue(index)}
                  name={`${name}.${index}.value`}
                  style={{ backgroundColor: 'white', borderRadius: '8px' }}
                  placeholder={placeholder ? placeholder : t("input_attributes.add_value")}
                  component={WrappedMaterialTextField}
                />
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}>
                <IconButton
                  disabled={shouldDisableRemoveButton(index)}
                  data-testid={`input-attr-${name}-remove-${index}`}
                  onClick={() => handleRemove(index)}
                >
                  <DeleteIcon />
                </IconButton>
                {index === (items.length - 1) &&
                  <IconButton
                    data-testid={`input-attr-${name}-add-${index}`}
                    onClick={handleAdd}
                  >
                    <AddIcon />
                  </IconButton>
                }
              </Box>
            </Box>

          </Box>
        ))
      }
    </Fragment>
  )
}

InputAttr.propTypes = {
  name: PropTypes.string,
  loading: PropTypes.bool
};

export default InputAttr;
