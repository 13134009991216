import { STATUS_COLLECTION } from "utils/collectionStatus";

export const filterSearch = {
  'pending': (value) => String(value).toLowerCase().startsWith(String(STATUS_COLLECTION.PENDING_APPROVE)),
  'approved': (value) => String(value).toLowerCase().startsWith(String(STATUS_COLLECTION.APPROVED)),
  'loading_nfts': (value) => String(value).toLowerCase().startsWith(String(STATUS_COLLECTION.LOADING_NFTs)),
  'loaded_nfts': (value) => String(value).toLowerCase().startsWith(String(STATUS_COLLECTION.LOADED_NFTs)),
  'pending_approve_nfts': (value) => String(value).toLowerCase().startsWith(String(STATUS_COLLECTION.PENDING_APPROVE_NFTs)),
  'approved_nfts': (value) => String(value).toLowerCase().startsWith(String(STATUS_COLLECTION.APPROVED_NFTs)),
  'rejected_nfts': (value) => String(value).toLowerCase().startsWith(String(STATUS_COLLECTION.REJECTED_NFTs)),
  'rejected': (value) => String(value).toLowerCase().startsWith(String(STATUS_COLLECTION.REJECTED)),
  'in_production': (value) => String(value).toLowerCase().startsWith(String(STATUS_COLLECTION.IN_PRODUCTION)),
  'hide_collection': (value) => String(value).toLowerCase().startsWith(String(STATUS_COLLECTION.HIDE_COLLECTION)),
};

export const searchValue = (data,search) => {
  const res = search && data?.filter(item => {
    return Object.values(item).some(value => {
      const searchLower = String(search).toLowerCase();
      if(value){
        if (typeof value === "object") {
          return Object.values(value).some(val => typeof val === 'string' && String(val+'').toLowerCase().includes(search.toLowerCase()));
        }
        if (filterSearch?.[searchLower]) {
          return filterSearch?.[searchLower]?.(value);
        }  
        return typeof value === 'string' && String(value+'').toLowerCase().includes(search.toLowerCase());
      }else{
        return false
      }
    });
  }) || data ;
  return res;
}

const FILTERS_MENU = {
  "===": (rowValue, filterValue) => String(rowValue) === String(filterValue),
  "!==": (rowValue, filterValue) => String(rowValue) !== String(filterValue),
  ">": (rowValue, filterValue) => String(rowValue) > String(filterValue),
  "<": (rowValue, filterValue) => String(rowValue) < String(filterValue),
  ">=": (rowValue, filterValue) => String(rowValue) >= String(filterValue),
  "<=": (rowValue, filterValue) => String(rowValue) <= String(filterValue),
  "includes": (rowValue, filterValue) => {
    return String(rowValue).includes(String(filterValue))
  },
  "not_includes": (rowValue, filterValue) => !String(rowValue).includes(String(filterValue)),
}

export const searchInObjectsWithRecursiveFilter = (value, valueToSearch, operator) => {
  if (typeof value === 'object') {
    return Object.values(value).some(valRow => {
      if(typeof valRow === 'object'){
        searchInObjectsWithRecursiveFilter(valRow, valueToSearch,operator);
      }
      return valRow === valueToSearch && FILTERS_MENU[operator](valRow, valueToSearch);
    });
  }
}

const applyFilterToStatus = (rowValue,filterValue,operator) =>{
  return FILTERS_MENU[operator](rowValue, filterValue) || false;
}

const ESPECIALLY_FILTERS = {
  "status": applyFilterToStatus
}

export const applyFilter = (data, filter) => {
  const { column, operator, value: search } = filter;
  if(column && operator && search){
    return data?.filter(row => {
      const rowValue = row[column];
      return ESPECIALLY_FILTERS?.[column]?.(rowValue,search,operator) ||
      FILTERS_MENU[operator](rowValue, search);
    }) || [];
  }
  return null;
}

export const availableFiltersDic = (
  t=(val)=>val+''
) => {
  const filtersKeys = Object.keys(FILTERS_MENU);
  return filtersKeys.map(key => {
    return {
      label: t(`filter.options.${key}`),
      value: key,
    }
  })
}

export const  stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const getComparator = (order, orderBy)  => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


