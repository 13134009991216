import { useContext, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import ColorsInfo from "components/ColorsInfo/ColorsInfo";
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { BreadCrumbsSaveData } from "components/BreadCrumbs/BreadCrumbs";
// import { useRewards } from "hooks/useReward";
import UserContext from 'context/UserContext';
import { useSpinnerContext } from 'context/SpinnerContext';
import { update } from 'services/rewards/rewards';
import { getRewards } from 'services/rewards/rewards';
import { useFetch } from 'hooks/useFetch';
import { getActionsByRoleCode } from 'utils/rols';
import DynamicTable from "components/DynamicTable/DynamicTable";



export default function Rewards() {
  const { t } = useTranslation("rewards");
  const navigate = useNavigate();
  const { t: t_benefits } = useTranslation("benefits");
  const { data: rewards, loading, refresh } = useFetch(getRewards,{
    params: { limit: 100, page: 0, order: 'created' }
  });
  const { user } = useContext(UserContext);
  const { handleChangeLoading ,handleChangeMessage, handleChangeError } = useSpinnerContext();
  const [ itemStatus, setItemStatus ] = useState([
    {
      label: t_benefits("status_info.pending_review"),
      color: '#ed6c02'
    },
    {
      label: t_benefits("status_info.in_progress"),
      color: '#2196f3'
    },
    {
      label: t_benefits("status_info.waiting_for_approval"),
      color: '#f44336'
    },
    {
      label: t_benefits("status_info.approved"),
      color: '#4caf50'
    }
  ]);
  const STATUS_REWARD ={
    0: {
      label: t("status_reward.status_pending"),
      color: "warning"
    },
    1: {
      label: t("status_reward.status_approved"),
      color: "success"
    },
    7: {
      label: t("status_reward.status_rejected"),
      color: "error"
    }
  }

  const configSelected = {
    defineId: 'name',
    // onSelect: ({ row, arraySelectedById }) => {
    //   console.log("debug item row::",row);
    //   console.log("debug arraySelectedById::",arraySelectedById)
    // },
    options:[
      {
        id:'approve',
        label: 'Approve',
        color: 'success',
        variant: 'contained',
        tooltip:'Click to approve',
        onClick: async (item) => {
          try {
            handleChangeLoading(true);
            handleChangeMessage(t("message.approving"));
            let rewardList = []
            for (let reward of item) {
              reward.status = 1;
              rewardList.push(reward)
            }
            await update({rewards : rewardList});
            handleChangeMessage(t("message.successful_approval"));
            refresh();
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,"approveCollection") && item?.status === 0);
        }      
      },
      {
        id:'rejected',
        label: 'Rejected',
        color: 'error',
        variant: 'contained',
        tooltip:'Click to rejected',
        onClick: async(item) => {
      
          try {
            handleChangeLoading(true);
            handleChangeMessage(t("message.rejecting"));
            let rewardList = []
            for (let reward of item) {
              reward.status = 7
              rewardList.push(reward)
            }
            await update({rewards : rewardList});
            handleChangeMessage(t("message.rejected"));
            refresh();
          } catch (error) {
            handleChangeError(error?.message|| ('An unexpected error occurred:'+ error));
          } finally {
            handleChangeLoading(false);
          }
        },
        conditions: (item)=>{
          return (getActionsByRoleCode(user?.role,"rejectedCollection") && item?.status === 0);
        }      
      }
    ]
  }

  const handleGoTo = (to) => () => {
    navigate(`/dashboard/rewards/${to}`);
  }

  const configCells = {
    status:{
      render: (item) => (
        <TableCell>
          <Box
             sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              gap:'0.7rem',
             }}
          >
            <Chip
              label={STATUS_REWARD[item]?.label}
              color={STATUS_REWARD[Number(item)]?.color} 
            />
          </Box>
        </TableCell>
      )
    }
  }

  return (
    <section>
      <div>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={10}>
            <Typography variant="body2" gutterBottom flex={1}>
              {t("description")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined" 
              color="info"
              fullWidth
              onClick={handleGoTo("create")}
            >
              + {t("options.create_reward")}
            </Button>
          </Grid>
        </Grid>
      </div>
      <div>
        <DynamicTable
          title={t("table_title")}
          activeSearchBar={true}
          activeModeView={false}
          data={rewards}
          loading={loading}
          setConfigParams={{
            emptyMessage: t("table_empy_message"),
            configCells,
            configSelected,
            onClick: (reward) =>{
              const value = {
                name: reward.name,
                id: reward.id
              }
              BreadCrumbsSaveData("rewardId",value);
              // navigate(`/dashboard/rewards/edit/${reward.id}`);
              navigate(`/dashboard/rewards/${reward.id}`);
            }
          }}
          preparedDataConfig={(item) => {
            return {
              id: item.id,
              image: item.thumb_url,
              name: item.name,
              description: item.description,
              status: item?.status,
              type: item.reward_type,
              external_id: item.external_id,
              contract_address: item.contract_address ? item.contract_address : t("smartcontract.no_applicable"),
            }
          }}
        />
        <ColorsInfo items={itemStatus} setItemStatus={setItemStatus}/>
      </div>
    </section>
  );
}