import React, { useContext, useState } from 'react';
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import { useTranslation } from 'react-i18next';
import {  uploadFileWithGateway } from 'services/files';
import { Box, Button, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import * as Yup from "yup";
import { useFetch } from 'hooks/useFetch';
import { findDefaultAttrs } from 'services/collection/defaultNFTAttrs';
import UserContext from 'context/UserContext';
import { postCreateNFTByCopy } from 'services/massiveMint/mintCopy';


const STEPS = {
  CONFIGURE: 0,
  CONFIRMATION: 1
}

const ignoreValidation = {
  name: false,
  description: false,
  external_url: true,
  copies: false,
  price: false,
  attributes: false,
  image: false
}

const initValuesStates = {
  name: 'string_short',
  description: 'string_long',
  copies: 'number',
  attributes: 'pair_list',
  image: 'multimedia',
}

const getInitStateWithValues = (blockchainName) => {
  let currencyOptions;
  console.log("Blockchain Name:: ",  blockchainName)

  switch (blockchainName) {
    case "AVALANCHE FUJI":
      currencyOptions = [{ label: "AVAX", value: "AVAX" }, {label: "USDT", value: "USDT"}];
      break;
    case "POLYGON MUMBAI":
      currencyOptions = [{ label: "MATIC", value: "MATIC" }, {label: "USDT", value: "USDT"}];
      break;
    case "SEPOLIA":
      currencyOptions = [{ label: "ETH", value: "ETHER" }, {label: "USDT", value: "USDT"}];
      break;
    default:
      currencyOptions = [
        { label: "ETH", value: "ETHER" },
        { label: "AVAX", value: "AVAX" },
        { label: "MATIC", value: "MATIC" },
        { label: "USDT", value: "USDT" },
      ];
  }

  return {
    name: '',
    description: '',
    external_url: '',
    copies: "1",
    price: "0",
    currency: {
      value: currencyOptions[0].value,
      options: currencyOptions,
    },
    attributes: '',
    image: '',
    mint_type: {
      value: 'MINT',
      options: [
        { label: "MINT", value: "MINT" },
        { label: "DROP", value: "DROP" }
      ],
    },
  };
};

export default function MinterWithCopy({
  isLoading,
  setIsLoading,
  setError,
  collection,
  onCancel,
  onConfirm,
  setNFTs
}) {
  const { executeFunctionIfUserIsConnected } = useContext(UserContext);
  const [config, setConfig] = useState(null);
  const [activeStep, setActiveStep] = useState(STEPS.CONFIGURE);
  const { t } = useTranslation("massiveMint");
  const BASE_TRANSLATE = "mint_types.mint";
  const initState = getInitStateWithValues(collection.blockchain_name);
  const { data: defaultAttrs, loading: loadingDefaultAttrs } = useFetch(findDefaultAttrs, {
    params: { collection_key: collection.collection_key }
  });

  initState.attributes = defaultAttrs.length > 0 ? defaultAttrs : '';

  const handleSubmit = async (values, paramsFormik) => {
    await executeFunctionIfUserIsConnected(async()=>{
      setIsLoading(true);
      try {
        const tesmplateMetadata = await buildTemplateMetadata(values);
        const{ image_url, gateway }= await uploadFileWithGateway(values.image)
        setConfig({
          collection_key: collection.collection_key,
          copies: parseInt(values.copies),
          currency: "",
          price: 0,
          asset_url: gateway + image_url,
          base_data: tesmplateMetadata,
        });
        setNFTs(buildPremintNFTS(
          parseInt(values.copies),
          values.name,
          values.description,
          gateway + image_url
        ))
        console.log("Config:: ", config)
        setActiveStep(STEPS.CONFIRMATION);
      } catch (e) {
        console.log(e);
        setError(t(`${BASE_TRANSLATE}.errors.generic`))
      } finally {
        paramsFormik.setSubmitting(false);
        setIsLoading(false)
      }
    },()=>{
      paramsFormik.setSubmitting(false);
      setIsLoading(false)
    });
  }

  const handleConfirm = async () => {
    await executeFunctionIfUserIsConnected(async()=>{
      setIsLoading(true);
      try {
        await postCreateNFTByCopy(config);
        onConfirm();
      } catch (error) {
        if (error.code === 'ECONNABORTED' || error.message === 'Network Error') {
          onConfirm();
        } else {
          setError(t(`${BASE_TRANSLATE}.errors.generic`));
        }
      } finally {
        setIsLoading(false);
      }
    },()=>{});
  }

  const buildTemplateMetadata = async (values) => {
    return {
      animation_url: values.isAnimation ? values.animation_url : null,
      external_url: values.external_url ? values.external_url : null,
      name: values.name,
      description: values.description,
      properties: values.attributes ? values.attributes : [],
    }
  }

  const buildPremintNFTS = (copies, name, description, imageUrl) => {
    let nftList = []

    for (let i = 1; i <= copies; i++) {
      nftList.push({
        token_id: i,
        metadata: {
          json_data: {
            name: name,
            description: description,
          }
        },
        thumb_url: imageUrl
      })
    }
    return nftList;
  }
  

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>{t(`${BASE_TRANSLATE}.steps.configure`)}</StepLabel>
          <StepContent>
            <FormMint
              isLoading={(isLoading || loadingDefaultAttrs)}
              handleSubmit={handleSubmit}
              onCancel={onCancel}
              initState={initState}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t(`${BASE_TRANSLATE}.steps.confirm`)}</StepLabel>
          <StepContent>
            <Button
              id='minter-with-copy-does-not-confirm'
              onClick={() => setActiveStep(STEPS.CONFIGURE)}
            >
              {t(`${BASE_TRANSLATE}.actions.back`)}
            </Button>
            <Button
              id='minter-with-copy-confirm'
              onClick={() => handleConfirm()}
            >
              {t(`${BASE_TRANSLATE}.actions.confirm`)}
            </Button>
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
}

const FormMint = ({ isLoading, handleSubmit, onCancel, initState }) => {
  if (isLoading) {
    return <FormSkeleton />
  }
  return (
    <Form
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      initState={initState}
    />
  )
}

const FormSkeleton = () => {
  return <Box data-testid="form-mint-skeleton">Loading...</Box>
}

const Form = ({ isLoading, handleSubmit, onCancel, initState }) => {
  const { t } = useTranslation("massiveMint");
  const { t: tForm } = useTranslation("form");
  const BASE_TRANSLATE = "mint_types.mint.form";

  const concatValidations = {
    copies: Yup.number()
    .required(`${tForm("copies.required")}`)
    .min(1, `${tForm("copies.min_length")}`) 
    .max(50000, `${tForm("copies.max_length")}`)  
  }

  const inputDescription = {
    name: t("inputDescriptions.name"),
    description: t("inputDescriptions.description"),
    copies: t("inputDescriptions.copies"),
    attributes: t("inputDescriptions.attributes"),
  }

  return (
    <NewFormDynamic
      initValues={initValuesStates}
      initStateWithValues={initState}
      inputDescriptions={inputDescription}
      loading={isLoading}
      onSubmit={handleSubmit}
      t={t}
      handleCancel={onCancel}
      textButtons={{
        submit: t("options.send"),
        cancel: t("options.cancel")
      }}
      enableReinitialize={true}
      textLabels={{
        name: t(`${BASE_TRANSLATE}.name`),
        image: t(`${BASE_TRANSLATE}.image`),
        description: t(`${BASE_TRANSLATE}.description`),
        external_url: t(`${BASE_TRANSLATE}.external_url`),
        copies: t(`${BASE_TRANSLATE}.copies`),
        currency: t(`${BASE_TRANSLATE}.currency`),
        price: t(`${BASE_TRANSLATE}.price`),
        mint_type: t(`${BASE_TRANSLATE}.mint_type`),
        attributes: t(`${BASE_TRANSLATE}.attributes`),
      }}
      textPlaceholders={{
        name: t(`${BASE_TRANSLATE}.name_placeholder`),
        description: t(`${BASE_TRANSLATE}.description_placeholder`),
        external_url: t(`${BASE_TRANSLATE}.external_url_placeholder`),
        copies: t(`${BASE_TRANSLATE}.copies_placeholder`),
        price: t(`${BASE_TRANSLATE}.price_placeholder`),
      }}
      ignoreValidation={ignoreValidation}
      concatValidations={concatValidations}
    />
  );
}
