// import BarChartIcon from '@mui/icons-material/BarChart';
import FolderIcon from '@mui/icons-material/Folder';
import CampaignIcon from '@mui/icons-material/Campaign';

import { useTheme } from '@mui/material/styles';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TagIcon from '@mui/icons-material/Tag';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CollectionsIcon from '@mui/icons-material/Collections';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import GroupsIcon from '@mui/icons-material/Groups';
import ApiIcon from '@mui/icons-material/Api';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
// import PersonIcon from '@mui/icons-material/Person';
// import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
// import LaptopMacIcon from '@mui/icons-material/LaptopMac';
// import ReceiptIcon from '@mui/icons-material/Receipt';


export const DiscordLogoSVG = (props) => {
  const theme = useTheme();
  const { sx } = props;
  const {color} = sx;
  const currentColor = color ? color(theme) : theme.palette.secondary.main;
  return  <svg  style={{ maxWidth: '22px',minWidth:'22px', fill: currentColor }} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36"><defs></defs><g id="图层_2" data-name="图层 2"><g id="Discord_Logos" data-name="Discord Logos"><g id="Discord_Logo_-_Large_-_White" data-name="Discord Logo - Large - White"><path className="cls-1" d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/></g></g></g></svg>
}

export const TokTokLogoSVG = (props) => {
  const theme = useTheme();
  const { sx } = props;
  const {color} = sx;
  const currentColor = color ? color(theme) : theme.palette.secondary.main;
  const pathStyle = { fill: currentColor };

  return (
    <svg 
      style={{ 
        maxWidth: '22px', 
        minWidth: '22px',
      }} 
      viewBox="0 0 24 24" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path style={pathStyle} d="M16.8218 5.1344C16.0887 4.29394 15.648 3.19805 15.648 2H14.7293C14.9659 3.3095 15.7454 4.43326 16.8218 5.1344Z" fill="#fff"/>
      <path style={pathStyle} d="M8.3218 11.9048C6.73038 11.9048 5.43591 13.2004 5.43591 14.7931C5.43591 15.903 6.06691 16.8688 6.98556 17.3517C6.64223 16.8781 6.43808 16.2977 6.43808 15.6661C6.43808 14.0734 7.73255 12.7778 9.324 12.7778C9.62093 12.7778 9.90856 12.8288 10.1777 12.9124V9.40192C9.89927 9.36473 9.61628 9.34149 9.324 9.34149C9.27294 9.34149 9.22654 9.34614 9.1755 9.34614V12.0394C8.90176 11.9558 8.61873 11.9048 8.3218 11.9048Z" fill="#fff"/>
      <path style={pathStyle} d="M19.4245 6.67608V9.34614C17.6429 9.34614 15.9912 8.77501 14.6456 7.80911V14.7977C14.6456 18.2851 11.8108 21.127 8.32172 21.127C6.97621 21.127 5.7235 20.6998 4.69812 19.98C5.8534 21.2198 7.50049 22 9.32392 22C12.8083 22 15.6478 19.1627 15.6478 15.6707V8.68211C16.9933 9.64801 18.645 10.2191 20.4267 10.2191V6.78293C20.0787 6.78293 19.7446 6.74574 19.4245 6.67608Z" fill="#fff"/>
      <path style={pathStyle} d="M14.6456 14.7977V7.80911C15.9912 8.77501 17.6429 9.34614 19.4245 9.34614V6.67608C18.3945 6.45788 17.4899 5.90063 16.8218 5.1344C15.7454 4.43326 14.9704 3.3095 14.7245 2H12.2098L12.2051 15.7775C12.1495 17.3192 10.8782 18.5591 9.32393 18.5591C8.35884 18.5591 7.50977 18.0808 6.98085 17.3564C6.06219 16.8688 5.4312 15.9076 5.4312 14.7977C5.4312 13.205 6.72567 11.9094 8.31708 11.9094C8.61402 11.9094 8.90168 11.9605 9.17079 12.0441V9.35079C5.75598 9.42509 3 12.2298 3 15.6707C3 17.3331 3.64492 18.847 4.69812 19.98C5.7235 20.6998 6.97621 21.127 8.32172 21.127C11.8061 21.127 14.6456 18.2851 14.6456 14.7977Z" fill="#fff"/>
    </svg>
  );
}

export const YoutubeLogoSVG = (props) => {
  const theme = useTheme();
  const { sx } = props;
  const {color} = sx;
  const currentColor = color ? color(theme) : theme.palette.secondary.main;

  return (
    <svg style={{ maxWidth: '22px',minWidth:'22px', fill: currentColor }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="-271 311.2 256 179.8" >
      <path d="M-59.1,311.2h-167.8c0,0-44.1,0-44.1,44.1v91.5c0,0,0,44.1,44.1,44.1h167.8c0,0,44.1,0,44.1-44.1v-91.5
   C-15,355.3-15,311.2-59.1,311.2z M-177.1,450.3v-98.5l83.8,49.3L-177.1,450.3z"/>
 </svg>
  );
}



export const RenderIcon = ({ sx, IconComponent }) => (
  <IconComponent sx={{...sx, fill: sx.color }} />
);


export const listMenuDashboard = [
  {
    label: 'collections',
    options: [
      {name: "collections", icon: FolderIcon, translationKey: "collections"},
      {name: "campaigns", icon: CampaignIcon, translationKey: "campaigns"}
    ]
  },
  {
    label: 'assetHub',
    options: [
      {name: "nftCatalog", icon: CollectionsIcon, translationKey: "nft_catalog"},
      { id:1, name: "rewards", icon: EmojiEventsIcon, translationKey: "rewards_catalog" },
      { id:2, name: "benefits", icon: CardGiftcardIcon, translationKey: "benefit_catalog" },
      { id:4, name: "targets", icon: TagIcon, translationKey: "targets" },

    ]
  },
  {
    label: 'members',
    options: [
      {name: "clients", icon: Diversity1Icon, translationKey: "clients"},
      {name: "users", icon: GroupsIcon, translationKey: "users"}
    ]
  },
  {
    label: '',
    options: [
      {name: "api-keys", icon: ApiIcon, translationKey: "api_keys"},
      {name: "gas-station", icon: LocalGasStationIcon, translationKey: "gas_station" }
    ]
  }
];


export const allLogos = {
  header:{
    logosLeft:{
      perseaIcon:{
        name: 'Persea icon',
        src: '/persea-logo-white@2x.png',
        description: "Persea icon"
      },
      perseaText:{
        name: 'Persea text',
        src: '/persea-logo-white1@2x.png',
        description: "Persea text"
      }
    },
    logosRight:{
      copy:{
        name: 'copy',
        src: '/vector2.svg',
        description: "Copy logo"
      },
      avatar:{
        name: 'Avatar',
        src: '',
        description: "Avatar logo"
      }
    }
  },
  campaign:{
    selectTypeCampaign:[
      {
        id: 1,
        name: "Campaña por cantidad de NFTs",
        description: "Campaña diseñada para promocionar o vender un número específico de NFTs.",
        thumb_url: "/rectangle-2108@2x.png",
        translationKey: "campaigns_by_amount_nfts",
        disabled: false,
      },
      {
        id: 2,
        name: "Campaña por whitelist",
        description: "Campaña exclusiva para miembros que estén en una lista blanca previamente establecida.",
        thumb_url: "/rectangle-2108@2x.png",
        translationKey: "campaigns_by_whitelist",
        disabled: false,
      },
      {
        id: 3,
        name: "Campaña por límite de reclamos",
        description: "Campaña en la que hay un número determinado de reclamos o redenciones disponibles.",
        thumb_url: "/rectangle-2108@2x.png",
        translationKey: "campaigns_by_limit_claims",
        disabled: false,
      },
      {
        id: 4,
        name: "Campaña blank",
        description: "Campaña genérica lista para ser personalizada según las necesidades específicas.",
        thumb_url: "/rectangle-2108@2x.png",
        translationKey: "campaigns_blank",
        disabled: false,
      },
      {
        id: 5,
        name: "Campaña por acciones",
        description: "Campaña que se activa o premia según las acciones realizadas por los usuarios.",
        thumb_url: "/rectangle-2108@2x.png",
        translationKey: "campaigns_by_actions",
        disabled: false,
      },
    ]
  },
  post:{
    list:[
      {
        id: 1,
        title: 'Charla sobre Web3',
        description: `Acompáñanos en esta interesante charla donde exploraremos el mundo del Web3, la nueva era de la web descentralizada.
        Un viaje sonoro que combina ritmos modernos y atmosferas envolventes, perfecto para amantes de la música y aquellos buscando una experiencia auditiva única...`,
        thumb_url: "/rectangle-2108@2x.png",
        translationKey: "campaigns_by_actions"
      }
    ]
  }
}