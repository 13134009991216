import { useEffect, useState } from "react";
import styles from "./EditWalletTheme.module.css";
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';



const defaultColors = {
  primary: '#18212B',
  secondary: '#EBEDE9',
  third: '#EE3A43'
}

const WalletTheme = () => {
  const [selectedColor, setSelectedColor] = useState(defaultColors);
  const { t } = useTranslation("walletTheme");




  useEffect(() => {
    setSelectedColor(defaultColors)
  }, [])

  const handleChangeColor = (colorType) => (event) => {
    setSelectedColor(prevColors => ({
      ...prevColors,
      [colorType]: event.target.value
    }));
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log(file);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("main")}</b>
                  <input
                    type="color"
                    value={selectedColor.primary}
                    onChange={handleChangeColor('primary')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("background")}</b>
                  <input
                    type="color"
                    value={selectedColor.secondary}
                    onChange={handleChangeColor('secondary')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <div className={styles.colorPrincipal}>
                  <b className={styles.colorDeFondo}>{t("section")}</b>
                  <input
                    type="color"
                    value={selectedColor.third}
                    onChange={handleChangeColor('third')}
                    className={styles.circularColorInput}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                <Card>
                  <CardContent>
                    <Typography>{t("upload")}</Typography>
                    <input
                      type="file"
                      onChange={handleFileUpload}
                      style={{ marginTop: '10px' }}
                    />
                  </CardContent>
                </Card>
              </CardContent>
              </Card>
          </Grid>
          <Grid item xs={4}>
            <img src='/WalletThemePreview.png' alt="Descripción" className={styles.previewImage} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WalletTheme;
