import { useState, useEffect, useMemo } from 'react';
import { getAllRewardsByDomain, getRewards, getRewardsByDomainId } from 'services/rewards/rewards';
import useAPIFetchTemplate from './useAPIFetchTemplate';

const useReward = ({ id, reward_type, limit, page, order, errorMessage="Failed to get rewards" }) => {
  const rewards = useMemo(() => getRewards({ id, reward_type, limit, page, order }), [id, reward_type, limit, page, order]);
  const { data, loading, error } = useAPIFetchTemplate(rewards, errorMessage);

  return {
    rewards: data,
    loading,
    error
  }
}

const useRewards = () => {

  const [rewards, setRewards] = useState([]);
  const [loadingRewards, setLoadingRewards] = useState(false);
  const [errorRewards, setErrorRewards] = useState(null);

  const getAllRewards = async () => {
    try {
      setLoadingRewards(true);
      const response = await getAllRewardsByDomain(process.env.REACT_APP_DOMAIN);
      setRewards(response.data);
    } catch (error) {
      setErrorRewards(error);
    } finally {
      setLoadingRewards(false);
    }
  }

  useEffect(() => {
    getAllRewards();
  }, [])
  return {
    rewards,
    loadingRewards,
    errorRewards,
    getAllRewards
  }
}


const useRewardById = (rewardId) => {

  const [reward, setReward] = useState(null);
  const [loadingReward, setLoadingReward] = useState(false);
  const [errorReward, setErrorReward] = useState(null);

  const getRewardById = async (rewardId) => {
    try {
      setLoadingReward(true);
      const response = await getRewardsByDomainId(process.env.REACT_APP_DOMAIN, rewardId);
      setReward(response.data[0]);
    } catch (error) {
      setErrorReward(error);
    } finally {
      setLoadingReward(false);
    }
  }

  useEffect(() => {
    getRewardById(rewardId);
  }, [rewardId])

  return {
    reward,
    loadingReward,
    errorReward,
    getRewardById
  }
}

export { useRewards, useRewardById, useReward }