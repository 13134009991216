import { useContext } from "react"; 
import UserContext from "context/UserContext";
import styles from "./MenuDashBoardMobile.module.css";

const MenuDashBoardMobile = ({ goPage,eventName }) => {

  const { user } = useContext(UserContext)

  const handleLogout = (e) => {
    if(user?.disconnect){
      user.disconnect(e);
    }
  }
  
  return (
    <div className={styles.contenido}>
      <div className={styles.vinculaRedesSociales} onClick={goPage("Statistics")}>
        <div className={styles.ttulo1}>
          <img className={styles.perfilIcon} alt="" 
            src={(eventName === "Statistics" || !eventName) ?"/estadisticas1.svg":"/estadisticas.svg"}
          />
          <div className={styles.itemname}>Estadísticas</div>
        </div>
      </div>
      <div className={styles.vinculaRedesSociales} onClick={goPage("profile")}>
        <div className={styles.ttulo1}>
          <img className={styles.perfilIcon} alt="" 
            src={eventName === "profile" ? "/perfil3.svg":"/perfil2.svg" }
          />
          <div className={styles.itemname}>Perfil</div>
        </div>
      </div>
      <div className={styles.vinculaRedesSociales} onClick={goPage("Collections")}>
        <div className={styles.ttulo1}>
          <img className={styles.perfilIcon} alt="" 
             src={eventName === "Collections" ? "/colecciones2.svg" : "/colecciones.svg"} 
          />
          <div className={styles.itemname}>Colleciones</div>
        </div>
      </div>
      <div className={styles.vinculaRedesSociales} onClick={goPage("NFTs")}>
        <div className={styles.ttulo1}>
          <img className={styles.perfilIcon} alt="" 
            src={eventName === "NFTs" ? "/nfts1.svg":"/nfts.svg"}
          />
          <div className={styles.itemname}>NFTs</div>
        </div>
      </div>
      <div className={styles.vinculaRedesSociales} onClick={goPage("Benefits")}>
        <div className={styles.ttulo1}>
          <img className={styles.perfilIcon} alt="" 
            src={eventName === "Benefits" ? "/beneficio1.svg":"/beneficio.svg"} 
          />
          <div className={styles.itemname}>Beneficios</div>
        </div>
      </div>
      <div className={styles.vinculaRedesSociales} onClick={goPage("Events")}>
        <div className={styles.ttulo1}>
          <img className={styles.perfilIcon} alt="" 
            src={eventName === "Events" ? "/eventos.svg":"/eventos1.svg"}
          />
          <div className={styles.itemname}>Eventos</div>
        </div>
      </div>
      <div className={styles.configuraPinDeTransferencia}>
        <div className={styles.ttulo2}>
          <div className={styles.toggle}>
            <div className={styles.toggleChild} />
            <img className={styles.toggleItem} alt="" src="/ellipse-5.svg" />
          </div>
          <div className={styles.itemname2}>Tema Oscuro</div>
        </div>
      </div>
      <div className={styles.metdosDePago}>
        <div className={styles.ttulo1}>
          <div className={styles.icon}>
            <img className={styles.vectorIcon} alt="" src="/vector.svg" />
          </div>
          <div className={styles.itemname3}>Métodos de pago</div>
        </div>
      </div>
      <div className={styles.vinculaRedesSociales} onClick={handleLogout} >
        <div className={styles.ttulo1}>
          <img className={styles.perfilIcon} alt="" src="/perfil1.svg" />
          <div className={styles.itemname}>Cerrar sesión</div>
        </div>
      </div>
    </div>
  );
};

export default MenuDashBoardMobile;
