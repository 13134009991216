import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';

export default function SkeletonCards({ nRows, nColumns }) {
  const rows = Array.from({ length: nRows });

  return (
    <div 
      style={{ 
        display: 'grid', 
        width: '100%',
        minHeight: '300px',
        gridTemplateColumns: `repeat(${nColumns}, 1fr)`, 
        gap: '1rem' ,
        padding: '1rem'
      }}
    >
      {rows.map((_, rowIndex) => (
        <SkeletonRow key={rowIndex} nColumns={nColumns} />
      ))}
    </div>
  );
}

const SkeletonRow = ({ nColumns }) => {
  return (
    Array(nColumns).fill(null).map((_, columnIndex) => (
      <Skeleton 
        key={columnIndex} 
        variant="rectangular" 
        style={{ margin: '0 auto',width: '100%', height: '100%' }} 
      />
    ))
  );
}

SkeletonCards.defaultProps = {
  nRows: 2,
  nColumns: 2
};

SkeletonCards.propTypes = {
  nRows: PropTypes.number,
  nColumns: PropTypes.number
};
