import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 200;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft:  `-${drawerWidth}px`,
    ...(open && {

      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    "@media (max-width: 700px)": {
      marginLeft:  `0px`,
    }
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `100%`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function MenuDrawer({ header, menu, body }) {
 
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  
  const handleResize = () => {
    if(window.innerWidth < 700) {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);


  return (
    
    <Box sx={{ display:'flex', flexDirection:'row' }}  >
      <CssBaseline />
      <AppBar
        position="fixed"
        color="primary"
        open={open}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ 
              mr: 2, 
              "@media (max-width: 700px)": {
                display: 'none'
              }
            }}
          >
            <MenuIcon />
          </IconButton>
          {header}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          marginTop: '70px',
          '& .MuiDrawer-paper': {
            marginTop: '70px',
            width: drawerWidth,
            boxSizing: 'border-box',
            borderTop: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
            "@media (max-width: 700px)": {
              display: 'none'
            }
          },
          "@media (max-width: 700px)": {
            display: 'none'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >  
      {menu}
      </Drawer>
      <Main open={open}>
        {body}
      </Main>
    </Box>
  );
}
