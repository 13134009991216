import axios from "axios";
axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;

export const uploadFile = async (file) => {
  const formImage = new FormData();
  formImage.append("files", file);
  const response = await axios.post(
    `${process.env.REACT_APP_API}/files`,
    formImage,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        'X-API-Key': process.env.REACT_APP_X_API_KEY,
        'Referer': window.location.href
      },
      params: {
        domain: process.env.REACT_APP_DOMAIN
      }
    }
  );
  const { urls } = response.data;
  return urls?.[0];

}




export const uploadFileWithGateway = async (file) => {
  const formImage = new FormData();
  formImage.append("files", file);
  const response = await axios.post(
    `${process.env.REACT_APP_API}/files`,
    formImage,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        'X-API-Key': localStorage.getItem("userApiKey"),
        'Referer': window.location.href
      },
      params: {
        domain: process.env.REACT_APP_DOMAIN
      }
    }
  );
  const { urls , gateway} = response.data;
  return { image_url : urls[0] , gateway : gateway};

}

