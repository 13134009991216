import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import FormLabel from '@mui/material/FormLabel';
import ImpulseForm from '../components/forms/ImpulseForm';
import { Stepper, Step, StepLabel, StepContent, Card } from '@mui/material';
import CreateCamapaignForm from '../components/forms/CreateCampaignForm';
import ConfirmForm from '../components/forms/ConfirmForm';
import { useSpinnerContext } from 'context/SpinnerContext';
import { uploadFile } from 'services/files';
import { create as saveCampaign } from 'services/campaigns/campaigns';
import { create as saveCampaignObjectives } from 'services/campaigns/campaignObjectives';
import { create as saveCampaignMissionObjectives } from 'services/campaigns/campaignMission';
import UserContext from 'context/UserContext';
import SocialMediaForm from '../components/forms/SocialMeidaForm';
import { getBenefits, saveBenefit } from 'services/benefits';

export const STEPS = {
  GENERAL: 0,
  CONFIGURE: 1,
  CONFIGURE_SOCIAL_MEDIA: 2,
  CONFIRM: 3,
}

export default function CreateCampaing() {
  const { t } = useTranslation('campaigns');
  const navigate = useNavigate();
  const [configuration, setConfiguration] = useState({});
  const [activeStep, setActiveStep] = useState(STEPS.GENERAL);
  const { handleChangeLoading, handleChangeMessage, handleChangeError } = useSpinnerContext();
  const { executeFunctionIfUserIsConnected } = useContext(UserContext);

  const handleSubmitCampaign = (values) => {
    setConfiguration({
      ...configuration,
      campaign: values,
    })
    setActiveStep(STEPS.CONFIGURE);
  }

  const handleSubmitConfiguration = (values) => {
    setConfiguration({
      ...configuration,
      configuration: values,
    })
    setActiveStep(STEPS.CONFIGURE_SOCIAL_MEDIA);
  }
  const handleSubmitConfigurationSocialMedia = (values) => {
    setConfiguration({
      ...configuration,
      missions: values.missions,
    })
    setActiveStep(STEPS.CONFIRM);
  }

  const handleCancelConfiguration = () => {
    setActiveStep(STEPS.GENERAL);
  }

  const handleCancelConfigurationSocialMedia = () => {
    setActiveStep(STEPS.CONFIGURE);
  }

  const handleCancelGeneral = () => {
    navigate('/dashBoard/campaigns');
  }

  const handleConfirm = async (confirm) => {
    await executeFunctionIfUserIsConnected(async()=>{
      console.log("confirm", confirm)
      if (!confirm) {
        setActiveStep(STEPS.CONFIGURE);
        return;
      }
      await processConfirm();
    });
  }

  const processConfirm = async () => {
    handleChangeError("");
    handleChangeLoading(true);
    try {
      handleChangeMessage(t("create_view.creation_process.creating_campaign"));
      const campaign = await createCampaign()
      handleChangeMessage(t("create_view.creation_process.creating_campaign_goal"));
      await createCampaignObjectives(campaign.id);
      await createCampaignMissions(campaign.id, campaign.benefit_contract_id, campaign.benefit_contract_address)
      navigate(`/dashBoard/campaigns/${campaign.id}`)
    } catch(e) {
      console.log(e);
      handleChangeError(e.message);
    } finally {
      handleChangeLoading(false);
      handleChangeMessage("");
    }
  }

  const createCampaign = async () => {
    const campaign = configuration.campaign;
    const hashBanner = await uploadFile(campaign.banner);
    const body = {
      name: campaign.name,
      description: campaign.description,
      banner_url: hashBanner,
      release_date: campaign.release_date,
      start_date: campaign.start_date,
      end_date: campaign.end_date,
      blockchain_name: configuration.configuration.blockchain.name,
      type: configuration.configuration.type,
      collection_key: configuration.configuration.collection.collection_key,
    }
    return await saveCampaign(body);
  }

  const createCampaignObjectives = async (campaignId) => {
    const campaignConfiguration = configuration.configuration;
    const body = campaignConfiguration.protoObjectives.map((objective) => ({
      ...objective,
      campaign_id: campaignId,
    }));
    return await saveCampaignObjectives(body);
  }

  const createCampaignMissions = async (campaignId, benefit_contract_id, benefit_contract_address) => {
    const missions = configuration.missions;
    console.log(benefit_contract_id, benefit_contract_address, missions)
    let benefits = await getBenefits({
      "contract_address": benefit_contract_address
    })
    let current_benefit_contract_id = benefits.length
    for (let mission of missions) {
      current_benefit_contract_id++
      mission['campaign_id'] = campaignId;
      mission['benefits_of_mission'] = [{
        "benefit_contract_type_id" : current_benefit_contract_id,
        "benefit_contract_address" : benefit_contract_address,
        "benefit_amount" : 1,
      } ]
      let benefit = {
        "contract_address":benefit_contract_address,
        "contract_id": benefit_contract_id,
        "contract_type_id" : current_benefit_contract_id,
        "amount" : 1000,
        "reward_id": mission.reward.id,
        "details" : {"instructions": {"type": "QR"}}
      }

      await saveBenefit(benefit)

      mission['objectives'] = [{
        "name": "Connect",
        "type" : `CONNECT:SM__${mission.socialNetwork.id}`,
        "description" : "test",
        "quantity" : 1,
        "experience_to_win" : 1,
        "details" : {"template": mission.template},
        "children": [],
        "depends_on": null,
        "save_type": {
          "target_id": mission.target.id,
          "post_type": "POST",
          "social_media_id": mission.socialNetwork.id,
          "type": "CONNECT"
        }
      }]

      mission['objectives'][0]["children"].push({
        "name": "Realiza un post",
        "type" : `MAKE_POST:SM_${mission.socialNetwork.id}:POST_TYPE_POST:INCLUDE_TARGET_${mission.target.id}`,
        "description" : "test",
        "quantity" : 1,
        "experience_to_win" : 1,
        "details" : {"template": mission.template},
        "children": [],
        "save_type": {
          "target_id": mission.target.id,
          "post_type": "POST",
          "social_media_id": mission.socialNetwork.id,
          "type": "MAKE_POST"
        }
      })
      if (mission.views > 0) {
        mission['objectives'][0]["children"].push({
          "name": "Vistas",
          "type" : `CONTEST_SM:ACTION_GET_VIEWS:SM_${mission.socialNetwork.id}:POST_TYPE_POST:TARGET_${mission.target.id}`,
          "description" : "test",
          "quantity" : mission.views,
          "experience_to_win" : mission.views,
          "details" : {"template": mission.template},
          "save_type": {
            "target_id": mission.target.id,
            "post_type": "POST",
            "social_media_id": mission.socialNetwork.id,
            "type": "GET_VIEWS"
          }
        })
      } 

      if (mission.reactions > 0) {
        mission['objectives'][0]["children"].push({
          "name": "Reacciones",
          "type" : `GET_LIKES:SM_${mission.socialNetwork.id}:POST_TYPE_POST:INCLUDE_TARGET_${mission.target.id}`,
          "description" : "test",
          "quantity" : mission.reactions,
          "experience_to_win" : mission.reactions,
          "details" :{"template": mission.template},
          "save_type": {
            "target_id": mission.target.id,
            "post_type": "POST",
            "social_media_id": mission.socialNetwork.id,
            "type": "GET_LIKES"
          }
        })
      } 

      if (mission.shares > 0) {
        mission['objectives'][0]["children"].push({
          "name": "Compartir",
          "type" : `GET_SHARE:SM_${mission.socialNetwork.id}:POST_TYPE_POST:INCLUDE_TARGET_${mission.target.id}`,
          "description" : "test",
          "quantity" : mission.shares,
          "experience_to_win" : mission.shares,
          "details" : {"template": mission.template},
          "save_type": {
            "target_id": mission.target.id,
            "post_type": "POST",
            "social_media_id": mission.socialNetwork.id,
            "type": "GET_SHARES"
          }
        })
      } 
      await saveCampaignMissionObjectives(mission);
    }
  }
  return (
    <Card sx={{ padding: '1rem' }}>
      <Stepper activeStep={activeStep} orientation="vertical" id="impulse-form">
        <Step data-testid={`step-${STEPS.GENERAL}`}>
          <StepLabel>{t("create_view.sections.first")}</StepLabel>
          <StepContent>
            <CreateCamapaignForm
              onSubmit={handleSubmitCampaign}
              onCancel={handleCancelGeneral}
            />
          </StepContent>
        </Step>
        <Step data-testid={`step-${STEPS.CONFIGURE}`}>
          <StepLabel>{t("create_view.sections.second")}</StepLabel>
          <StepContent>
            <ImpulseForm
              onSuccess={handleSubmitConfiguration}
              onCancel={handleCancelConfiguration}
            />
          </StepContent>
        </Step>
        <Step data-testid={`step-${STEPS.CONFIGURE_SOCIAL_MEDIA}`}>
          <StepLabel>{t("create_view.sections.second")}</StepLabel>
          <StepContent>
            <SocialMediaForm
              onSuccess={handleSubmitConfigurationSocialMedia}
              onCancel={handleCancelConfigurationSocialMedia}
            /> 
          </StepContent>
        </Step>
        <Step data-testid={`step-${STEPS.CONFIRM}`}>
          <StepLabel>{t("create_view.sections.third")}</StepLabel>
          <StepContent>
            <div data-testid="campaign-configuration-confirm">
              <FormLabel htmlFor={"confirm"}>
                {t("confimr_title")}
              </FormLabel>
              <ConfirmForm onSubmit={handleConfirm}/>
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </Card>
  )
}
