import React, { useState } from 'react';

const LookerEmbed = () => {
    const [url] = useState('https://avocado.cloud.looker.com/embed/dashboards/5?allow_login_screen=true');
  return url ? (
    <iframe
      src={url}
      width="100%"
      height="600"
      style={{ border: 'none' }}
    ></iframe>
  ) : (
    <div>Loading...</div>
  );
};

export default LookerEmbed;
