import Skeleton from '@mui/material/Skeleton';

export function LargeFormSkeleton() {
  return (
    <div data-testid="large-form-skeleton">
      <Skeleton variant="text" width="100%" height={40} />
      <Skeleton variant="text" width="100%" height={40} />
      <Skeleton variant="text" width="100%" height={40} />
      <Skeleton variant="text" width="100%" height={40} />
      <Skeleton variant="text" width="100%" height={40} />
    </div>
  );
}