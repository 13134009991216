import { Magic } from 'magic-sdk';
import { findByWallet } from 'services/user';
import Web3 from 'web3';


export const magicInit = () => {
  return new Magic(process.env.REACT_APP_MAGIC_API_KEY,{
    network:{
      rpcUrl: process.env.REACT_APP_RPC_TO_MAGIC,
      chainId: process.env.REACT_APP_NETWORK,
    }
  });
};

export  const loginMagic = async () => {

    const magic = magicInit();
    const provider = new Web3(magic.rpcProvider);
    const accounts = await magic.wallet.connectWithUI();
    const wallet = accounts[0];
    const chainID = await provider.eth.getChainId();
    const network = await provider.eth.net.getNetworkType();
    const infoUser = await findByWallet(process.env.REACT_APP_DOMAIN ,process.env.REACT_APP_NAME_NETWORK , wallet);
    const userMetadata = await getMetadataMagic(magic);
    const loginEmail = userMetadata.email || '';
    const magic_token = await getIdTokenWithMagic(magic);

    const user = {
      provider: provider,
      chainID: chainID,
      network: network,
      wallet: wallet,
      magic: magic,
      loginEmail: loginEmail,
      magic_token: magic_token,
      ...infoUser,
      typeWallet: "MAGIC_WALLET"
    }
    return user;
};

export const getIdTokenWithMagic = async (magic) => {
  return await magic?.user?.getIdToken();
};

export const signMessageWithMagic = async (addressUser, magic, message = "CREATE_COLLECTION") => {
  const web3 = new Web3(magic.rpcProvider);
  const signature = await web3.eth.personal.sign(message, addressUser);
  return {
    signature,
    message
  }
};

export const getMetadataMagic = async (magic) => {
  return await magic.user.getInfo();
};

export const getBalanceByUser = async(address,provider) => {
  const wei = await provider.eth.getBalance(address);
  const balance = provider.utils.fromWei(wei);
  return balance || 0;
}

export  const showUIMagic = async (magic) => {
  try {
    //await magic.wallet.showBalances();
    //await magic.wallet.showOnRamp();
    await magic.wallet.showUI();
  } catch (error) {
    console.error(error);
  }
};

export  const showOnRamp = async (magic) => {
  try {
    await magic.wallet.showOnRamp();
  } catch (error) {
    console.error(error);
  }
};

export const disconnectMagicStandard = async (magic) => {
  return await magic?.user?.logout();
};

export const disconnectMagicOauth = async (magic) => {
  await magic?.user?.logout();
}

export const validateIfIsLoggedIn = async(magic) => {
  return magic?.user?.isLoggedIn();
}