import React, {Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import { Field } from "formik";
import FormLabel from '@mui/material/FormLabel';
import { capitalizeFirstLetter } from "utils/strings";


const style = {
  ".MuiInputBase-root":{
    background:' #fff'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: "1px solid rgba(0, 0, 0, 0.23)",
    },
    '&:hover fieldset': {
      border: "1px solid #000",
    },
    '&.Mui-focused fieldset': {
      border: "1px solid #000",
    }
  }
}

const start = dayjs(new Date());
const end = dayjs(new Date()).add(9, 'year');

const WrappedComponent = ({ form, field, minDate, maxDate, placeholder, dateType, initValue }) => {
  const { setFieldValue, values } = form;
  const valueDate = dayjs(values[field.name]).toDate();

  const handleChangeDate = (newValue) => {
    setFieldValue(field.name, newValue?.toDate() || newValue,true);
  }

  useEffect(() => {
    handleChangeDate(initValue)
  }, [])
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {dateType === "date" ? (
        <DatePicker 
          label={""} 
          slotProps={{
              textField: {
                helperText: '',
                placeholder: placeholder
              }
          }}
          openTo="month"
          views={['month', 'year', 'day']}
          onChange={handleChangeDate}
          minDate={minDate || start}
          maxDate={maxDate || end}
          PopperProps={{
            className: "popperCustom"
          }}
          value={initValue || valueDate || null}
          fullWidth
          name={field.name}
          sx={style}
        />
      ):
      (
        <DateTimePicker
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slotProps={{
            textField: {
              helperText: '',
              placeholder: placeholder
            },
            field: {
              name: field.name
            }
          }}
          onChange={handleChangeDate}
          sx={style}
          minDate={minDate || start}
          maxDate={maxDate || end}
          value={initValue || valueDate || null}
        />
      ) 
    }
  </LocalizationProvider>
  )
}

const InputDateTime = ({ initValue, minDate, maxDate, name, placeholder, label, loading }) => {
  const newLabel = typeof label === 'string' ? label : null;
  const newPlaceHolder = typeof placeholder === 'string' ? placeholder : null;

  const getNewIinitValue = () => {
    if (!initValue) return null;
    if (typeof initValue === 'number') {
      return dayjs.unix(initValue)
    }
    return dayjs(initValue)
  }

  return (
    <Fragment>
      <FormLabel htmlFor={name}>
        {newLabel || capitalizeFirstLetter(name+'')}
      </FormLabel>
      <Field 
        type="text"
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        placeholder={newPlaceHolder || capitalizeFirstLetter(name+'')}
        label={newLabel}
        loading={loading}
        initValue={getNewIinitValue()}
        component={WrappedComponent}
      />
    </Fragment>
  );
};

const InputDate = ({ initValue, minDate, maxDate, name, placeholder, label, loading }) => {

  const newLabel = typeof label === 'string' ? label : null;
  const newPlaceHolder = typeof placeholder === 'string' ? placeholder : null;
  const newInitValue = initValue ? dayjs(initValue) : null;

  return (
    <Fragment>
      <FormLabel htmlFor={name}>
        {newLabel || capitalizeFirstLetter(name+'')}
      </FormLabel>
      <Field 
        type="text"
        minDate={minDate || start}
        maxDate={maxDate || end}
        name={name}
        placeholder={newPlaceHolder || capitalizeFirstLetter(name+'')}
        label={newLabel}
        loading={loading}
        dateType="date"
        initValue={newInitValue}
        component={WrappedComponent}
      />
    </Fragment>
  );
};

InputDateTime.defaultProps = {
  minDate: start,
  maxDate: end
}

InputDateTime.propTypes = {
  minDate: PropTypes.any,
  maxDate: PropTypes.any
};

InputDate.defaultProps = {
  minDate: start,
  maxDate: end
}

InputDate.propTypes = {
  minDate: PropTypes.any,
  maxDate: PropTypes.any
};

export {
  InputDateTime,
  InputDate
}