import axios from "axios"
import { buildUrlParams } from "utils/fetch";
axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;
const path = "collection/mint-url";
const URL = `${process.env.REACT_APP_API}/${path}`;


export const getMintUrls = async ({collection_key, limit, page, order }) => {
	const params = { collection_key,limit, page, order, domain: process.env.REACT_APP_DOMAIN }
	const response =  await axios.get(URL, {
		headers: {
			'X-API-Key': localStorage.getItem("userApiKey")
		},
		params
	});
	return await response.data;
}

export const getMintUrlsCustom = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {
    limit: 10,
    page: 0,
    order: "created"
  }
})=>{
  const result = axios.get(
    `${URL}?domain=${domain}${buildUrlParams(params)}`,{
		headers: {
			'X-API-Key': localStorage.getItem("userApiKey")
		}}
  );
  const response = await result;
  return response?.data || [];
}


export const deleteMintUrls = async (urlId) => {
	const response =  await axios.delete(URL, {
		headers: {
			'X-API-Key': localStorage.getItem("userApiKey")
		},
		params :{
			domain: process.env.REACT_APP_DOMAIN,
			id:urlId
		}
	});
	console.log("debug respose.data::", response.data)
	return await response.data;
}