import { Alert, Chip, Skeleton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { find } from "services/socialMediaSummary";
import { useFetch } from "hooks/useFetch";
import LookerEmbed from "locales/sections/campaigns/looker/looker";

export default function CampaignAnalytics({campaignId}) {
  return (
    <Stack spacing={2}>
      <SummarySection campaignId={campaignId} />
      <LookerEmbed />
    </Stack>
  );
}

function SummarySection({campaignId}) {
  const { t } = useTranslation('campaignAnalytics');
  const { data: summary, loading, error } = useFetch(find, {
    params: { campaign_id: campaignId, limit: 100, page: 0, order: 'created' }
  });

  if (loading) {
    return <LoadingSkeleton />
  }

  if (error) {
    return <ErrorMessage />
  }

  return (
    <Stack spacing={1} direction="row">
      <CustomChip label={t("analytics_summary.shares")} value={summary[0].shares} />
      <CustomChip label={t("analytics_summary.likes")} value={summary[0].likes} />
      <CustomChip label={t("analytics_summary.views")} value={summary[0].count} />
      <CustomChip label={t("analytics_summary.followers")} value={summary[0].followers} />
      <CustomChip label={t("analytics_summary.posts")} value={summary[0].posts} />
    </Stack>
  );
}

const CustomChip = ({label, value}) => {
  return (
    <Chip label={`${label}: ${value}`} sx={{fontWeight: 'bold'}} />
  );
}

const LoadingSkeleton = () => {
  return (
    <Stack spacing={1} direction="row">
      <Skeleton variant="rounded" height={45} width={100} />
      <Skeleton variant="rounded" height={45} width={100} />
      <Skeleton variant="rounded" height={45} width={100} />
    </Stack>
  );
}

const ErrorMessage = () => {
  const { t } = useTranslation('participantTable');
  return (
    <Alert severity="error">
      {t("messages.error")}
    </Alert>
  );
}