import { useState, Fragment } from 'react';
import TopCollections from 'components/OverviewCustom/components/TopCollections';
import SocialsShortInfo from 'components/OverviewCustom/components/SocialsShortInfo';
import ChartSlider from 'components/OverviewCustom/components/ChartSlider';
import Divider from '@mui/material/Divider'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import TimelineIcon from '@mui/icons-material/Timeline';
import ListItemText from 'components/OverviewCustom/components/ListItemText';
import Card from '@mui/material/Card';

const useConfig = () => {

  const data1 = [
    {title: "Collection 1", total: "5,200"},
    {title: "Collection 2", total: "4,800"}
  ]

  const data2 = [
    {title: "Campaign 1", total: "2,200"},
    {title: "Campaign 2", total: "6,800"}
  ]

  const [dataListAccount, setDataListAccount] = useState([]);
  const handleSubmit1 = (value) => {
    const newList = [...dataListAccount, value];
    // Actualizar el estado con la nueva lista
    setDataListAccount(newList);
    console.log("debug value::",value);
  }

  const [dataListHashtag, setDataListHashtag] = useState(["#gol","#example"]);
  const handleSubmit2 = (value) => {
    const newList = [...dataListHashtag, value];
    // Actualizar el estado con la nueva lista
    setDataListHashtag(newList);
    console.log("debug value::",value);
  }
  
  const shortData = [
    {
      name: 'Discord',
      total: '2,000',
      label: 'Followers',
      thumb_url: 'https://th.bing.com/th?id=OIP.fTSJbqzaePI2rbeV9lmOswHaHa&w=250&h=250&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2'
    },
    {
      name: 'Discord',
      total: '+9,900',
      label: 'Number of videos',
      thumb_url: <OndemandVideoIcon />
    },
    {
      name: 'Discord',
      total: '1,000',
      label: 'Interactions',
      thumb_url: <TimelineIcon />
    }
  ];

  const componentsItem = [
    ()=>(
      <Fragment>
        <SocialsShortInfo 
          data={shortData}
          styleIcons={{
            width: '40',
            height: '40'
          }}
        />
        <ChartSlider 
          configChart={{
            barColor:"#5865F2",
            label: 'Discord'
          }}
        />
      </Fragment>
    ),
    ()=>(
      <Fragment>
        <TopCollections title="Top collections" data={data1} />
        <TopCollections title="Top campaigns" data={data2} />
      </Fragment>
    ),
    ()=>(
      <Card>
        <ListItemText
          title="List of accounts"
          data={dataListAccount}
          onSubmit={handleSubmit1}
        />
        <Divider />
        <ListItemText
          title="List of hashtag (#)"
          data={dataListHashtag}
          onSubmit={handleSubmit2}
        />
      </Card>
    )
  ];


  return {
    componentsItem
  }

};

export { useConfig };
