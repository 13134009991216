import React from 'react';
import PropTypes from 'prop-types';
import styles from './SocialsShortInfo.module.css';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card'

const SocialsShortInfo = ({
  title,
  data,
  variantAvatar,
  styleIcons
}) => {

  return (
    <Card className={styles.card}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.contentCard}>
      {
          data && data.length > 0 &&
          data.map((item, index) => {
            const isIcon = React.isValidElement(item.thumb_url);

            return (
              <div 
                key={index}
                className={styles.itemCard}
              >
                {
                  isIcon ? 
                  React.cloneElement(item.thumb_url,{
                    style:{...styleIcons}
                  }) :
                  <Avatar 
                    alt={item.name}
                    variant={variantAvatar}
                    src={item.thumb_url}
                  />
                }
                <b>{item.total}</b>
                <div>{item.label}</div>
              </div>
            );
          })
        }
      </div>
    </Card>
  );
};

const defaultData = [
  {
    name: 'Discord',
    total: '2,000',
    label: 'Followers',
    thumb_url: 'https://th.bing.com/th?id=OIP.fTSJbqzaePI2rbeV9lmOswHaHa&w=250&h=250&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2'
  },
  {
    name: 'TikTok',
    total: '+9,900',
    label: 'Followers',
    thumb_url: 'https://th.bing.com/th/id/OIP.tVblGMivAc9tREi3zu_JTwHaE8?w=274&h=183&c=7&r=0&o=5&pid=1.7'
  },
  {
    name: 'Youtube',
    total: '1,000',
    label: 'Followers',
    thumb_url: 'https://clipartcraft.com/images/youtube-icon-clipart-video-8.png'
  }
];

SocialsShortInfo.defaultProps = {
  data: defaultData,
  variantAvatar: "rounded",
  styleIcons: {}
}

SocialsShortInfo.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  variantAvatar: PropTypes.string,
  styleIcons: PropTypes.object
};

export default SocialsShortInfo;