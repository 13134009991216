import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function CreateTargetButton({ ...props }) {
  const { t } = useTranslation("targetsTable");
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      data-testid="create-target-button"
      color="info"
      onClick={() => navigate(`/dashboard/targets/create`)}
      {...props}
    >
      + {t("options.create_target")}
    </Button>
  )
}