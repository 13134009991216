import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { extendCollection } from 'services/collection/extendCollectionNFTs';
import { useSpinnerContext } from 'context/SpinnerContext';
import { useTranslation } from 'react-i18next';

export default function ExtendCollectionNFTsModal({ collection_key }) {
  const [open, setOpen] = React.useState(false);
  const { handleChangeLoading ,handleChangeMessage, handleChangeError } = useSpinnerContext();
  const { t } = useTranslation("collections");
  const BASE_TRANSLATION = "extend_collection_form";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async (event) => {
    handleChangeError(null);
    try {
      handleChangeLoading(true);
      handleChangeMessage(t(`${BASE_TRANSLATION}.submmiting_extension`))
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const formJson = Object.fromEntries(formData.entries());
      const cuantity = parseInt(formJson.quantity);
      handleClose();
      await extendCollection(collection_key, cuantity);
    } catch (error) {
      console.error(error);
      handleChangeError(t(`${BASE_TRANSLATION}.error_extension`))
    } finally {
      handleChangeLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Button
        data-testid="extend-collection-nfts-button"
        variant="outlined"
        color='info'
        onClick={handleClickOpen}
      >
        {t(`${BASE_TRANSLATION}.title`)}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleConfirm,
        }}
      >
        <DialogTitle>{t(`${BASE_TRANSLATION}.title`)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(`${BASE_TRANSLATION}.description`)}
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="quantity"
            name="quantity"
            label={t(`${BASE_TRANSLATION}.quantity`)}
            type="number"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t(`${BASE_TRANSLATION}.button_cancel`)}</Button>
          <Button type="submit">{t(`${BASE_TRANSLATION}.button_submit`)}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}