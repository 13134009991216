
import React, { useState, useCallback, useMemo } from 'react';
import { newConnection, reConnection } from 'services/providers/providers';
import { useNavigate } from 'react-router-dom';
import { userIsLoggedOut } from 'services/providers/magicWalletOAuth';
import { handleErrors } from 'utils/messages';
import { useTranslation } from 'react-i18next';

const UserContext = React.createContext();

export const UserProvider = ({ children, ...props }) => {

  const [ user, setUser ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ expiredSession, setExpiredSession ] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("messages");

  const connectBrowser = useCallback(async () => {
    try{
      setLoading(true)
      const res = await newConnection("BROWSER_WALLET");
      setUser(res);
    } catch (e) {
      console.error(e);
      if(String(e?.message).includes("errors.messages.user_not_exists")){
        setError(t("errors.messages.user_not_exists"));
        return;
      }
      setError(handleErrors(e)?.message+'' || t("errors.messages.error_trying_to_connect_your_wallet"));
    } finally {
      setLoading(false)
    }
  },[setLoading,setUser,setError,newConnection]);  
  
  const connectOauth = useCallback(async (social_provider) => {
    try{
      setLoading(true)
      const res = await newConnection("MAGIC_WALLET_OAUTH",social_provider);
      setUser(res);
    } catch (e) {
      console.error(e);
      setError(handleErrors(e)?.message+'' || t("errors.messages.error_trying_to_connect_your_wallet"));
    } 
  },[setLoading,setUser,setError,newConnection]);  

  const connectOauthEmail = useCallback(async (email) => {
    try{
      setLoading(true)
      const res = await newConnection("MAGIC_EMAIL_OTP",email);
      setUser(res);
    } catch (e) {
      console.error(e);
      setError(handleErrors(e,t)?.message+'' || t("errors.messages.error_trying_to_connect_your_wallet"));
    } finally {
      setLoading(false)
    }
  },[setLoading,setUser,setError,newConnection]);  

  const reconnect = useCallback(async (provider) => {
    try{
      setLoading(true);
      const recoveredUser = await reConnection(provider);
      setUser(recoveredUser);
    }catch (e) {
      console.error(e);
      setError(handleErrors(e,t)?.message+'' || t("errors.messages.error_trying_to_reconnect_your_wallet"));
      navigate("/login");
    } finally {
      setLoading(false)
    }
  },[setLoading,setError,setUser,reConnection]);  

  const initRedirectLogin = useCallback(async () => {
    setExpiredSession(true);
  },[setExpiredSession,navigate]);


  const executeFunctionIfUserIsConnected = useCallback(async (callback,fallBack) => {
    try {
      const isLoggedIn = await userIsLoggedOut(user?.magic);
      if(isLoggedIn){
        if(callback){
          await callback()
        }
      }else{
        if(fallBack){
          fallBack();
        }
        initRedirectLogin();
      }
    } catch (e) {
      console.error(e);
      if(fallBack){
        fallBack();
      }
      initRedirectLogin();
    }
  },[user,initRedirectLogin]);

  const values = useMemo(() => ({
    user,
    setUser,
    connectBrowser,
    connectOauth,
    reconnect,
    error,
    setError,
    loading,
    setLoading,
    executeFunctionIfUserIsConnected,
    expiredSession,
    connectOauthEmail
  }), [user, setUser, connectBrowser, connectOauth, reconnect, error, loading, setLoading,executeFunctionIfUserIsConnected,expiredSession,connectOauthEmail,setError]);
  

  return (
    <UserContext.Provider value={values} {...props}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;