import React from 'react';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import TargetsTable from 'components/AutoManagedTables/TargetsTable';
import CreateTargetButton from 'components/Buttons/CreateTargetButton';

export default function ViewTargets() {
  const { t } = useTranslation('targets');

  return (
    <section data-testid="targets-view">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        pb={2}
      >
        <Typography variant="body2" gutterBottom flex={1}>
          {t("all_targets_view.description")}
        </Typography>
        <CreateTargetButton />
      </Stack>
      <TargetsTable />
    </section>
  );
}