import { createTheme } from "@mui/material";

const statusTab = {
  creation: '#00649C',
  pending: '#009C38',
  addCollections: '#890202',
  step4:'#FF5733'
}

export const statusCollections = {
  statusGray: {
    main: '#868484',
    contrastText: '#fff'
  },
  statusGreen: {
    main: '#38B764',
    contrastText: '#fff'
  },
  statusYellow: {
    main: '#F5A623',
    contrastText: '#fff'
  },
  statusBlue: {
    main: '#249FDE',
    contrastText: '#fff'
  },
  statusRed:{
    main: '#DC3545',
    contrastText: '#fff'
  }
}

export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#19407f', //Barra de Titulo 
    },
    textSelected:{
      main: '#19407f' // Item selected Backgournd color
    },
    backgroundSelected:{
      main: '#F3F3F3' // Item selected Backgournd color
    },
    menuHover:{
      main:'#7a92b9'
    },
    menuText:{
      main: '#19407f' // Menu Text
    },
    secondary: {
      main: '#FFF',  //Texto e icono Seleccionado
    },
    info: {
      main: '#19407f',
      contrastText: '#fff'
    },
    success: {
      main: '#38B764',
    },
    background: {
      paper: '#fff',
      default: '#fff',
      white: '#fff'
    },
    text: {
      primary: '#151D28', // Texto De contenido (NO MENU)
    },
    error: {
      main: '#EE3A43',
    },
    danger:{
      main: '#DC3545'
    },
    darkGreen:{
      main: '#fff' //Fondo del menu
    },
    white:{
      main: '#fff'
    },
    ...statusCollections
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 8,
  },
  tabsCustom: {
   ...statusTab
  }
};

export default createTheme(themeOptions);