import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import BenefitForm from './Benefits/BenefitForm';
import { Alert, Button } from '@mui/material';
import { IPFS_GATEWAY } from 'utils/constants';
import { uploadFile } from 'services/files';
import { isVideo } from 'utils/supportedFiles';
import { getRewardRecords, mint } from 'services/contracts/benefitsMethods';
import UserContext from 'context/UserContext';
import { updateBenefit } from 'services/benefits';

const disableAll = {
  name: true,
  asset: true,
  description: true,
  type: true,
  supply: true,
  reward: true,
};

const disableUneditableFields = {
  type: true,
  reward: true,
};

export default function BenefitsEditModal({ open, handleClose, benefit }) {
  if (!benefit) return null;
  const { user } = useContext(UserContext);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ rewards, setRewards ] = useState([]);

  const curatedBenefit = {
    ...benefit,
    asset: benefit.thumb_url,
    reward: {
      rewardRecordId: benefit.reward.id,
      amount: benefit.rewardAmount,
    },
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    asset: Yup.mixed().required("Asset is required"),
    description: Yup.string().required("Description is required"),
    type: Yup.number().min(1, "Must be a number more than zero").required("Type is required"),
    supply: Yup.number().min(curatedBenefit.supply, "Must be greater than or equal to the current supply"),
    reward: Yup.object().shape({
      rewardRecordId: Yup.number().min(1, "Invalid reward ID").required("Reward is required"),
      amount: Yup.number().min(1, "Must be a number more than zero").required("Reward amount is required"),
    }).required("Reward data is required"),
  });

  useEffect(() => {
    setIsLoading(true);
    getRewardRecords(user.provider, benefit.contract_key).then((rewards) => {
      setRewards(rewards);
    }).catch((error) => {
      setError(error.message);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [benefit.contract_key]);

  const onClose = () => {
    if (isLoading) return;
    handleClose();
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    setError(null);
    try {
      const thumbURL = await uploadImage(values.asset);
      const metadataURL = await uploadMetadata({...values, image: thumbURL, isVideo: isVideo(values.asset)});
      const transaction = await mintAndUpdateMetadata(values.supply, values.reward, metadataURL);
      const updatedBenefit = await updateBenefitInDatabase(values.name, values.description, thumbURL, values.supply);
      handleClose({ updatedBenefit, transaction });
    } catch(error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadImage = async (file) => {
    try {
      const ipfs_hash = await uploadFile(file);
      return IPFS_GATEWAY + ipfs_hash;
    } catch(error) {
      error.message = "Failed to upload image";
      console.error(error);
      throw error;
    }
  }

  const uploadMetadata = async (values) => {
    try {
      const metadata = {
        name: values.name,
        description: values.description,
        image: values.image,
        isVideo: values.isVideo,
        external_url: '',
        attributes: [],
      };
      const blobMetadatata = new Blob([JSON.stringify(metadata)], {type : 'application/json'});
      const ipfs_hash = await uploadFile(blobMetadatata);
      return IPFS_GATEWAY + ipfs_hash;
    } catch(error) {
      error.message = "Failed to upload metadata";
      console.error(error);
      throw error;
    }
  }

  const mintAndUpdateMetadata = async (newSupply, reward, metadataURL) => {
    try {
      const amoutnToMint = newSupply - curatedBenefit.supply;
      return await mint(
        user.wallet,
        user.provider,
        curatedBenefit.contract_key,
        curatedBenefit.type,
        amoutnToMint,
        reward,
        metadataURL
      );
    } catch(error) {
      error.message = "Something went wrong updating the smart contract";
      console.error(error);
      throw error;
    }
  }

  const updateBenefitInDatabase = async (name, description, thumbURL, supply) => {
    try {
      return await updateBenefit(benefit.contract_key, benefit.project_key, benefit.type, {
        name,
        description,
        thumb_url: thumbURL,
        supply,
      })
    } catch(error) {
      error.message = "Failed to update benefit in database";
      console.error(error);
      throw error;
    }
  };

  const actions = (
    <>
      <Button
        type="submit"
        variant="contained"
        disabled={isLoading}
        fullWidth
        color="success"
        sx={{ mb: 1, mt: 3 }}
      >
        Update
      </Button>
      <Button
        onClick={onClose}
        disabled={isLoading}
        variant="text"
        fullWidth
      >
        Cancel
      </Button>
    </>
  );

  return (
    <div>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Edit benefit</DialogTitle>
        <DialogContent dividers={false}>
          { error && <Alert sx={{mb: 2}} severity="error">{error}</Alert> }
          <Formik
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={curatedBenefit}
          >
            <BenefitForm
              disabledFields={ isLoading ? disableAll : disableUneditableFields }
              initialValues={curatedBenefit}
              actions={actions}
              rewards={rewards}
            />
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}