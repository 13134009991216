import axios from "axios";
import { buildUrlParams } from "utils/fetch";

axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;

const path = "campaigns"
const url = `${process.env.REACT_APP_API}/${path}`;

const PATH = "campaigns";
const URL = process.env.REACT_APP_API;

export const create = async (body) => {
	const response =  await axios.post(url, body,
		{
			headers: {
				'X-API-Key': localStorage.getItem("userApiKey")

			},
			params: {
				domain: process.env.REACT_APP_DOMAIN,
			}
		});
  return response.data[0]
}

export const approve = async (campaign_id) => {
  const response =  await axios.post(`${url}/approve`, {campaign_id},
    {
      headers: { 'X-API-Key': localStorage.getItem("userApiKey")},
      params: { domain: process.env.REACT_APP_DOMAIN }
    });
  return response.data
}

export const update = async (body) => {
  const response =  await axios.put(url, body,
    {
      headers: { 'X-API-Key': localStorage.getItem("userApiKey")},
      params: { domain: process.env.REACT_APP_DOMAIN }
    });
  return response.data
}

export const getAllCampaigns = async( ) => {
    const response = await axios.get(process.env.REACT_APP_API+`/campaigns?domain=${process.env.REACT_APP_DOMAIN}`,{
        headers:{
            'Content-Type': 'text/plain;charset=utf-8',
            'X-API-Key': process.env.REACT_APP_X_API_KEY
        }
    })

    return response.data
		
}


export const findAll = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {
    limit: 100,
    page: 0,
    order: "id_desc"
  }
}) => {
  const result = await axios.get(
    `${URL}/${PATH}?domain=${domain}${buildUrlParams(params)}`
  );
  return result.data;
};

export const find = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = {
    limit: 100,
    page: 0,
    order: "id_desc"
  }
}) => {
  const result = await axios.get(
    `${URL}/${PATH}?domain=${domain}${buildUrlParams(params)}`
  );
  return result.data[0];
};

export const formatCampaignsToUpdateStatus = (campaings, newStatus) => {
  return campaings.map((campaing) => {
    return {
      id: campaing.id,
      status: newStatus,
    }
  });
}