import axios from "axios";
axios.defaults.headers.common['X-API-Key'] =  process.env.REACT_APP_X_API_KEY;


export const postCreateNFTBySheet = async ({sheet_uri, folder_uri, collection_key, blockchain_name}) => {
  const body = {sheet_uri, folder_uri, blockchain_name, collection_key}
  const res = await axios.post(`${process.env.REACT_APP_API}/nft/massive`, body, {
    headers: {
      'X-API-Key': localStorage.getItem("userApiKey"),
      'Referer': window.location.href
    },
    params: { domain: process.env.REACT_APP_DOMAIN }
  });
  return res.data;
}

export const getDataIfExistsNfts = async ({collection_key, limit=100, page=0, order="created desc"}) => {
  const params = {limit, page, domain: process.env.REACT_APP_DOMAIN, collection_key, order}
  const res = await axios.get(`${process.env.REACT_APP_API}/nft/massive`, {
    headers: {
      'X-API-Key': localStorage.getItem("userApiKey"),
      'Referer': window.location.href
    },
    params
  });
  if(res?.status == 200){
    return await res.data;
  } else{
    return [];
  }
}

export const postConfirmMint = async ({price=0, collection_key, reveal_type, reveal_date, sale_date, additional={}, extra_details=""}) => {
  const body = {price, collection_key, reveal_type, reveal_date, sale_date, additional, extra_details}
  const res = await axios.put(`${process.env.REACT_APP_API}/nft/massive`, body, {
    headers: {
      'X-API-Key': localStorage.getItem("userApiKey"),
      'Referer': window.location.href
    },
    params :{
      domain: process.env.REACT_APP_DOMAIN,
    }
  });
  return await res.data;
}

export const cancelMint = async ({collection_key}) => {
  const res = await axios.delete(`${process.env.REACT_APP_API}/nft/massive`, {
    headers: {
      'X-API-Key': localStorage.getItem("userApiKey"),
      'Referer': window.location.href
    },
    params :{
      domain: process.env.REACT_APP_DOMAIN,
      collection_key
    }
  });
  return await res.data;
}