import React from 'react';
import styles from './ViewCampaigns.module.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CampaignsTable from './CampaignsTable';

const ViewCampaigns = () => {

  const { t } = useTranslation('campaigns');
  const navigate = useNavigate();

  const handleGo = (path) => () => {
    navigate(`/dashboard/campaigns/${path}`);
  }

  return (
    <section className={styles.contentSection} data-testid="id-view-campaigns">
      <div className={styles.headerSection}>

        <div className={styles.subHeaderSection}>
          <Typography variant="body2" gutterBottom flex={1}>
            {t("description")}
          </Typography>
          <Button
            variant="outlined"
            color="info"
            onClick={handleGo("create")}
          >
            +{t("options.create_campaign")}
          </Button>
        </div>
      </div>
      <CampaignsTable />
    </section>
  );
};

export default ViewCampaigns;