import axios from "axios";
import { buildUrlParams } from "utils/fetch";

axios.defaults.headers.common['X-API-Key'] = process.env.REACT_APP_X_API_KEY;

const PATH = "social_media/summary";
const URL = `${process.env.REACT_APP_API}/${PATH}`;

export const find = async ({
  domain = process.env.REACT_APP_DOMAIN,
  params = { limit: 100, page: 0, order: "created" }
}) => {
  const response = await axios.get(`${URL}?domain=${domain}${buildUrlParams(params)}`);
  return response.data;
}
