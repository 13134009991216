import { useContext, useState, useEffect } from 'react';
import styles from './CreateCollection.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import { create, update } from 'services/collections/collection';
import { uploadFile } from 'services/files';
import UserContext from 'context/UserContext';
import { isVideo } from 'utils/supportedFiles';
import WithLoadingBackdropAndErrorSnack from 'HOCS/WithLoadingBackdropAndErrorSnack';
import { useTranslation } from 'react-i18next';
import { getSingByProvider } from 'services/user';
import Card from '@mui/material/Card';
import { getCollectionByKey } from "services/collection/collections";
import NewFormDynamic from 'components/NewFormDynamic/NewFormDynamic';
import { getAllBlockchain } from 'services/blockchain';
import { useFetch } from 'hooks/useFetch';
import { BreadCrumbsSaveData } from 'components/BreadCrumbs/BreadCrumbs';
import { DEFAULT_NFT_ATTRS_RESTRICTIONS } from 'services/collection/constants';
import * as Yup from "yup";
import { findDefaultAttrs } from 'services/collection/defaultNFTAttrs';

const ignoreValidation = {
  blockchains: false,
  name: false,
  attributes: true,
  description: false,
  summary: false,
  image: false,
  banner: false
}

const initValuesNew = {
  blockchains: 'select',
  name: 'string_short',
  attributes: 'pair_list',
  description: 'rich_text_area',
  summary: 'string_long',
  image: 'multimedia',
  banner: 'multimedia'
}


export default WithLoadingBackdropAndErrorSnack(function CreateCollectionForm({ setError, setIsLoading }) {
  const { user, executeFunctionIfUserIsConnected } = useContext(UserContext);
  const [loadingForm, setLoadingForm] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('collections');
  const { t: t_form } = useTranslation('form');
  const { collectionKey } = useParams();
  const isEditMode = !!collectionKey;
  const [collection, setCollection] = useState();
  const { data: blockchains, loading: loadingBlockchains } = useFetch(getAllBlockchain, { params: { limit: 30, page: 0, order: 'name', testnet: true } });

  const goCollectionOverview = (collectionKey, name) => {
    localStorage.removeItem('selected')
    const value = {
      name: name,
      collection_id: collectionKey
    }
    BreadCrumbsSaveData("collectionKey", value);
    navigate(`/dashBoard/collections/${collectionKey}`);
  }

  const getCollection = async (collectionKey) => {
    const params = { collection_key: collectionKey, limit: 1, page: 0, order: 'created' };
    const collection = await getCollectionByKey({ params })
    const defaultAttrs = await findDefaultAttrs({ params: { collection_key: collectionKey } });
    return {
      banner: collection.banner_url,
      thumbnail: collection.thumb_url,
      name: collection.name,
      description: collection.description,
      summary: collection.summary,
      attributes: defaultAttrs,
      blockchain: {
        label: collection.blockchain.blockchain_name,
        value: collection.blockchain.blockchain_name
      },
    }
  }

  useEffect(() => {
    if (isEditMode) {
      setIsLoading(true);
      getCollection(collectionKey).then(collection => {
        setCollection(collection);
      }).catch(e => {
        console.error(e);
        setError("Sorry, something went wrong fetching the collection.");
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [isEditMode]);

  const handleSubmit = async (values, { setSubmitting }) => {
    await executeFunctionIfUserIsConnected(async()=>{
      setIsLoading(true);
      setLoadingForm(true);
      try {
        if (isEditMode) {
          await updatecollection(values)
          setLoadingForm(false)
          setIsLoading(false);
          goCollectionOverview(collectionKey, values.name);
        } else {
          const collection = await createCollection(values);
          setLoadingForm(false);
          goCollectionOverview(collection.data[0].collection_key, values.name);
        }
      } catch (error) {
        console.log("error: ", error);
        setError("Sorry, something went wrong. Please try again later");
      } finally {
        setLoadingForm(false)
        setIsLoading(false);
        setSubmitting(false);
      }
    },
    ()=>{
      setLoadingForm(false)
      setIsLoading(false);
      setSubmitting(false);
    });
  }

  const updatecollection = async (values) => {
    const thumb_url = await uploadFile(values.image);
    const banner_url = await uploadFile(values.banner);
    const blockchainName = values.blockchains[0].value;

    const newCollection = {
      name: values.name,
      collection_key: collectionKey,
      description: values.description,
      summary: values.summary,
      thumb_url: thumb_url,
      banner_url: banner_url,
      is_video: false,
      blockchain_name: blockchainName,
      attributes: formatAttributes(values.attributes),
    }
    await update({collections: [newCollection]})

  }

  const createCollection = async (values) => {
    const thumb_url = await uploadFile(values.image);
    const banner_url = await uploadFile(values.banner);
    const { signature } = await getSingByProvider(user, user.typeWallet);
    return await create({
      address_collectible: signature,
      project_key: signature,
      transaction: signature,
      only_domain: 1,
      status: 1,
      imported: 0,
      standard: 'ERC721',
      tags: [],
      categories: [],
      social_networks: [],
      files_url: [],
      commission: [],
      external_url: '',
      description: values.description,
      summary: values.summary,
      name: values.name,
      thumb_url: thumb_url,
      banner_url: banner_url,
      is_video: isVideo(values.image) ? 1 : 0,
      domain: process.env.REACT_APP_DOMAIN,
      action: 'CREATED_PROJECT',
      blockchain_name: values?.blockchains,
      thumb_second: -1,
      extra_details: '',
      default_nft_attrs: formatAttributes(values.attributes),
    });
  }

  const formatAttributes = (attributes) => {
    if (!attributes || !attributes.length) return [];
    return attributes.map(attr => {
      return {
        name: attr.name,
        value: attr.value,
        restriction_level: DEFAULT_NFT_ATTRS_RESTRICTIONS.REQUIRED,
      }
    })
  }

  const inputDescriptions = {
    blockchains: t("inputDescriptions.blockchain"),
    name: t("inputDescriptions.name"),
    attributes:t("inputDescriptions.name"),
    description: t("inputDescriptions.description"),
    summary: t("inputDescriptions.summary")
  };
  

  const validations = {
    attributes: Yup.array()
    .test('no-duplicates', t_form(`attributes.duplicate_list`), list => {
      const names = list?.map(item => item?.name?.toLowerCase());
      const result = names?.length >= 0 && names.length === new Set(names).size
      return result;
    })
  }

  if (!collection && isEditMode) return <div>Loading...</div>;

  return (
    <section className={styles.contentSection} >
      <Card
        sx={{
          padding: '1.5rem'
        }}
      >
        <NewFormDynamic
          enableReinitialize={true}
          initValues={initValuesNew}
          inputDescriptions={inputDescriptions}
          ignoreValidation={ignoreValidation}
          initStateWithValues={
            isEditMode ?
              {
                blockchains: {
                  values: collection?.blockchain.label || "",
                  options: blockchains
                },
                name: collection?.name,
                description: collection?.description,
                summary: collection?.summary,
                image: collection?.thumbnail,
                banner: collection?.banner,
                attributes: collection?.attributes
              }
              :
              {
                blockchains: {
                  value: "",
                  options: blockchains
                }
              }}
          loading={loadingForm || loadingBlockchains}
          onSubmit={handleSubmit}
          t={t}
          handleCancel={() => goCollectionOverview(collectionKey)}
          textButtons={{
            submit: isEditMode ? t("edit.form.send") : t("create.form.send"),
            cancel: t("create.form.cancel")
          }}
          textLabels={{
            blockchains: t("create.form.blockchains"),
            name: t("create.form.name"),
            description: t("create.form.description"),
            summary: t("create.form.summary"),
            attributes: t("create.form.default_attributes"),
            image: t("create.form.image"),
            banner: t("create.form.banner")
          }}
          textPlaceholders={{
            blockchains: t("create.form.blockchains"),
            name: t("create.form.name"),
            attributes: t("create.form.default_attributes"),
            description: t("create.form.description"),
            summary: t("create.form.summary"),
            image: t("create.form.image"),
            banner: t("create.form.banner")
          }}
          configMultimedia={{
            image: {
              styles: {
                width: '200px',
                height: '200px',
                fontSize: '0.8rem',
              }
            },
            banner: {
              styles: {
                fontSize: '0.8rem',
              }
            }
          }}
          concatValidations={validations}
        />
      </Card>
    </section>
  );
});