import { useState, useEffect, useMemo } from "react";
import { isAddress } from "services/blockchain";
import PROJECT_STATUS from "utils/projectStatus";
import { 
  getAllCollections,
  getCollectionByKey,
  getCollectionByProjectKey
} from "api/collections.api";
import { getCollectionBenefits } from "services/collections/collection";
import useAPIFetchTemplate from "./useAPIFetchTemplate";

export const useCollectionBenefit = ({ colleciton_key, contract_key, limit, page, order, errorMessage="Failed to get rewards" }) => {
  const collectionBenefits = useMemo(() => getCollectionBenefits({ colleciton_key, contract_key, limit, page, order }), [colleciton_key, contract_key, limit, page, order]);
  const { data, loading, error } = useAPIFetchTemplate(collectionBenefits, errorMessage);

  return {
    collectionBenefits: data,
    loading,
    error
  }
}

export const useCollection = (collectionKey) => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const setCorrectStatus = (collections) => {
    return collections.map((collection) => {
      if (isAddress(collection.project_key)) {
        return {
          ...collection,
          status: PROJECT_STATUS.DEPLOYED
        }
      }
      if(!isAddress(collection.project_key) && parseInt(collection.collection_of) > 0) {
          return {
          ...collection,
          status: PROJECT_STATUS.PREMIT_CONFIRM
        }
      }
      return {
        ...collection,
        status: PROJECT_STATUS.EMPTY
      }
    })
  }

  const resolveCollection = async () => {
    let collections = [];
    if (collectionKey) {
      collections = await getCollectionByProjectKey(collectionKey);
    } else {
      collections = await getAllCollections();
    }
    collections = setCorrectStatus(collections);
    checkValidResponse(collections);
    return collectionKey ? collections[0] : collections;
  }

  const checkValidResponse = (collections) => {
    if (collectionKey && collections.length === 0) {
      throw new Error("Collection not found");
    }
  }

  useEffect(() => {
    setLoading(true);
    setError(null);
    resolveCollection().then((result) => {
      setCollections(result);
    }).catch((error) => {
      console.log(error);
      setError("An error occurred while getting the collections. Please try again");
    }).finally(() => {
      setLoading(false);
    });
  }, [collectionKey]);

  return {
    collections,
    loading,
    error
  };
}

export const useCollectionByCollectionKey = (collectionKey) => {
  const [collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const resolveCollection = async (collectionKey) => {
    try{
      setLoading(true);
      const collection = await getCollectionByKey(collectionKey);
      setCollection(collection)
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    resolveCollection(collectionKey)
  },[collectionKey]);


  return {
    collection,
    loading,
    error
  };
}