import React from "react";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { CardSkeleton } from "components/Cards/CustomizedCard";

export default function CardsContainer ({ isLoading, error, cards }) {
  return (
    <>
      <Grid container spacing={2}>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Something went wrong while fetching — <strong>Please try again later!</strong>
            </Alert>
          </Grid>
        )}
        {isLoading && (
          Array(6).fill().map((_, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <CardSkeleton />
            </Grid>
          ))
        )}
        {!isLoading && !error && cards.map((card, index) => (
          <Grid item xs={12} md={6} lg={4} key={ index }>
            { card }
          </Grid>
        ))
        }
      </Grid>
    </>
  );
}